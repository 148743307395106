import { APP_ENTRYPOINT } from "../../../config/config";
import { GetIndicators } from "../../../interface/indicators"
import { ResponseGenerator } from "../../../interface/Response";
import { request } from "../../request/Request";

function* getIndicators(value: GetIndicators) {
    const response: ResponseGenerator = yield request.get(
        `${APP_ENTRYPOINT}indicators`,
        {
            params : value
        }
    )   
    return response;
}

const indicator = {
    getIndicators,
}

export default indicator