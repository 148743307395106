import Axios from 'axios';
import { storageEngine } from '../../common/utility';
import { refreshAccessToken } from '../Apis/user/user';
import { navigator } from '../../navigation/navigator';
const request = Axios.create();
let reloadFlag = false;
// Add a request interceptor
request.interceptors.request.use(async (config) => {
  // Do something before request is sent
  const access_token = storageEngine.get('access_token');
  config.headers = {
    Authorization: `Bearer ${access_token.token}`,
  };
  return config;
});

function reloadOnce() {
  if (!reloadFlag) {
    reloadFlag = true;
    navigator.history.replace("/");
  }
}
// Add a response interceptor
request.interceptors.response.use(
  async (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    const user = storageEngine.get('user');
    if (user.roles.role === 'clientAdmin') {
      const access_token = storageEngine.get('access_token');
      fetch(`${process.env.REACT_APP_ENTRYPOINT}auth/userDetail`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${access_token.token}`,
        }),
      })
        .then((response) => response.json())
        .then((data: any) => {
          if (user.roles.role === 'clientAdmin' && data.data.roles.role === 'clientAdmin') {
            storageEngine.set('user', data.data);
            if (!data.data.is_plan_active && data.data.role_id ==="2") {
              navigator.history.replace('/package-selection');
            } 
          } else if (data.data.roles.role === 'group') {
            storageEngine.set("user", data.data);
          }
         
        });
    }
    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalReq = error.config;
    if (
      error?.response?.status === 401 &&
      !originalReq._retry &&
      originalReq.url !== 'auth/logout'
    ) {
      originalReq._retry = true;
      const access_token = await refreshAccessToken();
      originalReq.headers = {
        Authorization: `Bearer ${access_token}`,
      };
      request.defaults.headers = {
        Authorization: `Bearer ${access_token}`,
      };
      return new Promise((resolve, reject) => {
        request
          .request(originalReq)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    }
    if (error.response.status === 410 || error.response.status === 406) {
      storageEngine.unset('user');
      storageEngine.unset('access_token');
      storageEngine.unset('refresh_token');
      navigator.history.replace('/');
      window.sessionStorage.clear();
    }
    return Promise.reject(error);
  }
);
export { request };
