import { randomBGColor } from "../../common/utility";

interface propsInterface {
	profile: boolean;
	label: string;
	value: any;
	index: number;
	handleActiveState: any;
	activeChat: any;
}

function UserCard(props: propsInterface) {
	const getFirstLetter = () => {
		return props.label[0];
	};
	return (
		<button
			key={props.index}
			onClick={() => {
				props.handleActiveState("individual", props.index, props.value,props.label);
			}}
			className={`flex flex-row items-center hover:bg-gray-100 rounded-md p-4 user whitespace-no-wrap  ${
				props.activeChat === "individual-" + props.index
					? "individual-active"
					: ""
			}`}
		>
			{props.profile && (
				<div
					className={
						`flex items-center justify-center h-8 w-8 rounded-full` +
						randomBGColor()
					}
				>
					{getFirstLetter()}
				</div>
			)}
			<div className="ml-2 text-sm font-medium">{props.label}</div>
		</button>
	);
}

export default UserCard;
