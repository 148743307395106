/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Sidenav from './Sidenav';
import Topnav from './Topnav';
import { storageEngine } from '../../common/helper';
import { useDispatch, useSelector } from 'react-redux';
import MobileTopNav from './mobileTopNav/MobileTopNav';
import GroupList from '../groupList/GroupList';
import UserList from '../userList/UserList';
import DetailedGroups from '../DetailedGroups/DetailedGroups';
import SideNavTab from './sideNavTab';
import PageLoader from '../../components/Loader/PageLoader';
import ClientUserList from '../clientUserList/ClientUserList';
import SuperAdminAssessmentResults from '../SuperAdmin/SuperAdminAssessmentResults/SuperAdminAssessmentResults';
import SuperAdminIndividualUserList from '../SuperAdmin/SuperAdminIndividualUserList/SuperAdminIndividualUserList';
import SuperAdminBusinessUserList from '../SuperAdmin/SuperAdminBusinessUserList/SuperAdminBusinessUserList';
import groupAdminSlice from '../../redux/slices/groupAdmin.slice';
import Actions from '../Actions/Actions';
import Message from '../Message/Message';
const Error404 = lazy(() => import('../Error/Error404'));

const ClientDashboard = lazy(
  () => import('../ClientDashboard/ClientDashboard')
);

const Indicators = lazy(() => import('../Indicators/Indicators'));
const LearningLibrary = lazy(() => import('../LearningLibrary/LearningLibrary'));
const Subscriptions = lazy(
  () => import('../IndividualUsers/Subscriptions/Subscriptions')
);

const SuperUsers = lazy(() => import('../SuperAdmin/SuperUsers/SuperUsers'));
const ClientSettings = lazy(() => import('../ClientSettings/ClientSettings'));
const UserDetails = lazy(() => import('../UserDetails/UserDetails'));
const GroupAdminDashboard = lazy(
  () => import('../GroupAdminDashboard/GroupAdminDashboard')
);

const SuperDashboard = lazy(
  () => import('../SuperAdmin/Dashboard/SuperDashboard')
);
const SuperClients = lazy(() => import('../SuperAdmin/Clients/SuperClients'));
const ClientDetail = lazy(
  () => import('../SuperAdmin/ClientDetail/ClientDetail')
);
const Layout = (props: any) => {
  const [showSide, setShowSide] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;

  const user = storageEngine.get('user');
  const role = user && user.roles.role;

  const [tabSideShow, setTabSideShow] = useState<boolean>(false);

  useEffect(() => {
    dispatch(groupAdminSlice.actions.getRemainingUsers());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {}, [showSide]);

  const title = useSelector((state: any) => state.auth.topBatTitle);
  const isSideNav = useSelector((state: any) => state.auth.showSideNav);

  const closeSidenav = (newState: any) => {
    setShowSide(newState);
  };

  const opentabSideNav = (newState: any) => {
    setTabSideShow(newState);
  };

  const onPressHamBurger = async () => {
    setShowSide(!showSide);
  };

  return (
    <section
      className={`main-container flex w-full flex-col md:flex-row overflow-y-scroll overflow-x-hidden relative`}
      style={{
        height: title === 'message' ? '100vh' : 'auto',
        overflowY:title==='message'?'auto':'auto',
      }}
    >
      <div className={`flex md:hidden h-20 mobileTopNav`}>
        <MobileTopNav
          onPressHamBurger={onPressHamBurger}
          tabSideShow={tabSideShow}
        />
      </div>
      <SideNavTab
        title={title}
        tabSideNav={opentabSideNav}
        isSideNav={isSideNav}
        onPressTabHamburgerMenu={() => setTabSideShow(!tabSideShow)}
      />
      <Sidenav showSide={showSide} onChange={closeSidenav} />
      <div
        className={`right-col w-full md:w-full flex flex-col overflow-y-auto ${
          showSide && 'mobile-container'
        } ${!isSideNav && 'layoutside'}`}
      >
        <Topnav title={title} user={user} isHidden={ title==='message'? true:false } />
        <div className={`w-full flex flex-col ${showSide ? '' : ''}`}>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route exact path="/dashboard">
                {role && role === 'vibeonixAdmin' ? (
                  <SuperDashboard />
                ) : role && role === 'clientAdmin' ? (
                  <ClientDashboard />
                ) : (
                  <GroupAdminDashboard />
                )}
              </Route>
              <Route exact path="/userList">
                {role && role === 'vibeonixAdmin' ? (
                  <ClientUserList />
                ) : role && (role === 'clientAdmin' || role === 'group') ? (
                  <UserList />
                ) : null}
              </Route>
              <Route exact path="/userDetails/:id">
                <UserDetails {...props} />
              </Route>

              <Route exact path="/groupList">
                <GroupList />
              </Route>
              <Route exact path="/groupDetails/:id">
                <DetailedGroups {...props} />
              </Route>
              <Route exact path="/indicators">
                <Indicators />
              </Route>
              <Route exact path="/learningLibrary">
                <LearningLibrary />
              </Route>
              <Route exact path="/subscriptions">
                <Subscriptions />
              </Route>
              <Route exact path="/actions">
                <Actions />
              </Route>
              <Route exact path="/settings">
                <ClientSettings />
              </Route>
              <Route exact path="/message">
                <Message />
              </Route>
              <Route exact path="/superDashboard">
                <SuperDashboard />
              </Route>

              <Route exact path="/superClients">
                <SuperClients />
              </Route>

              <Route exact path="/superAdminAssessmentResults">
                <SuperAdminAssessmentResults />
              </Route>

              <Route exact path="/superAdminIndividualUserList">
                <SuperAdminIndividualUserList />
              </Route>

              <Route exact path="/SuperAdminBusinessUserList">
                <SuperAdminBusinessUserList />
              </Route>

              <Route exact path="/superUsers">
                <SuperUsers />
              </Route>

              <Route exact path="/superClientDetails/:id">
                <ClientDetail {...props} />
              </Route>

              <Route exact path="/*">
                <Error404 />
              </Route>
            </Switch>
          </Suspense>
        </div>
        <footer
          style={{ bottom: 0 }}
          className="w-full text-right py-3 px-4 flex items-center justify-end mb-1 absolute left-0 table-text-color text-sm font-normal footer-global"
        >
          © Copyright Vibeonix - All Rights Reserved.
        </footer>
      </div>
    </section>
  );
};
export default Layout;
