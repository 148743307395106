import React, { useState } from "react";

import { AsyncPaginate } from "react-select-async-paginate";

const DropDownWithDefault = (props: any) => {
    const { options, onFieldChange, label, fetchMoreData, placeholder, isLoading } = props
    const [value, setValue] = useState(null);
    const onChange = (e:any) => {
        setValue(e);
        onFieldChange(e);
    }
    const loadOptions = async (search:any, prevOptions:any) => {
        if (!isLoading) {
            fetchMoreData();
        }
        let filteredOptions;
        if (!search) {
            filteredOptions = options;
        } else {
            const searchLower = search.toLowerCase();

            filteredOptions = options.filter(({ labelVal }:any) =>
                labelVal.toLowerCase().includes(searchLower)
            );
        }

        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 5
        );
        return {
            options: slicedOptions,
            hasMore: true
        };
    };

    return (
        <div>

            <label
                className="text-secondary font-semibold font-Lexend-Regular"
                htmlFor={label} style={{ fontSize: 11, top: -5 }}
            >
                {label}
            </label>
            <AsyncPaginate
                isSearchable={false}
                maxMenuHeight={150}
                value={value}
                loadOptions={loadOptions}
                onChange={(e) => { onChange(e) }}
                placeholder={placeholder}
            />
        </div>
    );
};

export default DropDownWithDefault;