import { takeEvery, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ResponseGenerator } from '../../interface/Response';
import {
  InviteUser,
  GetUserList,
  BlockUser,
  GetUserByDocId,
  AssessmentsHistoryInerfase,
  GetUserWellbingCalender,
  GetUserAssessmentDetails,
  GetGropListForSortUserList,
  EmailInterface,
  ContactUs,
} from '../../interface/users';
import {
  GET_ASSESSMENTS_HISTORY,
  GET_USER_DETAILS,
  INVITE_USER,
  USER_LIST,
  GET_USER_WELLBEING_CALENDAR,
  GET_USER_ASSESSMENT_DETAILS,
  GET_GROUP_LIST_FOR_USER_LIST,
  BLOCK_USER,
  DELETE_USER,
  UPDATE_USER,
  RESEND_INVITE,
  CONTACT_US,
} from '../actions/user.action';
import Api from '../../services/Apis';
import userSlice from '../slices/user.slice';
import groupAdminSlice from '../slices/groupAdmin.slice';
import superAdminSlice from '../slices/superAdmin.slice';
import { GetSuperAdminIndividualUserList } from '../../interface/superAdmin';
import { navigator } from '../../navigation/navigator';

function* inviteUser(action: PayloadAction<InviteUser>) {
  try {
    // yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.user.inviteUser(
      action.payload
    );
    const userListParams: GetUserList = yield select(
      (state) => state.user.userFilterParams
    );
    const inviteResponse: ResponseGenerator = yield Api.user.getUserList(
      userListParams
    );
    yield put(userSlice.actions.setUserList(inviteResponse.data.data));
    const remainResponse: ResponseGenerator =
      yield Api.groupAdmin.getRemainingUsers();
    yield put(userSlice.actions.setInviteUser(false));
    toast.success('User Invited Successfully');
    yield put(
      groupAdminSlice.actions.setRemainingUsers(remainResponse.data.data)
    );
  } catch (err: any) {
    yield put(userSlice.actions.setInviteUser(false));
    toast.error(err?.response?.data?.message);
  }
}

function* getUserList(action: PayloadAction<GetUserList>) {
  try {
    const response: ResponseGenerator = yield Api.user.getUserList(
      action.payload
    );
    yield put(userSlice.actions.setUserList(response.data.data));
    yield put(groupAdminSlice.actions.getRemainingUsers());
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* blockUser(action: PayloadAction<BlockUser>) {
  try {
    const { doc_id, status, type } = action.payload;
    const response: ResponseGenerator = yield Api.user.blockUser({
      doc_id,
      status,
    });
    if (type === 'clientAdmin') {
      const userListParams: GetUserList = yield select(
        (state) => state.user.userFilterParams
      );
      const inviteResponse: ResponseGenerator = yield Api.user.getUserList(
        userListParams
      );
      yield put(userSlice.actions.setUserList(inviteResponse.data.data));

      const remainResponse: ResponseGenerator =
        yield Api.groupAdmin.getRemainingUsers();
      yield put(
        groupAdminSlice.actions.setRemainingUsers(remainResponse.data.data)
      );
    } else if(type !== 'groupAdmin') {
      const individualUserFilterParams: GetSuperAdminIndividualUserList =
        yield select((state) => state.superAdmin.individualUserFilterParams);
      const inviteResponse: ResponseGenerator =
        yield Api.superAdmin.getSuperAdminIndividualUserList(
          individualUserFilterParams
        );
      yield put(
        superAdminSlice.actions.setSuperAdminIndividualUserList(
          inviteResponse.data.data
        )
      );
    }
    yield put(userSlice.actions.setRefresh(true));
    if (action.payload.status === 'blocked') {
      toast.success('User has been Blocked');
    } else {
      toast.success('User has been Unlocked');
    }
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* deleteUser(action: PayloadAction<GetUserByDocId>) {
  try {
    const response: ResponseGenerator = yield Api.user.deleteUser({
      doc_id: action.payload.doc_id,
    });
    if (action.payload.type === 'individualUser') {
      const individualUserFilterParams: GetSuperAdminIndividualUserList =
        yield select((state) => state.superAdmin.individualUserFilterParams);
      const inviteResponse: ResponseGenerator =
        yield Api.superAdmin.getSuperAdminIndividualUserList(
          individualUserFilterParams
        );
      yield put(
        superAdminSlice.actions.setSuperAdminIndividualUserList(
          inviteResponse.data.data
        )
      );
    } else {
      const userListParams: GetUserList = yield select(
        (state) => state.user.userFilterParams
      );
      const inviteResponse: ResponseGenerator = yield Api.user.getUserList(
        userListParams
      );
      yield put(userSlice.actions.setUserList(inviteResponse.data.data));
      const remainResponse: ResponseGenerator =
        yield Api.groupAdmin.getRemainingUsers();
      yield put(
        groupAdminSlice.actions.setRemainingUsers(remainResponse.data.data)
      );
      if(action.payload.type === 'groupAdmin'){
        navigator.history.replace('/userList');
      }
    }
    toast.success('Successfully Deleted');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getUserDetails(action: PayloadAction<GetUserByDocId>) {
  try {
    const response: ResponseGenerator = yield Api.user.getUserByDocId(
      action.payload
    );
    yield put(userSlice.actions.setUserDetails(response.data.data));
  } catch (err: any) {}
}

function* getAssessmentsHistorys(
  action: PayloadAction<AssessmentsHistoryInerfase>
) {
  try {
    const response: ResponseGenerator = yield Api.user.getAssessmentsHistorys(
      action.payload
    );
    yield put(userSlice.actions.setAssessmentsHistorys(response.data.data));
  } catch (err: any) {}
}
function* getUserWellbeingCalendar(
  action: PayloadAction<GetUserWellbingCalender>
) {
  try {
    const response: ResponseGenerator = yield Api.user.getUserWellbeingCalendar(
      action.payload
    );
    yield put(userSlice.actions.setUserWellbeingCalendar(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}
function* getUserAssessmentDetails(
  action: PayloadAction<GetUserAssessmentDetails>
) {
  try {
    const response: ResponseGenerator = yield Api.user.getUserAssessmentDetails(
      action.payload
    );
    yield put(userSlice.actions.setUserAssessmentDetails(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}
function* getGropListForSortUserList(
  action: PayloadAction<GetGropListForSortUserList>
) {
  try {
    const response: ResponseGenerator =
      yield Api.user.getGropListForSortUserList(action.payload);
    yield put(userSlice.actions.setGropListForSortUserList(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}

function* updateUser(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator = yield Api.user.updateUser(
      action.payload
    );
    yield put(userSlice.actions.setUpdateUser(response.data.data));
    yield put(userSlice.actions.setRefresh(true));
    const userListParams: GetUserList = yield select(
      (state) => state.user.userFilterParams
    );
    const inviteResponse: ResponseGenerator = yield Api.user.getUserList(
      userListParams
    );
    yield put(userSlice.actions.setUserList(inviteResponse.data.data));
    toast.success('Successfully updated');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* resendInvite(action: PayloadAction<EmailInterface>) {
  try {
    const response: ResponseGenerator = yield Api.user.resendInvite(
      action.payload
    );
    yield put(userSlice.actions.setResendInvite(true));
    toast.success('Invitation resend successfully');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
    yield put(userSlice.actions.setResendInvite(true));
  }
}

function* contactUs(action: PayloadAction<ContactUs>) {
  try {
    const response: ResponseGenerator = yield Api.user.contactUs(
      action.payload
    );
    yield put(userSlice.actions.setContactUs(true));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
    yield put(userSlice.actions.setContactUs(true));
  }
}
function* userSaga() {
  yield takeEvery(INVITE_USER, inviteUser);
  yield takeEvery(RESEND_INVITE, resendInvite);
  yield takeEvery(USER_LIST, getUserList);
  yield takeEvery(GET_USER_DETAILS, getUserDetails);
  yield takeEvery(GET_ASSESSMENTS_HISTORY, getAssessmentsHistorys);
  yield takeEvery(GET_USER_WELLBEING_CALENDAR, getUserWellbeingCalendar);
  yield takeEvery(GET_USER_ASSESSMENT_DETAILS, getUserAssessmentDetails);
  yield takeEvery(GET_GROUP_LIST_FOR_USER_LIST, getGropListForSortUserList);
  yield takeEvery(BLOCK_USER, blockUser);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(CONTACT_US, contactUs);
}

export default userSaga;
