import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface propsInterface {
	count: number;
}

const SkeletonLoaderLine = (props: propsInterface) => {
	return (
		<div className="p-5">
			<Skeleton
				style={{ width: "100%", marginTop: 10, marginBottom: 10, height:30, borderRadius:7 }}
				count={props.count}
			/>
		</div>
	);
};

export default SkeletonLoaderLine;
