import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import editClose from '../../../../assets/img/icons/editClose.svg';
import './styles.scss';
import { Formik } from 'formik';
import moment from 'moment';
import InputBox from '../../../FormikFields/InputBox/InputBox';
import Button from '../../../FormikFields/Button/Button';
import CancelButton from '../../../../components/button/Button';
import DropDownWithDefault from '../../../Dropdown/DropDownWithDefault';
import clientAdminSlice from '../../../../redux/slices/clientAdmin.slice';
import { storageEngine } from '../../../../common/helper';
import ButtonLoader from '../../../Loader/ButtonLoader';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  name: '',
  createdAt: '',
  lastAssessments: '',
};
const UserEditModal = ({ closeModal, hasModalView, selectedItem }: any) => {
  const formikRef = useRef<any>(null);

  const user = storageEngine.get('user');
  const user_id = user?.id;

  const [prvGroup, setprvGroup] = useState<any>([]);

  const dispatch = useDispatch();
  const [groupListFilterData, setGroupListFilterData] = useState({
    limit: 5,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
  });

  const [options, setOptions] = useState([]);
  const [newGroupId, setNewGroupId] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    const groupNameArray =
      selectedItem.group_details &&
      selectedItem.group_details.length &&
      selectedItem.group_details.filter(
        (item: any) => item.groups.group_manager_id !== user_id
      );
    formikRef?.current?.setValues({
      createdAt: moment(selectedItem.createdAt).format('MMMM DD, YYYY'),
      lastAssessments:
        selectedItem.assessments && selectedItem.assessments.length
          ? moment(selectedItem.assessments[0].createdAt).format(
              'MMMM DD, YYYY h:mm A'
            )
          : '--',
      email: selectedItem.email,
      firstName: selectedItem.firstName,
      lastName: selectedItem.lastName,
      name:
        groupNameArray &&
        groupNameArray.length &&
        groupNameArray[0].groups.name,
    });
    setprvGroup(groupNameArray);
    // setNewGroupId(groupNameArray?.id)
  }, []);
  useEffect(() => {
    dispatch(
      clientAdminSlice.actions.groupsWithGroupManager(groupListFilterData)
    );
  }, []);

  const groupList = useSelector(
    (state: any) => state.clientAdmin?.groupsWithGroupManagerList?.groups
  );
  const groupListCount = useSelector(
    (state: any) => state.clientAdmin?.groupsWithGroupManagerList?.count
  );
  const userGroupChangeLoading = useSelector(
    (state: any) => state.clientAdmin?.userGroupChangeLoading
  );
  const userGroupChangeModal = useSelector(
    (state: any) => state.clientAdmin?.userGroupChangeModal
  );
  const groupListLoading = useSelector(
    (state: any) => state.clientAdmin?.groupsWithGroupManagerListLoading
  );

  const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (groupList && groupList.length) {
      setOptions([]);
      groupList.map((grp: any) => {
        const label = capitalizeFirstLetter(grp.name);  
        const value = parseInt(grp.id);
        setOptions((oldArray): any => [...oldArray, { label, value }]);
      });
    }
  }, [groupList]);

  const fetchMoreData = () => {
    let args = { ...groupListFilterData, page: groupListFilterData.page + 1 };
    setGroupListFilterData(args);
    dispatch(clientAdminSlice.actions.groupsWithGroupManager(args));
  };
  const handleEdit = () => {
    setIsSubmit(true);
    dispatch(
      clientAdminSlice.actions.userGroupChange({
        doc_id: selectedItem.doc_id,
        prev_group_id: parseInt(prvGroup[0]?.group_id),
        new_group_id:
          newGroupId === null ? parseInt(prvGroup[0]?.group_id) : newGroupId,
      })
    );
  };
  useEffect(() => {
    if (isSubmit && !userGroupChangeLoading && !userGroupChangeModal) {
      closeModal();
    }
  }, [userGroupChangeLoading, userGroupChangeModal]);
  return (
    <div>
      <div
        className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          hasModalView ? 'block' : 'hidden'
        }`}
      >
        <div className='popup-container absolute w-full mb-6 top-6'>
          <img
            className='img z-10 absolute cursor-pointer'
            onClick={closeModal}
            src={editClose}
            alt=""
          />
          <div className='popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden'>
            <div className='popup-title font-bold text-2xl flex justify-center'>
              User Edit
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={handleEdit}
            >
              {({ handleSubmit, values, isValid }) => (
                <div>
                  <div className='my-4'>
                    <InputBox
                      name='firstName'
                      label='First Name'
                      placeholder=''
                      value={values.firstName}
                      disabled={true}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='lastName'
                      label='Last Name'
                      placeholder=''
                      value={values.lastName}
                      disabled={true}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='email'
                      label='Email'
                      placeholder=''
                      value={values.email}
                      disabled={true}
                    />
                  </div>
                  <div className='my-4'>
                    <DropDownWithDefault
                      options={options}
                      onFieldChange={(e: any) => {
                        setNewGroupId(e.value);
                      }}
                      fetchMoreData={fetchMoreData}
                      totalCount={groupListCount}
                      label={`Group Name`}
                      isLoading={groupListLoading}
                      placeholder={
                        prvGroup &&
                        prvGroup.length &&
                        capitalizeFirstLetter(prvGroup[0]?.groups.name)
                      }
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='createdAt'
                      label='Created On'
                      placeholder=''
                      value={values.createdAt}
                      disabled={true}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='lastAssessments'
                      label='Last Assessment'
                      placeholder=''
                      value={values.lastAssessments}
                      disabled={true}
                    />
                  </div>

                  <div className='flex justify-center gap-4 w-full'>
                    <div className=''>
                      <CancelButton
                        onPress={closeModal}
                        value={`Cancel`}
                        bgColor='#fff'
                        borderColor='#A6A9B4'
                        color='#A6A9B4'
                        disabled={false}
                        buttonWidth={'123px'}
                        buttonHeight={'38px'}
                      />
                    </div>

                    <div className=''>
                      <Button
                        onClick={handleSubmit}
                        text={
                          userGroupChangeLoading ? <ButtonLoader /> : 'Save'
                        }
                        active={isValid}
                        buttonWidth={'123px'}
                        buttonHeight={'38px'}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditModal;
