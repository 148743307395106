import { APP_ENTRYPOINT } from '../../../config/config';
import {
  CourseDetailsDto,
  UpdateClientInfo,
  UserGroupChangeDTO,
} from '../../../interface/clientAdmin';
import { ResponseGenerator } from '../../../interface/Response';
import { request } from '../../request/Request';

function* getQuickInfo(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/quickInfo`,
    {
      params: value,
    }
  );
  return response;
}
function* getAlluserWellBeing(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/userWellBeing`,
    {
      params: value,
    }
  );
  return response;
}
function* getuserEnergyState(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/getAllUsersEnergyState`,
    {
      params: value,
    }
  );
  return response;
}
function* getGroupWellBeingPattern(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/groupWellBeingPattern`,
    {
      params: value,
    }
  );
  return response;
}
function* getGroupWellBeingPatternCustomDate(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/groupWellBeingPatternCustom`,
    {
      params: value,
    }
  );
  return response;
}
function* getGroupList(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groups/getAllGroups`,
    {
      params: value,
    }
  );
  return response;
}

function* getAllGroupList() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/getGroupList`
  );
  return response;
}

function* getuserEmotionalInfo(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/userEmotionalInfo`,
    {
      params: value,
    }
  );
  return response;
}
function* updateClientAdminAccount(value: UpdateClientInfo) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}clientAdmin/updateMyAccount`,
    value
  );
  return response;
}

function* getRemainingGroupsAndUsers() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/remainingGroupsAndUsers`
  );
  return response;
}
function* userGroupChange(value: UserGroupChangeDTO) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}clientAdmin/userGroupChange`,
    value
  );
  return response;
}

function* getSettings() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/settings`
  );
  return response;
}
function* exportUsers() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/exportUsers`
  );
  return response;
}
function* handleUserDetailExport() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/export/business/users`
  );
  return response;
}
function* groupsWithGroupManager(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groups/groupsWithGroupManager`,
    {
      params: value,
    }
  );
  return response;
}

function* packageSelection() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}packages/select/plan?plan_id=2`
  );
  return response;
}

function* getSavedCards() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}payments/listAllSavedCards`
  );
  return response;
}

function* defaultCard(data: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}payments/chooseDefaultCard`,
    data
  );
  return response;
}
function* deleteCard(data: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}payments/deleteMyCard`,
    data
  );
  return response;
}

function* subscriptionDetail() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/subscriptionDetail`
  );
  return response;
}

function* userDetail() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}auth/userDetail`
  );
  return response;
}

export const activateFreeTrial = async () => {
  try {
    const response = await request.put(
      `${APP_ENTRYPOINT}packages/activateFreeTrial`
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

function* cancelSubscription() {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}clientAdmin/cancelSubscription`
  );
  return response;
}

function* invoices(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/invoices`,
    {
      params: value,
    }
  );
  return response;
}

function* getRemainingUsers() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groupAdmin/remainingUsers`
  );
  return response;
}

function* getCourseDetails() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/courseDetails`
  );
  return response;
}

function* updateCourseDetails(value: CourseDetailsDto) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}clientAdmin/courseDetails`,
    value
  );
  return response;
}

function* getCourseDefaultDetails() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/defaultCourseDetails`
  );
  return response;
}

const clientAdmin = {
  getQuickInfo,
  getAlluserWellBeing,
  getuserEnergyState,
  getGroupWellBeingPattern,
  getGroupList,
  getRemainingUsers,
  getAllGroupList,
  getuserEmotionalInfo,
  updateClientAdminAccount,
  getRemainingGroupsAndUsers,
  userGroupChange,
  getSettings,
  exportUsers,
  handleUserDetailExport,
  groupsWithGroupManager,
  packageSelection,
  getSavedCards,
  defaultCard,
  deleteCard,
  subscriptionDetail,
  userDetail,
  cancelSubscription,
  getGroupWellBeingPatternCustomDate,
  invoices,
  getCourseDetails,
  updateCourseDetails,
  getCourseDefaultDetails
};

export default clientAdmin;
