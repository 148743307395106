import React from 'react';
import { IndividualUserCongrats } from '../../../assets/images';
import './MySubscriptionStyle.scss';
import Button from '../../../components/button/Button';
import { navigator } from '../../../navigation/navigator';

const UpgradeCongratulationsPage = () => {
  return (
    <div className='flex gap-5 px-10 lg:px-20 py-20 justify-between w-full'>
      <div className='flex flex-col gap-10 justify-center items-start'>
        <h2 className='vibeonix-color font-semibold text-4xl'>
          Congratulations!
        </h2>
        <h4 className='text-xl font-normal text-gray-700'>
          You are now subscribed to the yearly plan.<br/> Enjoy the experience using
          Vibeonix!
        </h4>
        <div>
          <Button onPress={() => {navigator.history.replace('/individualUser/my-subscription')}} value={'Back To Home'} />
        </div>
      </div>
      <div className='hidden md:block'>
        <img src={IndividualUserCongrats} className='congrats-icon' alt=''/>
      </div>
    </div>
  );
};

export default UpgradeCongratulationsPage;
