import { takeEvery,put} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ResponseGenerator } from '../../interface/Response';
import superAdmin from '../slices/superAdmin.slice';
import {
  GetSuperAdminClientList,
  GetClientQuickInfo,
  GetWellbeing,
  GetEnergyState,
  GetDashboardEnergyState,
  BlockUser,
  GetSubscriptionStatus,
  CopyEmail,
  DeleteClientAdmin,
  GetSuperAdminBusinessUserList,
  GetSuperAdminIndividualUserList,
  GetSuperAdminLearningLibrary,
  GetSuperAdminTopics
} from '../../interface/superAdmin';
import {
  GET_SUPER_ADMIN_CLIENT_LIST,
  GET_CLIENT_QUICK_INFO,
  GET_CLIENT_DETAILS_VIEW,
  GET_CLIENT_USERS_WELL_BEING,
  GET_CLIENT_USERS_ENERGY_STATE,
  GET_CLIENT_USER_LIST,
  GET_SUPER_ADMIN_USER_LIST,
  EXPORT_USERS,
  EXPORT_CLIENTS,
  GET_DASHBOARD_QUICK_INFO,
  GET_DASHBOARD_CLIENT_STATUS,
  GET_ALL_USERS_ENERGY_STATE,
  BLOCK_USER,
  BLOCK_BUSSINESS_USER,
  GET_SUBSCRIPTION_STATUS,
  COPY_EMAIL,
  GET_ALL_ORG_LIST,
  DELETE_CLIENT_ADMIN,
  CHANGE_SUBSCRIPTION_END_DATE,
  GET_SUPER_ADMIN_INDIVIDUAL_USER_LIST,
  GET_SUPER_ADMIN_BUSINESS_USER_LIST,
  DELETE_BUSINESS_USER,
  EXPORT_BUSINESS_USERS,
  EXPORT_INDIVIDUAL_USERS,
  GET_SUPER_ADMIN_LEARNING_LIBRARY,
  GET_SUPER_ADMIN_TOPICS,
} from '../actions/superAdmin.action';

import Api from '../../services/Apis';
import loaderSlice from '../slices/loader.slice';
import superAdminSlice from '../slices/superAdmin.slice';

function* getSuperAdminClientList(
  action: PayloadAction<GetSuperAdminClientList>
) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getSuperAdminClientListService(action.payload);
    yield put(
      superAdminSlice.actions.setSuperAdminClientList(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getSuperAdminUserList(
  action: PayloadAction<GetSuperAdminClientList>
) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getSuperAdminUserList(action.payload);
    yield put(
      superAdminSlice.actions.setSuperAdminUserList(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* getSuperAdminIndividualUserList(
  action: PayloadAction<GetSuperAdminIndividualUserList>
) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getSuperAdminIndividualUserList(action.payload);
    yield put(
      superAdminSlice.actions.setSuperAdminIndividualUserList(
        response.data.data
      )
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* getSuperAdminBusinessUserList(
  action: PayloadAction<GetSuperAdminBusinessUserList>
) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getSuperAdminBusinessUserList(action.payload);
    yield put(
      superAdminSlice.actions.setSuperAdminBusinessUserList(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* getClientQuickInfo(action: PayloadAction<GetClientQuickInfo>) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getClientQuickInfoService(action.payload);
    yield put(superAdminSlice.actions.setClientQuickInfo(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getClientDetailsView(action: PayloadAction<GetClientQuickInfo>) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getClientDetailsViewService(action.payload);
    yield put(superAdminSlice.actions.setClientDetailsView(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getClientUsersWellbeing(action: PayloadAction<GetWellbeing>) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getClientUsersWellbeingService(action.payload);
    yield put(
      superAdminSlice.actions.setClientUsersWellbeing(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getClientUsersEnergyState(action: PayloadAction<GetEnergyState>) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getClientUsersEnergyStateService(action.payload);
    yield put(
      superAdminSlice.actions.setClientUsersEnergyState(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getDashboardQuickInfo(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getDashboardQuickInfoService(action.payload);
    yield put(
      superAdminSlice.actions.setDashboardQuickInfo(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getDashboardClientStatus() {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getDashboardClientStatusService();
    yield put(
      superAdminSlice.actions.setDashboardClientStatus(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getAllUsersEnergyState(
  action: PayloadAction<GetDashboardEnergyState>
) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getAllUsersEnergyStateService(action.payload);
    yield put(
      superAdminSlice.actions.setAllUsersEnergyState(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* getClientUserList(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.superAdmin.getClientUserList(
      action.payload
    );
    yield put(superAdmin.actions.setClientUserList(response.data.data));
  } catch (err: any) {}
}

function* getAllOrgList(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator =
      yield Api.superAdmin.getSuperAdminOrgList();
    yield put(superAdmin.actions.setAllOrgList(response.data.data.orgList));
  } catch (err: any) {}
}

function* exportUsers() {
  try {
    const response: ResponseGenerator = yield Api.superAdmin.exportUsers();
    yield put(superAdmin.actions.setexportUsers(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}

function* exportBusinessUser() {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.exportBusinessUser();
    yield put(superAdmin.actions.setexportBusinessUsers(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}

function* exportIndividualUser() {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.exportIndividualUser();
    yield put(superAdmin.actions.setexportIndividualUsers(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}

function* exportClients(action: any) {
  try {
    const response: ResponseGenerator = yield Api.superAdmin.exportClients(
      action.payload
    );
    yield put(superAdmin.actions.setexportClients(response.data.data));
  } catch (err: any) {
    console.log(err);
  }
}

function* blockUser(action: PayloadAction<BlockUser>) {
  try {
    const data = {
      doc_id: action.payload.doc_id,
      status: action.payload.status,
    };
    const response: ResponseGenerator = yield Api.user.blockUser(data);
    if (!response.data.error) {
    }
    yield put(
      superAdminSlice.actions.getSuperAdminClientList(action.payload.filterData)
    );
    if (action.payload.status === 'blocked') {
      toast.success('User has been Blocked');
    } else {
      toast.success('User has been Unlocked');
    }
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* blockBussinessUser(action: PayloadAction<BlockUser>) {
  try {
    const data = {
      doc_id: action.payload.doc_id,
      status: action.payload.status,
    };
    const response: ResponseGenerator = yield Api.user.blockUser(data);
    if (!response.data.error) {
    }
    yield put(
      superAdminSlice.actions.getSuperAdminBusinessUserList(
        action.payload.filterData
      )
    );
    if (action.payload.status === 'blocked') {
      toast.success('User has been Blocked');
    } else {
      toast.success('User has been Unblocked');
    }
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getSubscriptionStatus(action: PayloadAction<GetSubscriptionStatus>) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getSubscriptionStatus(action.payload);
    yield put(
      superAdminSlice.actions.setSubscriptionStatus(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* copyEmails(action: PayloadAction<CopyEmail>) {
  try {
    const response: ResponseGenerator = yield Api.superAdmin.copyEmails(
      action.payload
    );
    yield put(superAdminSlice.actions.setcopiedEmail(response.data.data));
    // yield window.location.reload(true);
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* deleteClientAdmin(action: PayloadAction<DeleteClientAdmin>) {
  const data = {
    doc_id: action.payload.doc_id,
  };
  try {
     const response: ResponseGenerator = yield Api.superAdmin.deleteClientAdmin(
      data
    );
    if (!response.data.error) {
    }
    yield put(superAdminSlice.actions.setcopiedEmail(response.data.data)); 
    toast.success('Client has been Deleted');
    // yield put(superAdminSlice.actions.setcopiedEmail(response.data.data));
    // yield window.location.reload(true);
    yield put(superAdminSlice.actions.setClientDeleted(true as any));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* changeSubscriptionEndDate(action: any) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.changeSubscriptionEndDate(action.payload.data);
    if (!response.data.error) {
    }
    yield put(
      superAdminSlice.actions.getClientDetailsView({
        doc_id: action.payload.doc_id,
      })
    );
    // toast.success('Client has been Deleted');
    // yield put(superAdminSlice.actions.setcopiedEmail(response.data.data));
    // yield window.location.reload(true);
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* deleteBusinessUser(action: any) {
  try {
    const data = {
      doc_id: action.payload.doc_id,
    };
    const response: ResponseGenerator = yield Api.user.deleteUser(data);
    if (!response.data.error) {
    }
    yield put(
      superAdminSlice.actions.getSuperAdminBusinessUserList(
        action.payload.filterData
      )
    );
    toast.success('User has been Deleted');
    // yield put(superAdminSlice.actions.setcopiedEmail(response.data.data));
    // yield window.location.reload(true);
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getSuperAdminLearningLibrary(
  action: PayloadAction<GetSuperAdminLearningLibrary>
) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getSuperAdminLearningLibrary(action.payload);
    yield put(
      superAdminSlice.actions.setSuperAdminLearningLibrary(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getSuperAdminTopics(action: any) {
  try {
    const response: ResponseGenerator =
      yield Api.superAdmin.getSuperAdminTopics(action.payload);
    yield put(
      superAdminSlice.actions.setSuperAdminTopics(response.data.data)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* superAdminSaga() {
  yield takeEvery(GET_SUPER_ADMIN_CLIENT_LIST, getSuperAdminClientList);
  yield takeEvery(
    GET_SUPER_ADMIN_INDIVIDUAL_USER_LIST,
    getSuperAdminIndividualUserList
  );
  yield takeEvery(
    GET_SUPER_ADMIN_BUSINESS_USER_LIST,
    getSuperAdminBusinessUserList
  );
  yield takeEvery(GET_SUPER_ADMIN_USER_LIST, getSuperAdminUserList);
  yield takeEvery(GET_CLIENT_USER_LIST, getClientUserList);
  yield takeEvery(GET_ALL_ORG_LIST, getAllOrgList);
  yield takeEvery(GET_CLIENT_QUICK_INFO, getClientQuickInfo);
  yield takeEvery(GET_CLIENT_DETAILS_VIEW, getClientDetailsView);
  yield takeEvery(GET_CLIENT_USERS_WELL_BEING, getClientUsersWellbeing);
  yield takeEvery(GET_CLIENT_USERS_ENERGY_STATE, getClientUsersEnergyState);
  yield takeEvery(EXPORT_USERS, exportUsers);
  yield takeEvery(EXPORT_BUSINESS_USERS, exportBusinessUser);
  yield takeEvery(EXPORT_INDIVIDUAL_USERS, exportIndividualUser);
  yield takeEvery(EXPORT_CLIENTS, exportClients);
  yield takeEvery(GET_DASHBOARD_QUICK_INFO, getDashboardQuickInfo);
  yield takeEvery(GET_DASHBOARD_CLIENT_STATUS, getDashboardClientStatus);
  yield takeEvery(GET_ALL_USERS_ENERGY_STATE, getAllUsersEnergyState);
  yield takeEvery(BLOCK_USER, blockUser);
  yield takeEvery(BLOCK_BUSSINESS_USER, blockBussinessUser);
  yield takeEvery(GET_SUBSCRIPTION_STATUS, getSubscriptionStatus);
  yield takeEvery(COPY_EMAIL, copyEmails);
  yield takeEvery(DELETE_CLIENT_ADMIN, deleteClientAdmin);
  yield takeEvery(DELETE_BUSINESS_USER, deleteBusinessUser);
  yield takeEvery(CHANGE_SUBSCRIPTION_END_DATE, changeSubscriptionEndDate);
  yield takeEvery(GET_SUPER_ADMIN_LEARNING_LIBRARY, getSuperAdminLearningLibrary);
  yield takeEvery(GET_SUPER_ADMIN_TOPICS, getSuperAdminTopics);
}

export default superAdminSaga;
