import React from 'react';
import { VictoryPie, VictoryTooltip, VictoryLabel } from 'victory';

const HTMLFlyOut = (props: any) => {
  const { x, y, datum } = props;
  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject x={x - 40} y={y - 50} width='140' height='100'>
        <div className='bg-white border p-4'>
          <div className='w-4 h-4' style={{ backgroundColor: datum.color }} />
          <div className='pt-1'>{`${datum.label}`}</div>
          {props.flag === 'superAdmin'
            ? <div className='pt-1'>{`${datum.count}`}</div>
            : <div className='font-bold text-xl'>{`${Math.round(datum.y * 100)}%`}</div>
          }
        </div>
      </foreignObject>
    </g>
  );
};

const VictoryDonutChart = ({ data, centerLabel, bottomLabels, value, flag, isWellBeing=false, isAverage=true, bottomValue, bottomRightLabel }: any) => {
  return (
    <div>
      <svg viewBox='0 50 300 290' className='overflow-visible'>
        <VictoryPie
          data={data}
          padAngle={({ datum }) => datum.y}
          innerRadius={110}
          labels={() => ' '}
          width={300}
          height={400}
          style={{
            data: { fill: (d) => d.datum.color },
            labels: { display: 'none' },
          }}
          standalone={false}
        />
        {centerLabel && (
          <VictoryLabel
            textAnchor='middle'
            lineHeight={[2,1]}
            // className={`${['victoryStyle','victorysubStyle']}`}
            style={[{ fontSize: '20px',color:' #3A9EA5',fill:'#3A9EA5',fontFamily:'Lexend-Regular',fontWeight:'bolder'},{fontSize: '14px',color:' #444C63',fill:'#444C63',fontFamily:'Lexend-Regular',fontWeight:'bolder' }]}
            x={150}
            y={200}
            text={`${centerLabel} \n ${isWellBeing ? value : `${centerLabel === 1  ? value :`${value}s`}`} `}
          />
        )}
      </svg>

      <div  className='grid grid-cols-2'>
      <div className='grid  gap-4'>
        {bottomLabels.map((item: any) => (
          <div key={item.label} className='flex flex-row '>
            <div className='w-4 h-4' style={{ backgroundColor: item.color }} />
            <div className='ml-2 text-xs font-Lexend-Regular label-victory-style'>{item.label}</div>
          </div>
        ))}
      </div>
      {!isAverage && <div  className='flex '>
        <div className='grid grid-cols-3 gap-4'>
          <div></div>
          <div></div>
          <div className='grid grid-rows-4 flex flex-col items-center'>
            <div></div>
            <div></div>
            <div></div>
          <div  className=' flex flex-col items-center '>
            <div className='victorysubStyle'>{`${bottomRightLabel===1 ? bottomValue :`${bottomValue}s`}`}</div>
            <div className='victoryStyle'>{`${bottomRightLabel}`}</div>

          </div>
          </div>
          </div>
      </div>}
      </div>
    </div>
  );
};

export default VictoryDonutChart;