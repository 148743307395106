/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  GetClientQuickInfo,
  GetSuperAdminClientList,
  GetWellbeing,
  GetEnergyState,
  GetDashboardClientStatus,
  GetDashboardEnergyState,
  BlockUser,
  GetSubscriptionStatus,
  CopyEmail,
  DeleteClientAdmin,
  GetSuperAdminBusinessUserList,
  GetSuperAdminIndividualUserList,
  GetSuperAdminLearningLibrary,
  GetSuperAdminTopics
} from '../../interface/superAdmin';

type InitialState = {
  clientList: any;
  businessUserList: any;
  individualsUserList: any;
  individualUserFilterParams: {
    limit: number;
    page: number;
    sort: string;
    order: string;
    search: string;
    status: any;
    subscription: any;
  };
  allOrgList: any;
  clientListLoading: boolean;
  individualUserListLoading: boolean;
  businessUserListLoading: boolean;
  clientUserList: any;
  userList: any;
  userListLoading: any;
  clientQuickInfo: any;
  clientQuickInfoLoading: boolean;
  clientDetails: any;
  clientDetailsloading: boolean;
  clientUsersWellBeingList: any;
  clientUsersWellBeingLoading: boolean;
  clientUsersEnergyStateList: any;
  clientUsersEnergyStateLoading: boolean;
  exportUserList: any;
  exportBusinessUserList: any;
  exportIndividualUserList: any;
  exportClientList: any;
  exportUserListLoading: boolean;
  exportClientListLoading: boolean;
  dashboardQuickInfo: any;
  dashboardQuickInfoLoading: boolean;
  dashboardClientStatus: any;
  dashboardClientStatusLoading: boolean;
  allUsersEnergyState: any;
  allUsersEnergyStateLoading: boolean;
  subscriptionStatusData: any;
  copiedEmail: any;
  copyEmailListLoading: any;
  exportBusinessUserListLoading: boolean;
  exportIndividualUserListLoading: boolean;
  subscriptionStatusListLoading: boolean;
  learningLibrary: any;
  learningLibraryLoading: boolean;
  topics: any;
  topicsLoading: boolean;
};

const initialState: InitialState = {
  clientList: undefined,
  businessUserList: undefined,
  individualsUserList: undefined,
  individualUserFilterParams: {
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
    search: '',
    status: ['all'],
    subscription: ['all'],
  },
  allOrgList: undefined,
  clientListLoading: false,
  individualUserListLoading: false,
  businessUserListLoading: false,
  clientUserList: undefined,
  userList: undefined,
  userListLoading: undefined,
  clientQuickInfo: undefined,
  clientQuickInfoLoading: false,
  clientDetails: undefined,
  clientDetailsloading: false,
  clientUsersWellBeingList: undefined,
  clientUsersWellBeingLoading: false,
  clientUsersEnergyStateList: undefined,
  clientUsersEnergyStateLoading: false,
  exportUserList: undefined,
  exportBusinessUserList: undefined,
  exportIndividualUserList: undefined,
  exportClientList: undefined,
  exportUserListLoading: false,
  exportClientListLoading: false,
  dashboardQuickInfo: undefined,
  dashboardQuickInfoLoading: false,
  dashboardClientStatus: undefined,
  dashboardClientStatusLoading: false,
  allUsersEnergyState: undefined,
  allUsersEnergyStateLoading: false,
  subscriptionStatusData: undefined,
  copiedEmail: undefined,
  copyEmailListLoading: undefined,
  exportBusinessUserListLoading: false,
  exportIndividualUserListLoading: false,
  subscriptionStatusListLoading: false,
  learningLibrary: undefined,
  learningLibraryLoading: false,
  topics: undefined,
  topicsLoading: false
};

const getSuperAdminClientList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminClientList>
) => {
  state.clientListLoading = true;
};
const getSuperAdminIndividualUserList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminIndividualUserList>
) => {
  if (action.payload === undefined) {
    state.individualUserFilterParams.search = '';
    state.individualUserFilterParams.order = 'DESC';
    state.individualUserFilterParams.limit = 10;
    state.individualUserFilterParams.page = 1;
    state.individualUserFilterParams.sort = 'createdAt';
    state.individualUserFilterParams.status = ['all'];
    state.individualUserFilterParams.subscription = ['all'];
  } else {
    state.individualUserFilterParams.search = action.payload.search;
    state.individualUserFilterParams.order = action.payload.order;
    state.individualUserFilterParams.limit = action.payload.limit;
    state.individualUserFilterParams.page = action.payload.page;
    state.individualUserFilterParams.sort = action.payload.sort;
    state.individualUserFilterParams.status = action.payload.status;
    state.individualUserFilterParams.subscription = action.payload.subscription;
  }
  state.individualUserListLoading = true;
};
const getSuperAdminBusinessUserList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminClientList>
) => {
  state.businessUserListLoading = true;
};
const setSuperAdminClientList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminClientList>
) => {
  state.clientList = action.payload;
  state.clientListLoading = false;
};
const getSuperAdminUserList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminClientList>
) => {
  state.clientListLoading = true;
};
const setSuperAdminUserList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminClientList>
) => {
  state.userList = action.payload;
  state.userListLoading = false;
};
const setSuperAdminBusinessUserList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminBusinessUserList>
) => {
  state.businessUserList = action.payload;
  state.businessUserListLoading = false;
};
const setSuperAdminIndividualUserList = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminIndividualUserList>
) => {
  state.individualsUserList = action.payload;
  state.individualUserListLoading = false;
};
const getClientUserList = () => {};
const setClientUserList = (state: InitialState, action: any) => {
  state.clientUserList = action.payload;
};

const getAllOrgList = () => {};
const setAllOrgList = (state: InitialState, action: any) => {
  state.allOrgList = action.payload;
};

const getClientQuickInfo = (
  state: InitialState,
  action: PayloadAction<GetClientQuickInfo>
) => {
  state.clientDetailsloading = true;
};

const setClientQuickInfo = (
  state: InitialState,
  action: PayloadAction<GetClientQuickInfo>
) => {
  state.clientQuickInfo = action.payload;
  state.clientQuickInfoLoading = false;
};

const getClientDetailsView = (
  state: InitialState,
  action: PayloadAction<GetClientQuickInfo>
) => {
  state.clientQuickInfoLoading = true;
};

const setClientDetailsView = (
  state: InitialState,
  action: PayloadAction<GetClientQuickInfo>
) => {
  state.clientDetails = action.payload;
  state.clientDetailsloading = false;
};

const getClientUsersWellbeing = (
  state: InitialState,
  action: PayloadAction<GetWellbeing>
) => {
  state.clientUsersWellBeingLoading = true;
};

const setClientUsersWellbeing = (
  state: InitialState,
  action: PayloadAction<GetWellbeing>
) => {
  state.clientUsersWellBeingList = action.payload;
  state.clientUsersWellBeingLoading = false;
};

const getClientUsersEnergyState = (
  state: InitialState,
  action: PayloadAction<GetEnergyState>
) => {
  state.clientUsersEnergyStateLoading = true;
};

const setClientUsersEnergyState = (
  state: InitialState,
  action: PayloadAction<GetEnergyState>
) => {
  state.clientUsersEnergyStateList = action.payload;
  state.clientUsersEnergyStateLoading = false;
};
const exportUsers = (state: InitialState) => {
  state.exportUserListLoading = true;
};
const exportClients = (state: InitialState) => {
  state.exportClientListLoading = true;
};
const exportBusinessUser = (state: InitialState) => {
  state.exportBusinessUserListLoading = true;
};
const exportIndividualUser = (state: InitialState) => {
  state.exportIndividualUserListLoading = true;
};
const setexportUsers = (state: InitialState, action: any) => {
  const dataArray: any[] = [];
  action.payload.data.map((item: any, index: number) => {
    dataArray.push({
      ...item,
      Assessment_time: item.Assessment_time
        ? moment(item.Assessment_time).format('MMMM DD, YYYY, h:mm A')
        : ' ',
    });
  });
  state.exportUserList = { ...action.payload, data: dataArray };
  state.exportUserListLoading = false;
};
const setexportBusinessUsers = (state: InitialState, action: any) => {
  const dataArray: any[] = [];
  action.payload.data.map((item: any, index: number) => {
    dataArray.push({
      ...item,
      CreatedAt: item.CreatedAt
        ? moment(item.CreatedAt).format('MMMM DD, YYYY')
        : ' ',
    });
  });
  state.exportBusinessUserList = { ...action.payload, data: dataArray };
  state.exportBusinessUserListLoading = false;
};
const setexportIndividualUsers = (state: InitialState, action: any) => {
  const dataArray: any[] = [];
  action.payload.data.map((item: any, index: number) => {
    dataArray.push({
      ...item,
      CreatedAt: item.CreatedAt
        ? moment(item.CreatedAt).format('MMMM DD, YYYY')
        : ' ',
      expiry: item.expiry ? moment(item.expiry).format('MMMM DD, YYYY') : ' ',
    });
  });
  state.exportIndividualUserList = { ...action.payload, data: dataArray };
  state.exportIndividualUserListLoading = false;
};
const setexportClients = (state: InitialState, action: any) => {
  const dataArray: any[] = [];
  action.payload.data.map((item: any, index: number) => {
    dataArray.push({
      ...item,
      createdAt: item.createdAt
        ? moment(item.createdAt).format('MMMM DD, YYYY')
        : ' ',
    });
  });
  state.exportClientList = { ...action.payload, data: dataArray };
  state.exportClientListLoading = false;
};
const getDashboardQuickInfo = (state: InitialState, action: any) => {
  state.dashboardQuickInfoLoading = true;
};

const setDashboardQuickInfo = (
  state: InitialState,
  action: PayloadAction<GetClientQuickInfo>
) => {
  state.dashboardQuickInfo = action.payload;
  state.dashboardQuickInfoLoading = false;
};

const getDashboardClientStatus = (state: InitialState) => {
  state.dashboardClientStatusLoading = true;
};

const setDashboardClientStatus = (
  state: InitialState,
  action: PayloadAction<GetDashboardClientStatus>
) => {
  state.dashboardClientStatus = action.payload;
  state.dashboardClientStatusLoading = false;
};

const getAllUsersEnergyState = (
  state: InitialState,
  action: PayloadAction<GetDashboardEnergyState>
) => {
  state.allUsersEnergyStateLoading = true;
};

const setAllUsersEnergyState = (
  state: InitialState,
  action: PayloadAction<GetDashboardEnergyState>
) => {
  state.allUsersEnergyState = action.payload;
  state.allUsersEnergyStateLoading = false;
};
const blockUser = (state: InitialState, action: PayloadAction<BlockUser>) =>
  state;
const blockBussinessUser = (
  state: InitialState,
  action: PayloadAction<BlockUser>
) => state;
const getSubscriptionStatus = (
  state: InitialState,
  action: PayloadAction<GetSubscriptionStatus>
) => {
  state.subscriptionStatusListLoading = true;
};
const setSubscriptionStatus = (
  state: InitialState,
  action: PayloadAction<GetDashboardEnergyState>
) => {
  state.subscriptionStatusData = action.payload;
  state.subscriptionStatusListLoading = false;
};
const copyEmails = (state: InitialState, action: PayloadAction<CopyEmail>) => {
  state.copyEmailListLoading = true;
};
const setcopiedEmail = (
  state: InitialState,
  action: PayloadAction<CopyEmail>
) => {
  state.copiedEmail = action.payload;
  state.copyEmailListLoading = false;
};

const setClientDeleted = (state:any) => {
  state.clientDeleted = state;
};

const deleteClientAdmin = (
  state: InitialState,
  action: PayloadAction<DeleteClientAdmin>
) => {};
const deleteBusinessUser = (
  state: InitialState,
  action: PayloadAction<DeleteClientAdmin>
) => {};
const changeSubscriptionEndDate = (state: InitialState, action: any) => {};
const setexportClientsNull = (state: InitialState) => {
  state.exportClientList = undefined;
  state.exportClientListLoading = false;
};

const getSuperAdminLearningLibrary = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminLearningLibrary>
) => {
  state.learningLibraryLoading = true;
};

const setSuperAdminLearningLibrary = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminLearningLibrary>
) => {
  state.learningLibrary = action.payload;
  state.learningLibraryLoading = false;
};

const getSuperAdminTopics= (
  state: InitialState,
  action: PayloadAction<GetSuperAdminTopics>
) => {
  state.topicsLoading = true;
};

const setSuperAdminTopics = (
  state: InitialState,
  action: PayloadAction<GetSuperAdminTopics>
) => {
  state.topics = action.payload;
  state.topicsLoading = false;
};

const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    getSuperAdminClientList,
    getSuperAdminIndividualUserList,
    getSuperAdminBusinessUserList,
    setSuperAdminClientList,
    getSuperAdminUserList,
    setSuperAdminUserList,
    getClientUserList,
    setClientUserList,
    getAllOrgList,
    setAllOrgList,
    getClientQuickInfo,
    setClientQuickInfo,
    getClientDetailsView,
    setClientDetailsView,
    getClientUsersWellbeing,
    setClientUsersWellbeing,
    getClientUsersEnergyState,
    setClientUsersEnergyState,
    exportUsers,
    setexportUsers,
    setexportClients,
    setDashboardQuickInfo,
    getDashboardClientStatus,
    setDashboardClientStatus,
    getAllUsersEnergyState,
    setAllUsersEnergyState,
    getDashboardQuickInfo,
    getSubscriptionStatus,
    blockUser,
    blockBussinessUser,
    setSubscriptionStatus,
    copyEmails,
    setcopiedEmail,
    deleteClientAdmin,
    deleteBusinessUser,
    changeSubscriptionEndDate,
    exportClients,
    exportBusinessUser,
    exportIndividualUser,
    setSuperAdminBusinessUserList,
    setSuperAdminIndividualUserList,
    setexportBusinessUsers,
    setexportIndividualUsers,
    setexportClientsNull,
    getSuperAdminLearningLibrary,
    setSuperAdminLearningLibrary,
    getSuperAdminTopics,
    setSuperAdminTopics,
    setClientDeleted
  },
});

export default superAdminSlice;
