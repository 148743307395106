/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import PageLoader from '../../components/Loader/PageLoader';

const UserSignUp = lazy(() => import('../Auth/UserSignUp/UserSignUp'));
const ThanksPage = lazy(() => import('../Auth/UserSignUp/ThanksPage'));

const NewAuthLayout = () => {
  const history = useHistory();
  const { location } = history;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <section className='w-screen h-screen'>
      <div className='bg-primary w-full min-h-full flex flex-col justify-center items-center py-5 overflow-auto'>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route exact path='/userSignUp/:id'>
              <UserSignUp />
            </Route>
            <Route exact path='/thanksforsignup'>
              <ThanksPage />
            </Route>
          </Switch>
        </Suspense>
        <footer
          className='flex justify-center font-Lexend-Light text-base mt-4'
          style={{ color: '#D1E6F0' }}
        >
          © Copyright Vibeonix - All Rights Reserved.
        </footer>
      </div>
    </section>
  );
};
export default NewAuthLayout;
