import React from 'react';

function SubHeading(props) {
    return (
        <div className="mt-3 mb-3">
            <span className="font-Lexend text-lg h-18 font-medium text-copy-primary" style={{color:'#161F38'}}>{props.title}</span>
        </div>
    );
}

export default SubHeading;