import { takeEvery,  put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ResponseGenerator } from '../../interface/Response';
import { storageEngine } from '../../common/helper';
import {
  APP_TOUR_FINISHED,
  GET_QUICK_INFO,
  GET_USER_WELLBEING,
  GET_USER_ENERGY_STATE,
  GET_USER_WELLBEING_PATTERN,
  GET_USER_EMO_INFO,
  GET_REMAINING_USERS,
  GET_INVITE_LINK,
  GET_CHAT_RECIPIENTS,
  GET_CHAT_GROUPS,
  CREATE_CHAT_GROUP,
  GET_CHAT_MESSAGES,
  SEND_CHAT_MESSAGE,
  DELETE_CHAT_GROUP,
  EDIT_CHAT_GROUP,
  GET_GROUP_MEMBERS,
  GET_PRIVATE_VIMEO_VIDEO,
} from '../actions/groupAdmin.actions';
import Api from '../../services/Apis';
import {
  AppTourAction,
  GroupChatRecipient,
  GroupChatGroups,
} from '../../interface/groupAdmin';
import loaderSlice from '../slices/loader.slice';
import groupAdminSlice from '../slices/groupAdmin.slice';
import { toast } from 'react-toastify';
import userSlice from '../slices/user.slice';

function* getQuickInfo(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.groupAdmin.getQuickInfo(action.payload);
    yield put(groupAdminSlice.actions.setQuickInfo(response.data.data));
  } catch (err) {}
}
function* getuserWellBeing(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.groupAdmin.getuserWellBeing(
      action.payload
    );
    yield put(groupAdminSlice.actions.setuserWellBeing(response.data.data));
  } catch (err) {}
}
function* getuserEnergyState(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.groupAdmin.getuserEnergyState(
      action.payload
    );
    yield put(groupAdminSlice.actions.setuserEnergyState(response.data.data));
  } catch (err) {}
}
function* getuserWellBeingPattern(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator =
      yield Api.groupAdmin.getuserWellBeingPattern(action.payload);
    yield put(
      groupAdminSlice.actions.setuserWellBeingPattern(response.data.data)
    );
  } catch (err) {}
}

function* getuserEmotionalInfo(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator =
      yield Api.groupAdmin.getuserEmotionalInfo(action.payload);
    yield put(groupAdminSlice.actions.setuserEmotionalInfo(response.data.data));
  } catch (err) {}
}

function* getRemainingUsers() {
  try {
    const response: ResponseGenerator =
      yield Api.groupAdmin.getRemainingUsers();
    yield put(groupAdminSlice.actions.setRemainingUsers(response.data.data));
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* appTourFinished(action: PayloadAction<AppTourAction>) {
  try {
    const response: ResponseGenerator = yield Api.groupAdmin.appTourFinished(
      action.payload
    );
    storageEngine.set('appTour', response.data.data.screen_data);
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* getInviteLink() {
  try {
    const response: ResponseGenerator = yield Api.groupAdmin.getInviteLink();
    yield put(
      groupAdminSlice.actions.setInviteLink(response.data.data.invite_link)
    );
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* getChatRecipients(
  action: PayloadAction<GroupChatRecipient>
) {
  if (action.payload.reset === true) {
    if (action.payload.type === 'user') {
      yield put(
        groupAdminSlice.actions.setChatUsers(undefined)
      );
    } else if (action.payload.type === 'recipient') {
      yield put(
        groupAdminSlice.actions.setChatRecipients(undefined)
      );
    } else if (action.payload.type === 'editRecipient') {
      yield put(
        groupAdminSlice.actions.setChatEditRecipients(undefined)
      );
    }
    return false;
  }
  try {
    const response: ResponseGenerator = yield Api.groupAdmin.getChatRecipients(action.payload);
   
    if (action.payload.type === 'user') {
      yield put(
        groupAdminSlice.actions.setChatUsers(response.data.data)
      );
    } else if (action.payload.type === 'recipient') {
      yield put(
        groupAdminSlice.actions.setChatRecipients(response.data.data)
      );
    } else if (action.payload.type === 'editRecipient') {
      yield put(
        groupAdminSlice.actions.setChatEditRecipients(response.data.data)
      );
    }
    
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* getChatGroups(
  action: PayloadAction<GroupChatGroups>
) {
  try {
    const response: ResponseGenerator = yield Api.groupAdmin.getChatGroups(action.payload);
    yield put(
      groupAdminSlice.actions.setChatGroups(response.data.data)
    );
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* createChatGroup(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.groupAdmin.createChatGroup(action.payload);
      yield put(groupAdminSlice.actions.setCreateChatGroup(response.data));
      toast.success('Successfully saved');
  } catch (err: any) {
    toast.success(err?.response?.data?.message);
  }
}

function* getChatMessages(
  action: PayloadAction<any>
) {
  try {
    const response: ResponseGenerator = yield Api.groupAdmin.getChatMessages(action.payload);
    yield put(
      groupAdminSlice.actions.setChatMessages(response.data.data)
    );
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* sendChatMessage(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.groupAdmin.sendChatMessage(action.payload);
      yield put(groupAdminSlice.actions.setSendChatMessage(response.data));
      yield put(groupAdminSlice.actions.setSendMessageStatus(true));
    
  } catch (err: any) {
    yield put(groupAdminSlice.actions.setSendMessageStatus(false));
    toast.error('Image size or character limit exceeded');
  }
}

function* editChatGroup(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.groupAdmin.editChatGroup(action.payload);
    yield put(groupAdminSlice.actions.setEditChatGroup(response.data));
    toast.success('Successfully updated!');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* deleteChatGroup(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.groupAdmin.deleteChatGroup(action.payload);
    yield put(groupAdminSlice.actions.setDeleteChatGroup(response.data));
    toast.success('Successfully deleted');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getGroupMembers(
  action: PayloadAction<any>
) {

  if (action.payload.reset === true) { 
    groupAdminSlice.actions.setGroupMembers(undefined);
    return false;
  }
  try {
    const response: ResponseGenerator = yield Api.groupAdmin.getGroupMembers(action.payload);
    yield put(
      groupAdminSlice.actions.setGroupMembers(response.data.data)
    );
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* getPrivateVimeoVideo(
  action: PayloadAction<any>
) {

  if (action.payload.reset === true) { 
    groupAdminSlice.actions.setPrivateVimeoVideo(undefined);
    return false;
  }
  try {
    const response: ResponseGenerator = yield Api.groupAdmin.getPrivateVimeoVideo(action.payload);
    yield put(
      groupAdminSlice.actions.setPrivateVimeoVideo(response.data.data)
    );
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* groupAdminSaga() {
  yield takeEvery(GET_QUICK_INFO, getQuickInfo);
  yield takeEvery(GET_USER_WELLBEING, getuserWellBeing);
  yield takeEvery(GET_USER_ENERGY_STATE, getuserEnergyState);
  yield takeEvery(GET_USER_WELLBEING_PATTERN, getuserWellBeingPattern);
  yield takeEvery(GET_USER_EMO_INFO, getuserEmotionalInfo);
  yield takeEvery(GET_REMAINING_USERS, getRemainingUsers);
  yield takeEvery(APP_TOUR_FINISHED, appTourFinished);
  yield takeEvery(GET_INVITE_LINK, getInviteLink);
  yield takeEvery(GET_CHAT_RECIPIENTS, getChatRecipients);
  yield takeEvery(GET_CHAT_GROUPS, getChatGroups);
  yield takeEvery(CREATE_CHAT_GROUP, createChatGroup);
  yield takeEvery(GET_CHAT_MESSAGES, getChatMessages);
  yield takeEvery(SEND_CHAT_MESSAGE, sendChatMessage);
  yield takeEvery(DELETE_CHAT_GROUP, deleteChatGroup);
  yield takeEvery(EDIT_CHAT_GROUP, editChatGroup);
  yield takeEvery(GET_GROUP_MEMBERS, getGroupMembers);
  yield takeEvery(GET_PRIVATE_VIMEO_VIDEO, getPrivateVimeoVideo);
}

export default groupAdminSaga;
