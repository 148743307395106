
const PlanUpdateToast = (props:any) => {
  const { isToastOpen, handleToastClose } = props;

  return (
    <div
      className={`${
        isToastOpen ? 'flex' : 'hidden'
      } absolute right-0 z-10  justify-end limitWarning rounded-full mt-2 p-3 items-center`}
    >
      <div className='limitWarning-svg p-2 rounded-full '>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M10.017 1.60046L15.6753 12.0173C16.1019 12.7349 16.1101 13.6165 15.6881 14.3589C15.2652 15.1016 14.4719 15.5447 13.6173 15.5447H2.35027C1.49567 15.5447 0.733671 15.1016 0.310701 14.3589C-0.111299 13.6165 -0.103049 12.7349 0.33317 12.0008L5.97268 1.61696C5.97429 1.61421 5.97578 1.61146 5.97726 1.60871C5.97874 1.60596 5.98023 1.60321 5.98184 1.60046C6.4089 0.881524 7.16315 0.452148 7.9994 0.452148C8.83566 0.452148 9.58991 0.881524 10.017 1.60046ZM6.40059 7.28219C7.69166 6.53679 9.28624 6.7933 10.284 7.81049L4.91641 10.9095C4.53438 9.53679 5.10953 8.02758 6.40059 7.28219ZM5.22931 11.6535L5.21048 11.6204L5.24852 11.6863L5.22931 11.6535ZM9.60059 12.8247C8.30905 13.5704 6.71376 13.3134 5.71606 12.2953L11.0844 9.19593C11.4672 10.569 10.8921 12.0791 9.60059 12.8247Z'
              fill='white'
            />
          </g>
          <defs>
            <clipPath id='clip0'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className='mx-2 text-base'>You have exceeded the limit.</div>
      <div className='text-base limitWarning-update-text'>Upgrade Now</div>
      <div className='mx-2 cursor-pointer' onClick={handleToastClose}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 4L4 12'
            stroke='#161F38'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M4 4L12 12'
            stroke='#161F38'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );
};
export default PlanUpdateToast;
