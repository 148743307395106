import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import Button from '../../../components/button/Button';
import {
  addCard,
  individualUserAddCard,
} from '../../../services/Apis/user/user';
import { storageEngine } from '../../../common/utility';
import ButtonLoader from '../../../components/Loader/ButtonLoader';
import { useDispatch } from 'react-redux';
import clientAdminSlice from '../../../redux/slices/clientAdmin.slice';
import individualUserSlice from '../../../redux/slices/individualUser.slice';

const CARD_NUMBER_OPTIONS = {
  style: {
    base: {
      iconColor: '#666EE8',
      color: '#000',
      fontSmoothing: 'antialiased',
      '::placeholder': {},
    },
    invalid: {
      color: '#eb1c26',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = ({ closeModel, page }: any) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [isPayLoading, setIsPayLoading] = useState(false);
  const [cardOwnerName, setCardOwnerName] = useState('');
  const user = storageEngine.get('user');

  const handleSubmit = async () => {
    try {
      setIsPayLoading(true);
      const cardNumber = elements?.getElement(CardNumberElement);
      if(cardNumber){
      const paymentMethod:any = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {
          name: cardOwnerName,
        },
      });
    
      if (paymentMethod) {
        const payload = {
          payment_method: paymentMethod?.paymentMethod?.id,
          name: cardOwnerName,
          card_number: parseInt(paymentMethod?.paymentMethod?.card?.last4),
          expiry_date: `${paymentMethod.paymentMethod.card.exp_month}/${paymentMethod.paymentMethod.card.exp_year}`,
          card_type: paymentMethod.paymentMethod.card.brand,
        };
        if (page === 'MySubscriptionMycard') {
          const payment = await individualUserAddCard({
            ...payload,
            email: user.email,
          });
          setIsPayLoading(false);
          if (!payment.error) {
            closeModel();
            dispatch(individualUserSlice.actions.getSavedCardData());
          }
        } else {
          const payment = await addCard(payload);
          setIsPayLoading(false);
          if (!payment.error) {
            closeModel();
            dispatch(clientAdminSlice.actions.getSavedCards());
          }
        }

        // stripe.confirmCardPayment(payment.data.response.clientSecret, {
        //     payment_method: paymentMethod.paymentMethod.id,
        // }).then(async (data) => {
        //     const confirmPayload = {
        //         paymentId: data.paymentIntent.id,
        //         saved_card_id: parseInt(payment.data.cardData.id),
        //         total_calculated_amount: paymentData.totalYearlyAmount,
        //         total_discount_applied: paymentData.totalYearlySavingAmount,
        //         final_amount_paid: paymentData.finalYearlyAmountToPay,
        //         package_type: "yearly", //monthly
        //         number_of_users: paymentData.numberOfusers,
        //         number_of_groups: paymentData.numberOfGroup,
        //         stripe_customer_id: parseInt(payment.data.customerData.id)
        //     }
        //     const res = await confirmClientPayment(confirmPayload)
        //     res.data && storageEngine.set('user', { ...user, package_id: "RTGGfggyy%%$433" })
        //     res.data && closeModel(); history.push('/');

        // }).catch((error) => {
        //     toast.error("Payment failed")
        // })
      } else {
        toast.error(paymentMethod?.error?.message);
      }
    }
    } catch (err: any) {
      toast.error(err?.response?.data?.message);
      setIsPayLoading(false);
    }
    
  };
  return (
    <div>
      <div className='w-full justify-center flex'>
        <div className='font-Lexend-Regular font-medium card-row-text text-2xl'>
          Add your Card
        </div>
      </div>
      <div className='my-3'>
        <label
          htmlFor='cardNumber'
          className='light-payment-color text-sm font-Lexend-Regular'
        >
          Card number
        </label>
        <div className=''>
          <CardNumberElement id='cardNumber' options={CARD_NUMBER_OPTIONS} />
        </div>
      </div>
      <div className='my-3'>
        <label
          htmlFor='ExpiryElement'
          className='light-payment-color text-sm font-Lexend-Regular'
        >
          Expiry Date
        </label>
        <div className=''>
          <CardExpiryElement id='ExpiryElement' options={CARD_NUMBER_OPTIONS} />
        </div>
      </div>
      <div className='my-3'>
        <label
          htmlFor='CvcElement'
          className='light-payment-color text-sm font-Lexend-Regular'
        >
          CVC
        </label>
        <div className=''>
          <CardCvcElement id='CvcElement' options={CARD_NUMBER_OPTIONS} />
        </div>
      </div>
      <div className='my-3'>
        <label
          htmlFor='CvcElement'
          className='light-payment-color text-sm font-Lexend-Regular'
        >
          Card owner
        </label>
        <div className='p-3 payment-card-shadow'>
          <input
            name='cardholder'
            type='text'
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[0-9]/g, '');
              setCardOwnerName(value);
            }}
            placeholder='Card owner name'
          />
        </div>
      </div>
      <div className='flex w-full mt-8 mb-6'>
        <div className='w-1/2'>
          <Button
            onPress={closeModel}
            value={`Cancel`}
            bgColor='#fff'
            borderColor='#A6A9B4'
            color='#A6A9B4'
            disabled={false}
          />
        </div>
        <div className='w-1/2'>
          <Button
            onPress={() => handleSubmit()}
            value={isPayLoading ? <ButtonLoader /> : 'Add Card'}
            disabled={cardOwnerName.trim().length === 0 ? true : false}
            bgColor={cardOwnerName.trim().length === 0 ? '#BEC2CE' : '#3A9EA5'}
            borderColor={
              cardOwnerName.trim().length === 0 ? '#BEC2CE' : '#3A9EA5'
            }
          />
        </div>
      </div>
    </div>
  );
};

// Setup Stripe.js and the Elements provider
const stripeKey:any=process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(stripeKey);
const AddCard = ({ isOpen, closeModel, page }: any) => {
  return (
    <div>
      <div
        className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <div className='popup-container absolute w-full top-6'>
          <div className='popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden z-10'>
            <Elements stripe={stripePromise}>
              <CheckoutForm closeModel={closeModel} page={page} />
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCard;
