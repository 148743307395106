import { APP_ENTRYPOINT } from "../../../config/config";
import {
	AppTourAction,
	CreateGroupDto,
} from "../../../interface/groupAdmin";
import { ResponseGenerator } from "../../../interface/Response";
import { request } from "../../request/Request";

function* getQuickInfo(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/groupQuickInfo`,
		{
			params: value,
		}
	);
	return response;
}
function* getuserWellBeing(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/userWellBeing`,
		{
			params: value,
		}
	);
	return response;
}
function* getuserEnergyState(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/userEnergyState`,
		{
			params: value,
		}
	);
	return response;
}
function* getuserWellBeingPattern(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/userWellBeingPattern`,
		{
			params: value,
		}
	);
	return response;
}

function* getuserEmotionalInfo(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/userEmotionalInfo`,
		{
			params: value,
		}
	);
	return response;
}

function* getRemainingUsers() {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/remainingUsers`
	);
	return response;
}
function* appTourFinished(value: AppTourAction) {
	const response: ResponseGenerator = yield request.put(
		`${APP_ENTRYPOINT}appTour/markAsFinished`,
		value
	);
	return response;
}

function* getInviteLink() {
	const response: ResponseGenerator = yield request.post(
		`${APP_ENTRYPOINT}groupAdmin/invite/link`
	);
	return response;
}

function* getChatRecipients(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/chat/recipients`,
		{
			params: value,
		}
	);
	return response;
}

function* getChatGroups(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/chatGroups`,
		{
			params: value,
		}
	);
	return response;
}

function* createChatGroup(value: CreateGroupDto) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}groupAdmin/chat/createGroup`,
    value
  );
  return response;
}

function* getChatMessages(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/messages`,
		{
			params: value,
		}
	);
	return response;
}

function* sendChatMessage(value: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}groupAdmin/sendMessage`,
    value
  );
  return response;
}

function* deleteChatGroup(value: any) {
	const response: ResponseGenerator = yield request.put(
		`${APP_ENTRYPOINT}groupAdmin/chat/deleteGroup?group_uuid=${value.group_uuid}`
	);
	return response;
}

function* editChatGroup(value: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}groupAdmin/chat/editGroup`,
    value
  );
  return response;
}

function* getGroupMembers(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}groupAdmin/chatGroup/existingMembers`,
		{
			params: value,
		}
	);
	return response;
}

function* getPrivateVimeoVideo(value: any) {
	const response: ResponseGenerator = yield request.get(
		`${APP_ENTRYPOINT}common/getPublicVimeoLink/`+value.video_id,
	);
	return response;
}

function* switchAccount(value: any) {
	const response: ResponseGenerator = yield request.post(
		`${APP_ENTRYPOINT}common/switchAdmin`
	);
	return response;
}

const groupAdmin = {
	getQuickInfo,
	getuserWellBeing,
	getuserEnergyState,
	getuserWellBeingPattern,
	getuserEmotionalInfo,
	getRemainingUsers,
	appTourFinished,
	getInviteLink,
	getChatRecipients,
	getChatGroups,
	createChatGroup,
	getChatMessages,
	sendChatMessage,
	deleteChatGroup,
	editChatGroup,
	getGroupMembers,
	getPrivateVimeoVideo,
	switchAccount
};

export default groupAdmin;
