/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import PageLoader from '../../components/Loader/PageLoader';

const SignupSubscription = lazy(
  () => import('../Auth/SignupSubscription/SignupSubscription')
);

const PaymentLayout = () => {
  const history = useHistory();
  const { location } = history;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <section className='bg-primary w-screen h-screen'>
      <div className='bg-primary w-full min-h-full flex flex-col justify-center items-center py-10 overflow-auto'>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route exact path='/package-selection'>
              <SignupSubscription />
            </Route>
          </Switch>
        </Suspense>
        <footer
          className='flex justify-center font-Lexend-Light text-base mt-4'
          style={{ color: '#D1E6F0' }}
        >
          © Copyright Vibeonix - All Rights Reserved.
        </footer>
      </div>
    </section>
  );
};
export default PaymentLayout;
