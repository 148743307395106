import { APP_ENTRYPOINT } from "../../../config/config";
import { request } from "../../request/Request";
import { storageEngine } from "../../../common/utility";
import { ResponseGenerator } from "../../../interface/Response";
import { InviteUser, GetUserList, BlockUser, GetUserByDocId, AssessmentsHistoryInerfase, GetUserWellbingCalender, GetUserAssessmentDetails, GetGropListForSortUserList, EmailInterface, ContactUs } from "../../../interface/users";
import { toast } from "react-toastify";

function* inviteUser(values: InviteUser) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}users/inviteUser`,
    values
  );
  return response;
}
function* resendInvite(values: EmailInterface) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}users/resendInvite`,
    values
  );
  return response;
}

function* getUserList(value: GetUserList) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}users/userList`,
    {
      params: value,
    }
  );
  return response;
}
function* getUserByDocId(value: GetUserByDocId) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}users/getUserById`,
    {
      params: value,
    }
  );
  return response;
}
function* getAssessmentsHistorys(value: AssessmentsHistoryInerfase) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}users/getAssessmentsHistorys`,
    {
      params: value,
    }
  );
  return response;
}


function* blockUser(value: BlockUser) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}users/blockUser`,
    value
  );
  return response;
}
function* deleteUser(value: GetUserByDocId) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}users/deleteUser`,
    value
  );
  return response;
}

function* logout() {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}auth/logout`
  );
  return response;
}

function* getUserWellbeingCalendar(value: GetUserWellbingCalender) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groupAdmin/userWellbeingCalendar`,
    { params: value }
  );
  return response;
}
function* getUserAssessmentDetails(value: GetUserAssessmentDetails) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groupAdmin/userAssessmentDetails`,
    { params: value }
  );
  return response;
}

function* getGropListForSortUserList(value: GetGropListForSortUserList) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groups/getAllGroups`,
    {
      params: value,
    }
  );
  return response;
}

function* updateUser(value: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}users/updateUser`,
    value
  );
  return response;
}
export const refreshAccessToken = async () => {
  try {
    const refreshToken = storageEngine.get("refresh_token");

    const getTokenUrl = APP_ENTRYPOINT + "auth/getToken";
    if (refreshToken) {
      const response = await fetch(getTokenUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + refreshToken.token,
          Accept: "application/json, text/plain, */*",
        },
      });
      const { access_token, refresh_token } = await response.json();
      if (access_token && refresh_token) {
        setToken(access_token, refresh_token);
      }
      return access_token.token;
    }
  } catch (error) {
    localStorage.clear();
  }
};


export async function setToken(access_token: any, refresh_token: any) {
  storageEngine.set("access_token", access_token);
  storageEngine.set("refresh_token", refresh_token);
}

export const startPayment = async (payload: any) => {
  try {
    const response = await request.post(`${APP_ENTRYPOINT}payments/startPayment`, payload);
    return response.data
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const confirmClientPayment = async (id: any) => {
  try {
    const response = await request.post(`${APP_ENTRYPOINT}payments/confirmClientPayment`, id);
    return response.data
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function* contactUs(value: ContactUs) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}webApp/contactUs`,
    value
  );
  return response;
}

export const addCard = async (payload: any) => {
  try {
    const response = await request.post(`${APP_ENTRYPOINT}payments/addNewCard`, payload);
    return response.data
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const individualUserAddCard = async (payload: any) => {
  try {
    const response = await request.post(`${APP_ENTRYPOINT}individuals/add/card`, payload);
    toast.success(response.data.message)
    return response.data
  } catch (error:any) {
    toast.error(error);
    console.log(error);
    throw error;
  }
}

const user = {
  logout,
  inviteUser, resendInvite,
  getUserList,
  blockUser, deleteUser, updateUser,
  getUserByDocId,
  getAssessmentsHistorys,
  getUserWellbeingCalendar,
  getUserAssessmentDetails,
  getGropListForSortUserList,
  contactUs,
};

export default user;
