/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const CreateClientAdminSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Valid email required')
    .matches(
      '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-_]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-zA-Z]{2,}|.[0-9]{1,})$',
      'Please enter a valid email'
    ),
  password: Yup.string()
    .required('Required')
    .min(8, 'Must contain 8 characters')
    .max(50, 'Must contain below 50 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must contain 8 characters including alphabet, number and special character'
    ),
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),
});

export const AddActionSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
});

export const GroupSignUpSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must contain 8 characters')
    .max(50, 'Must contain below 50 characters')
    .required('Required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must contain 8 characters including alphabet, number and special character'
    ),
  confirmPassword: Yup.string()
  .required('Required')
  .when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Both password need to be the same')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must contain 8 characters including alphabet, number and special character'
      )
      ,
  }),
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),
});

export const IndividualUserSignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  email: Yup.string()
    .required('Required')
    .email('Valid email required')
    .matches(
      '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-_]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-zA-Z]{2,}|.[0-9]{1,})$',
      'Please enter a valid email'
    ),
  birthYear: Yup.number()
    .required('Required')
    .min(1900, 'Enter a valid birth year')
    .max(
      new Date().getFullYear() - 14,
      'You must be at least 13 years old to register'
    ),
  gender: Yup.string().required('Required'),
  phone: Yup.string().notRequired(),
  password: Yup.string()
    .min(8, 'Must contain 8 characters')
    .max(50, 'Must contain below 50 characters')
    .required('Required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_~^()\-+=|<>{}\/\\])[A-Za-z\d@$!%*#?&_~^()\-+=|<>{}\/\\]{8,}$/,
      'Must contain 8 characters including alphabet, number and special character'
    ),
  confirmPassword: Yup.string()
  .required('Required')
  .when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Both password need to be the same')
      ,
  }),
});

export const InviteUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  email: Yup.string()
    .required('Required')
    .email('Valid email required')
    .matches(
      '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-_]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-zA-Z]{2,}|.[0-9]{1,})$',
      'Please enter a valid email'
    ),
});

export const EditUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
});

export const AddGroupWithNameSchema = (limit, userMinValue) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
    lastName: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
    email: Yup.string()
      .required('Required')
      .email('Valid email required')
      .matches(
        '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-_]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-zA-Z]{2,}|.[0-9]{1,})$',
        'Please enter a valid email'
      ),
    userLimit: Yup.number().integer()
      .required('Required')
      .max(limit, `You have only ${limit} remaining `)
      .min(1, 'Minimum one user required'),
    userLimitEdit: Yup.number().integer()
      .notRequired()
      .max(limit, `You have only ${limit} remaining `)
      .min(
        userMinValue,
        `${
          userMinValue === 1
            ? 'Number of Users must be greater than or equal to 1'
            : `Group already contains ${userMinValue} members`
        }`
      ),
    name: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  });

export const UpdateGroupWithNameSchema = (
  limit,
  userMinValue,
  finalRemainingUsers
) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
    lastName: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
    email: Yup.string()
      .required('Required')
      .email('Valid email required')
      .matches(
        '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-_]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-zA-Z]{2,}|.[0-9]{1,})$',
        'Please enter a valid email'
      ),
    userLimit: Yup.number().integer().notRequired(),
    userLimitEdit: Yup.number().integer()
      .required('Required')
      .max(
        +finalRemainingUsers + +userMinValue,
        `${userMinValue} users already in the group. You can only add ${finalRemainingUsers} more.`
      )
      .min(
        userMinValue,
        `${
          userMinValue === 1
            ? 'Number of Users must be greater than or equal to 1'
            : `Group already contains ${userMinValue} members`
        }`
      ),
    name: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  });

export const AddGroupWithNameOnlySchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
});

export const AddGroupWithExistingName = {};

export const CreateUserSchema = (maxDate) =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, 'Must contain 8 characters')
      .max(50, 'Must contain below 50 characters')
      .required('Required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must contain 8 characters including alphabet, number and special character'
      ),
    confirmPassword: Yup.string()
    .required('Required')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf([Yup.ref('password')], 'Both password need to be the same')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Must contain 8 characters including alphabet, number and special character'
        ),
    }),
    firstName: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
    lastName: Yup.string()
      .required('Required')
      .max(50, 'Too Long!')
      .matches(/^[^\s].*/, 'First character cannot be blanks space')
      .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Accept Terms & Conditions is required'
    ),
    gender: Yup.string().required('Required'),
    dateOfBirth: Yup.date()
      .required('Required')
      .max(new Date(maxDate))
      .min('Fri Dec 12 1800 00:00:00 GMT+0553 (India Standard Time)'),
  });

export const SettingsSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  organization_name: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space'),
  industry_type: Yup.string().required('Required'),
  role_in_company: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  address_line1: Yup.string()
    .max(200, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space'),
  address_line2: Yup.string()
    .max(200, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space'),
  city: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  zip_code: Yup.number()
    .required('Required')
    .min(10000, 'Too Short!')
    .max(1000000, 'Too Long!'),
  email: Yup.string()
    .required('Required')
    .email('Valid email required')
    .matches(
      '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-_]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-zA-Z]{2,}|.[0-9]{1,})$',
      'Please enter a valid email'
    ),
});

export const OrganizationSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space'),
  industry_type: Yup.string().required('Required'),
  role_in_company: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  address_line1: Yup.string()
    .max(200, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space'),
  address_line2: Yup.string()
    .max(200, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space'),
  city: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  zip_code: Yup.number()
    .required('Required')
    .min(10000, 'Too Short!')
    .max(1000000, 'Too Long!'),
  sales_representative_introduced: Yup.string()
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
});

export const ForgotPassowrdSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Valid email required')
    .matches(
      '^(?:(?!.*?[.]{2})[a-zA-Z0-9](?:[a-zA-Z0-9.+!%-_]{1,64}|)|"[a-zA-Z0-9.+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(.[a-zA-Z]{2,}|.[0-9]{1,})$',
      'Please enter a valid email'
    ),
});

export const groupManagerEditValidation = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  firstName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
  lastName: Yup.string()
    .required('Required')
    .max(50, 'Too Long!')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[aA-zZ\s]+$/, 'Numbers and Special Characters not allowed'),
});

export const ActionSchema = Yup.object().shape({
  buttonName: Yup.string()
    .required('Required')
    .max(20, 'Total Character limit is 20')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  buttonUrl: Yup.string()
    .required('Required')
    .url('Valid url required')
    .matches(
      /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+(?:\/[^\s]*)?$/,
      'Please enter a valid url'
    ),
});
export const CreateGroupSchema = Yup.object().shape({
  groupName: Yup.string()
    .required('Required')
    //.max(12, 'Total Character limit is 12')
    .matches(/^[^\s].*/, 'First character cannot be blanks space')
    .matches(/^[0-9aA-zZ\s]+$/, 'No Special Characters allowed'),
  recipients: Yup.array()
    .of(Yup.string().required('This field is required'))
    .min(1, 'At least one recipients is required')
});


export const EditGroupSchema = Yup.object().shape({
  groupName: Yup.string()
    .required('Required')
    //.max(12, 'Total Character limit is 12')
    .matches(/^[^\s].*/, 'First character cannot be blanks space'),
  recipients: Yup.array()
    .of(Yup.string().required('This field is required'))
    .min(1, 'At least one recipients is required') 
});
