import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import editClose from "../../../../assets/img/icons/editClose.svg";
import { emailSent } from "../../../../assets/images";
import Button from '../../../button/Button';
import ButtonLoader from '../../../Loader/ButtonLoader';

const ResendEmail = ({ closeModal, hasModalView, onSubmit }:any) => {

    const resendInviteLoader = useSelector((state:any) => state.user.resendInviteLoader);
    const [isSubmit, setIsSubmit] = useState(false)
    const onPress = () => {
        setIsSubmit(true)
        onSubmit()
    }
    useEffect(() => {
        if (isSubmit && !resendInviteLoader) {
            closeModal();
        }
    }, [resendInviteLoader]);

    return (
        <div>
            <div
                className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${hasModalView ? "block" : "hidden"
                    }`}
            >
                <div className="popup-container absolute w-full mb-6 top-6">
                    <img
                        className="img z-10 absolute"
                        onClick={closeModal}
                        src={editClose}
                        alt=""
                    />
                    <div className="popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 relative overflow-hidden">
                        <div className="w-full justify-center flex">
                            <div className="bg-blue-200 border border-blue-700 items-center justify-center flex rounded-md w-12 h-12">
                                <img
                                    className="z-10 "
                                    onClick={closeModal}
                                    src={emailSent}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="px-10 flex w-full flex-col">
                            <div className="text-gray-900 my-6 popup-title font-semibold text-l justify-center flex">
                                Do you want to Resend Email ?
            </div>
                            <p className="text-gray-900 text-center">Are you sure you want to resend invite email to this user?
            </p>
                            <div className="popup-frm w-full float-left pt-4 pb-2">
                                <div className="flex flex-row w-full gap-2">
                                    <div className="w-2/4">
                                        <Button
                                            onPress={closeModal}
                                            value="Cancel"
                                            bgColor="#fff"
                                            borderColor="#A6A9B4"
                                            color="#A6A9B4"
                                        />
                                    </div>
                                    <div className="w-2/4 ">
                                        <Button onPress={() => onPress()} value={resendInviteLoader ? <ButtonLoader /> : "Send"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResendEmail;