import Button from '../../../button/Button';
import userBlockModal from '../../../../assets/img/icons/userBlockModal.svg';
import editClose from '../../../../assets/img/icons/editClose.svg';
import './styles.scss';
const UserBlockModal = ({
  closeModal,
  hasModalView,
  onPress,
  confirmText,
  title = 'Do you want to block this group?',
  buttonText = 'Block',
}:any) => {
  
  return (
    <div>
      <div
        className={`z-50 popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          hasModalView ? 'block' : 'hidden'
        }`}
      >
        <div className='popup-container absolute w-full mb-6 top-6'>
          <img
            className='img z-10 absolute cursor-pointer'
            onClick={closeModal}
            src={editClose}
            alt=""
          />
          <div className='popup-inner-container w-full float-left bg-white px-5 rounded-md relative overflow-hidden px-10'>
            <div className='w-full justify-center flex pt-16'>
              <div className='w-10 h-10 bg-red-200 border border-red-700 items-center justify-center flex rounded-md'>
                <img
                  width={'24px'}
                  height={'24px'}
                  className='img z-10 '
                  onClick={closeModal}
                  src={userBlockModal}
                  alt=""
                />
              </div>
            </div>
            <div className='flex w-full flex-col'>
              <div className='popup-title font-medium text-2xl justify-center flex pb-6' style={{paddingTop:'36px', color:'#161F38'}}>
                {title}
              </div>
              <p className='text-center font-medium text-base px-16' style={{paddingBottom:'28px', color:'#444C63'}}>{confirmText}</p>
              <div className='popup-frm w-full float-left'>
                <div className='flex flex-row justify-center gap-4 w-full' style={{paddingBottom:'72px'}}>
                  <div className=''>
                    <Button
                      onPress={closeModal}
                      value={`Cancel`}
                      bgColor='#fff'
                      borderColor='#A6A9B4'
                      color='#A6A9B4'
                      buttonWidth={'123px'}
                      buttonHeight={'38px'}
                    />
                  </div>
                  <div className=''>
                    <Button
                      onPress={onPress}
                      value={<span className='capitalize'>{buttonText}</span>}
                      buttonWidth={'123px'}
                      buttonHeight={'38px'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBlockModal;
