import React from "react";
import { Field, useField } from "formik";
interface propsInterface {
	name: string;
	options: any;
	initial: any;
	handleGroupCheckbox: any;
}
const CheckboxGroup = (props: propsInterface) => {
	const [field] = useField(props);
	return (
		<div className="flex flex-col">
			{props.options.map((option: any, index: number) => (
				<label key={index} className="text-base font-medium flex items-center">
					<Field
						{...field}
						className="m-3 h-4 w-4"
						{...props}
						type="checkbox"
						value={option.value}
						checked={(option.value && props.initial.includes(option.value))}
						onChange={(event:any)=>{props.handleGroupCheckbox(option.value,event.target.checked)}}
					/>
					{option.label}
				</label>
			))}
		
		</div>
	);
};

export default CheckboxGroup;
