import React, { useState } from "react";
import './Dropdown.scss'

import { AsyncPaginate } from "react-select-async-paginate";

const DropDownWithMuiltiSelect = ({ styles, options, onFieldChange, label, fetchMoreData, totalCount, isLoading }) => {
    const [value, setValue] = useState(null);
    const onChange = (e) => {
        setValue(e);
        onFieldChange(e);
    }
    const loadOptions = async (search, prevOptions) => {
        if (!isLoading) {
            fetchMoreData();
        }
        let filteredOptions;
        if (!search) {
            filteredOptions = options;
        } 
        else {
            const searchLower = search.toLowerCase();
            filteredOptions = options.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }
        const hasMore = options.length < totalCount
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 5
        );
        return {
            options: slicedOptions,
            hasMore: true
        };
    };

    return (
        <div className="muiltiselectdropdown">
            <label
                className="text-secondary font-semibold font-Lexend-Regular"
                htmlFor={label} style={{ fontSize: 11, top: -5 }}
            >
                {!!value && !!value.length && label}
            </label>
            <AsyncPaginate
                isSearchable={false}
                maxMenuHeight={250}
                value={value}
                isMulti
                loadOptions={loadOptions}
                onChange={(e) => { onChange(e) }}
                placeholder={label}
                closeMenuOnSelect={false}
                style={{overflow: 'hidden'}}
            />
        </div>
    );
};

export default DropDownWithMuiltiSelect;