/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

export const InputBox = (props: any) => {
  const [field, meta] = useField(props);
  const {
    label,
    type,
    maxLength,
    placeholder,
    name,
    righticon,
    onClickRionghtButton,
    disabled,
    defaultValue,
    isColorChange,
    isStyle,
    ...rest    
  } = props;

  const isInError = (meta.touched && meta.error && true) || false;

  return (
    <div className='w-full flex flex-col items-start relative font-Lexend-Light'>
      <div className={`form-item w-full`}>
        <input
          id={label}
          {...field}
          {...rest}
          autoComplete="new-password"
          required
          disabled={disabled}
          defaultValue={defaultValue}
          name={name}
          type={type}
          maxLength ={maxLength}
          readOnly={disabled === true}
          placeholder={placeholder}
          value={props.value}
          className={`${isStyle &&"cursor-pointer"} bg-white rounded-lg focus:outline-none py-3 block w-full appearance-none mb-1 pr-6 ${
            isInError
              ? 'border-b-error text-error placeholder::text-red-500'
              : disabled && !isColorChange
              ? ' border-b-2 text-secondary reduce-opacity font-Lexend-Light'
              : 'border-b-2 text-secondary text-black text-lg font-medium pt-5'
              
          }`}
        />
        {disabled ? (
          <label
            className='text-secondary font-semibold font-Lexend-Light'
            style={{
              fontSize: 11,
              top: -5,
              opacity: !isColorChange ? 0.48 : 1,
            }}
            htmlFor={label}
          >
            {label}
          </label>
        ) : (
          <label
            className={
              meta.touched && meta.error
                ? 'text-error'
                : 'text-secondary font-semibold font-Lexend-Light'
            }
            htmlFor={label}
          >
            {label}
          </label>
        )}
      </div>
      {righticon && (
        <div
          onClick={onClickRionghtButton}
          className='absolute top-0 right-0 h-full flex items-center '
        >
          {righticon()}
        </div>
      )}
      {meta.touched && meta.error && (
        <div className='absolute text-left text-xs mt-10 right-0 text-error font-Lexend-Light'>
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default InputBox;

InputBox.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  righticon: PropTypes.func,
  disabled: PropTypes.bool,
  setFunction: PropTypes.func,
  value: PropTypes.string,
  isColorChange: PropTypes.bool,
  isStyle: PropTypes.bool,
  onChange: PropTypes.any,
  maxLength: PropTypes.any,
  defaultValue: PropTypes.any,
};

InputBox.defaultProps = {
  label: '',
  type: '',
  placeholder: '',
  name: '',
  righticon: null,
  disabled: false,
  isColorChange: false,
  isStyle: false,
  defaultValue:'',
  
};
