import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import editClose from '../../../../assets/img/icons/editClose.svg';
import './styles.scss';
import { Formik } from 'formik';
import moment from 'moment';
import InputBox from '../../../FormikFields/InputBox/InputBox';
import Button from '../../../FormikFields/Button/Button';
import CancelButton from '../../../../components/button/Button';
import userSlice from '../../../../redux/slices/user.slice';
import { EditUserSchema } from '../../../../utils/validation/schemas';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  name: '',
  createdAt: '',
  lastAssessments: '',
};
const UserEditByGroupManager = ({ closeModal, hasModalView, selectedItem }:any) => {
  const formikRef = useRef<any>(null);

  const dispatch = useDispatch();

  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    formikRef?.current?.setValues({
      createdAt: moment(selectedItem.createdAt).format('MMMM DD, YYYY'),
      lastAssessments:
        selectedItem.assessments && selectedItem.assessments.length
          ? moment(selectedItem.assessments[0].createdAt).format(
              'MMMM DD, YYYY h:mm A'
            )
          : '--',
      email: selectedItem.email,
      firstName: selectedItem.firstName,
      lastName: selectedItem.lastName,
      name:
        selectedItem.group_details &&
        selectedItem.group_details.length &&
        selectedItem.group_details[0].groups.name,
    });
  }, []);

  const userUpdateLoader = useSelector((state:any) => state.user?.userUpdateLoader);

  const handleEdit = (value: any) => {
    setIsSubmit(true);
    dispatch(
      userSlice.actions.updateUser({
        doc_id: selectedItem.doc_id,
        firstName: value.firstName?.replace(/\s+$/, ''),
        lastName: value.lastName?.replace(/\s+$/, ''),
      })
    );
  };
  useEffect(() => {
    if (isSubmit && !userUpdateLoader) {
      closeModal();
    }
  }, [userUpdateLoader]);
  return (
    <div>
      <div
        className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          hasModalView ? 'block' : 'hidden'
        }`}
      >
        <div className='popup-container absolute w-full mb-6 top-6'>
          <img
            className='img z-10 absolute'
            onClick={closeModal}
            src={editClose}
            alt=""
          />
          <div className='popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden'>
            <div className='popup-title font-bold text-2xl flex justify-center'>
              User Edit
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={handleEdit}
              validationSchema={() => EditUserSchema}
            >
              {({ handleSubmit, values, isValid }) => (
                <div>
                  <div className='my-4'>
                    <InputBox
                      name='firstName'
                      label='First Name'
                      placeholder=''
                      value={values.firstName}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='lastName'
                      label='Last Name'
                      placeholder=''
                      value={values.lastName}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='email'
                      label='Email'
                      placeholder=''
                      value={values.email}
                      disabled={true}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='Group Name'
                      label='Email'
                      placeholder=''
                      value={values.name}
                      disabled={true}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='createdAt'
                      label='Created On'
                      placeholder=''
                      value={values.createdAt}
                      disabled={true}
                    />
                  </div>
                  <div className='my-4'>
                    <InputBox
                      name='lastAssessments'
                      label='Last Assessment'
                      placeholder=''
                      value={values.lastAssessments}
                      disabled={true}
                    />
                  </div>

                  <div className='flex w-full'>
                    <div className='w-1/2'>
                      <CancelButton
                        onPress={closeModal}
                        value={`Cancel`}
                        bgColor='#fff'
                        borderColor='#A6A9B4'
                        color='#A6A9B4'
                        disabled={false}
                      />
                    </div>

                    <div className='w-1/2'>
                      <Button
                        onClick={handleSubmit}
                        text={`${isSubmit ? 'Loading' : 'Save'}`}
                        active={isValid}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditByGroupManager;
