import { takeEvery, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ResponseGenerator } from '../../interface/Response';
import {
  GET_ALL_USER_WELLBEING,
  GET_QUICK_INFO,
  GET_GROUP_WELLBEING_PATTERN,
  GET_GROUP_WELLBEING_PATTERN_CUSTOM_DATE,
  GET_GROUP_LIST,
  GET_USER_ENERGY_STATE,
  GET_USER_EMO_INFO,
  UPDATE_CILENT_ADMIN_ACCOUNT,
  GET_REMAINING_GROUP_AND_USERS,
  USER_GROUP_CHANGE,
  GET_SETTINGS,
  EXPORT_USERS,
  EXPORT_USER_DETAIL_USERS,
  GROUP_WITH_GROUP_MANAGER,
  PACKAGE_SELECTION,
  GET_SAVED_CARDS,
  GET_DEFAULT_CARD,
  GET_DELETE_CARD,
  GET_SUBSCRIPTION_DETAIL,
  GET_USER_DETAIL,
  GET_ALL_GROUP_LIST,
  CANCEL_SUBSCRIPTION,
  INVOICE,
  GET_REMAINING_USERS,
  GET_COURSE_DETAILS,
  UPDATE_COURSE_DETAILS,
  GET_COURSE_DEFAULT_DETAILS
} from '../actions/clientAdmin.action';
import Api from '../../services/Apis';
import loaderSlice from '../slices/loader.slice';
import clientAdminSlice from '../slices/clientAdmin.slice';
import { UserGroupChangeDTO } from '../../interface/clientAdmin';
import { toast } from 'react-toastify';
import { GetUserList } from '../../interface/users';
import userSlice from '../slices/user.slice';
import { storageEngine } from '../../common/helper';
import { GetAllGroupList } from '../../interface/clientAdmin';

function* getQuickInfo(action: PayloadAction<any>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.clientAdmin.getQuickInfo(
      action.payload
    );
    storageEngine.set('active', response.data.data.is_plan_active);
    yield put(clientAdminSlice.actions.setQuickInfo(response.data.data));
    yield put(loaderSlice.actions.hide());
  } catch (err) {}
}

function* getAlluserWellBeing(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getAlluserWellBeing(action.payload);
    yield put(clientAdminSlice.actions.setAlluserWellBeing(response.data.data));
  } catch (err) {}
}

function* getGroupWellBeingPattern(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getGroupWellBeingPattern(action.payload);
    yield put(
      clientAdminSlice.actions.setGroupWellBeingPattern(response.data.data)
    );
  } catch (err) {}
}

function* getGroupWellBeingPatternCustomDate(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getGroupWellBeingPatternCustomDate(action.payload);
    yield put(
      clientAdminSlice.actions.setGroupWellBeingPattern(response.data.data)
    );
  } catch (err) {}
}

function* getGroupList(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.getGroupList(
      action.payload
    );
    yield put(clientAdminSlice.actions.setGroupList(response.data.data));
  } catch (err) {}
}

function* getAllGroupList(action: PayloadAction<GetAllGroupList>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.clientAdmin.getAllGroupList();
    yield put(clientAdminSlice.actions.setAllGroupList(response?.data?.data));
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    yield put(loaderSlice.actions.hide());
    //toast.error(err?.response?.data?.message);
    window.location.href = "/";
  }
}

function* getuserEnergyState(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getuserEnergyState(action.payload);
    yield put(clientAdminSlice.actions.setuserEnergyState(response.data.data));
  } catch (err) {}
}

function* getuserEmotionalInfo(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getuserEmotionalInfo(action.payload);
    yield put(
      clientAdminSlice.actions.setuserEmotionalInfo(response.data.data)
    );
  } catch (err) {}
}
function* updateClientAdminAccount(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.updateClientAdminAccount(action.payload);
    yield put(clientAdminSlice.actions.setSettings(response.data.data));
    yield put(
      clientAdminSlice.actions.setUpdateClientAdminAccount(response.data.data)
    );
    toast.success('Successfully saved');
  } catch (err: any) {
    toast.success(err?.response?.data?.message);
  }
}
function* getRemainingGroupsAndUsers() {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getRemainingGroupsAndUsers();
    yield put(
      clientAdminSlice.actions.setRemainingGroupsAndUsers(response.data.data)
    );
  } catch (err) {}
}

function* userGroupChange(action: PayloadAction<UserGroupChangeDTO>) {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.userGroupChange(
      action.payload
    );
    yield put(clientAdminSlice.actions.setUserGroupChange(false));
    yield put(clientAdminSlice.actions.setUserGroupChangeModal(false));
    const userListParams: GetUserList = yield select(
      (state) => state.user.userFilterParams
    );
    const inviteResponse: ResponseGenerator = yield Api.user.getUserList(
      userListParams
    );
    yield put(userSlice.actions.setUserList(inviteResponse.data.data));
    toast.success('Successfully saved');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
    yield put(clientAdminSlice.actions.setUserGroupChange(false));
  }
}
function* getSettings() {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.getSettings();
    yield put(clientAdminSlice.actions.setSettings(response.data.data));
  } catch (err) {}
}

function* exportUsers() {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.exportUsers();
    if (response?.data?.data?.data?.length > 0) {
      yield put(clientAdminSlice.actions.setexportUsers(response.data.data));
    } else {
      yield put(clientAdminSlice.actions.setExportUserListLoading());
      toast.error('No Assessment Data Found');
    }
  } catch (err: any) {
    yield put(clientAdminSlice.actions.setExportUserListLoading());
    console.log(err);
  }
}

function* handleUserDetailExport() {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.handleUserDetailExport();
    if (response?.data?.data?.data?.length > 0) {
      yield put(
        clientAdminSlice.actions.setUserDetailexportUsers(response.data.data)
      );
    } else {
      yield put(clientAdminSlice.actions.setExportUserDetailLoading());
      toast.error('No User Data Found');
    }
  } catch (err: any) {
    yield put(clientAdminSlice.actions.setExportUserDetailLoading());
    console.log(err);
  }
}

function* groupsWithGroupManager(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.groupsWithGroupManager(action.payload);
    yield put(
      clientAdminSlice.actions.setGroupsWithGroupManager(response.data.data)
    );
  } catch (err) {}
}
function* packageSelection() {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.packageSelection();
    yield put(clientAdminSlice.actions.setPackageSelection(response.data.data));
  } catch (err: any) {
    console.log(err.response.data.message);
  }
}

function* getSavedCards() {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.getSavedCards();
    yield put(clientAdminSlice.actions.setSavedCards(response.data.data));
  } catch (err) {}
}

function* defaultCard(action: any) {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.defaultCard(
      action.payload
    );
    yield put(clientAdminSlice.actions.getSavedCards());
  } catch (err) {}
}

function* deleteCard(action: any) {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.deleteCard(
      action.payload
    );
    yield put(clientAdminSlice.actions.getSavedCards());
    toast.success('Card deleted successfully');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* subscriptionDetail() {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.subscriptionDetail();
    yield put(
      clientAdminSlice.actions.setsubscriptionDetail(response.data.data)
    );
  } catch (err) {}
}
function* userDetail() {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.userDetail();
    storageEngine.set('user', response.data.data);
  } catch (err) {}
}

function* cancelSubscription() {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.cancelSubscription();
  } catch (err) {}
}
function* invoices(action: any) {
  try {
    const response: ResponseGenerator = yield Api.clientAdmin.invoices(
      action.payload
    );
    yield put(clientAdminSlice.actions.setInvoiceDetail(response.data.data));
  } catch (err) {}
}

function* getRemainingUsers(action: any) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getRemainingUsers();
    yield put(clientAdminSlice.actions.setRemainingUsers(response.data.data));
  } catch (err) {}
}

function* getCourseDetails(action: any) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getCourseDetails();
    yield put(clientAdminSlice.actions.setCourseDetails(response.data.data));
  } catch (err) {}
}

function* updateCourseDetails(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.updateCourseDetails(action.payload);
    yield put(clientAdminSlice.actions.setCourseDetails(response.data.data));
    yield put(
      clientAdminSlice.actions.setUpdateCourseDetails(response.data.data)
    );
    toast.success('Successfully saved');
  } catch (err: any) {
    toast.success(err?.response?.data?.message);
  }
}

function* getCourseDefaultDetails(action: any) {
  try {
    const response: ResponseGenerator =
      yield Api.clientAdmin.getCourseDefaultDetails();
    yield put(clientAdminSlice.actions.setCourseDefaultDetails(response.data.data));
  } catch (err) {}
}

function* clientAdminSaga() {
  yield takeEvery(GET_QUICK_INFO, getQuickInfo);
  yield takeEvery(GET_ALL_USER_WELLBEING, getAlluserWellBeing);
  yield takeEvery(GET_GROUP_WELLBEING_PATTERN, getGroupWellBeingPattern);
  yield takeEvery(
    GET_GROUP_WELLBEING_PATTERN_CUSTOM_DATE,
    getGroupWellBeingPatternCustomDate
  );
  yield takeEvery(GET_GROUP_LIST, getGroupList);
  yield takeEvery(GET_USER_ENERGY_STATE, getuserEnergyState);
  yield takeEvery(GET_USER_EMO_INFO, getuserEmotionalInfo);
  yield takeEvery(UPDATE_CILENT_ADMIN_ACCOUNT, updateClientAdminAccount);
  yield takeEvery(GET_REMAINING_GROUP_AND_USERS, getRemainingGroupsAndUsers);
  yield takeEvery(USER_GROUP_CHANGE, userGroupChange);
  yield takeEvery(GET_SETTINGS, getSettings);
  yield takeEvery(EXPORT_USERS, exportUsers);
  yield takeEvery(EXPORT_USER_DETAIL_USERS, handleUserDetailExport);
  yield takeEvery(GROUP_WITH_GROUP_MANAGER, groupsWithGroupManager);
  yield takeEvery(PACKAGE_SELECTION, packageSelection);
  yield takeEvery(GET_SAVED_CARDS, getSavedCards);
  yield takeEvery(GET_DEFAULT_CARD, defaultCard);
  yield takeEvery(GET_DELETE_CARD, deleteCard);
  yield takeEvery(GET_SUBSCRIPTION_DETAIL, subscriptionDetail);
  yield takeEvery(GET_USER_DETAIL, userDetail);
  yield takeEvery(GET_ALL_GROUP_LIST, getAllGroupList);
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
  yield takeEvery(INVOICE, invoices);
  yield takeEvery(GET_REMAINING_USERS, getRemainingUsers);
  yield takeEvery(GET_COURSE_DETAILS, getCourseDetails);
  yield takeEvery(UPDATE_COURSE_DETAILS, updateCourseDetails);
  yield takeEvery(GET_COURSE_DEFAULT_DETAILS, getCourseDefaultDetails);
}

export default clientAdminSaga;
