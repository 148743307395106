import React, { useState, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';
import Page from '../../../components/Page/Page';
import SearchBox from '../../../components/SearchBox/SearchBox';
import Paginate from '../../../components/Paginate/Paginate';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import authSlice from '../../../redux/slices/auth.slice';
import superAdminSlice from '../../../redux/slices/superAdmin.slice';
import TableLoader from '../../../components/Loader/TableLoader';
import UserBlockModal from '../../../components/modals/userList/userBlock/UserBlockModal';
import UserDeleteModal from '../../../components/modals/userList/userDelete/UserDeleteModal';
import CancelSubscription from '../../../components/modals/cancelSubscription/CancelSubscription';
import CustomMultiselect from '../../../components/Dropdown/CustomMultiselect';
import ButtonLoader from '../../../components/Loader/ButtonLoader';
import ResponsiveButton from '../../../components/responsiveButton/ResponsiveButton';
import download from '../../../assets/img/icons/download.svg';
import ExportCsvFile from '../../../components/ExportCsvFile/ExportCsvFile';
import DeleteUser from '../../../components/Tooltip/DeleteUser';
import copy from '../../../assets/img/icons/copy.svg';
import tick from '../../../assets/img/icons/tick.svg';

function SuperAdminBusinessUserList() {
  const dispatch = useDispatch();
  

  //State
  const [currentSelected, setCurrentSelected] = useState<any>(undefined);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [totalCountValue, setTotalCountValue] = useState(0);
  const [flag, setFlag] = useState(false);
  const [item, setItem]: any = useState('');
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [filterData, setFilterData] = useState<any>({
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
    search: '',
    status: ['all'],
    subscription: ['all'],
    group_id: null,
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(superAdminSlice.actions.getAllOrgList());
  }, []);

  const allOrgList = useSelector(
    (state: { superAdmin: { allOrgList: any } }) => state.superAdmin.allOrgList
  );

  const case_insensitive_comp = (strA:any, strB:any) => {
    return strA.toLowerCase().localeCompare(strB.toLowerCase());
  };

  useEffect(() => {
    let labelArray: any = [];
    let sortArray: any = [];
    if (allOrgList && allOrgList.length) {
      allOrgList.map((grp: any) => {
        const value = grp?.organization_name;
        const found = sortArray.some((el: any) => el.value === value);
        if (!found) {
          sortArray.push(value);
        }
      });
      const strValue = sortArray.sort(case_insensitive_comp);
      strValue.map((grp: any) => {
        const label = grp;
        const value = grp;
        const found = labelArray.some((el: any) => el.value === value);
        if (!found) {
          labelArray.push({ label, value });
        }
        setOptions(labelArray);
      });
    }
  }, [allOrgList]);

  useEffect(() => {
    getClientList(filterData);
  }, [filterData]);

  const getClientList = (args: any) => {
    dispatch(authSlice.actions.setTopBatTitle('Business Users'));
    dispatch(superAdminSlice.actions.getSuperAdminBusinessUserList(args));
  };

  //Redux State
  const businessUserList = useSelector(
    (state: { superAdmin: { businessUserList: any } }) =>
      state.superAdmin.businessUserList
  );
  const businessUserListLoading = useSelector(
    (state: { superAdmin: { businessUserListLoading: any } }) =>
      state.superAdmin.businessUserListLoading
  );
  const exportBusinessUserListLoading = useSelector(
    (state: { superAdmin: { exportBusinessUserListLoading: any } }) =>
      state.superAdmin.exportBusinessUserListLoading
  );
  const exportBusinessUserList = useSelector(
    (state: { superAdmin: { exportBusinessUserList: any } }) =>
      state.superAdmin.exportBusinessUserList
  );

  const copyEmailListLoading = useSelector(
    (state: { superAdmin: { copyEmailListLoading: any } }) =>
      state.superAdmin.copyEmailListLoading
  );

  const copiedEmail = useSelector(
    (state: { superAdmin: { copiedEmail: any } }) =>
      state.superAdmin.copiedEmail
  );

  const allClient = businessUserList && businessUserList?.myAllUsers;
  const totalCount = businessUserList && businessUserList?.count;

  useEffect(() => {
    setTotalCountValue(businessUserList && businessUserList?.count);
  }, [businessUserList]);

  const search = async (value: string) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      window.setTimeout(() => {
        goToSearch(value);
      }, 1000)
    );
  };

  const goToSearch = async (value:any) => {
    let args = { ...filterData, search: value };
    setFilterData(args);
    getClientList(args);
  };

  const handlePageChange = (event: any) => {
    const selected = event.selected;
    const arg = { ...filterData, page: selected + 1 };
    setFilterData(arg);
    getClientList(arg);
  };
  const handleStatus = (event: any[]) => {
    const status: any = [];
    event &&
      event.length &&
      event.map((item: any) => {
        status.push(item);
      });
    if (event.length === 0 && totalCount / 10 < 1) {
      setFlag(false);
    } else {
      setFlag(true);
    }
    if (status.length > 0) {
      let args = { ...filterData, status: status, page: 1 };
      setFilterData(args);
      getClientList(args);
    } else {
      let args = { ...filterData, status: ['all'], page: 1 };
      setFilterData(args);
      getClientList(args);
    }
  };


  const reRouteToDetails = () => {
    // history.push(`/superClientDetails/${item.doc_id}`, {
    //   clientName: item.firstName + ' ' + item.lastName,
    // });
  };
  const handleBlockedUser = () => {
    if (currentSelected.status !== 'blocked') {
      dispatch(
        superAdminSlice.actions.blockBussinessUser({
          doc_id: currentSelected?.doc_id,
          status: 'blocked',
          type: 'bussinessUser',
          filterData: filterData,
        })
      );
    } else {
      dispatch(
        superAdminSlice.actions.blockBussinessUser({
          doc_id: currentSelected?.doc_id,
          status: 'active',
          type: 'bussinessUser',
          filterData: filterData,
        })
      );
    }
    setIsBlockOpen(false);
  };
  const handleDeleteUser = () => {
    dispatch(
      superAdminSlice.actions.deleteBusinessUser({
        doc_id: item?.doc_id,
        filterData: filterData,
      })
    );
    setIsDeleteOpen(false);
  };
  const onDeleteSelected = () => {
    dispatch(
      superAdminSlice.actions.deleteBusinessUser({
        doc_id: item?.doc_id,
        filterData: filterData,
      })
    );
    setCancelSubscription(false);
  };
  const handleExport = () => {
    dispatch(superAdminSlice.actions.exportBusinessUser());
    setIsExportOpen(true);
  };
  useEffect(() => {
    if (!exportBusinessUserListLoading) {
      setIsExportOpen(false);
    }
  }, [exportBusinessUserListLoading]);

  const handleCopy = () => {
    dispatch(superAdminSlice.actions.copyEmails(filterData));
  };

  useEffect(() => {
    copiedEmail?.length > 0 && navigator.clipboard.writeText(copiedEmail);
  }, [copiedEmail]);

  const handleOrganization = (event: any) => {
    const organization: any = [];
    event &&
      event.length &&
      event.map((item: any) => ( organization.push(item)));
    if (event.length === 0 && totalCount / 10 < 1) {
      setFlag(false);
    } else {
      setFlag(true);
    }
    let args = { ...filterData, organization_name: organization, page: 1 };
    setFilterData(args);
    getClientList(args);
  };

  return (
    <Page title='Clients'>
      <div className='mx-auto px-4 sm:px-8'>
        <div className=''>
          <div className='flex w-full mb-3 justify-between'>
            <div className='md:flex md:w-2/3 md:gap-4'>
              <div className='w-full md:w-1/3 flex items-end'>
                <SearchBox
                  onChange={(e: any) => search(e.target.value)}
                  placeholder='Search by email'
                />
              </div>
              <div className='w-1/2 md:w-1/4 grid items-end'>
                <CustomMultiselect
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Blocked', value: 'blocked' },
                    { label: 'Pending', value: 'pending' },
                  ]}
                  onFieldChange={(e:any) => {
                    handleStatus(e);
                  }}
                  label={`Status`}
                />
              </div>
              <div className='w-full sm:w-1/4 sm:flex-shrink-0 flex items-end'>
                <CustomMultiselect
                  options={options}
                  onFieldChange={(e: any) => {
                    handleOrganization(e);
                  }}
                  label={`Organization`}
                />
                {/* <CustomDropdown /> */}
              </div>
            </div>
            <div className='w-full md:w-3/12 flex h-full md:justify-end mt-4'>
              <div>
                <ResponsiveButton
                  onPress={() => {
                    handleCopy();
                  }}
                  value={copyEmailListLoading ? 'Copied' : 'Copy'}
                  icon={copyEmailListLoading ? tick : copy}
                />
              </div>
              <div className='ml-4'>
                <ResponsiveButton
                  onPress={() => {
                    handleExport();
                  }}
                  value={
                    exportBusinessUserListLoading ? (
                      <ButtonLoader />
                    ) : (
                      'Download'
                    )
                  }
                  icon={download}
                />
              </div>
            </div>
            {isExportOpen && exportBusinessUserList && (
              <ExportCsvFile csvReport={exportBusinessUserList} />
            )}
          </div>
        </div>
        <div className='overflow-x-hidden relative'>
          <div className='overflow-x-auto border-user rounded-xl'>
            <div className='inline-block min-w-full rounded-lg overflow-hidden'>
              <table className='min-w-full leading-normal myborder user-table-width'>
                <thead
                  style={{
                    minWidth: 1110,
                    width: '100%',
                  }}
                >
                  <tr
                    className='flex justify-between'
                    style={{ backgroundColor: '#F5F7FF' }}
                  >
                    <th
                      style={{ width: 80 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider '
                    >
                      Sl No
                    </th>
                    {/* <th
                      style={{ width: 150 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider '
                    >
                      <div className='flex items-center justify-between '>
                        <span>User id</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('id')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th> */}
                    <th
                      style={{ width: 150 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider '
                    >
                      <div className='flex items-center justify-between '>
                        <span>First Name</span>
                        {/* <img
                          className='cursor-pointer'
                          onClick={() => handleSort('id')}
                          src={sort}
                          alt=''
                        /> */}
                      </div>
                    </th>
                    <th
                      style={{ width: 200 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Last Name</span>
                        {/* <img
                          className='cursor-pointer'
                          onClick={() => handleSort('firstName')}
                          src={sort}
                          alt=''
                        /> */}
                      </div>
                    </th>
                    {/* <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Phone Numner</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('organization_name')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th> */}
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Email</span>
                        {/* <img
                          className='cursor-pointer'
                          onClick={() => handleSort('organization_name')}
                          src={sort}
                          alt=''
                        /> */}
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Date of Birth</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Joined Date</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Organisation Name</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Gender</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Total Assessments</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 200 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Status</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 30, backgroundColor: '#F5F7FF' }}
                      className='top-border-radius px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider headcol'
                    >
                      <span style={{ color: '#F5F7FF' }}>.</span>
                    </th>
                  </tr>
                </thead>
                {!businessUserListLoading ? (
                  <tbody
                    style={{
                      minWidth: 1110,
                      width: '100%',
                    }}
                  >
                    {!!allClient &&
                      !!allClient.length &&
                      allClient.map((item: any, index: any) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 ? 'secondcolor' : 'bg-white'
                          } flex justify-between cursor-pointer`}
                        >
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 80 }}
                            className='hover px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            <div className='flex'>
                              {' '}
                              {index +
                                1 +
                                (filterData.page - 1) * filterData.limit}
                            </div>
                          </td>
                          {/* <td
                            onClick={() => reRouteToDetails(item)}
                            style={{ width: 150 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.User_id?.length > 0 ? item?.User_id : '-'}
                          </td> */}
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 150 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.firstName}
                          </td>
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 200 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.lastName}
                          </td>
                          {/* <td
                            onClick={() => reRouteToDetails(item)}
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.PhoneNumber?.length > 0 ? item?.PhoneNumber : '-'}
                          </td> */}
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.email}
                          </td>
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.dateOfBirth
                              ? moment(item.dateOfBirth).format('MMMM DD, YYYY')
                              : '-'}
                          </td>
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.createdAt
                              ? moment(item.createdAt).format(
                                  'MMMM DD, YYYY, h:mm A'
                                )
                              : '-'}
                          </td>
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.organization_name
                              ? item?.organization_name
                              : '-'}
                          </td>
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.gender}
                          </td>
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.total_assessments}
                          </td>
                          <td
                            onClick={() => reRouteToDetails()}
                            style={{ width: 200 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            <span className='relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight'>
                              <span
                                aria-hidden
                                className={`${
                                  item.status === 'active'
                                    ? 'active-bg'
                                    : item.status === 'blocked'
                                    ? 'blocked-bg'
                                    : 'pending-bg'
                                } absolute inset-0  rounded-full`}
                              ></span>
                              <span
                                className={`${
                                  item.status === 'active'
                                    ? 'active-bg'
                                    : item.status === 'blocked'
                                    ? 'blocked-bg'
                                    : 'pending-bg'
                                } relative capitalize text-xs`}
                              >
                                {item.status}
                              </span>
                            </span>
                          </td>
                          <td
                            style={{ width: 30 }}
                            className={`${
                              index % 2 ? 'secondcolor' : 'bg-white'
                            } px-5 py-3  text-sm text-right headcol`}
                          >
                            <button
                              type='button'
                              className='inline-block card-text-primary'
                              onClick={() => setCurrentSelected(item)}
                              onMouseEnter={() => ReactTooltip.hide()}
                            >
                              <DeleteUser
                                item={item}
                                key={index}
                                index={index}
                                onDeleteSelected={(item: any) => {
                                  setItem(item);
                                  setIsDeleteOpen(true);
                                }}
                                onBlockSelected={() =>
                                  setIsBlockOpen(!isBlockOpen)
                                }
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <TableLoader />
                )}
              </table>
            </div>
          </div>
          <CancelSubscription
            hasModalView={cancelSubscription}
            title='Do you want to delete this Client'
            closeModal={() => setCancelSubscription(false)}
            onPress={() => onDeleteSelected()}
            buttonTextOne='No'
            buttonTextTwo='Yes'
          />
          {isBlockOpen && (
            <UserBlockModal
              hasModalView={isBlockOpen}
              closeModal={() => setIsBlockOpen(false)}
              onPress={handleBlockedUser}
              title={`Do you want to ${
                currentSelected?.status !== 'blocked' ? 'block' : 'unblock'
              } ?`}
              confirmText={`Are you sure you want to ${
                currentSelected?.status !== 'blocked' ? 'block' : 'unblock'
              } this user?`}
              buttonText={`${
                currentSelected?.status !== 'blocked' ? 'block' : 'unblock'
              }`}
            />
          )}
          {isDeleteOpen && (
            <UserDeleteModal
              hasModalView={isDeleteOpen}
              closeModal={() => setIsDeleteOpen(false)}
              onPress={handleDeleteUser}
              type='Business User'
            />
          )}
          <div className=''>
            <Paginate
              pageCount={totalCountValue / 10}
              handlePageChange={handlePageChange}
              page={filterData.page}
              limit={filterData.limit}
              totalCount={totalCountValue}
              pageValue={1}
              flag={flag}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default SuperAdminBusinessUserList;
