import React from 'react';

export default function Button(props:any) {
  const {
    onPress,
    value,
    icon,
    bgColor = '#3A9EA5',
    borderColor = '#3A9EA5',
    color = '#fff',
    type = 'button',
    disabled,
    width,
    height,
    iconLeft,
    buttonWidth,
    buttonHeight,
    fontSize,
    fontWeight,
    opacity = props.disabled ? 0.5 : 1,
    cursor = props.disabled ? 'default' : 'pointer',
  } = props;

  return (
    <div className='flex justify-center'>
      <button
        disabled={disabled}
        type={type}
        onClick={() => {
          onPress();
        }}
        style={{
          backgroundColor: bgColor,
          borderColor: borderColor,
          color: color,
          width: buttonWidth,
          height: buttonHeight,
          opacity: opacity,
          cursor:cursor
        }}
        className={`${width ? width : 'w-40'} ${
          iconLeft && 'flex flex-row-reverse gap-2'
        } rounded-full border items-center py-4 px-1 px-auto ${
          height ? height : 'h-11'
        } focus:outline-none flex justify-center`}
      >
        <span className={`text-Lexend ${fontWeight ? fontWeight : 'font-medium'} ${fontSize ? fontSize : 'text-sm'} leading-3`}>
          {value}
        </span>

        {icon && <img className='ml-4' src={icon} alt='' />}
      </button>
    </div>
  );
}
