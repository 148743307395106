/* eslint-disable import/no-unresolved */
import { all } from 'redux-saga/effects';
import authSaga from '../sagas/auth.saga';
import group from '../sagas/group.saga';
import userSaga from '../sagas/user.saga';
import indicatorSaga from '../sagas/indicator.saga';
import groupAdminSaga from '../sagas/groupAdmin.saga';
import clientAdminSaga from '../sagas/clientAdmin.saga';
import superAdminSaga from '../sagas/superAdmin.saga';
import individualUserSaga from '../sagas/individualUser.saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    group(),
    userSaga(),
    indicatorSaga(),
    groupAdminSaga(),
    clientAdminSaga(),
    superAdminSaga(),
    individualUserSaga()
  ]);
}
