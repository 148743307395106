import withoutAuth from './withoutAuth/withoutAuth';
import user from './user/user';
import group from './group/group'
import indicator from "./indicators/indicators";
import groupAdmin from './groupAdmin/groupAdmin';
import clientAdmin from './clientAdmin/clientAdmin';
import superAdmin from './superAdmin/superAdmin';
import individualUser from './individualUser/individualUser';

const Api = {
  withoutAuth,
  user,
  group,
  indicator,
  groupAdmin,
  clientAdmin,
  superAdmin,
  individualUser
};

export default Api;
