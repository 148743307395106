/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { ModelCloseButton } from "../../../assets/images";
import { Field, Formik } from "formik";
import InputBox from "../../FormikFields/InputBox/InputBox";
import Button from "../../button/Button";
import SearchBox from "../../SearchBox/SearchBox";
import CheckboxGroup from "../../FormikFields/CheckBoxGroup/CheckboxGroup";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import groupAdminSlice from "../../../redux/slices/groupAdmin.slice";
import { LabelValueFilterDto } from "../../../interface/groupAdmin";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import SpinerRoundLoader from "../../Loader/SpinerRoundLoader";
import {
	concatUniqeId,
	concatUniqeItem,
	currentPage,
	isExitSpecialCharacters,
} from "../../../utils/utils";
import { EditGroupSchema } from "../../../utils/validation/schemas";
import LoaderButton from "../../LoaderButton/LoaderButton";

const EditGroup = (props: any) => {
	const dispatch = useDispatch();
	const formikRef = useRef<any>(null);
	const { isOpen, closeModel, handleGroupEditReload } = props as any;
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [isScrolledBottom, setIsScrolledBottom] = useState(true);
	const [recipients, setRecipients] = useState([]);
	const [initialRecipients, setInitialRecipients] = useState([""]);
	const [isFormSubmited, setIsFormSubmited] = useState(false);
	const [members, setMembers] = useState([]);
	const [scrollTimeout, setScrollTimeout] = useState(null);
	const [searchRecipientError, setSearchRecipientError] = useState(false);
	const [isShowSelectAll, setIsShowSelectAll] = useState(true);
	const [tempRecipients, setTempRecipients] = useState([]);
	const [tempUnselectedUser, setTempUnselectedUser] = useState([]);
	const [isAllSelectedDuplicate, setIsAllSelectedDuplicate] = useState(false);
	const [filterData, setFilterData] = useState({
		limit: 20,
		page: 1,
		sort: "firstName",
		order: "ASC",
		search: "",
		type: "editRecipient",
		reset: false,
	});

	const [filterDataGroupMembers, setFilterDataGroupMembers] = useState({
		group_uuid: props.groupId,
		limit: 1000,
		page: 1,
		sort: "createdAt",
		order: "DESC",
		search: "",
		reset: false,
	});
	const [typingTimeout, setTypingTimeout] = useState(0);

	const initialValues = {
		groupName: props.label,
		recipients: initialRecipients,
	};

	const reValidateForm = () => {
		setTimeout(
			() => {
				formikRef.current.validateForm();
			},
			[500] as any
		);
	};

	const handleCloseModel = () => {
		setIsAllSelected(false);
		const arg: any = { ...filterData, reset: true };
		const argMember: any = { ...filterDataGroupMembers, reset: true };
		dispatch(groupAdminSlice.actions.getChatRecipients(arg));
		dispatch(groupAdminSlice.actions.getGroupMembers(argMember));
		setInitialRecipients([]);
		closeModel();
	};

	const getIdOfMembers = (items: { id: any }[]) => {
		return items ? items.map((item) => item.id) : [];
	};

	useEffect(() => {
		getRecipients(filterData);
	}, [filterData]);

	useEffect(() => {
		setInitialRecipients([]);
		getMembers(filterDataGroupMembers);
	}, [filterDataGroupMembers]);

	const getRecipients = (args: any) => {
		dispatch(groupAdminSlice.actions.getChatRecipients(args));
	};

	const getMembers = (args: any) => {
		setInitialRecipients([""]);
		dispatch(groupAdminSlice.actions.getGroupMembers(args));
	};

	const chatRecipients = useSelector(
		(state: { groupAdmin: { chatEditRecipient: any } }) =>
			state.groupAdmin.chatEditRecipient
	);

	const groupMembers = useSelector(
		(state: { groupAdmin: { groupMembers: any } }) =>
			state.groupAdmin.groupMembers
	);

	const editGroupDetails = useSelector(
		(state: { groupAdmin: { editChatGroup: any } }) =>
			state.groupAdmin.editChatGroup
	);
	useEffect(() => {
		if (groupMembers) {
			setMembers(groupMembers.return_array);
			const filterMembers = groupMembers.return_array.map((item: any) => {
				return { label: `${item.firstName} ${item.lastName}`, value: item.id };
			});
			setInitialRecipients(getIdOfMembers(groupMembers.return_array));
			formikRef.current.values.recipients = getIdOfRecipients(filterMembers);
		}
	}, [groupMembers]);

	useEffect(() => {
		if (isFormSubmited) {
			handleGroupEditReload(formikRef.current.values.groupName, props.groupId);
			handleCloseModel();
		}
	}, [editGroupDetails]);

	const handlePageChange = (page: number) => {
		clearTimeout(scrollTimeout as any);
		setScrollTimeout(
			setTimeout(() => {
				const arg = { ...filterData, page: page + 1 };
				setFilterData(arg);
			}, 1000) as any
		);
	};

	let filterRecipients: LabelValueFilterDto[] = [];

	useEffect(() => {
		if (chatRecipients && chatRecipients?.count) {
			setIsScrolledBottom(false);
			filterRecipients = chatRecipients.data.map((item: any) => {
				return { label: `${item.firstName} ${item.lastName}`, value: item.id };
			});

			if (filterRecipients.length !== 0) {
				const mergedRecipients: any = concatUniqeItem(
					recipients,
					filterRecipients
				);
				setRecipients(mergedRecipients);

				if (isAllSelected || isAllSelectedDuplicate) {
					populateCheckBoxRecipients(mergedRecipients);
				}
			}
		} else if (chatRecipients && chatRecipients?.count === 0) {
			setIsScrolledBottom(false);
		}
	}, [chatRecipients]);

	const getIdOfRecipients = (items: { value: any }[]) => {
		return items ? items.map((item) => item.value) : [];
	};

	const populateCheckBoxRecipients = (items: any) => {
		if (isAllSelected && isAllSelectedDuplicate) {
			setInitialRecipients(getIdOfRecipients(items));
			formikRef.current.values.recipients = getIdOfRecipients(items);
		} else if (!isAllSelected && isAllSelectedDuplicate) {
			const uncheckedUsers = tempUnselectedUser;
			const concatUncheckedUser = concatUniqeId(
				tempUnselectedUser,
				uncheckedUsers
			);
			setTimeout(
				() => {
					if (isShowSelectAll) {
						setTempUnselectedUser(concatUncheckedUser);
					}
				},
				[100] as any
			);
			setInitialRecipients(
				getSelectedUserByUncheckedUser(items, concatUncheckedUser)
			);
			formikRef.current.values.recipients = getSelectedUserByUncheckedUser(
				items,
				concatUncheckedUser
			);
		} else {
			setInitialRecipients([]);
			formikRef.current.values.recipients = [];
		}
	};

	useEffect(() => {
		if (isShowSelectAll) {
			populateCheckBoxRecipients(recipients);
			setTempUnselectedUser([]);
		}
	}, [isAllSelected]);

	const handleEditGroup = (form: any) => {
		let body = {};
		if (isAllSelected || isAllSelectedDuplicate) {
			const uncheckedUsers = tempUnselectedUser;
			body = {
				is_select_all: true,
				selected_recipient_array: getNewlyAdded(
					getIdOfMembers(members),
					initialRecipients
				),
				unselected_recipient_array: getNewlyRemoved(
					getIdOfMembers(members),
					initialRecipients
				),
				overall_recipient_array: uncheckedUsers,
				name: form.groupName,
				group_uuid: props.groupId,
			};
		} else {
			body = {
				is_select_all: false,
				selected_recipient_array: getNewlyAdded(
					getIdOfMembers(members),
					initialRecipients
				),
				unselected_recipient_array: getNewlyRemoved(
					getIdOfMembers(members),
					initialRecipients
				),
				overall_recipient_array: [],
				name: form.groupName,
				group_uuid: props.groupId,
			};
		}
		dispatch(groupAdminSlice.actions.editChatGroup(body as any & void));
		setIsFormSubmited(true);
	};

	const getUncheckedUsers = (users: any, initialUsers: any) => {
		let selectedUsers: any = [];
		users.map((item: any) => {
			if (!initialUsers.includes(item.value)) {
				selectedUsers.push(item.value);
			}
		});
		return selectedUsers;
	};

	const getSelectedUserByUncheckedUser = (users: any, uncheckedUsers: any) => {
		let selectedUsers: any = [];
		users.map((item: any) => {
			if (!uncheckedUsers.includes(item.value)) {
				selectedUsers.push(item.value);
			}
		});
		return selectedUsers;
	};

	const getNewlyAdded = (members: any, initialUsers: any) => {
		return initialUsers.filter((item: any) => !members.includes(item));
	};

	const getNewlyRemoved = (members: any, initialUsers: any) => {
		return members.filter((item: any) => !initialUsers.includes(item));
	};

	const handleScroll = () => {
		if (chatRecipients && chatRecipients.count <= recipients.length) {
			setIsScrolledBottom(false);
		} else {
			setIsScrolledBottom(true);
			handlePageChange(currentPage(recipients.length, 20));
		}
	};

	const scrollRef = useBottomScrollListener(handleScroll);

	const search = async (value: string) => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		if (!isExitSpecialCharacters(value) || value === "") {
			setSearchRecipientError(false);
			setTypingTimeout(
				window.setTimeout(() => {
					setIsScrolledBottom(true);
					setRecipients([]);
					goToSearch(value);
					if (value === "") {
						setIsShowSelectAll(true);
					} else {
						setIsShowSelectAll(false);
					}
				}, 1000)
			);
		} else {
			setSearchRecipientError(true);
		}
	};

	const goToSearch = async (value: any) => {
		let args = { ...filterData, search: value, page: 1 };
		setFilterData(args);
		getRecipients(args);
	};

	const handleGroupCheckbox = (id: any, isChecked: boolean) => {
		reValidateForm();
		if (!isChecked) {
			const newPopulatedRecipients = initialRecipients.filter(
				(item) => item !== id
			);
			setInitialRecipients(newPopulatedRecipients);
			formikRef.current.values.recipients = newPopulatedRecipients;
			if (isAllSelected) {
				setIsAllSelected(false);
			}

			setTempUnselectedUser([...tempUnselectedUser, id] as any);
		} else {
			const appendInitialRecipient = [...initialRecipients, id];
			setInitialRecipients(appendInitialRecipient);
			formikRef.current.values.recipients = [...initialRecipients, id];
			if (
				appendInitialRecipient.length === recipients.length &&
				isShowSelectAll
			) {
				setIsAllSelected(true);
			}
			if (!isAllSelected && isAllSelectedDuplicate) {
				const arr = tempUnselectedUser.filter(function (value) {
					return value !== id;
				});
				setTempUnselectedUser(arr);
			}
			setTempRecipients(recipients);
		}
	};

	useEffect(() => {
		if (chatRecipients && chatRecipients?.count) {
			if (
				initialRecipients.length === chatRecipients.count &&
				isShowSelectAll
			) {
				setIsAllSelected(true);
				setIsAllSelectedDuplicate(true);
			}
		}
	}, [initialRecipients, chatRecipients]);

	return (
		<div>
			<div
				className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
					isOpen ? "block" : "hidden"
				}`}
			>
				<div className="popup-container absolute w-full mb-6 top-6">
					<img
						className="img z-10 absolute"
						onClick={() => handleCloseModel()}
						src={ModelCloseButton}
						alt=""
					/>
					<div className="popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
						<div className="w-full justify-center flex">
							<div className="font-Lexend-Regular font-medium text-secondary text-2xl">
								Edit Group
							</div>
						</div>
						<div className="w-full h-px bg-secondary-light my-6" />

						<Formik
							innerRef={formikRef}
							initialValues={initialValues}
							onSubmit={handleEditGroup}
							validationSchema={() => EditGroupSchema}
						>
							{({ values, errors, handleSubmit, touched, isValid }) => (
								<div>
									<div className="">
										<InputBox
											name="groupName"
											label="Group Name"
											placeholder=""
											defaultValue={props.label}
										/>
									</div>
									<div className=" font-medium text-xl py-2 mt-1">
										Select Group Members
									</div>

									<div
										className={`${
											searchRecipientError ? "search-error-bottom" : ""
										}`}
									>
										<SearchBox
											className="mt-1"
											onChange={(e: any) => search(e.target.value)}
											placeholder="Search by first name..."
										/>
										<p className="error-message">
											No special characters allowed
										</p>
									</div>
									{recipients.length !== 0 && (
										<>
											<div
												style={{ display: isShowSelectAll ? "block" : "none" }}
											>
												<label className="text-base font-medium flex items-center">
													<Field
														className="m-3 h-4 w-4"
														{...props}
														type="checkbox"
														checked={isAllSelected}
														onChange={() => {
															setIsAllSelected(!isAllSelected);
															setIsAllSelectedDuplicate(!isAllSelected);
															reValidateForm();
														}}
													/>
													Select All
												</label>
											</div>
										</>
									)}

									<div
										ref={scrollRef as React.RefObject<HTMLDivElement>}
										className="recipient-list mostly-customized-scrollbar"
									>
										<CheckboxGroup
											name="recipients"
											initial={initialRecipients}
											options={recipients}
											handleGroupCheckbox={handleGroupCheckbox}
										/>
										{recipients.length === 0 && !isScrolledBottom ? (
											<>
												<div style={{ textAlign: "center" }} className="m-5">
													<p>No data found</p>
												</div>
											</>
										) : (
											<></>
										)}
										{isScrolledBottom ? (
											<>
												<div className="m-5">
													<SpinerRoundLoader size={20} />
												</div>
											</>
										) : (
											<></>
										)}
									</div>
									{touched.recipients && errors.recipients && (
										<div className="text-left absolute left-5 text-xs mt-2  text-error font-Lexend-Light">
											{errors.recipients}
										</div>
									)}

									<div className="mt-8 flex justify-center">
										<div className="w-1/2">
											{isFormSubmited ? (
												<>
													<LoaderButton value="Updating..." />
												</>
											) : (
												<Button onPress={handleSubmit} value="Update" />
											)}
										</div>
									</div>
								</div>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
};
export default EditGroup;
