import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PageLoader from '../../components/Loader/PageLoader';
const ForgotPassword = lazy(
  () => import('../Auth/ForgotPassowrd/ForgotPassword')
);
const EmailNotFound = lazy(() => import('../Auth/EmailNotFound/EmailNotFound'));
const EmailSendConfirmation = lazy(
  () => import('../Auth/EmailSendConfirmation/EmailSendConfirmation')
);
const CreatePassword = lazy(
  () => import('../Auth/CreatePassword/CreatePassword')
);
const PasswordChangedConfirmation = lazy(
  () =>
    import('../Auth/PasswordChangedConfirmation/PasswordChangedConfirmation')
);

const ForgotPasswordLayout = () => {
  return (
    <section className='bg-primary w-screen h-screen'>
      <div className='bg-primary w-full min-h-full flex flex-col justify-center items-center py-5 overflow-auto'>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route exact path='/forgot-password'>
              <ForgotPassword />
            </Route>
            <Route exact path='/email-not-found'>
              <EmailNotFound />
            </Route>
            <Route exact path='/password-changed-confirmation'>
              <PasswordChangedConfirmation />
            </Route>
            <Route exact path='/create-new-password/:id'>
              <CreatePassword />
            </Route>
            <Route exact path='/email-send-confirmation'>
              <EmailSendConfirmation />
            </Route>
          </Switch>
        </Suspense>
        <footer
          className='flex justify-center font-Lexend-Light text-base pt-4'
          style={{ color: '#D1E6F0' }}
        >
          © Copyright Vibeonix - All Rights Reserved.
        </footer>
      </div>
    </section>
  );
};

export default ForgotPasswordLayout;
