import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

const ChatTitle = (props: { activeUser: any }) => {
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < 622 ? true : false
	);
	const handleResize = () => {
		if (window.innerWidth < 622) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};
	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});

	const truncate = (textvalue: string) => {
		return textvalue.length > 6 ? textvalue.substring(0, 5) + ".." : textvalue;
	};
	return (
		<>
			<div data-tip data-for="tip3" className="text-16px font-semibold m-6">
				{props.activeUser && props.activeUser.label ? (
					<>
						{isMobile
							? truncate(props.activeUser.label)
							: props.activeUser.label}
					</>
				) : (
					""
				)}
			</div>
			{isMobile && (
				<ReactTooltip className="react-tooltip" id="tip3" place="top" effect="solid">
					{props.activeUser && props.activeUser.label
						? props.activeUser.label
						: ""}
				</ReactTooltip>
			)}
		</>
	);
};
export default ChatTitle;
