import ReactTooltip from 'react-tooltip';
import './styles.scss';
export default function EditBlockDelete({
  onPendingDots,
  onEditSelected,
  index,
  onBlockSelected,
  onDeleteSelected,
  item,
  onReSendEmailRequest,
}: any) {
  return (
    <div className='editBlockDeleteTooltop cursor-pointer'>
      <div onClick={() => onPendingDots()}>
        <div className="cursor-pointer" data-for={`editBlockDelete${index}`} data-tip='8'>
          <svg
            className='inline-block h-6 w-6 fill-current'
            viewBox='0 0 24 24'
          >
            <path d='M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z' />
          </svg>
        </div>
      </div>
      <ReactTooltip
        id={`editBlockDelete${index}`}
        getContent={() => (
          <div className='flex flex-col'>
            {/* {onPendingDots()} */}
            <button className='pb-1 flex' onClick={() => onEditSelected()}>
              Edit User
            </button>
            <div className='h-px w-full bg-white' />
            {item?.status !== 'pending' && (
              <>
                <button className='py-1 flex width-style' onClick={() => onBlockSelected()}>
                  {item?.status === 'blocked' ? 'Unblock User' : 'Block User'}
                </button>
                <div className='h-px w-full bg-white' />
              </>
            )}

            <button className='pt-1 flex' onClick={() => onDeleteSelected()}>
              Delete User
            </button>
            {item?.status === 'pending' && (
              <>
                <div className='h-px mt-1 w-full bg-white' />
                <button
                  className='pt-1 flex gap-2'
                  onClick={() => onReSendEmailRequest()}
                >
                  Resend Email
                </button>
              </>
            )}
          </div>
        )}
        clickable={true}
        multiline={true}
        backgroundColor={`#3A9EA5`}
        place='left'
        event='click'
        globalEventOff='click'
      />
    </div>
  );
}
