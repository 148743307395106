export const GET_QUICK_INFO = "groupAdmin/getQuickInfo";
export const GET_USER_WELLBEING = "groupAdmin/getuserWellBeing";
export const GET_USER_ENERGY_STATE = "groupAdmin/getuserEnergyState";
export const GET_USER_WELLBEING_PATTERN = "groupAdmin/getuserWellBeingPattern";
export const GET_USER_EMO_INFO = "groupAdmin/getuserEmotionalInfo";
export const GET_REMAINING_USERS = 'groupAdmin/getRemainingUsers';
export const APP_TOUR_FINISHED = 'groupAdmin/appTourFinished';
export const GET_INVITE_LINK = 'groupAdmin/getInviteLink';
export const GET_CHAT_RECIPIENTS = 'groupAdmin/getChatRecipients';
export const GET_CHAT_GROUPS = 'groupAdmin/getChatGroups';
export const CREATE_CHAT_GROUP = 'groupAdmin/createChatGroup';
export const GET_CHAT_MESSAGES = 'groupAdmin/getChatMessages';
export const SEND_CHAT_MESSAGE = 'groupAdmin/sendChatMessage';
export const DELETE_CHAT_GROUP = 'groupAdmin/deleteChatGroup';
export const EDIT_CHAT_GROUP = 'groupAdmin/editChatGroup';
export const GET_GROUP_MEMBERS = 'groupAdmin/getGroupMembers';
export const GET_PRIVATE_VIMEO_VIDEO = 'groupAdmin/getPrivateVimeoVideo';