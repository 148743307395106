import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { navigator } from "../../navigation/navigator";
import loaderSlice from "../slices/loader.slice";
import authSlice from "../slices/auth.slice";
import { storageEngine } from "../../common/helper";
import Api from "../../services/Apis/index";
import {
	LOGIN,
	LOGOUT,
	USER_SIGN_UP,
	ORGANIZATION_DETAILS,
	SET_CLIENT_REGISTER,
	SEND_VERIFICATION_EMAIL,
	SEND_EMAIL_To_VERIFY,
	GROUP_ADMIN_SIGN_UP,
	CHANGE_PASSWORD,
	FORGOTPASSWORD,
	CHECK_IF_EMAIL_ALREADY_EXISTS,
	IS_ALREADY_REGISTERED,
	INDIVIDUAL_USER_SIGN_UP,
	RESEND_EMAIL,
	INDIVIDUAL_USER_PLANS,
	INDIVIDUAL_USER_ADD_CARD,
	INDIVIDUAL_SUBSCRIPTION_DETAIL,
	EMAIL_UNSUBSCRIBE,
	SWITCH_ACCOUNT,
} from "../actions/auth.actions";
import { setToken } from "../../services/Apis/user/user";
import { ResponseGenerator } from "../../interface/Response";
import {
	LoginUser,
	CreateUser,
	CreateClientAdmin,
	EmailInterface,
	GroupAdminSignUp,
	CheckEmail,
	IndividualUserSignup,
} from "../../interface/users";
import individualUserSlice from "../slices/individualUser.slice";

function* login(action: PayloadAction<LoginUser>) {
	try {
		yield put(loaderSlice.actions.show());
		const response: ResponseGenerator = yield Api.withoutAuth.login(
			action.payload
		);
		yield put(authSlice.actions.setUserData(response.data.data));
		const { access_token, refresh_token } = response.data;
		const user = response.data.data;
		const role = user.roles.role;
		if (
			role === "clientAdmin" ||
			role === "group" ||
			role === "vibeonixAdmin" ||
			role === "individual"
		) {
			toast.success("Successfully Logged In");
			storageEngine.set("user", response.data.data);
			storageEngine.set("appTour", response.data.app_tour);
			storageEngine.set("active", response.data.data.is_plan_active);
			setToken(access_token, refresh_token);
			navigator.history.replace("/");
		} else {
			toast.error("Permission Denied");
		}
		if (role === "individual") {
			yield put(individualUserSlice.actions.setUserData(response.data.data));
		}
		yield put(authSlice.actions.setAuthentication(true));
		yield put(loaderSlice.actions.hide());
		yield put(authSlice.actions.setlogin(true));
	} catch (err: any) {
		if (err.response.status === 411) {
			yield put(authSlice.actions.setAuthentication(false));
			yield put(loaderSlice.actions.hide());
			yield put(authSlice.actions.setlogin(true));
			yield put(
				authSlice.actions.setCurrentUserEmail({ email: action.payload.email })
			);
			navigator.history.replace("/individualUser/feature-list");
			toast.error(err?.response?.data?.message);
		} else {
			yield put(authSlice.actions.setAuthentication(false));
			yield put(loaderSlice.actions.hide());
			yield put(authSlice.actions.setlogin(true));
			toast.error(err?.response?.data?.message);
		}
	}
}

function* logout() {
	try {
		yield put(loaderSlice.actions.show());
		yield Api.user.logout();
		storageEngine.unset("user");
		storageEngine.unset("access_token");
		storageEngine.unset("refresh_token");
		storageEngine.unset("appTour");
		storageEngine.unset("active");
		navigator.history.replace("/");
		yield put(loaderSlice.actions.hide());
		toast.warn("Successfully Logged Out");
		yield put(authSlice.actions.setAuthentication(false));
		// setTimeout(() => {
		//   window.location.reload(true);
		// }, 500);
	} catch (err) {
		yield put(loaderSlice.actions.hide());
	}
}

function* userSignUp(action: PayloadAction<CreateUser>) {
	try {
		yield put(loaderSlice.actions.show());
		yield Api.withoutAuth.userSignUp(action.payload);
		navigator.history.replace("/thanksforsignup");
		yield put(loaderSlice.actions.hide());
	} catch (e: any) {
		toast.error(e.response.data.message);
	}
}

function* individualUserSignup(action: PayloadAction<IndividualUserSignup>) {
	try {
		yield put(authSlice.actions.individualUserSignupLoader(true));
		yield put(loaderSlice.actions.show());
		storageEngine.set("emailToVerify", action.payload.email);
		if (action.payload.inviteLinkUuid) {
			yield Api.withoutAuth.individualAcceptInvite(action.payload);
			yield put(authSlice.actions.individualUserSignupLoader(false));
			window.location.href = "https://vibeonix.com/thank-you.html";
		} else {
			yield Api.withoutAuth.individualUserSignup(action.payload);
			yield put(authSlice.actions.individualUserSignupLoader(false));
			navigator.history.replace("/individualUser/email-verification");
		}
		yield put(loaderSlice.actions.hide());
	} catch (e: any) {
		yield put(authSlice.actions.individualUserSignupLoader(false));
		toast.error(e.response.data.message);
	}
}

function* getIndividualUserPlans() {
	try {
		yield put(loaderSlice.actions.show());
		const response: ResponseGenerator =
			yield Api.withoutAuth.individualUserPlans();
		yield put(authSlice.actions.setIndividualUserPlans(response?.data?.data));
		yield put(loaderSlice.actions.hide());
	} catch (e: any) {
		toast.error(e.response.data.message);
	}
}

function* individualUserAddCard(action: PayloadAction<any>) {
	try {
		yield put(loaderSlice.actions.show());
		const response: ResponseGenerator =
			yield Api.withoutAuth.individualUserAddCard(action.payload);
		yield put(loaderSlice.actions.hide());
		window.location.href = "https://vibeonix.com/thank-you.html";
	} catch (e: any) {
		toast.error(e.response.data.message);
	}
}

function* resendEmail(action: PayloadAction<EmailInterface>) {
	try {
		const response: ResponseGenerator = yield Api.withoutAuth.resendEmail(
			action.payload
		);
		toast.success("Verification email resend successfully");
	} catch (err: any) {
		toast.error(err.response.data.message);
	}
}

function* organizationDetails(action: any) {
	try {
		// yield put(loaderSlice.actions.show());
		const response: ResponseGenerator = yield Api.withoutAuth.clientSignUp(
			action.payload
		);
		yield put(authSlice.actions.setOrganizationDetailsLoader(false));
		navigator.history.replace("/email-Verify");
		storageEngine.set("emailToVerify", action.payload.email);
		// yield put(loaderSlice.actions.hide());
	} catch (e) {
		yield put(authSlice.actions.setOrganizationDetailsLoader(false));
	}
}

function* reRouteToOrganization(action: PayloadAction<CreateClientAdmin>) {
	const emailExist: ResponseGenerator = yield Api.withoutAuth.existingEmail({
		email: action.payload.email,
	});
	if (!emailExist.data.data) {
		yield put(authSlice.actions.setClientRegisterLoader(false));
		navigator.history.replace("/organizationDetails");
	} else {
		toast.error("Email already exists");
		yield put(authSlice.actions.setClientRegisterLoader(false));
	}
}

function* sendVerificationEmail(action: PayloadAction<EmailInterface>) {
	try {
		const response: ResponseGenerator =
			yield Api.withoutAuth.sendVerificationEmail(action.payload);
		toast.success("Verification email resent successfully");
	} catch (err: any) {
		console.log(err.response.data.message);
	}
}

function* forgotPassword(action: PayloadAction<EmailInterface>) {
	try {
		const responseUserRole: ResponseGenerator =
			yield Api.withoutAuth.userRoleCheck(action.payload);

		const role = responseUserRole.data.data.role;
		if (role === "user") {
			yield put(authSlice.actions.setforgotPassword(false));
			toast.error("Email not found");
			return false;
		}
		const response: ResponseGenerator = yield Api.withoutAuth.forgotPassword(
			action.payload
		);
		yield put(authSlice.actions.setforgotPassword(response.data.data));
		navigator.history.replace("/email-send-confirmation");
		toast.success("Email sent successfully");
	} catch (err: any) {
		yield put(authSlice.actions.setforgotPassword(false));
		navigator.history.replace("/email-not-found");
		console.log(err.response.data.message);
	}
}

function* sendEmailToVerify(action: PayloadAction<EmailInterface>) {
	try {
		const response: ResponseGenerator = yield Api.withoutAuth.sendEmailToVerify(
			action.payload
		);
		response?.data?.data?.type === "individualUser"
			? navigator.history.replace("/individualUser/feature-list")
			: navigator.history.replace("/");
		toast.success("Verified your email");
	} catch (err: any) {
		toast.error(err.response.data.message);
		navigator.history.replace("/");
	}
}

function* emailUnsubscribe(action: PayloadAction<EmailInterface>) {
	try {
		const response: ResponseGenerator = yield Api.withoutAuth.emailUnsubscribe(
			action.payload
		);
		navigator.history.replace("/");
		toast.success("Email Unsubscribed successfully");
	} catch (err: any) {
		toast.error(err.response.data.message);
		navigator.history.replace("/");
	}
}

function* groupAdminSignUp(action: PayloadAction<GroupAdminSignUp>) {
	try {
		const response: ResponseGenerator = yield Api.withoutAuth.groupAdminSignUp(
			action.payload
		);
		toast.success("SignUp Successfully");
		yield put(authSlice.actions.setGroupAdminSignUp(false));
		navigator.history.replace("/");
	} catch (e: any) {
		toast.error(e.response.data.message);
		yield put(authSlice.actions.setGroupAdminSignUp(false));
	}
}

function* changePassword(action: PayloadAction<LoginUser>) {
	try {
		const response: ResponseGenerator = yield Api.withoutAuth.changePassword(
			action.payload
		);
		navigator.history.replace("/password-changed-confirmation");
	} catch (err: any) {
		navigator.history.replace("/email-not-found");
		console.log(err.response.data.message);
	}
}

function* existingEmail(action: PayloadAction<CheckEmail>) {
	try {
		const response: ResponseGenerator = yield Api.withoutAuth.existingEmail(
			action.payload
		);
		yield put(authSlice.actions.doesEmailExist(response.data));
	} catch (err: any) {
		console.log(err.response.data.message);
	}
}

function* isAlreadyRegistered(action: PayloadAction<CheckEmail>) {
	try {
		const response: ResponseGenerator =
			yield Api.withoutAuth.isAlreadyRegistered(action.payload);
		if (response.data.data) {
			navigator.history.replace("/");
			toast.error(`You've already registered. Please login to continue`);
		}
	} catch (err: any) {
		console.log(err.response.data.message);
	}
}

function* subscriptionDetail() {
	try {
		const response: ResponseGenerator =
			yield Api.withoutAuth.subscriptionDetail();
		if (response.data.data) {
			yield put(authSlice.actions.setSubscriptionDetail(response.data.data));
		}
	} catch (err: any) {
		console.log(err.response.data.message);
	}
}

function* switchAccount(action: PayloadAction<any>) {
	try {
		yield put(loaderSlice.actions.show());
		const response: ResponseGenerator = yield Api.groupAdmin.switchAccount(
			action.payload
		);
		yield put(authSlice.actions.setUserData(response.data.data));
		const { access_token, refresh_token } = response.data.data;
		const user = response.data.data;

		const role = user.data.roles.role;
		
		if (role === "clientAdmin" || role === "group") {
			toast.success("Successfully Switched");
			storageEngine.set("user", response.data.data.data);
			storageEngine.set("appTour", response.data.data.app_tour);
			storageEngine.set("active", response.data.data.data.is_plan_active);
			setToken(access_token, refresh_token);
			navigator.history.replace("/");
		} else {
			toast.error("Permission Denied");
		}

		yield put(authSlice.actions.setAuthentication(true));
		yield put(loaderSlice.actions.hide());
		yield put(authSlice.actions.setlogin(true));
	} catch (err: any) {
		console.log(err);
		if (err.response.status === 411) {
			yield put(authSlice.actions.setAuthentication(false));
			yield put(loaderSlice.actions.hide());
			yield put(authSlice.actions.setlogin(true));
			yield put(
				authSlice.actions.setCurrentUserEmail({ email: action.payload.email })
			);
			navigator.history.replace("/individualUser/feature-list");
			toast.error(err?.response?.data?.message);
		} else {
			yield put(authSlice.actions.setAuthentication(false));
			yield put(loaderSlice.actions.hide());
			yield put(authSlice.actions.setlogin(true));
			toast.error(err?.response?.data?.message);
		}
	}
}

function* authSaga() {
	yield takeEvery(USER_SIGN_UP, userSignUp);
	yield takeEvery(LOGIN, login);
	yield takeEvery(LOGOUT, logout);
	yield takeEvery(ORGANIZATION_DETAILS, organizationDetails);
	yield takeEvery(SEND_VERIFICATION_EMAIL, sendVerificationEmail);
	yield takeEvery(SEND_EMAIL_To_VERIFY, sendEmailToVerify);
	yield takeEvery(GROUP_ADMIN_SIGN_UP, groupAdminSignUp);
	yield takeEvery(FORGOTPASSWORD, forgotPassword);
	yield takeEvery(CHANGE_PASSWORD, changePassword);
	yield takeEvery(CHECK_IF_EMAIL_ALREADY_EXISTS, existingEmail);
	yield takeEvery(IS_ALREADY_REGISTERED, isAlreadyRegistered);
	yield takeEvery(SET_CLIENT_REGISTER, reRouteToOrganization);
	yield takeEvery(INDIVIDUAL_USER_SIGN_UP, individualUserSignup);
	yield takeEvery(RESEND_EMAIL, resendEmail);
	yield takeEvery(INDIVIDUAL_USER_PLANS, getIndividualUserPlans);
	yield takeEvery(INDIVIDUAL_USER_ADD_CARD, individualUserAddCard);
	yield takeEvery(INDIVIDUAL_SUBSCRIPTION_DETAIL, subscriptionDetail);
	yield takeEvery(EMAIL_UNSUBSCRIBE, emailUnsubscribe);
	yield takeEvery(SWITCH_ACCOUNT, switchAccount);
}

export default authSaga;
