/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import clientAdminSlice from "../../redux/slices/clientAdmin.slice";
import { useDispatch, useSelector } from "react-redux";
import authSlice from "../../redux/slices/auth.slice";
import { Formik, useFormikContext } from "formik";
import InputBox from "../../components/FormikFields/InputBox/InputBox";
import { ActionSchema } from "../../utils/validation/schemas";
import ButonComponent from "../../components/button/Button";
import { CourseDetailsDto } from "../../interface/clientAdmin";
import LoaderButton from "../../components/LoaderButton/LoaderButton";

const Actions = () => {
	const dispatch = useDispatch();
	const formikRef = useRef<any>(null);

	const [isPersonalEditOpen, setIsPersonalEditOpen] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState({
		buttonName: "",
		buttonUrl: "",
	});

	const courseDetails = useSelector(
		(state: any) => state.clientAdmin.courseDetails
	);
	const courseDefaultDetails = useSelector(
		(state: any) => state.clientAdmin.courseDefaultDetails
	);

	const courseUpdateDetails = useSelector(
		(state: any) => state.clientAdmin.courseUpdateDetails
	);
	useEffect(() => {
		dispatch(authSlice.actions.setTopBatTitle("actions"));
		getInitialCours();
		getInitialDefaultCours();
		setTimeout(() => {
			let x = document.getElementsByClassName("top-title"); // Find the element
			x[0].innerHTML = "Learning Library"; // Change the content
		});
	}, []);

	const getInitialCours = () => {
		dispatch(clientAdminSlice.actions.getCourseDetails());
	};

	const getInitialDefaultCours = () => {
		dispatch(clientAdminSlice.actions.getCourseDefaultDetails());
	};

	useEffect(() => {
		if (courseUpdateDetails) {
			setIsFormSubmitting(false);
			setIsFormChanged(false);
		}
	}, [courseUpdateDetails]);

	useEffect(() => {
		if (courseDetails) {
			setFormikValues();
		}
	}, [courseDetails]);
	const setFormikValues = () => {
		formikRef.current.setValues({
			buttonName: courseDetails?.course_button_placeholder,
			buttonUrl: courseDetails?.course_button_url,
		});
		setInitialValues({
			buttonName: courseDetails?.course_button_placeholder,
			buttonUrl: courseDetails?.course_button_url,
		});
	};

	const updateCourse = (values: any) => {
		setIsFormSubmitting(true);
		dispatch(
			clientAdminSlice.actions.updateCourseDetails({
				course_button_placeholder: values.buttonName?.replace(/\s+$/, ""),
				course_button_url: values.buttonUrl,
			} as CourseDetailsDto & void)
		);
		setInitialValues({
			buttonName: values.buttonName?.replace(/\s+$/, ""),
			buttonUrl: values.buttonUrl,
		});
		setIsPersonalEditOpen(false);
		setIsFormChanged(false);
	};

	const formEmptyStateCheck = (e: any) => {
		if (courseDetails && courseDefaultDetails) {
			if (e.buttonName === "" && e.buttonUrl === "") {
				formikRef.current.values.buttonName =
					courseDefaultDetails.course_button_placeholder;
				formikRef.current.values.buttonUrl =
					courseDefaultDetails.course_button_url;
				formikRef.current.validateForm();
			}
		}
	};

	const handleFormChange = () => {
		setTimeout(
			() => {
				const formData = formikRef.current;
				if (formData) {
					const buttonName = formData.values.buttonName;
					const buttonUrl = formData.values.buttonUrl;
					if (
						initialValues.buttonName === buttonName &&
						initialValues.buttonUrl === buttonUrl
					) {
						setIsFormChanged(false);
					} else {
						setIsFormChanged(true);
					}
				}
			},
			[1000] as any
		);
	};
	return (
		<div>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				onSubmit={updateCourse}
				validationSchema={() => ActionSchema}
				validate={(e) => {
					formEmptyStateCheck(e);
				}}
			>
				{({ handleSubmit, values, isValid }) => (
					<form onChange={handleFormChange}>
						<div className="px-6 overflow-scroll" style={{ height: "90vh" }}>
							<div className="flex justify-between items-center mt-4 md:mt-0">
								<span className="text-gray-700" style={{ fontSize: 17 }}>
									To customize the name of the button and the link to address
									users, simply "<b>Edit</b>". You can then enter the name you
									would like to appear on the button and the link to the desired
									address. Once you are finished, click "<b>Submit</b>" to save
									your changes and the button will now take users to the
									specified address when clicked on their app.
								</span>
							</div>
							<div className="card-shadow p-6 my-4" style={{ height: "auto" }}>
								<div className="md:flex-row grid grid-cols-1 md:grid-cols-3 gap-4">
									<div className="md:pt-4 pt-0 w-full">
										<InputBox
											name="buttonName"
											label="Button Name"
											placeholder=""
											value={values.buttonName}
											disabled={!isPersonalEditOpen}
											isColorChange={!isPersonalEditOpen}
										/>
									</div>
									<div className="md:pt-4 pt-0 w-full">
										<InputBox
											name="buttonUrl"
											label="Url"
											placeholder=""
											value={values.buttonUrl}
											disabled={!isPersonalEditOpen}
											isColorChange={!isPersonalEditOpen}
										/>
									</div>

									<div className="md:pt-4 pt-0 w-full">
										<div className="flex justify-end gap-4 mt-4">
											<div className="">
												{isPersonalEditOpen ? (
													<>
														<ButonComponent
															onPress={() => {
																setIsPersonalEditOpen(false);
																setFormikValues();
																setIsFormChanged(false);
															}}
															value="Cancel"
															bgColor="#fff"
															borderColor="#A6A9B4"
															color="#A6A9B4"
															disabled={false}
															buttonWidth={"123px"}
															buttonHeight={"38px"}
															fontWeight={"500"}
															fontSize={"14px"}
														/>
													</>
												) : (
													<>
														<ButonComponent
															onPress={() => {
																setIsPersonalEditOpen(true);
																setFormikValues();
															}}
															value="Edit"
															bgColor="#fff"
															color="#3A9EA5"
															borderColor={`#3A9EA5`}
															disabled={false}
															buttonWidth={"123px"}
															buttonHeight={"38px"}
															fontWeight={"500"}
															fontSize={"14px"}
														/>
													</>
												)}
											</div>
											<div className="">
												{!isFormSubmitting ? (
													<ButonComponent
														onPress={() => {
															handleSubmit();
														}}
														value="Submit"
														bgColor={`#3A9EA5`}
														borderColor={`#3A9EA5`}
														buttonWidth={"123px"}
														buttonHeight={"38px"}
														fontWeight={"500"}
														fontSize={"14px"}
														disabled={!isFormChanged}
													/>
												) : (
													<LoaderButton buttonWidth="123px" buttonHeight="38px" bgColor="#3a9ea5" borderColor="#3a9ea5" value="Submitting.." />
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default Actions;
