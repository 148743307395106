import React, { useState } from "react";
import { useRef } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";

import "./index.css";
import { dotMenu } from "../../assets/images";

export default function ListItemTooltipClickable(props:any) {
	const clickMeButtonRef = useRef<HTMLButtonElement | undefined>();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	return (
		<>
			<div style={{ paddingLeft: 1, top: -5 }} className="flex items-center">
				<Popover
					isOpen={isPopoverOpen}
					positions={["bottom"]}
					padding={10}
					onClickOutside={() => setIsPopoverOpen(false)}
					ref={clickMeButtonRef as any} // if you'd like a ref to your popover's child, you can grab one here
					content={({ position, childRect, popoverRect }) => (
						<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
							position={position}
							childRect={childRect}
							popoverRect={popoverRect}
							arrowColor={"white"}
							arrowSize={10}
							arrowStyle={{ opacity: 0.7 }}
							className="popover-arrow-container "
							arrowClassName="popover-arrow  popover-arrow-item"
							style={{borderColor:'#e3e3e3'}}
						>
							<div
								className="custom-popover group-custom-popover"
								onClick={() => setIsPopoverOpen(!isPopoverOpen)}
								style={{ maxWidth: 250 }}
							>
								<div
									className="flex flex-col"
									style={{
										fontSize: 15,
										fontWeight: 400,
									}}
								>
									<div style={{ color: "#444C63" }}>
										<div
											className="py-1 text-black tool-item p-2 rounded-sm"
											style={{ color: "#ffff", cursor: 'pointer' }}
											onClick={()=>{props.handleEditPopup(props.uuid,props.label)}}
										>
											Edit
										</div>
										<div className="w-full h-px bg-white " />
										<div
											className="py-1 text-black tool-item p-2 rounded-sm"
											style={{ color: "#ffff", cursor: 'pointer' }}
											onClick={()=>{props.handleDeletePopup(props.uuid)}}
										>
											Delete
										</div>
									</div>
								</div>
							</div>
						</ArrowContainer>
					)}
				>
					<button
						onClick={() => setIsPopoverOpen(!isPopoverOpen)}
						style={{
							color: "#3A9EA5",
							textDecoration: "underline",
							cursor: "pointer",
							top: -3,
							position: "relative",
						}}
					>
						<img
							alt="dot"
							style={{
								cursor: "pointer",
								marginRight: "8px",
								marginLeft: "8px",
								padding: "8px",
							}}
							className=""
							src={dotMenu}
							//onClick={() => setShowChat(false)}
						/>
					</button>
				</Popover>
			</div>
		</>
	);
}
