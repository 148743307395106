import ReactTooltip from 'react-tooltip';
import './styles.scss';
export default function CardDelete({
  onSetDefault,
  index,
  onDeleteSelected,
  item,
}: any) {
  return (
    <div className='editBlockDeleteTooltop'>
      <div className="cursor-pointer" data-for={`editBlockDelete${index}`} data-tip='8'>
        <svg className='inline-block h-6 w-6 fill-current' viewBox='0 0 24 24'>
          <path d='M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z' />
        </svg>
      </div>
      <ReactTooltip
        id={`editBlockDelete${index}`}
        getContent={() => (
          <div className='flex flex-col'>
            <button className='flex' onClick={() => onSetDefault(item)}>
              Set as default
            </button>
            <div className='h-px my-1 w-full bg-white' />

            <button className='flex' onClick={() => onDeleteSelected(item)}>
              Delete Card
            </button>
          </div>
        )}
        clickable={true}
        multiline={true}
        backgroundColor={`#3A9EA5`}
        place='left'
        event='click'
        globalEventOff='click'
      />
    </div>
  );
}
