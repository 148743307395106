/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { DisableAdd, NoData } from "../../assets/images";
import { storageEngine } from "../../common/utility";
import Page from "../../components/Page/Page";
import SearchBox from "../../components/SearchBox/SearchBox";
import sort from "../../assets/img/icons/sort.svg";
import { Add } from "../../assets/images";
import "./userListStyles.scss";
import UserEditModal from "../../components/modals/userList/userEdit/UserEditModal";
import UserBlockModal from "../../components/modals/userList/userBlock/UserBlockModal";
import UserDeleteModal from "../../components/modals/userList/userDelete/UserDeleteModal";
import userSlice from "../../redux/slices/user.slice";
import { useDispatch, useSelector } from "react-redux";
import AddUserModal from "../../components/modals/userList/AddUserModal/AddUserModal";
import Button from "../../components/button/Button";
import authSlice from "../../redux/slices/auth.slice";
import { useHistory } from "react-router-dom";
import GroupNameTooltip from "../../components/Tooltip/GroupNameTooltip";
import Paginate from "../../components/Paginate/Paginate";
import groupAdminSlice from "../../redux/slices/groupAdmin.slice";
import EditBlockDelete from "../../components/Tooltip/EditBlockDelete";
import clientAdminSlice from "../../redux/slices/clientAdmin.slice";
import UserEditByGroupManager from "../../components/modals/userList/userEdit/UserEditByGroupManager";
import ResendEmail from "../../components/modals/userList/ResendEmail/ResendEmail";
import ExportCsvFile from "../../components/ExportCsvFile/ExportCsvFile";
import ButtonLoader from "../../components/Loader/ButtonLoader";
import TableLoader from "../../components/Loader/TableLoader";
import ReactTooltip from "react-tooltip";
import Text from "../../common/AppTour/Text";
import Tooltips from "../../common/AppTour/Tooltip";
import "../../common/AppTour/styles.css";
import CustomMultiselect from "../../components/Dropdown/CustomMultiselect";
import PageLoader from "../../components/Loader/PageLoader";

const UserList = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const dropContainerAction = useRef<HTMLDivElement>(null);
	const [isOpenAddUser, setIsOpenAddUser] = useState(false);

	const [filterData, setFilterData] = useState({
		limit: 20,
		page: 1,
		sort: "createdAt",
		order: "DESC",
		search: "",
		status: ["all"],
		group_id: null,
	});
	const [currentSelected, setCurrentSelected] = useState<any>();
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isBlockOpen, setIsBlockOpen] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [resendEmailIsOpen, setResendEmailIsOpen] = useState(false);
	const [appTourOneId, setAppTourOneId] = useState("");
	const [appTourTwoId, setAppTourTwoId] = useState("");
	const [actionDetailsDrop, setActionDetailsDrop] = useState(false);
	const [flag, setFlag] = useState(true);
	const user = storageEngine.get("user");
	const appTour = storageEngine.get("appTour");
	const role = user?.roles?.role;

	useEffect(() => {
		getUserList(filterData);
		if (role === "group") {
			dispatch(groupAdminSlice.actions.getRemainingUsers());
		} else if (role === "clientAdmin") {
			dispatch(clientAdminSlice.actions.getAllGroupList());
			dispatch(groupAdminSlice.actions.getRemainingUsers());
		}
		appTour?.map((item: any) => {
			if (item.app_tour_screens.screenPrefix === "USERS_PAGE") {
				if (!item?.is_finished) {
					openTourOne();
					setAppTourOneId(item.app_tour_screen_id);
				}
			}
		});
	}, []);

	const getUserList = (args: any) => {
		dispatch(authSlice.actions.setTopBatTitle("users"));

		dispatch(userSlice.actions.getUserList(args));
	};

	const userList = useSelector((state: any) => state.user.userList);
	const userListLoading = useSelector(
		(state: any) => state.user.userListLoading
	);
	const allUser = userList && userList?.allusers;
	const totalCount = userList && userList?.count;
	const remainingUsers = useSelector(
		(state: any) => state.groupAdmin?.remainingUsers?.remainingNumOfUsers
	);

	const [typingTimeout, setTypingTimeout] = useState(0);
	const search = async (value: string) => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		setTypingTimeout(
			window.setTimeout(() => {
				goToSearch(value);
			}, 1000)
		);
	};
	const goToSearch = async (value: any) => {
		let args = { ...filterData, search: value };
		setFilterData(args);
		getUserList(args);
	};

	const handleStatus = (event: any) => {
		let status: any = [];
		event &&
			event.length &&
			event.map((item: any) => {
				status.push(item);
			});
		status.length === 0 ? (status = ["all"]) : (status = status);
		let args = { ...filterData, status: status };
		setFilterData(args);
		getUserList(args);
	};
	const handleUserListWithGroupId = (event: any) => {
		const group_id: any = [];
		event &&
			event.length &&
			event.map((item: any) => {
				group_id.push(item);
			});
		let args = { ...filterData, group_id: group_id };
		setFilterData(args);
		dispatch(userSlice.actions.getUserList(args));
	};

	const reRouteToDetails = (item: any) => {
		if (!item.enableDataPrivacy) {
			role === "group" && history.push(`/userDetails/${item.doc_id}`);
		}
	};

	const handlePageChange = (event: any) => {
		const selected = event.selected;
		const arg = { ...filterData, page: selected + 1 };
		setFilterData(arg);
		getUserList(arg);
	};
	const handleBlockedUser = () => {
		if (currentSelected?.status !== "blocked") {
			dispatch(
				userSlice.actions.blockUser({
					doc_id: currentSelected?.doc_id,
					status: "blocked",
					type: "clientAdmin",
				})
			);
		} else {
			dispatch(
				userSlice.actions.blockUser({
					doc_id: currentSelected?.doc_id,
					status: "active",
					type: "clientAdmin",
				})
			);
		}
		setIsBlockOpen(false);
	};
	const handleDeleteUser = () => {
		dispatch(userSlice.actions.deleteUser({ doc_id: currentSelected?.doc_id }));
		setIsDeleteOpen(false);
	};
	const handleResendEmail = () => {
		dispatch(userSlice.actions.resendInvite({ email: currentSelected?.email }));
	};

	const [options, setOptions] = useState([]);

	const groupList: any[] = useSelector(
		(state: any) => state.clientAdmin?.allGroupList?.returnObj
	);

	const userDetailsRefresh: any[] = useSelector(
		(state: any) => state.user?.userDetailsRefresh
	);
	useEffect(() => {
		getUserList(filterData);
	}, [userDetailsRefresh]);
	useEffect(() => {
		if (groupList && groupList.length) {
			setOptions([]);
			groupList.map((grp) => {
				const label = grp.name;
				const value = parseInt(grp.id);

				setOptions((oldArray): any => [...oldArray, { label, value }]);
			});
		}
	}, [groupList]);
	useEffect(() => {}, [options]);
	const checkIspossibleToRoute = (item: any) => {
		if (!item.enableDataPrivacy && role === "group") {
			return true;
		} else {
			return false;
		}
	};

	const [isExportOpen, setIsExportOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isUserDetailExportOpen, setIsUserDetailExportOpen] = useState(false);
	const exportUserList = useSelector(
		(state: any) => state.clientAdmin?.exportUserList
	);
	const exportDetailUserList = useSelector(
		(state: any) => state.clientAdmin?.exportDetailUserList
	);
	const exportUserListLoading = useSelector(
		(state: any) => state.clientAdmin?.exportUserListLoading
	);
	const exportUserDetailLoading = useSelector(
		(state: any) => state.clientAdmin?.exportUserDetailLoading
	);
	const handleUserResultExport = () => {
		dispatch(clientAdminSlice.actions.exportUsers());
		setIsExportOpen(true);
	};
	useEffect(() => {
		if (!exportUserListLoading && isExportOpen) {
			setIsExportOpen(false);
			dispatch(clientAdminSlice.actions.setexportUserListNull());
		}
	}, [exportUserListLoading]);

	const handleUserDetailExport = () => {
		dispatch(clientAdminSlice.actions.handleUserDetailExport());
		setIsUserDetailExportOpen(true);
	};
	useEffect(() => {
		if (!exportUserDetailLoading && isUserDetailExportOpen) {
			setIsUserDetailExportOpen(false);
			dispatch(clientAdminSlice.actions.setexportUserDetailNull());
		}
	}, [exportUserDetailLoading]);

	const handleSort = (value: string) => {
		const order =
			filterData.sort === value
				? filterData.order === "DESC"
					? "ASC"
					: "DESC"
				: "ASC";
		let args = { ...filterData, sort: value, order };
		getUserList(args);
		setFilterData(args);
	};

	useEffect(() => {
		if (allUser?.length > 0) {
			appTour?.map((item: any) => {
				if (item.app_tour_screens.screenPrefix === "PENDING_DOTS") {
					if (!item?.is_finished) {
						setSwitchValue(2);
						openTourTwo();
						setAppTourTwoId(item.app_tour_screen_id);
					}
				}
			});
		}
	}, [allUser]);
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	const tourConfigOne = [
		{
			selector: '[data-tut="reactour__add_user"]',
			content: () => (
				<Text>
					Click <Tooltips>Add User</Tooltips> to <br />
					Enter in <Tooltips>first and last name</Tooltips>,<br />
					along with an <Tooltips>email</Tooltips>. <br />
					<Tooltips>Invite email</Tooltips> will be sent immediately.
				</Text>
			),
			position: "top",
		},
	];

	const tourConfigTwo = [
		{
			selector: '[data-tut="reactour__pending_dots"]',
			content: () => (
				<Text>
					<Tooltips>Resend email invitation</Tooltips> to users here
				</Text>
			),
			position: "left",
		},
	];

	const [isTourOpenOne, setIsTourOpenOne] = useState(false);
	const [isTourOpenTwo, setIsTourOpenTwo] = useState(false);
	const [switchValue, setSwitchValue] = useState(1);

	const disableBody = (target: any) => disableBodyScroll(target);
	const enableBody = (target: any) => enableBodyScroll(target);
	const closeTourOne = () => {
		setIsTourOpenOne(false);
		const data = {
			screen_id: parseInt(appTourOneId),
		};
		dispatch(groupAdminSlice.actions.appTourFinished(data));
	};
	const closeTourTwo = () => {
		setIsTourOpenTwo(false);
		const data = {
			screen_id: parseInt(appTourTwoId),
		};
		dispatch(groupAdminSlice.actions.appTourFinished(data));
	};
	const openTourOne = () => {
		setIsTourOpenOne(true);
	};
	const openTourTwo = () => {
		setIsTourOpenTwo(true);
	};
	const accentColor = "#5cb7b7";
	const onAddUser = () => {
		setIsOpenAddUser(true);
		closeTourOne();
	};
	useEffect(() => {
		const handleOutsideClick = (event: any) => {
			if (!dropContainerAction?.current?.contains(event.target)) {
				if (!actionDetailsDrop) return;
				setActionDetailsDrop(false);
			}
		};

		window.addEventListener("click", handleOutsideClick);
		return () => window.removeEventListener("click", handleOutsideClick);
	}, [actionDetailsDrop, dropContainerAction]);

	const onDataSharingChange = (e: any) => {
		if (e.target.checked) {
			let args = { ...filterData, is_data_sharing: e.target.checked };
			getUserList(args);
			setFilterData(args);
		} else {
			let args: any = { ...filterData };
			delete args.is_data_sharing;
			getUserList(args);
			setFilterData(args);
		}
	};

	return (
		<>
			{isLoading && <PageLoader />}
			{!isLoading && (
				<Page title="User List">
					{switchValue === 1 ? (
						<Tour
							onRequestClose={() => {
								closeTourOne();
							}}
							steps={tourConfigOne}
							isOpen={isTourOpenOne}
							maskClassName="mask"
							className="helper"
							rounded={5}
							accentColor={accentColor}
							onAfterOpen={disableBody}
							onBeforeClose={enableBody}
							showNumber={false}
							showNavigation={false}
							showButtons={false}
						/>
					) : (
						<Tour
							onRequestClose={() => {
								closeTourTwo();
							}}
							steps={tourConfigTwo}
							isOpen={isTourOpenTwo}
							maskClassName="mask"
							className="helper"
							rounded={5}
							accentColor={accentColor}
							onAfterOpen={disableBody}
							onBeforeClose={enableBody}
							showNumber={false}
							showNavigation={false}
							showButtons={false}
						/>
					)}
					<div className="mx-auto px-4 sm:px-8 my-5">
						{role === "clientAdmin" && (
							<span className="text-gray-600">
								Note: Only group managers can invite users. Invite a group
								manager to add users. Shared results available in group manager
								view only.
							</span>
						)}
						<div className="mt-4">
							<div className="md:flex w-full mb-3 mt-8 md:mt-0">
								<div className={`md:flex md:justify-evenly md:gap-5 w-full`}>
									<div className="flex items-end">
										<SearchBox
											onChange={(e: any) => search(e.target.value)}
											placeholder="Search email"
										/>
									</div>

									{role === "clientAdmin" && (
										<div className="w-full md:w-1/4 sm:flex-shrink-0 grid items-end md:mt-0 mt-5">
											<CustomMultiselect
												options={options}
												onFieldChange={(e: any) => {
													handleUserListWithGroupId(e);
												}}
												label={`Group Name`}
											/>
										</div>
									)}
									<div
										className={`w-full ${
											role === "group" ? "md:w-1/2" : "md:w-1/4"
										} md:flex-shrink-0 grid items-end md:mt-0 mt-5`}
									>
										<CustomMultiselect
											options={[
												{ label: "Active", value: "active" },
												{ label: "Blocked", value: "blocked" },
												{ label: "Pending", value: "pending" },
											]}
											onFieldChange={(e: any) => {
												handleStatus(e);
											}}
											label={`Status`}
										/>
									</div>
									<div className="h-full flex items-center justify-start md:justify-center md:w-auto w-full md:mt-0 mt-5 mr-2">
										<input
											onChange={onDataSharingChange}
											type="checkbox"
											id="dataSharing"
										></input>
										<label
											htmlFor="dataSharing"
											className="ml-2 font-normal text-gray-700 text-base"
										>
											Shared Results
										</label>
									</div>
								</div>
								<div
									ref={dropContainerAction}
									className={`flex relative justify-end mt-8 md:mt-0 ml-auto`}
								>
									{role === "group" && (
										<>
											<div data-tut="reactour__add_user">
												{remainingUsers > 0 ? (
													<Button
														onPress={() => {
															onAddUser();
														}}
														value="Add User"
														icon={Add}
														disabled={false}
													/>
												) : (
													<Button
														value="Add User"
														icon={DisableAdd}
														bgColor="#BEC2CE"
														borderColor="#BEC2CE"
														color="#444C63"
														disabled={false}
													/>
												)}
											</div>
										</>
									)}
									{role === "clientAdmin" && (
										<>
											<div data-tut="reactour__add_user">
												{remainingUsers > 0 ? (
													<Button
														onPress={() => {
															onAddUser();
														}}
														value="Add User"
														icon={Add}
														disabled={false}
													/>
												) : (
													<Button
														value="Add User"
														icon={DisableAdd}
														bgColor="#BEC2CE"
														borderColor="#BEC2CE"
														color="#444C63"
														disabled={false}
													/>
												)}
											</div>
											<div className="ml-3">
												<Button
													onPress={() => {
														setActionDetailsDrop(!actionDetailsDrop);
													}}
													value={
														exportUserListLoading || exportUserDetailLoading ? (
															<ButtonLoader />
														) : (
															"Download"
														)
													}
													icon={false}
													disabled={false}
													buttonWidth={"162px"}
													buttonHeight={"44px"}
													fontSize={"14px"}
													fontWeight={"500"}
												/>
											</div>
										</>
									)}
									<ul
										className={`z-50 mr-5 h-2/5 overflow-y-scroll absolute right-0 bg-white shadow-md border dropdown-export-user-list py-1 ${
											!actionDetailsDrop ? "hidden" : ""
										}`}
									>
										<li
											className="flex cursor-pointer	font-medium font-Lexend-Regular text-secondary px-4 py-3 text-sm justify-center"
											onClick={() => {
												setActionDetailsDrop(!actionDetailsDrop);
												handleUserDetailExport();
											}}
										>
											User Details
										</li>
										<div
											className="w-full h-px"
											style={{ backgroundColor: "#EDF0F9" }}
										/>
										<li
											className="flex cursor-pointer	font-medium font-Lexend-Regular text-secondary px-4 py-3 text-sm justify-center"
											onClick={() => {
												setActionDetailsDrop(!actionDetailsDrop);
												handleUserResultExport();
											}}
										>
											User Results
										</li>
									</ul>
								</div>
							</div>
							{isExportOpen && exportUserList && (
								<ExportCsvFile csvReport={exportUserList} />
							)}
							{isUserDetailExportOpen && exportDetailUserList && (
								<ExportCsvFile csvReport={exportDetailUserList} />
							)}
							{!!allUser && !!allUser.length && (
								<div className="overflow-x-hidden relative">
									<div className="overflow-x-auto border-user rounded-xl">
										<div className="inline-block min-w-full rounded-lg overflow-hidden">
											<table className="min-w-full leading-normal myborder user-table-width">
												<thead
													style={{
														minWidth:
															role === "clientAdmin" ? 2180 : 2180 - 400,
														width: "100%",
													}}
												>
													<tr
														className="flex justify-between"
														style={{ backgroundColor: "#F5F7FF" }}
													>
														<th
															style={{ width: 100 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															Sl No
														</th>
														<th
															style={{ width: 250 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>First Name</span>
																<img
																	className="cursor-pointer"
																	onClick={() => handleSort("firstName")}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>
														<th
															style={{ width: 250 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>Last Name</span>
																<img
																	className="cursor-pointer"
																	onClick={() => handleSort("lastName")}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>
														<th
															style={{ width: 250 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>Email</span>
																<img
																	className="cursor-pointer"
																	onClick={() => handleSort("email")}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>
														<th
															style={{ width: 250 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>No of Assessments</span>
																<img
																	className="cursor-pointer"
																	onClick={() =>
																		handleSort("total_assessments")
																	}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>
														<th
															style={{ width: 200 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>EI Score</span>
																<img
																	className="cursor-pointer"
																	onClick={() =>
																		handleSort("last_assessment_ei_score")
																	}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>
														<th
															style={{ width: 200 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>Average EI Score</span>
																<img
																	className="cursor-pointer"
																	onClick={() => handleSort("average_ei_score")}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>

														{role === "clientAdmin" && (
															<th
																style={{ width: 400 }}
																className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
															>
																<div className="flex items-center justify-between">
																	<span>Group Name</span>
																</div>
															</th>
														)}
														<th
															style={{ width: 300 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>Created On</span>
																<img
																	className="cursor-pointer"
																	onClick={() => handleSort("createdAt")}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>
														<th
															style={{ width: 300 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>Last Assessment</span>
																<img
																	className="cursor-pointer"
																	onClick={() =>
																		handleSort("last_assessment_date")
																	}
																	src={sort}
																	alt=""
																/>
															</div>
														</th>
														<th
															style={{ width: 150 }}
															className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
														>
															<div className="flex items-center justify-between">
																<span>Status</span>
															</div>
														</th>
														<th
															style={{ width: 30, backgroundColor: "#F5F7FF" }}
															className="top-border-radius px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider headcol"
														>
															<span style={{ color: "#F5F7FF" }}>.</span>
														</th>
													</tr>
												</thead>
												{!userListLoading ? (
													<tbody
														style={{
															minWidth:
																role === "clientAdmin" ? 2180 : 2180 - 400,
															width: "100%",
														}}
													>
														{!!allUser &&
															!!allUser.length &&
															allUser.map((item: any, index: any) => (
																<tr
																	key={index}
																	className={`${
																		index % 2 ? "secondcolor" : "bg-white"
																	} flex justify-between ${
																		checkIspossibleToRoute(item)
																			? "cursor-pointer"
																			: ""
																	}`}
																>
																	<td
																		onClick={() => reRouteToDetails(item)}
																		style={{ width: 100 }}
																		className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
																	>
																		<div className="flex">
																			{index +
																				1 +
																				(filterData.page - 1) *
																					filterData.limit}
																		</div>
																	</td>
																	<td
																		style={{ width: 250 }}
																		onClick={() => reRouteToDetails(item)}
																		className={`px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate`}
																	>
																		<span
																			className={`${
																				!item.enableDataPrivacy &&
																				"underline text-underline-position card-color-primary"
																			}`}
																		>
																			{item.firstName}
																		</span>
																	</td>
																	<td
																		style={{ width: 250 }}
																		className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
																	>
																		{item.lastName}
																	</td>{" "}
																	<td
																		style={{ width: 250 }}
																		className="px-5 py-3 text-left float-left text-sm card-text-primary truncate"
																	>
																		{item.email}
																	</td>
																	<td
																		style={{ width: 250 }}
																		className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
																	>
																		{item.total_assessments}
																	</td>
																	<td
																		style={{ width: 200 }}
																		className="px-5 py-3 text-left float-left text-sm card-text-primary truncate"
																	>
																		{item.last_assessment_ei_score}
																	</td>
																	<td
																		style={{ width: 200 }}
																		className="px-5 py-3 text-left float-left text-sm card-text-primary truncate"
																	>
																		{item.average_ei_score}
																	</td>
																	{role === "clientAdmin" && (
																		<td
																			style={{ width: 400 }}
																			className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
																		>
																			{!!item.group_details &&
																				!!item.group_details.length && (
																					<div className="flex">
																						<div className="flex">
																							{item.group_details.map(
																								(grp: any, i: any) => (
																									<div className="">
																										{i === 0 && (
																											<div className="">
																												{grp?.groups?.name}
																											</div>
																										)}
																										{i === 1 && (
																											<div className="ml-2 border-l">
																												<div className="ml-2">
																													{grp?.groups?.name}
																												</div>
																											</div>
																										)}
																									</div>
																								)
																							)}
																						</div>
																						{item.group_details.length - 2 >
																							0 && (
																							<GroupNameTooltip
																								number={
																									item.group_details.length - 2
																								}
																								index={index}
																								names={item.group_details.map(
																									(i: any) => i?.groups?.name
																								)}
																							/>
																						)}
																					</div>
																				)}
																		</td>
																	)}
																	<td
																		style={{ width: 300 }}
																		className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
																	>
																		{item?.createdAt
																			? moment(item?.createdAt).format("ll")
																			: "-"}
																	</td>
																	<td
																		style={{ width: 300 }}
																		className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
																	>
																		{!!item.assessments
																			? !!item.assessments.length
																				? moment(
																						item.assessments[0].createdAt
																				  ).format("MMMM DD, YYYY, h:mm A")
																				: "-"
																			: "-"}
																	</td>
																	<td
																		style={{ width: 150 }}
																		className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
																	>
																		<span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
																			<span
																				aria-hidden
																				className={`${
																					item.status === "active"
																						? "active-bg"
																						: item.status === "blocked"
																						? "blocked-bg"
																						: "pending-bg"
																				} absolute inset-0  rounded-full`}
																			></span>
																			<span
																				className={`${
																					item.status === "active"
																						? "active-bg"
																						: item.status === "blocked"
																						? "blocked-bg"
																						: "pending-bg"
																				} relative capitalize text-xs`}
																			>
																				{item.status}
																			</span>
																		</span>
																	</td>
																	<td
																		data-tut="reactour__pending_dots"
																		style={{ width: 30 }}
																		className={`${
																			index % 2 ? "secondcolor" : "bg-white"
																		} px-5 py-3  text-sm text-right headcol`}
																	>
																		<button
																			type="button"
																			className="inline-block card-text-primary"
																			onClick={() => {
																				setCurrentSelected(item);
																			}}
																			onMouseEnter={() => ReactTooltip.hide()}
																		>
																			<EditBlockDelete
																				key={index}
																				index={index}
																				onPendingDots={() => closeTourTwo()}
																				onEditSelected={() => {
																					setIsEditOpen(true);
																				}}
																				onBlockSelected={() =>
																					setIsBlockOpen(true)
																				}
																				onDeleteSelected={() =>
																					setIsDeleteOpen(true)
																				}
																				item={item}
																				onReSendEmailRequest={() =>
																					setResendEmailIsOpen(true)
																				}
																			/>
																		</button>
																	</td>
																</tr>
															))}
													</tbody>
												) : (
													<TableLoader />
												)}
											</table>
										</div>
									</div>

									<div className="">
										<Paginate
											pageCount={totalCount / 20}
											handlePageChange={handlePageChange}
											page={filterData.page}
											limit={filterData.limit}
											totalCount={totalCount}
										/>
									</div>
								</div>
							)}

							{!!allUser && !allUser.length && (
								<div className="border-user rounded-xl justify-center flex-col py-20">
									<div className="justify-center flex">
										<img src={NoData} alt="" />
									</div>
									<div className="justify-center flex">
										<span className="text-xl">No Data Found</span>
									</div>
								</div>
							)}
						</div>
						{isOpenAddUser && (
							<AddUserModal
								data-tut="reactour__add_user_modal"
								hasModalView={isOpenAddUser}
								closeModal={() => setIsOpenAddUser(false)}
								onUserInvite={() => setFlag(!flag)}
							/>
						)}
						{isEditOpen && role === "clientAdmin" && (
							<UserEditModal
								hasModalView={isEditOpen}
								closeModal={() => setIsEditOpen(false)}
								selectedItem={currentSelected}
							/>
						)}
						{isEditOpen && role === "group" && (
							<UserEditByGroupManager
								hasModalView={isEditOpen}
								closeModal={() => setIsEditOpen(false)}
								selectedItem={currentSelected}
							/>
						)}
						{isBlockOpen && (
							<UserBlockModal
								hasModalView={isBlockOpen}
								closeModal={() => setIsBlockOpen(false)}
								onPress={handleBlockedUser}
								title={`Do you want to ${
									currentSelected?.status !== "blocked" ? "block" : "unblock"
								} ?`}
								confirmText={`Are you sure you want to ${
									currentSelected?.status !== "blocked" ? "block" : "unblock"
								} this user?`}
								buttonText={`${
									currentSelected?.status !== "blocked" ? "block" : "unblock"
								}`}
							/>
						)}
						{isDeleteOpen && (
							<UserDeleteModal
								hasModalView={isDeleteOpen}
								closeModal={() => setIsDeleteOpen(false)}
								onPress={handleDeleteUser}
								type="user"
							/>
						)}
						{resendEmailIsOpen && (
							<ResendEmail
								hasModalView={resendEmailIsOpen}
								closeModal={() => setResendEmailIsOpen(false)}
								onSubmit={handleResendEmail}
							/>
						)}
					</div>
				</Page>
			)}
		</>
	);
};
export default UserList;
