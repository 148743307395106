import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './DatePicker.scss';
import { DownArrow } from '../../assets/images';



const VibeonixDatePicker = ({
  name,
  label,
  onFieldChange,
  selectedDate,
  width,
  format = 'MMMM-dd-yyyy',
  maxDate = `${new Date().getFullYear()}/${
    new Date().getMonth() + 1
  }/${new Date().getDate()}`,
}: any) => {
  const [isYearPickerOpen, setIsPickerOpen] = useState(false);
  return (
    <div className='z-10 mt-3'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          maxDate={maxDate}
          label={label}
          style={{ width: width, marginTop: '15px', height: 'auto' }}
          format={format}
          value={selectedDate}
          name={name}
          onChange={onFieldChange}
          keyboardIcon={ <img src={DownArrow} alt='down' />}

          onClick={() => setIsPickerOpen(true)}
          open={isYearPickerOpen}
          onClose={() => setIsPickerOpen(false)}
          allowKeyboardControl={false}
          InputProps={{ readOnly: true }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default VibeonixDatePicker;
