export const INVITE_USER = "user/inviteUser";
export const RESEND_INVITE = "user/resendInvite";
export const USER_LIST = "user/getUserList";
export const BLOCK_USER = "user/blockUser";
export const DELETE_USER = "user/deleteUser";
export const UPDATE_USER = "user/updateUser";

export const GET_USER_DETAILS = "user/getUserDetails";
export const GET_ASSESSMENTS_HISTORY = "user/getAssessmentsHistorys";

export const GET_USER_WELLBEING_CALENDAR = "user/getUserWellbeingCalendar";
export const GET_USER_ASSESSMENT_DETAILS = "user/getUserAssessmentDetails";
export const GET_GROUP_LIST_FOR_USER_LIST = "user/getGropListForSortUserList";
export const CONTACT_US = 'user/contactUs';
export const SIGNUP = 'user/signup';


