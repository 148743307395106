import { colors } from "@material-ui/core";
import React from "react";
import { randomBGColor } from "../../common/utility";
import { dotMenu } from "../../assets/images";
import ListItemTooltipClickable from "../../components/Tooltip/ListItemTooltipClickable";
import './GroupCard.css';

interface propsInterface {
	label: string;
	value: any;
	profile: boolean;
	index: number;
	handleActiveState: any;
	activeChat: any;
	uuid: any;
	handleEditPopup: any;
	handleDeletePopup: any;
}

function GroupCard(props: propsInterface) {
	const getFirstLetter = () => {
		return props.label[0];
	};

	return (
		<div key={props.index} className="flex flex-row justify-between group-card">
			<button
				onClick={() => {
					props.handleActiveState("group", props.index, props.value,props.label);
				}}
				className={`flex flex-row items-center hover:bg-gray-100 rounded-md p-4 group ${
					props.activeChat === "group-" + props.index ? "individual-active" : ""
				}`}
				style={{ width: "100%", marginRight: "10px" }}
			>
				{props.profile && (
					<div
						className={
							`flex items-center justify-center h-8 w-8 rounded-full ` +
							randomBGColor()
						}
					>
						{getFirstLetter()}
					</div>
				)}
				<div className="ml-2 text-sm font-medium">{props.label}</div>
			</button>
			<ListItemTooltipClickable
				uuid={props.uuid}
				handleEditPopup={props.handleEditPopup}
				handleDeletePopup={props.handleDeletePopup}
				label={props.label}
			/>
		</div>
	);
}

export default GroupCard;
