import React from 'react'

interface propsInterface{
  size: number;
}
const SpinerRoundLoader = (props: propsInterface) => {
  const { size } = props;
  return (
    <div className='flex justify-center items-center m-1'>
      <div className='l-spinner' style={{width:props.size, height:size}}></div>
   </div>
  )
}
export default SpinerRoundLoader
