import moment from "moment";
import React from "react";
import './ChatMessage.css';

function ChatMessage(props: any) {
	return (
		<div key={props.message.id} className="p-3 rounded-lg items-center flex w-full">
			<div className="flex flex-col items-start w-full pr-4">
				<div className="text-vibeonixColor font-semibold text-14px line-h leading-9 flex w-full justify-between">
					<span>{`${props.user.firstName} ${props.user.lastName}`}</span>
					<span style={{color:'#868484'}}>{moment(props.message?.created_at).format('MMM DD, h:mm:ss a') }</span>
				</div>
				<div className="chat-message-box" dangerouslySetInnerHTML={{ __html: props.message.message }} style={{ color: "#444C63" }}>
				</div>
				<div className="w-full">
				{props.message.isFailed && (
					<span style={{ background: '#ff4a4a', color: 'white', borderRadius: 15, padding: '2px 10px', fontWeight:700, float:'right', fontSize:'12px', marginTop:5 }}>i</span>
				)}
				</div>
				<div
					style={{
						border: "1px solid #E4E4E4",
						marginTop: "14px",
						width: "100%",
					}}
				/>
			</div>
		</div>
	);
}

export default ChatMessage;
