import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { storageEngine } from '../common/helper';
import { APP_ENTRYPOINT } from '../config/config';
import axios from 'axios';
import { setToken } from '../services/Apis/user/user';
import { navigator } from './navigator';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  // check whether the props contain redirect(ie 'to' exist or not)
  // eslint-disable-next-line max-len
  const checkIfRedirectPresent = (props: any, restParam: any) => (restParam.to ? { ...props, to: restParam.to } : props);
  const subscripitionPath = "/individualUser/subscription";

  // check if is authenticationg
  const isAuthenticating = true;

  // checking token present in local storage
  const checkIfTokenExist = () => {
    const publicRouteArray = ['/userSignUp/:id', '/verifing-email/:id', '/groupAdminSignUp/:id'];
    if (publicRouteArray.includes(rest.path)) {
      storageEngine.clear();
      return false;
    }
    const access_token = storageEngine.get('access_token');
    if (!access_token) return false;
    return true;
  };

  function getQueryParamByName(name: any) {
		const urlSearchParams = new URLSearchParams(window.location.search);
		return urlSearchParams.get(name);
	}
	async function makePostRequest(data: any) {
		try {
			const response = await axios.post(
				`${APP_ENTRYPOINT}auth/validateIncomingToken`,
				data
			);
			const { access_token, refresh_token } = response.data.data;
			storageEngine.set("user", response.data.data.data);
			storageEngine.set("appTour", response.data.data.app_tour);
			storageEngine.set("active", response.data.data.data.is_plan_active);
			setToken(access_token, refresh_token);
			navigator.history.replace("/");
		} catch (error) {
			navigator.history.replace("/");
		}
	}

	if (window.location.pathname.includes(subscripitionPath)) {
		const param1Value = getQueryParamByName("t");
		if (param1Value) {
			const body = { short_lived_token: param1Value };
			makePostRequest(body);
			return false;
		}

		// dispatch(authSlice.actions.switchAccount());
	}
  return (
    <Route
      {...rest}
      render={(props) => (checkIfTokenExist() ? (
        isAuthenticating ? (
          <Component {...checkIfRedirectPresent(props, rest)} />
        ) : (
          <Component {...checkIfRedirectPresent(props, rest)} />
        )
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ))}
    />
  );
};

export default PrivateRoute;