import React, { useState, useEffect, useRef } from 'react'
import { DownArrow, PopupClose } from '../../assets/images';

function CustomMultiselect({ options, label, onFieldChange }: any) {
    const dropContainerAction = useRef<HTMLDivElement>(null);
    const firstUpdate = useRef(false)
    const [actionDetailsDrop, setActionDetailsDrop] = useState(false)
    const [organisationValue, setOrganisationValue]: any = useState([])
    const [organisationLabel, setOrganisationLabel]: any = useState([])

    useEffect(() => {
        const handleOutsideClick = (event: any) => {

            if (!dropContainerAction?.current?.contains(event.target)) {

                if (!actionDetailsDrop) return;
                setActionDetailsDrop(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);
        return () => window.removeEventListener('click', handleOutsideClick);
    }, [actionDetailsDrop, dropContainerAction]);
    useEffect(() => {
        if (firstUpdate.current) {
            onFieldChange(organisationValue);
        }
        firstUpdate.current = true
    }, [organisationValue])

    const handleChangeChk = () => {

    }
    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(false);

    const onScroll = (e:any) => {
        const currentScrollY = e.target.scrollTop;
        if (prevScrollY.current < currentScrollY && goingUp) {
            setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
            setGoingUp(true);
        }
        prevScrollY.current = currentScrollY;

    };
    const onSelect = (item: any) => {
        if (organisationValue.includes(item.value)) {

            const array = organisationValue
            const index = array.indexOf(item.value)
            array.splice(index, 1)

            setOrganisationValue([...array])
            const arrayLabel = organisationLabel
            const indexLabel = arrayLabel.indexOf(item.label)
            arrayLabel.splice(indexLabel, 1)
            setOrganisationLabel([...arrayLabel])
        }
        else {

            setOrganisationValue([...organisationValue, item.value])
            setOrganisationLabel([...organisationLabel, item.label])
        }
    }
    const slectedValueContainer = (item: any) => {
        // const val = organisationValue[0] ? organisationValue[1]

        const val = item;
        const value = val.map((item:any) => {

            return (
                <div className='px-2 mr-1 py-.5 text-xs truncate rounded-3xl container-color'>
                    {organisationLabel[item]}
                </div>
            )
        })
        return value
    }
    const deleteAllValue = () => {
        setOrganisationValue([])
        setOrganisationLabel([])
    }
    return (
        <div className="flex w-full container relative border-b border-gray-400 flex-shrink-0">
            <div
                ref={dropContainerAction}
                onClick={() => {
                    setActionDetailsDrop(!actionDetailsDrop);
                }}
                className="w-full h-full flex font-xs flex-row justify-between items-center h-dot-icon cursor-pointer"
            >
                <div className="flex w-full flex-row label-style">
                    <div className='flex flex-row  w-11/12'>
                        {organisationValue.length === 0
                            ? label
                            : organisationValue.length === 1
                                ? slectedValueContainer([0])
                                : organisationValue.length >= 2
                                && (slectedValueContainer([0, 1]))
                        }
                        {organisationValue.length > 2 &&
                            <div className='text-xs'>
                                +{organisationValue.length - 2}
                            </div>
                        }
                    </div>
                    {organisationValue.length > 0 &&
                        <div className='flex  items-center justify-center pr-1w-1/12'>
                            <img
                                className='h-2 w-2 z-100'
                                onClick={() => deleteAllValue()}
                                src={PopupClose}
                                alt=""
                            />
                        </div>}
                </div>
                <div>
                    <img src={DownArrow} alt='down' />
                </div>
                {/* <span className="rounded-full bg-gray-500 float-left" />
                <span className="rounded-full bg-gray-500 float-left" />
                <span className="rounded-full bg-gray-500 float-left" /> */}
            </div>

            <ul
                className={`z-50 left-0 h-2/5 overflow-y-scroll absolute right-0 bg-white shadow-md border dropdown-list-location-details py-1 ${!actionDetailsDrop ? 'hidden' : ''
                    }`}
                onScroll={(e) => onScroll(e)}
            >
                {options.map((item: any, index: any) => (
                    <li
                        key={index}
                        onClick={(e) => { e.stopPropagation(); onSelect(item) }}
                        className="flex flex-row items-center cursor-pointer hover:bg-blue-200  text-sm font-normal px-2 py-1"
                    >

                        <input
                            type="checkbox"
                            checked={organisationValue.includes(item.value)}
                            // defaultChecked={organisationValue.includes(item.value)} 
                            style={{ marginRight: 10 }}
                            onChange={() => handleChangeChk()} />
                        {item.label}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default CustomMultiselect
