import React, { useEffect, useState } from 'react';
import sort from '../../assets/img/icons/sort.svg';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import groupSlice from '../../redux/slices/group.slice';
import clientAdminSlice from '../../redux/slices/clientAdmin.slice';
import Modal from 'react-modal';
import moment from 'moment';
import { Add, NoData } from '../../assets/images';
import SearchBox from '../../components/SearchBox/SearchBox';
import Button from '../../components/button/Button';
import { useHistory } from 'react-router-dom';
import AddGroup from '../../components/modals/groupsList/addGroup/AddGroup';
import authSlice from '../../redux/slices/auth.slice';
import { storageEngine } from '../../common/helper';
import Page from '../../components/Page/Page';
import Paginate from '../../components/Paginate/Paginate';
import EditBlock from '../../components/Tooltip/EditBlock';
import groupAdminSlice from '../../redux/slices/groupAdmin.slice';
import UserBlockModal from '../../components/modals/userList/userBlock/UserBlockModal';
import PlanUpdateToast from '../../components/Toast/PlanUpdate';
import TableLoader from '../../components/Loader/TableLoader';
import UserDeleteModal from '../../components/modals/userList/userDelete/UserDeleteModal';
import ReactTooltip from 'react-tooltip';
import Text from '../../common/AppTour/Text';
import Tooltips from '../../common/AppTour/Tooltip';
import '../../common/AppTour/styles.css';
import { GroupCloseTour } from '../../interface/group';

Modal.setAppElement(document.getElementById('root') as any);

export default function GroupList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = storageEngine.get('user');

  const [filterData, setFilterData] = useState({
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
    search: '',
  });

  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isTourOpenOne, setIsTourOpenOne] = useState(false);
  const [isTourOpenTwo, setIsTourOpenTwo] = useState(false);
  const [isTourOpenThree, setIsTourOpenThree] = useState(false);
  const [switchValue, setSwitchValue] = useState(1);
  const [appTourOneId, setAppTourOneId] = useState('');
  const [appTourTwoId, setAppTourTwoId] = useState('');
  const [appTourThreeId, setAppTourThreeId] = useState('');
  const appTour = storageEngine.get('appTour');

  const getInitData = (args: any) => {
    dispatch(authSlice.actions.setTopBatTitle('Groups'));
    dispatch(groupSlice.actions.getGroupList(args));
  };

  const groupList = useSelector((state: any) => state.group.groupList);
  //console.log(groupList && groupList.allActions)
  const allGroupslist = groupList && groupList.groups;
  const totalCount = groupList && groupList?.count;
  const groupListLoader = useSelector((state:any) => state.group.groupListLoader);

  const remaining = useSelector(
    (state:any) => state.clientAdmin.remainingGroupsAndUsers
  );
  const remainingGroups = remaining?.remainingNumOfGroups;

  const [typingTimeout, setTypingTimeout] = useState(0);

  useEffect(() => {
    dispatch(clientAdminSlice.actions.getRemainingGroupsAndUsers());
  }, [allGroupslist]);

  useEffect(() => {
    getInitData(filterData);
    appTour?.map((item: any) => {
      if (item.app_tour_screens.screenPrefix === 'GROUP_PAGE') {
        if (!item?.is_finished) {
          openTourOne();
          setAppTourOneId(item.app_tour_screen_id);
        }
      }
    });
  }, []);
  const search = async (value: string) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      window.setTimeout(() => {
        goToSearch(value);
      }, 1000)
    );
  };

  const goToSearch = async (value: string) => {
    let args = { ...filterData, search: value };
    setFilterData(args);
    getInitData(args);
  };

  const openDetailedGroupsPage = (item: any) => {
    history.push(`/groupDetails/${item.group_doc_id}`);
  };
  const handlePageChange = (event:any) => {
    const selected = event.selected;
    const arg = { ...filterData, page: selected + 1 };
    setFilterData(arg);
    getInitData(arg);
  };
  const handleBlockGroup = () => {
    if (selectedGroup?.status !== 'blocked') {
      dispatch(
        groupSlice.actions.blockGroup({
          doc_id: selectedGroup?.group_doc_id,
          status: 'blocked',
        })
      );
    } else {
      dispatch(
        groupSlice.actions.blockGroup({
          doc_id: selectedGroup?.group_doc_id,
          status: 'active',
        })
      );
    }
    setIsBlockOpen(false);
  };

  const handleDeleteGroup = () => {
    dispatch(
      groupSlice.actions.deleteGroup({
        group_id: selectedGroup?.group_id,
      })
    );
    setIsDeleteOpen(false);
  };

  const handleSort = (value: string) => {
    const order =
      filterData.sort === value
        ? filterData.order === 'DESC'
          ? 'ASC'
          : 'DESC'
        : 'ASC';
    let args = { ...filterData, sort: value, order };
    getInitData(args);
    setFilterData(args);
  };

  const handleAddGroup = () => {
    appTour?.map((item: any) => {
      if (item.app_tour_screens.screenPrefix === 'GROUP_MODAL') {
        if (!item?.is_finished) {
          setSwitchValue(2);
          closeTourOne();
          openTourTwo();
          setAppTourTwoId(item.app_tour_screen_id);
        }
      }
    });
    if (remainingGroups > 0) setIsOpenAddUser(true);
    else {
      setIsToastOpen(true);
    }
  };

  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  const tourConfigOne = [
    {
      selector: '[data-tut="reactour__add_group"]',
      content: () => (
        <Text>
          Click <Tooltips>Add Group</Tooltips> to invite <br />
          group manager
        </Text>
      ),
      position: 'top',
    },
  ];

  const tourConfigTwo = [
    {
      selector: '[data-tut="reactour__name_group"]',
      content: () => (
        <Text>
          Name the <Tooltips>group</Tooltips>
        </Text>
      ),
      position: 'right',
    },
    {
      selector: '[data-tut="reactour__turn_on"]',
      content: () => (
        <Text>
          Turn <Tooltips>On</Tooltips> to invite a group manager
        </Text>
      ),
      position: 'right',
    },
    {
      selector: '[data-tut="reactour__email"]',
      content: () => <Text>Emails can only be used once</Text>,
      position: 'right',
    },
    {
      selector: '[data-tut="reactour__admin"]',
      content: () => (
        <Text>
          <Tooltips>Admin</Tooltips> cannot access personal data for any user.
        </Text>
      ),
      position: 'right',
    },
    {
      selector: '[data-tut="reactour__personal"]',
      content: () => (
        <Text>
          Group managers can access personal data <Tooltips>ONLY</Tooltips> if
          the user chooses.
        </Text>
      ),
      position: 'right',
    },
  ];
  const tourConfigThree = [
    {
      selector: '[data-tut="reactour__success"]',
      content: () => (
        <Text>
          The group manager must verify the email.
          <br /> A group manager can now sign in and invite users!
        </Text>
      ),
      position: 'right',
    },
  ];
  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);
  const closeTourOne = () => {
    const data = {
      screen_id: parseInt(appTourOneId),
    };
    dispatch(groupAdminSlice.actions.appTourFinished(data as GroupCloseTour & void));
    setIsTourOpenOne(false);
  };
  const closeTourTwo = () => {
    const data = {
      screen_id: parseInt(appTourTwoId),
    };
    dispatch(groupAdminSlice.actions.appTourFinished(data as GroupCloseTour & void));
    setIsTourOpenTwo(false);
  };
  const closeTourThree = () => {
    const data = {
      screen_id: parseInt(appTourThreeId),
    };
    dispatch(groupAdminSlice.actions.appTourFinished(data as GroupCloseTour & void));
    setIsTourOpenThree(false);
  };
  const openTourOne = () => {
    setIsTourOpenOne(true);
  };
  const openTourTwo = () => {
    setIsTourOpenTwo(true);
  };
  const accentColor = '#5cb7b7';

  const onCloseAddGroup = () => {
    setIsOpenAddUser(false);
  };

  return (
    
    <Page title='Group List'>
      <div className='mx-auto px-4 sm:px-8'>
        {switchValue === 1 ? (
          <Tour
            onRequestClose={() => {
              closeTourOne();
            }}
            steps={tourConfigOne}
            isOpen={isTourOpenOne}
            maskClassName='mask'
            className='helper'
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            showNumber={false}
            showNavigation={false}
            showButtons={false}
          />
        ) : switchValue === 2 ? (
          <Tour
            onRequestClose={() => {
              closeTourTwo();
            }}
            steps={tourConfigTwo}
            isOpen={isTourOpenTwo}
            maskClassName='mask'
            className='helper'
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
          />
        ) : (
          <Tour
            onRequestClose={() => {
              closeTourThree();
            }}
            steps={tourConfigThree}
            isOpen={isTourOpenThree}
            maskClassName='mask'
            className='helper'
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            showNumber={false}
            showNavigation={false}
            showButtons={false}
          />
        )}

        <div className='mt-5'>
          <div className='flex md:flex-row flex-col gap-4 justify-between w-full mb-3'>
            <div
              className=''
              style={{ width: '338px' }}
            >
              <SearchBox
                onChange={(e:any) => search(e.target.value)}
                placeholder='Search group'
              />
            </div>
            <div className='md:ml-0 ml-auto'>
              <div data-tut='reactour__add_group'>
                <Button
                  onPress={handleAddGroup}
                  value='Add Group'
                  icon={Add}
                  disabled={false}
                  buttonWidth={'162px'}
                  buttonHeight={'44px'}
                  fontSize={'14px'}
                  fontWeight={'500'}
                />
              </div>

              {isToastOpen && (
                <PlanUpdateToast
                  isToastOpen={isToastOpen}
                  handleToastClose={handleToastClose}
                />
              )}
            </div>
          </div>
          
          {!!allGroupslist && !!allGroupslist.length && (
            <div className='overflow-x-hidden relative'>
              <div className='overflow-x-auto border-user rounded-xl'>
                <div className='inline-block min-w-full rounded-lg overflow-hidden'>
                  <table className='min-w-full leading-normal myborder'>
                    <thead style={{ minWidth: 1760, width: '100%' }}>
                      <tr
                        className='flex justify-between text-sm font-medium'
                        style={{ backgroundColor: '#F5F7FF' }}
                      >
                        <th
                          style={{ width: 80 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          Sl No
                        </th>
                        <th
                          style={{ width: 200 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>Group Name</span>
                            <img
                              className='cursor-pointer'
                              onClick={() => handleSort('name')}
                              src={sort}
                              alt=''
                            />
                          </div>
                        </th>
                        <th
                          style={{ width: 200 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>First Name</span>
                          </div>
                        </th>
                        <th
                          style={{ width: 250 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>Last Name</span>
                          </div>
                        </th>
                        <th
                          style={{ width: 300 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>Email</span>
                          </div>
                        </th>
                        <th
                          style={{ width: 200 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>Created On</span>
                            <img
                              className='cursor-pointer'
                              onClick={() => handleSort('createdAt')}
                              src={sort}
                              alt=''
                            />
                          </div>
                        </th>
                        <th
                          style={{ width: 150 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>No Of Users</span>
                          </div>
                        </th>
                        <th
                          style={{ width: 200 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>Total Assessment</span>
                          </div>
                        </th>
                        <th
                          style={{ width: 150 }}
                          className='px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider'
                        >
                          <div className='flex items-center justify-between'>
                            <span>Status</span>
                          </div>
                        </th>
                        <th
                          style={{ width: 30, backgroundColor: '#F5F7FF' }}
                          className='top-border-radius px-5 py-3 border-b-2 border-gray-200 text-left text-gray-700 tracking-wider headcol'
                        >
                          <span style={{ color: '#F5F7FF' }}>.</span>
                        </th>
                      </tr>
                    </thead>
                    {!groupListLoader ? (
                      <tbody style={{ minWidth: 1760, width: '100%' }}>
                        {!!allGroupslist &&
                          !!allGroupslist.length &&
                          allGroupslist.map((item: any, index: any) => (
                            <tr
                              key={index}
                              className={`${
                                index % 2 ? 'secondcolor' : 'bg-white'
                              } flex justify-between`}
                              style={{ cursor: 'pointer' , fontWeight:item.is_default_group?'600':'500'}}
                            >
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 80 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                <div className='flex'>
                                  {index +
                                    1 +
                                    (filterData.page - 1) * filterData.limit}
                                </div>
                              </td>
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 200 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                {item.group_name}
                              </td>
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 200 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                {item.group_manager_id !== user.id
                                  ? item.first_name
                                  : '-'}
                              </td>
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 250 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                {item.group_manager_id !== user.id
                                  ? item.last_name
                                  : '-'}
                              </td>
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 300 }}
                                className='px-5 py-3 text-left float-left text-sm card-text-primary truncate '
                              >
                                {item.group_manager_id !== user.id && item.is_default_group === false
                                  ? item.group_manager_email
                                  : '-'}
                              </td>

                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 200 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                {moment(item?.createdAt).format('ll')}
                              </td>
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 150 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                {!item.is_group_manager_invite ? (<>{item.existing_users}</>) : (<>{ item.user_limit? item.existing_users+ '/' +item.user_limit:item.existing_users+ '/' +0}</>)}
                               
                              </td>
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 200 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                {item.total_assessment}
                              </td>
                              <td
                                onClick={() => openDetailedGroupsPage(item)}
                                style={{ width: 150 }}
                                className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                              >
                                <span className='relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight'>
                                  <span
                                    aria-hidden
                                    className={`${
                                      item.status === 'active'
                                        ? 'active-bg'
                                        : item.status === 'blocked'
                                        ? 'blocked-bg'
                                        : 'pending-bg'
                                    } absolute inset-0  rounded-full`}
                                  ></span>
                                  <span
                                    className={`${
                                      item.status === 'active'
                                        ? 'active-bg'
                                        : item.status === 'blocked'
                                        ? 'blocked-bg'
                                        : 'pending-bg'
                                    } relative capitalize text-xs`}
                                  >
                                    {item.status}
                                  </span>
                                </span>
                              </td>

                              <td
                                // data-tut="reactour__success"
                                style={{ width: 30 }}
                                className={`${
                                  index % 2 ? 'secondcolor' : 'bg-white'
                                } px-5 py-3  text-sm text-right headcol`}
                              >
                                <button
                                  type='button'
                                  className='inline-block card-text-primary'
                                  onClick={() => {
                                    setSelectedGroup(item);
                                  }}
                                  onMouseEnter={() => ReactTooltip.hide()}
                                >
                                  {item.is_group_manager_invite !== user.id ? (
                                    <EditBlock
                                      index={index}
                                      item={item}
                                      user={user}
                                      onEditSelected={() => setIsEditOpen(true)}
                                      onBlockSelected={() =>
                                        setIsBlockOpen(true)
                                      }
                                      onDeleteSelected={() =>
                                        setIsDeleteOpen(true)
                                      }
                                    />
                                  ) : (
                                    <EditBlock
                                      index={index}
                                      item={item}
                                      user={user}
                                      onEditSelected={() => setIsEditOpen(true)}
                                      onBlockSelected={() =>
                                        setIsBlockOpen(true)
                                      }
                                      onDeleteSelected={() =>
                                        setIsDeleteOpen(true)
                                      }
                                    />
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <TableLoader />
                    )}
                  </table>
                </div>
              </div>

              <div className=''>
                <Paginate
                  pageCount={totalCount / 10}
                  handlePageChange={handlePageChange}
                  page={filterData.page}
                  limit={filterData.limit}
                  totalCount={totalCount}
                />
              </div>
            </div>
          )}

          {!!allGroupslist && !allGroupslist.length && (
            <div className='border-user rounded-xl justify-center flex-col py-20'>
              <div className='justify-center flex'>
                <img src={NoData} alt='' />
              </div>
              <div className='justify-center flex'>
                <span className='text-xl'>No Data Found</span>
              </div>
            </div>
          )}
        </div>
        {isOpenAddUser && (
          <AddGroup
            hasModalView={isOpenAddUser}
            closeModal={() => {
              onCloseAddGroup();
            }}
          />
        )}
        {isBlockOpen && (
          <UserBlockModal
            hasModalView={isBlockOpen}
            closeModal={() => setIsBlockOpen(false)}
            onPress={handleBlockGroup}
            title={`Do you want to ${
              selectedGroup?.status !== 'blocked' ? 'block' : 'unblock'
            } ?`}
            confirmText={`Are you sure you want to ${
              selectedGroup?.status !== 'blocked' ? 'block' : 'unblock'
            } this group?`}
            buttonText={`${
              selectedGroup?.status !== 'blocked' ? 'block' : 'unblock'
            }`}
          />
        )}
        {isDeleteOpen && (
          <UserDeleteModal
            hasModalView={isDeleteOpen}
            closeModal={() => setIsDeleteOpen(false)}
            onPress={handleDeleteGroup}
            type='group'
          />
        )}
        {isEditOpen && (
          <AddGroup
            hasModalView={isEditOpen}
            closeModal={() => setIsEditOpen(false)}
            groupData={selectedGroup}
          />
        )}
      </div>
    </Page>
  );
}
