/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { DisableAdd, NoData } from "../../assets/images";
import { storageEngine } from "../../common/utility";
import Page from "../../components/Page/Page";
import SearchBox from "../../components/SearchBox/SearchBox";
import sort from "../../assets/img/icons/sort.svg";
import { Add } from "../../assets/images";
import "./clientUserListStyles.scss";
import UserEditModal from "../../components/modals/userList/userEdit/UserEditModal";
import UserBlockModal from "../../components/modals/userList/userBlock/UserBlockModal";
import UserDeleteModal from "../../components/modals/userList/userDelete/UserDeleteModal";
import userSlice from "../../redux/slices/user.slice";
import { useDispatch, useSelector } from "react-redux";
import AddUserModal from "../../components/modals/userList/AddUserModal/AddUserModal";
import Button from "../../components/button/Button";
import authSlice from "../../redux/slices/auth.slice";
import { useHistory } from "react-router-dom";
import GroupNameTooltip from "../../components/Tooltip/GroupNameTooltip";
import Paginate from "../../components/Paginate/Paginate";
import groupAdminSlice from "../../redux/slices/groupAdmin.slice";
import EditBlockDelete from "../../components/Tooltip/EditBlockDelete";
import DropDownWithMuiltiSelect from "../../components/Dropdown/MuiltiSelectGroupList";
import superAdminSlice from "../../redux/slices/superAdmin.slice";
import UserEditByGroupManager from "../../components/modals/userList/userEdit/UserEditByGroupManager";
import ResendEmail from "../../components/modals/userList/ResendEmail/ResendEmail";
import ExportCsvFile from "../../components/ExportCsvFile/ExportCsvFile";
import ButtonLoader from "../../components/Loader/ButtonLoader";
import TableLoader from "../../components/Loader/TableLoader";

const ClientUserList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpenAddUser, setIsOpenAddUser] = useState(false);

  const [filterData, setFilterData] = useState({
    limit: 10,
    page: 1,
    sort: "createdAt",
    order: "DESC",
    search: "",
    status: ["blocked", "active", "pending", "email not verified"],
  });
  const [currentSelected, setCurrentSelected] = useState();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [resendEmailIsOpen, setResendEmailIsOpen] = useState(false);

  // const [status, setStatus] = useState([]);
  const user = storageEngine.get("user");
  const role = user?.roles?.role;

  useEffect(() => {
      dispatch(superAdminSlice.actions.getClientUserList(filterData));
  }, []);

  const getUserList = (args) => {
    dispatch(authSlice.actions.setTopBatTitle("users"));

    dispatch(userSlice.actions.getUserList(args));
  };

  const userList = useSelector((state) => state.user.userList);
  const userListLoading = useSelector((state) => state.user.userListLoading);
  const clientUserList = useSelector((state) => state.superAdmin.clientUserList);
  const allUser = userList && userList?.allusers;
  const totalCount = userList && userList?.count;
  const remainingUsers = useSelector(
    (state) => state.groupAdmin?.remainingUsers?.remainingNumOfUsers
  );
  const [typingTimeout, setTypingTimeout] = useState(0);
  const search = async (value) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      window.setTimeout(() => {
        goToSearch(value);
      }, 1000)
    );
  };
  const goToSearch = async (value) => {
    let args = { ...filterData, search: value };
    setFilterData(args);
    getUserList(args);
  };


  const handleStatus = (event) => {
    const status: any = [];
    event &&
      event.length &&
      event.map((item: any) => {
        status.push(item.value);
      });
    let args = { ...filterData, status: status };
    setFilterData(args);
    getUserList(args);
  };
  const handleUserListWithGroupId = (event) => {
    const group_id: any = [];
    event &&
      event.length &&
      event.map((item: any) => {
        group_id.push(item.value);
      });
    let args = { ...filterData, group_id: group_id }
    setFilterData(args)
    dispatch(
      userSlice.actions.getUserList(args)
    );

  };

  const reRouteToDetails = (item) => {
    if (!item.enableDataPrivacy) {
      role === "group" && history.push(`/userDetails/${item.doc_id}`);
    }
  };

  const handlePageChange = (event) => {
    const selected = event.selected;
    const arg = { ...filterData, page: selected + 1 };
    setFilterData(arg);
    getUserList(arg);
  };
  const handleBlockedUser = () => {
    if (currentSelected.status !== "blocked") {
      dispatch(
        userSlice.actions.blockUser({
          doc_id: currentSelected?.doc_id,
          status: "blocked",
        })
      );
    } else {
      dispatch(
        userSlice.actions.blockUser({
          doc_id: currentSelected?.doc_id,
          status: "active",
        })
      );
    }
    setIsBlockOpen(false);
  };
  const handleDeleteUser = () => {
    dispatch(userSlice.actions.deleteUser({ doc_id: currentSelected?.doc_id }));
    setIsDeleteOpen(false);
  };
  const handleResendEmail = () => {
    dispatch(userSlice.actions.resendInvite({ email: currentSelected?.email }));
  }
  const [groupListFilterData, setGroupListFilterData] = useState({
    limit: 5,
    page: 1,
    sort: "createdAt",
    order: "DESC",
    search: ""
  });
  const [options, setOptions] = useState([]);
  const fetchMoreData = () => {
    let args = { ...groupListFilterData, page: groupListFilterData.page + 1 };
    setGroupListFilterData(args);
    // dispatch(clientAdminSlice.actions.getGroupList(args));
  }


  const groupList = useSelector((state) => state.clientAdmin?.groupList?.allActions);
  const groupListCount = useSelector((state) => state.clientAdmin?.groupList?.count);
  const groupListLoading = useSelector((state) => state.clientAdmin?.groupListLoading);


  useEffect(() => {
    if (groupList && groupList.length) {
      setOptions([]);
      groupList.map((grp) => {
        const label = grp.name;
        const value = parseInt(grp.id);

        setOptions(oldArray => [...oldArray, { label, value }]);
      })
    }
  }, [groupList]);
  const checkIspossibleToRoute = (item) => {
    if (!item.enableDataPrivacy && role === "group") {
      return true;
    } else {
      return false;
    }
  }
  const [isExportOpen, setIsExportOpen] = useState(false);
  const exportUserList = useSelector((state) => state.clientAdmin?.exportUserList);
  const exportUserListLoading = useSelector((state) => state.clientAdmin?.exportUserListLoading);
  const handleExport = () => {
    // dispatch(clientAdminSlice.actions.exportUsers());
    setIsExportOpen(true)
  }
  useEffect(() => {
    if (!exportUserListLoading && isExportOpen) {
      setIsExportOpen(false)
    }
  }, [exportUserListLoading]);

  const handleSort = (value: string) => {
    const order = filterData.sort === value ? filterData.order === "DESC" ? "ASC" : "DESC" : "ASC";
    let args = { ...filterData, sort: value, order };
    getUserList(args);
    setFilterData(args);
  }

  return (
    <Page title="User List">
      <div className="mx-auto px-4 sm:px-8">
        <div className="">
          <div className="md:flex w-full mb-3 mt-8 md:mt-0">
            <div className={`md:flex md:justify-between md:gap-4 ${role === "group" ? "md:w-1/2" : "md:w-9/12"}`}>
              <div className="w-1/2 flex items-end">
                <SearchBox
                  onChange={(e) => search(e.target.value)}
                  placeholder="Search Here..."
                />
              </div>

              {role === 'clientAdmin' && (
                <div className="w-full grid items-end">
                  <DropDownWithMuiltiSelect
                    options={options}
                    onFieldChange={(e) => { handleUserListWithGroupId(e) }}
                    fetchMoreData={fetchMoreData}
                    totalCount={groupListCount}
                    label={`Group Name`}
                    isLoading={groupListLoading}
                  />
                </div>
              )}
              <div className="w-full grid items-end">
                <DropDownWithMuiltiSelect
                  options={[{ label: "Active", value: "active" }, { label: "Blocked", value: "blocked" }, { label: "Pending", value: "pending" }]}
                  onFieldChange={(e) => {
                    handleStatus(e);
                  }}
                  fetchMoreData={{}}
                  totalCount={3}
                  label={`Status`}
                  isLoading={true}
                />
              </div>

            </div>
            <div className={`flex justify-end mt-8 md:mt-0 ${role === "group" ? "md:w-1/2" : "md:w-3/12"}`}>
              {role === "group" && (
                <>
                  {remainingUsers > 0 ? (
                    <Button
                      onPress={() => setIsOpenAddUser(true)}
                      value="Add User"
                      icon={Add}
                    />
                  ) : (
                    <Button
                      value="Add User"
                      icon={DisableAdd}
                      bgColor="#BEC2CE"
                      borderColor="#BEC2CE"
                      color="#444C63"
                    />
                  )}
                </>
              )}
              {role === "clientAdmin" && (
                <Button onPress={() => { handleExport() }} value={exportUserListLoading ? <ButtonLoader /> : "Export"} icon={false} />
              )}
            </div>
          </div>
          {isExportOpen && exportUserList && <ExportCsvFile csvReport={exportUserList} />}
          {!!clientUserList && !!clientUserList?.clientAdmin?.length && (
            <div className="overflow-x-hidden relative">
              <div className="overflow-x-auto border-user rounded-xl">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                  <table className="min-w-full leading-normal myborder user-table-width">
                    <thead
                      style={{
                        minWidth: role === "clientAdmin" ? 2180 : 2180 - 400,
                        width: "100%",
                      }}
                    >
                      <tr
                        className="flex justify-between"
                        style={{ backgroundColor: "#F5F7FF" }}
                      >
                        <th
                          style={{ width: 100 }}
                          className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          Sl No
                        </th>
                        <th
                          style={{ width: 250 }}
                          className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          <div className="flex items-center justify-between">
                            <span>First Name</span>
                            <img className="cursor-pointer" onClick={() => handleSort("firstName")} src={sort} alt="" />
                          </div>
                        </th>
                        <th
                          style={{ width: 250 }}
                          className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          <div className="flex items-center justify-between">
                            <span>Last Name</span>
                            <img className="cursor-pointer" onClick={() => handleSort("lastName")} src={sort} alt="" />
                          </div>
                        </th>
                        <th
                          style={{ width: 400 }}
                          className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          <div className="flex items-center justify-between">
                            <span>Email</span>
                            <img className="cursor-pointer" onClick={() => handleSort("email")} src={sort} alt="" />
                          </div>
                        </th>
                        {role === "clientAdmin" && (
                          <th
                            style={{ width: 400 }}
                            className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                          >
                            <div className="flex items-center justify-between">
                              <span>Group Name</span>
                              <img className="cursor-pointer"
                                // onClick={() => handleSort("groupName")} 
                                src={sort} alt="" />
                            </div>
                          </th>
                        )}
                        <th
                          style={{ width: 300 }}
                          className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          <div className="flex items-center justify-between">
                            <span>Created On</span>
                            <img className="cursor-pointer" onClick={() => handleSort("createdAt")} src={sort} alt="" />
                          </div>
                        </th>
                        <th
                          style={{ width: 300 }}
                          className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          <div className="flex items-center justify-between">
                            <span>Last Assessment</span>
                            <img
                              // onClick={() => handleSort("lastAssessment")}
                              src={sort} alt="" />
                          </div>
                        </th>
                        <th
                          style={{ width: 150 }}
                          className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          <div className="flex items-center justify-between">
                            <span>Status</span>
                          </div>
                        </th>
                        <th
                          style={{ width: 30, backgroundColor: "#F5F7FF" }}
                          className="top-border-radius px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider headcol"
                        >
                          <span style={{ color: "#F5F7FF" }}>.</span>
                        </th>
                      </tr>
                    </thead>
                    {!userListLoading ? (
                      <tbody
                        style={{
                          minWidth: role === "clientAdmin" ? 2180 : 2180 - 400,
                          width: "100%",
                        }}
                      >
                        {!!clientUserList &&
                          !!clientUserList?.clientAdmin?.length &&
                          clientUserList?.clientAdmin?.map((item: any, index: any) => (
                            <tr
                              key={index}
                              className={`${index % 2 ? "secondcolor" : "bg-white"
                                } flex justify-between ${checkIspossibleToRoute(item) ? "cursor-pointer" : ""}`}
                            >
                              <td
                                onClick={() => reRouteToDetails(item)}
                                style={{ width: 100 }}
                                className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
                              >
                                <div className="flex">
                                  {" "}
                                  {index +
                                    1 +
                                    (filterData.page - 1) * filterData.limit}
                                </div>
                              </td>
                              <td
                                style={{ width: 250 }}
                                onClick={() => reRouteToDetails(item)}
                                className={`px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate`}
                              >
                                <span className={`${!item.enableDataPrivacy && "underline text-underline-position card-color-primary"}`}>{item.firstName}</span>

                              </td>
                              <td
                                style={{ width: 250 }}
                                className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
                              >
                                {item.lastName}
                              </td>
                              <td
                                style={{ width: 400 }}
                                className="px-5 py-3 text-left float-left text-sm card-text-primary truncate"
                              >
                                {item.email}
                              </td>
                              {role === "clientAdmin" && (
                                <td
                                  style={{ width: 400 }}
                                  className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
                                >
                                  {!!item.group_details &&
                                    !!item.group_details.length && (
                                      <div className="flex">
                                        <div className="flex">
                                          {item.group_details.map(
                                            (grp: any, i: any) => (
                                              <div className="">
                                                {i === 0 && (
                                                  <div className="">
                                                    {grp?.groups?.name}
                                                  </div>
                                                )}
                                                {i === 1 && (
                                                  <div className="ml-2 border-l">
                                                    <div className="ml-2">
                                                      {grp?.groups?.name}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                        {item.group_details.length - 2 > 0 && (
                                          <GroupNameTooltip
                                            number={item.group_details.length - 2}
                                            index={index}
                                            names={item.group_details.map(
                                              (i) => i?.groups?.name
                                            )}
                                          />
                                        )}
                                      </div>
                                    )}
                                </td>
                              )}

                              <td
                                style={{ width: 300 }}
                                className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
                              >
                                {moment(item?.createdAt).format("ll")}
                              </td>
                              <td
                                style={{ width: 300 }}
                                className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
                              >
                                {!!item.assessments &&
                                  !!item.assessments.length &&
                                  moment(item.assessments[0].createdAt).format(
                                    "MMMM DD, YYYY, h:mm A"
                                  )}
                              </td>
                              <td
                                style={{ width: 150 }}
                                className="px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate"
                              >
                                <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                  <span
                                    aria-hidden
                                    className={`${item.status === "active"
                                      ? "active-bg"
                                      : item.status === "blocked"
                                        ? "blocked-bg"
                                        : "pending-bg"
                                      } absolute inset-0  rounded-full`}
                                  ></span>
                                  <span
                                    className={`${item.status === "active"
                                      ? "active-bg"
                                      : item.status === "blocked"
                                        ? "blocked-bg"
                                        : "pending-bg"
                                      } relative capitalize text-xs`}
                                  >
                                    {item.status}
                                  </span>
                                </span>
                              </td>

                              <td
                                style={{ width: 30 }}
                                className={`${index % 2 ? "secondcolor" : "bg-white"
                                  } px-5 py-3  text-sm text-right headcol`}
                              >
                                <button
                                  type="button"
                                  className="inline-block card-text-primary"
                                  onClick={() => setCurrentSelected(item)}
                                >
                                  <EditBlockDelete
                                    key={index}
                                    index={index}
                                    onEditSelected={() => {
                                      setIsEditOpen(true);
                                    }}
                                    onBlockSelected={() => setIsBlockOpen(true)}
                                    onDeleteSelected={() => setIsDeleteOpen(true)}
                                    item={item}
                                    onReSendEmailRequest={() => setResendEmailIsOpen(true)}
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>

                    ) : (
                      <TableLoader />
                    )}
                  </table>
                </div>
              </div>

              <div className="">
                <Paginate
                  pageCount={totalCount / 10}
                  handlePageChange={handlePageChange}
                  page={filterData.page}
                  limit={filterData.limit}
                  totalCount={totalCount}
                />
              </div>
            </div>
          )}

          {!!allUser && !allUser.length && (
            <div className="border-user rounded-xl justify-center flex-col py-20">
              <div className="justify-center flex">
                <img src={NoData} alt="" />
              </div>
              <div className="justify-center flex">
                <span className="text-xl">No Data Found</span>
              </div>
            </div>
          )}
        </div>
        {isOpenAddUser && (
          <AddUserModal
            hasModalView={isOpenAddUser}
            closeModal={() => setIsOpenAddUser(false)}
          />
        )}
        {isEditOpen && role === 'clientAdmin' && (
          <UserEditModal
            hasModalView={isEditOpen}
            closeModal={() => setIsEditOpen(false)}
            selectedItem={currentSelected}
          />
        )}
        {isEditOpen && role === 'group' && (
          <UserEditByGroupManager
            hasModalView={isEditOpen}
            closeModal={() => setIsEditOpen(false)}
            selectedItem={currentSelected}
          />
        )}
        {isBlockOpen && (
          <UserBlockModal
            hasModalView={isBlockOpen}
            closeModal={() => setIsBlockOpen(false)}
            onPress={handleBlockedUser}
            title={`Do you want to ${currentSelected?.status !== "blocked" ? "block" : "unblock"
              } ?`}
            confirmText={`Are you sure you want to ${currentSelected?.status !== "blocked" ? "block" : "unblock"
              } this user?`}
            buttonText={`${currentSelected?.status !== "blocked" ? "block" : "unblock"
              }`}
          />
        )}
        {isDeleteOpen && (
          <UserDeleteModal
            hasModalView={isDeleteOpen}
            closeModal={() => setIsDeleteOpen(false)}
            onPress={handleDeleteUser}
          />
        )}
        {resendEmailIsOpen && (
          <ResendEmail
            hasModalView={resendEmailIsOpen}
            closeModal={() => setResendEmailIsOpen(false)}
            onSubmit={handleResendEmail}
          />
        )}
      </div>
    </Page>
  );
};
export default ClientUserList;
