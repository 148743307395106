import { takeEvery, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { navigator } from '../../navigation/navigator';
import individualUserSlice from '../slices/individualUser.slice';
import { storageEngine } from '../../common/helper';
import Api from '../../services/Apis/index';
import {
  INDIVIDUAL_SUBSCRIPTION_DETAIL,
  INDIVIDUAL_SUBSCRIPTION_GET_INVOICE,
  INDIVIDUAL_SUBSCRIPTION_CANCEL_SUBSCRIPTION,
  GET_SAVED_CARD_DATA,
  DELETE_CARD,
  SET_DEFAULT_CARD,
  DELETE_ACCOUNT,
  PLAN_UPGRADE,
  RENEW_SUBSCRIPTION,
  SKIP_PAYMENT,
  VERIFY_CAPCHA
} from '../actions/individualUser.action';
import { ResponseGenerator } from '../../interface/Response';
import { CardId, PlanUpgrade } from '../../interface/individualUser';

function* subscriptionDetail() {
  try {
    const response: ResponseGenerator =
      yield Api.individualUser.subscriptionDetail();
    if (response.data.data) {
      yield put(
        individualUserSlice.actions.setSubscriptionDetail(response.data.data)
      );
    }
  } catch (err: any) {
    console.log(err);
  }
}

function* getInvoices(action: any) {
  try {
    const response: ResponseGenerator = yield Api.individualUser.getInvoices(
      action.payload
    );
    if (response.data.data) {
      yield put(individualUserSlice.actions.setInvoices(response.data.data));
    }
  } catch (err: any) {
    console.log(err);
  }
}

function* deleteCard(action: PayloadAction<CardId>) {
  try {
    const response: ResponseGenerator = yield Api.individualUser.deleteCard(
      action.payload
    );
    toast.success(response.data.message);
    yield put(individualUserSlice.actions.getSavedCardData());
  } catch (err: any) {
    console.log(err);
  }
}

function* setDefaultCard(action: PayloadAction<CardId>) {
  try {
    const response: ResponseGenerator = yield Api.individualUser.setDefaultCard(
      action.payload
    );
    toast.success(response.data.message);
    yield put(individualUserSlice.actions.getSavedCardData());
  } catch (err: any) {
    console.log(err);
  }
}

function* planUpgrade(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator = yield Api.individualUser.planUpgrade({
      plan_type: action.payload.plan_type,
    });
    toast.success(response.data.message);
    yield storageEngine.set('user', response.data.data);
    yield put(individualUserSlice.actions.setUserData(response.data.data));
    yield put(
      individualUserSlice.actions.getInvoices({
        limit: 10,
        page: 1,
        sort: 'createdAt',
        order: 'DESC',
        search: '',
      })
    );
    action.payload.setCardListModalView(false);
    yield put(individualUserSlice.actions.setPlanUpgradeLoader(false));
    yield put(individualUserSlice.actions.subscriptionDetail());
    navigator.history.replace('/individualUser/upgrade-congratulations');
  } catch (err: any) {
    yield put(individualUserSlice.actions.setPlanUpgradeLoader(false));
    toast.error(err.response.data.message);
  }
}

function* cancelSubscription(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.individualUser.cancelSubscription();
    yield storageEngine.set('user', response.data.data);
    yield put(individualUserSlice.actions.setUserData(response.data.data));
    if (response.data.data) {
      toast.success('Subscription cancelled successfully');
    }
    yield put(individualUserSlice.actions.setCancelSubscriptionLoader(false));
    action.payload.setCancelSubscription(false);
  } catch (err: any) {
    yield put(individualUserSlice.actions.setCancelSubscriptionLoader(false));
    console.log(err.response.data.message);
  }
}

function* deleteAccount(action: PayloadAction<any>) {
  try {
    yield Api.individualUser.deleteAccount();
    yield put(individualUserSlice.actions.setDeleteAccountLoader(false));
    toast.success('Account deleted successfully');
    storageEngine.unset('user');
    storageEngine.unset('access_token');
    storageEngine.unset('refresh_token');
    storageEngine.unset('appTour');
    storageEngine.unset('active');
    navigator.history.replace('/');
  } catch (err: any) {
    toast.error(err.response.data.message);
  }
}

function* getSavedCardData(action: PayloadAction) {
  try {
    const response: ResponseGenerator =
      yield Api.individualUser.getSavedCardData();
    yield put(individualUserSlice.actions.setSavedCardData(response.data.data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* renewSubscription(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator =
      yield Api.individualUser.renewSubscription();
    yield put(individualUserSlice.actions.setRenewSubscriptionLoader(false));
    yield storageEngine.set('user', response.data.data);
    yield put(individualUserSlice.actions.setUserData(response.data.data));
    if (response.data.data) {
      toast.success('Subscription renewed successfully');
    }
    action.payload.setRenewSubscription(false);
  } catch (err: any) {
    yield put(individualUserSlice.actions.setRenewSubscriptionLoader(false));
    console.log(err.response.data.message);
  }
}
function* skipPayment(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator = yield Api.withoutAuth.skipPayment(
      action.payload
    );
    if (response.data.message) {
      toast.success(response.data.message);
    }
    window.location.href = 'https://vibeonix.com/thank-you.html'
  } catch (err: any) {
    console.log(err.response.data.message);
  }
}

function* setCapchaVerify(action: any) {
  try {
    const response: ResponseGenerator =
      yield Api.withoutAuth.verifyCapcha(action?.payload);
    yield put(
      individualUserSlice.actions.setCapchaData(response)
    );
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* individualUserSaga() {
  yield takeEvery(INDIVIDUAL_SUBSCRIPTION_DETAIL, subscriptionDetail);
  yield takeEvery(INDIVIDUAL_SUBSCRIPTION_GET_INVOICE, getInvoices);
  yield takeEvery(
    INDIVIDUAL_SUBSCRIPTION_CANCEL_SUBSCRIPTION,
    cancelSubscription
  );
  yield takeEvery(GET_SAVED_CARD_DATA, getSavedCardData);
  yield takeEvery(DELETE_CARD, deleteCard);
  yield takeEvery(SET_DEFAULT_CARD, setDefaultCard);
  yield takeEvery(DELETE_ACCOUNT, deleteAccount);
  yield takeEvery(PLAN_UPGRADE, planUpgrade);
  yield takeEvery(RENEW_SUBSCRIPTION, renewSubscription);
  yield takeEvery(SKIP_PAYMENT, skipPayment);
  yield takeEvery(VERIFY_CAPCHA, setCapchaVerify);
}

export default individualUserSaga;
