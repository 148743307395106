import { Line, Chart } from 'react-chartjs-2';
import moment from 'moment';

const defaults = Chart.defaults;

defaults.plugins.tooltip.backgroundColor = '#fff';
defaults.plugins.tooltip.titleColor = '#000';
defaults.plugins.tooltip.bodyColor = '#000';
defaults.plugins.tooltip.yAlign = 'center';
defaults.plugins.tooltip.borderWidth = 0.5;
defaults.plugins.tooltip.borderColor = '0px 0px 4px rgba(68, 76, 99, 0.2);';

// Legend
defaults.plugins.legend.display = true;
defaults.plugins.legend.align = 'start';
defaults.plugins.legend.position = 'bottom';
defaults.plugins.legend.labels.padding = 30;
defaults.plugins.legend.labels.boxWidth = 15;

function Graph({ data, height = 415 }: any) {
  return (
    <div style={{}}>
      <Line
        type={'Bar'}
        data={data}
        height={height}
        width={800}
        options={{
          plugins: {
            tooltip: {
              callbacks: {
                title: function (context) {
                  var title = context[0].label;
                  var l = title.length;
                  if (
                    moment(title).format('MMM D, yyyy') ===
                    moment(new Date()).format('MMM D, yyyy')
                  )
                    return moment(title).format('h:mm A');
                  else return title.substring(0, l - 3);
                },
              },
            },
          },
          animation: {
            duration: 2000,
          },

          scales: {
            x: {
              barPercentage: 1,
              maxBarThickness: 10,
              ticks: {
                callback: function (val: any) {
                  var string = this.getLabelForValue(val);
                  var l = this.getLabelForValue(val).length;
                  if (
                    moment(string).format('MMM D, yyyy') ===
                    moment(new Date()).format('MMM D, yyyy')
                  )
                    return moment(string).format('h:mm A');
                  else return string.substring(l - 3, l);
                },
              },
            },
            y: 
              {
                beginAtZero: true,
              },
            
          },
          borderJoinStyle: 'round',
          tension: 0.5,
        }}
      />
    </div>
  );
}

export default Graph;

// ticks: {
//   callback: function (val, index, ticks) {
//     var string = this.getLabelForValue(val);
//     var l = this.getLabelForValue(val).length;
//     if (this.getLabelForValue(val) === 'Today') return 'Today';
//     else if(this.getLabelForValue(val) === 'wed') return string;
//     else return string.substring(l-10,l-8);
//   },
// },
