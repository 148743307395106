import React, { useState, useEffect } from 'react';
import { DownArrow } from '../../assets/images';
import './styles.scss';

const MonthPicker = ({ setMonthYearParent }: any) => {
  const date = new Date().getFullYear();
  const [monthYear, setMonthYear] = useState({ month: '', year: date, key: 0 });

  const getMonthValue = () => {
    const monthVal = monthYear && monthYear.month ? monthYear.month : 0;
    const yearVal = monthYear && monthYear.year ? monthYear.year : 0;
    return monthVal && yearVal ? `${monthVal}-${yearVal}` : '';
  };
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const lang = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const increment = () => {
    setMonthYear({ month: '', year: monthYear.year + 1, key: monthYear.key });
  };
  const decrement = () => {
    setMonthYear({ month: '', year: monthYear.year - 1, key: monthYear.key });
  };
  const onSlectMonth = (val: string, key: number) => {
    setMonthYear({ month: val, year: monthYear.year, key: key });
  };
  useEffect(() => {
    setMonthYearParent(monthYear);
  }, [monthYear]);
  return (
    <div className='MonthYearPicker'>
      <button>{getMonthValue()}</button>
      <div>
        <div className='flex justify-between my-4'>
          <div
            className='transform rotate-90 cursor-pointer p-4'
            onClick={() => decrement()}
          >
            <img src={DownArrow} alt='up' />
          </div>
          <div>{monthYear.year}</div>
          <div
            className='transform -rotate-90 cursor-pointer p-4'
            onClick={() => {
              monthYear.year < date && increment();
            }}
          >
            <img src={DownArrow} alt='down' />
          </div>
        </div>
        <div className='grid grid-cols-4 gap-2'>
          {lang?.map((index, key:any) => {
            return (
              <div
              key={key}
                onClick={() => {
                  ((key <= month && monthYear.year === year) ||
                    monthYear.year !== year) &&
                    onSlectMonth(index, key + 1);
                }}
                className={`${
                  (key <= month && monthYear.year === year) ||
                  monthYear.year !== year
                    ? monthYear.month === index
                      ? 'bg-indigo-600 text-white'
                      : 'month-container month-container-hover cursor-pointer'
                    : 'bg-gray-200'
                } flex hover:bg-red-700 z-10 rounded-lg px-4 py-4 items-center justify-center`}
              >
                {index}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MonthPicker;
