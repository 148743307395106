export const GET_QUICK_INFO = 'clientAdmin/getQuickInfo';
export const GET_ALL_USER_WELLBEING = 'clientAdmin/getAlluserWellBeing';
export const GET_USER_ENERGY_STATE = 'clientAdmin/getuserEnergyState';
export const GET_GROUP_WELLBEING_PATTERN =
  'clientAdmin/getGroupWellBeingPattern';
export const GET_GROUP_WELLBEING_PATTERN_CUSTOM_DATE =
  'clientAdmin/getGroupWellBeingPatternCustomDate';
export const GET_GROUP_LIST = 'clientAdmin/getGroupList';
export const GET_ALL_GROUP_LIST = 'clientAdmin/getAllGroupList';
export const GET_USER_EMO_INFO = 'clientAdmin/getuserEmotionalInfo';
export const UPDATE_CILENT_ADMIN_ACCOUNT =
  'clientAdmin/updateClientAdminAccount';
export const GET_REMAINING_GROUP_AND_USERS =
  'clientAdmin/getRemainingGroupsAndUsers';
export const USER_GROUP_CHANGE = 'clientAdmin/userGroupChange';

export const GET_SETTINGS = 'clientAdmin/getSettings';
export const EXPORT_USERS = 'clientAdmin/exportUsers';
export const EXPORT_USER_DETAIL_USERS = 'clientAdmin/handleUserDetailExport';

export const GROUP_WITH_GROUP_MANAGER = 'clientAdmin/groupsWithGroupManager';
export const PACKAGE_SELECTION = 'clientAdmin/packageSelection';
export const GET_SAVED_CARDS = 'clientAdmin/getSavedCards';
export const GET_DEFAULT_CARD = 'clientAdmin/defaultCard';
export const GET_DELETE_CARD = 'clientAdmin/deleteCard';
export const GET_SUBSCRIPTION_DETAIL = 'clientAdmin/subscriptionDetail';
export const GET_USER_DETAIL = 'clientAdmin/userDetail';
export const CANCEL_SUBSCRIPTION = 'clientAdmin/cancelSubscription';
export const INVOICE = 'clientAdmin/invoices';
export const GET_INVITE_LINK = 'clientAdmin/getInviteLink';
export const GET_REMAINING_USERS = 'clientAdmin/getRemainingUsers';
export const GET_COURSE_DETAILS = 'clientAdmin/getCourseDetails';
export const UPDATE_COURSE_DETAILS = 'clientAdmin/updateCourseDetails';
export const GET_COURSE_DEFAULT_DETAILS = 'clientAdmin/getCourseDefaultDetails';