import React from 'react';
import { Search } from '../../assets/images'
import './SearchBox.scss';

const SearchBox = ({ onChange, placeholder }:any) => {
    return (
        <div className="flex viveonixSearchBox">
            <img src={Search} width={'20'} alt="search" />
            <input onChange={onChange} className="font-Lexend-Light ml-4 my-2 w-full" name="search" placeholder={placeholder}/>
        </div>
    )
}
export default SearchBox;