import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import './AddUserModal.scss';
import editClose from '../../../../assets/img/icons/editClose.svg';
import InputBox from '../../../../components/FormikFields/InputBox/InputBox';
import Button from '../../../FormikFields/Button/Button';
import { InviteUserSchema } from '../../../../utils/validation/schemas';
import userSlice from '../../../../redux/slices/user.slice';
import ButtonLoader from '../../../Loader/ButtonLoader';
import groupAdminSlice from '../../../../redux/slices/groupAdmin.slice';
import { toast } from 'react-toastify';

const AddUserModal = ({ hasModalView, closeModal, onUserInvite }: any) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const formikRef = useRef<any>(null);
  const dispatch = useDispatch();

  const inviteUserLoader = useSelector(
    (state: any) => state.user.inviteUserLoader
  );

  const inviteLink = useSelector((state: any) => state.groupAdmin?.inviteLink);

  const [isSubmit, setIsSubmit] = useState(false);

  const registerGroupAdmin = (values: any) => {
    setIsSubmit(true);
    dispatch(
      userSlice.actions.inviteUser({
        ...values,
        firstName: values.firstName?.replace(/\s+$/, ''),
        LastName: values.lastName?.replace(/\s+$/, ''),
      })
    );
  };

  useEffect(() => {
    dispatch(groupAdminSlice.actions.getInviteLink());
  }, []);

  useEffect(() => {
    if (!inviteUserLoader && isSubmit) {
      closeModal();
      onUserInvite();
    }
  }, [inviteUserLoader]);

  const onCopyToClipboard = async () => {
    toast.success('Invite link copied to clipboard');
    navigator.clipboard.writeText(inviteLink);
  };

  return (
    <div className="">
      <div
        className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          hasModalView ? 'block' : 'hidden'
        }`}
      >
        <div className="popup-container absolute w-full mb-6 top-6">
          <img
            className="img z-10 absolute cursor-pointer"
            onClick={() => closeModal()}
            src={editClose}
            alt="img"
          />
          <div className="popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 rounded-xl relative overflow-hidden">
            <div className="text-gray-900 popup-title text-2xl flex justify-center">
              Invite User
            </div>
            <div className="w-full float-left mt-5 pb-2">
              <div className="text-sm font-semibold card-color-primary py-2">
                Share invite link
              </div>
              <div className="flex space-x-2">
                <input
                  defaultValue={inviteLink}
                  disabled
                  className="w-full h-8 border border-primary bg-white rounded-md text-gray-dark text-xs px-2 focus:outline-none"
                  type="text"
                ></input>
                <button
                  className="copy-link-button flex-shrink-0 flex items-center text-xs h-8 px-2 rounded-md border bg-white shadow-xs font-semibold card-color-primary"
                  onClick={() => onCopyToClipboard()}
                >
                  Copy Link
                </button>
              </div>
            </div>
            <div className="popup-frm w-full float-left mt-6">
              <div className="text-sm font-semibold card-color-primary py-2">
                Invite through mail
              </div>
              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={registerGroupAdmin}
                validationSchema={() => InviteUserSchema}
              >
                {({ handleSubmit, isValid }) => (
                  <div>
                    <div className="">
                      <InputBox
                        name="firstName"
                        label="First Name"
                        placeholder=""
                      />
                    </div>
                    <div className="mt-4">
                      <InputBox
                        name="lastName"
                        label="Last Name"
                        placeholder=""
                      />
                    </div>
                    <div className="mt-4">
                      <InputBox name="email" label="Email" placeholder="" />
                    </div>
                    <div className="mt-8 flex justify-center">
                      <div className="w-1/2">
                        <Button
                          onClick={handleSubmit}
                          text={inviteUserLoader ? <ButtonLoader /> : 'Invite'}
                          active={isValid}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
