/* eslint-disable import/no-unresolved */
import { combineReducers } from 'redux';
import loaderSlice from '../slices/loader.slice';
import authSlice from '../slices/auth.slice';
import groupSlice from '../slices/group.slice';
import userSlice from '../slices/user.slice';
import indicatorSlice from '../slices/indicator.slice';
import groupAdminSlice from '../slices/groupAdmin.slice';
import clientAdminSlice from '../slices/clientAdmin.slice';
import superAdminSlice from '../slices/superAdmin.slice';
import individualUserSlice from '../slices/individualUser.slice'

const rootReducer = combineReducers({
  // selectedOptionReducer,
  loader: loaderSlice.reducer,
  auth: authSlice.reducer,
  group: groupSlice.reducer,
  user: userSlice.reducer,
  indicator: indicatorSlice.reducer,
  groupAdmin: groupAdminSlice.reducer,
  clientAdmin: clientAdminSlice.reducer,
  superAdmin: superAdminSlice.reducer,
  individualUser: individualUserSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
