/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetGroupById,
  GetGroupList,
  GetUserListWithClientGroupId,
  GetGroupWellBeing,
  GetGroupEnergyState,
  GetUserListWithGroupId,
  AddUsersToGroup,
  BlockGroup,
  NumUserValidation,
  DeleteGroup,
} from '../../interface/group';

type InitialState = {
  user: any;
  groupList: any;
  groupsListById: any;
  groupWellBeing: any;
  groupWellBeingLoader: boolean;
  groupsWiseEnergy: any;
  groupsWiseEnergyLoader: boolean;
  usersListWithGroupId: any;
  clientUserList: any;
  createGroupLoader: any;
  groupBlockLoader: boolean;
  groupDeleteLoader: boolean;
  addUsersToGroupLoader: boolean;
  groupFilterParams: {
    limit: number;
    page: number;
    sort: string;
    order: string;
    search: string;
  };
  getGroupDetails: { doc_id: string };
  clientUserListLoading: boolean;
  usersListWithGroupIdLoading: boolean;
  groupListLoader: boolean;
  createGroupError: string;
  numUserValidationList: any;
  addUsersToGroupModal: boolean;
  secondPage: boolean;
};

const initialState: InitialState = {
  user: undefined,
  groupList: null,
  groupsListById: undefined,
  groupWellBeing: undefined,
  groupWellBeingLoader: false,
  groupsWiseEnergy: undefined,
  groupsWiseEnergyLoader: false,
  usersListWithGroupId: undefined,
  clientUserList: undefined,
  createGroupLoader: false,
  groupBlockLoader: false,
  groupDeleteLoader: false,
  addUsersToGroupLoader: false,
  groupFilterParams: {
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
    search: '',
  },
  getGroupDetails: { doc_id: '' },
  clientUserListLoading: false,
  usersListWithGroupIdLoading: false,
  groupListLoader: false,
  createGroupError: '',
  numUserValidationList: undefined,
  addUsersToGroupModal: false,
  secondPage: false,
};


const createGroup = (state: InitialState, action: any) => {
  state.createGroupLoader = true;
  state.createGroupError = '';
};
const setCreatedGroup = (state: InitialState, action: any) => {
  state.createGroupLoader = false;
};

const setCreatedGroupError = (state: InitialState, action: any) => {
  state.createGroupError = action.payload;
};
const updateGroup = (state: InitialState, action: any) => {
  state.createGroupLoader = true;
  state.createGroupError = '';
};
const setUpdateGroup = (state: InitialState, action: any) => {
  state.createGroupLoader = false;
};

const blockGroup = (state: InitialState, action: PayloadAction<BlockGroup>) => {
  state.groupBlockLoader = true;
};
const setBlockGroup = (state: InitialState, action: any) => {
  state.groupBlockLoader = false;
};

const deleteGroup = (
  state: InitialState,
  action: PayloadAction<DeleteGroup>
) => {
  state.groupDeleteLoader = true;
};
const setDeleteGroup = (state: InitialState, action: any) => {
  state.groupDeleteLoader = false;
};

const getGroupList = (
  state: InitialState,
  action: PayloadAction<GetGroupList>
) => {
  state.groupsListById = undefined;
  state.usersListWithGroupId = undefined;
  state.groupWellBeing = undefined;
  state.groupsWiseEnergy = undefined;
  state.usersListWithGroupId = undefined;
  state.clientUserList = undefined;
  state.groupListLoader = true;
  if (action.payload === undefined) {
    state.groupFilterParams.search = '';
    state.groupFilterParams.order = 'DESC';
    state.groupFilterParams.limit = 10;
    state.groupFilterParams.page = 1;
    state.groupFilterParams.sort = 'createdAt';
  } else {
    state.groupFilterParams.search = action.payload.search;
    state.groupFilterParams.order = action.payload.order;
    state.groupFilterParams.limit = action.payload.limit;
    state.groupFilterParams.page = action.payload.page;
    state.groupFilterParams.sort = action.payload.sort;
  }
};
const setGroupList = (state: InitialState, action: any) => {
  state.groupList = action.payload;
  state.groupListLoader = false;
};
const getGroupById = (
  state: InitialState,
  action: PayloadAction<GetGroupById>
) => {
  state.getGroupDetails.doc_id = action.payload.doc_id;
};
const setGroupsListById = (state: any, action: any) => {
  state.groupsListById = action.payload;
};
const getGroupWellBeing = (
  state: any,
  action: PayloadAction<GetGroupWellBeing>
) => {
  state.groupWellBeingLoader = true;
};
const setGroupWellBeing = (state: InitialState, action: any) => {
  state.groupWellBeing = action.payload;
  state.groupWellBeingLoader = false;
};

const getGropwiseEnergeState = (
  state: any,
  action: PayloadAction<GetGroupEnergyState>
) => {
  state.groupsWiseEnergyLoader = true;
};
const setGroupwiseEnergeState = (state: InitialState, action: any) => {
  state.groupsWiseEnergy = action.payload;
  state.groupsWiseEnergyLoader = false;
};
const getUsersListWithGroupId = (
  state: InitialState,
  action: PayloadAction<GetUserListWithGroupId>
) => {
  state.usersListWithGroupIdLoading = true;
};
const setUsersListWithGroupId = (state: InitialState, action: any) => {
  state.usersListWithGroupId = action.payload;
  state.usersListWithGroupIdLoading = false;
};
const getClientUserList = (
  state: InitialState,
  action: PayloadAction<GetUserListWithGroupId>
) => {
  state.clientUserListLoading = true;
};
const setClientUserList = (state: InitialState, action: any) => {
  state.clientUserList = action.payload;
  state.clientUserListLoading = false;
};

const addUsersToGroup = (
  state: any,
  action: PayloadAction<AddUsersToGroup>
) => {
  // state;
  state.addUsersToGroupLoader = true;
};

const setAddUsersToGroup = (state: InitialState) => {
  state.addUsersToGroupLoader = false;
};

const getUserListByClientGroupId = (
  state: InitialState,
  action: PayloadAction<GetUserListWithClientGroupId>
) => {
  state.usersListWithGroupIdLoading = true;
};

const numUserValidation = (
  state: InitialState,
  action: PayloadAction<NumUserValidation>
) => {};
const setNumUserValidation = (
  state: InitialState,
  action: PayloadAction<NumUserValidation>
) => {
  state.numUserValidationList = action.payload;
};

const addUsersToGroupModalOpenClose = (state: InitialState) => {
  state.addUsersToGroupModal = !state.addUsersToGroupModal;
};

const addUsersToGroupModalReset = (state: InitialState) => {
  state.addUsersToGroupModal = false;
};

const setSecondPage = (state: InitialState) => {
  state.secondPage = !state.secondPage;
};

const setSecondPageReset = (state: InitialState) => {
  state.secondPage = false;
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    createGroup,
    setCreatedGroup,
    setCreatedGroupError,
    blockGroup,
    setBlockGroup,
    updateGroup,
    setUpdateGroup,
    getGroupList,
    setGroupList,
    getGroupById,
    setGroupsListById,
    getGroupWellBeing,
    setGroupWellBeing,
    getGropwiseEnergeState,
    setGroupwiseEnergeState,
    getUsersListWithGroupId,
    setUsersListWithGroupId,
    getClientUserList,
    setClientUserList,
    addUsersToGroup,
    setAddUsersToGroup,
    getUserListByClientGroupId,
    numUserValidation,
    setNumUserValidation,
    deleteGroup,
    setDeleteGroup,
    addUsersToGroupModalOpenClose,
    addUsersToGroupModalReset,
    setSecondPage,
    setSecondPageReset,
  },
});
export default groupSlice;
