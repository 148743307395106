import React, { useState} from "react";
import Button from "../../button/Button";
import editClose from "../../../assets/img/icons/editClose.svg";
import "./styles.scss";
import MonthPicker from "../../MonthPicker/MonthPicker";

const MonthPickerModal = ({ hasModalView, closeModal, buttonTextOne, buttonTextTwo, onPress }: any) => {

  const [monthYear, setMonthYear] = useState({ month: '', year: 2021 });

  return (

    <div
      className={`z-50 pop-up-wrapper-style items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${hasModalView ? "block" : "hidden"
        }`}
    >
      <div className="pop-up-container absolute w-full mb-6 top-6">
        <img
          className="img z-10 absolute"
          onClick={closeModal}
          src={editClose}
          alt=""
        />
        <div className="flex w-full flex-col">
          <MonthPicker setMonthYearParent= {(val: any) => setMonthYear(val)}/>
        </div>

          <div className="flex flex-row pt-4 pb-2 w-full gap-4">
            <div className="w-1/2 flex justify-end">
              <Button
                onPress={closeModal}
                value={buttonTextOne}
                bgColor="#fff" borderColor="#A6A9B4" color="#A6A9B4"
              />
            </div>
            <div className="w-1/2">
              <Button onPress={() => onPress(monthYear)} value={buttonTextTwo} />
            </div>
          </div>
      </div>

    </div>
  );
};

export default MonthPickerModal;
