/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
	getStarted,
	messageIconDark,
	mobileLearningIcon,
	mobileTabAssessment,
	mobileTabGroups,
	mobileTabIndicators,
	mobileTabIndividualUser,
	mobileTabUsers,
	sideNavSelector,
} from "../../assets/images";
import { storageEngine } from "../../common/helper";
import home from "../../assets/img/icons/home.svg";
import userIcon from "../../assets/img/icons/userIcon.svg";
import groupIcon from "../../assets/img/icons/groupIcon.svg";
import indicatorIcon from "../../assets/img/icons/indicatorIcon.svg";
import subscriptionIcon from "../../assets/img/icons/subscriptionIcon.svg";
import menuCollapse from "../../assets/img/icons/menuCollapse.svg";
import authSlice from "../../redux/slices/auth.slice";
import {
	mobileDashboard,
	mobileUsers,
	mobileGroups,
	mobileIndicator,
	mobileSubscription,
	mobileAccountsIcon,
	mobileUser,
	vibeonixLogo,
} from "../../assets/images";
import "./styles.scss";
import Button from "../../components/button/Button";

const Sidenav = (props: any) => {
	const node = useRef<any>();
	const { showSide, onChange } = props;

	useEffect(() => {
		// add when mounted
		document.addEventListener("mousedown", handleClick);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleClick = (e: any) => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		onChange(false);
		e.stopPropagation();
	};
	const [dropdownContent, setDropdownContent] = useState(false);
	const [accountStyle, setAccountStyle] = useState(false);
	//State
	const user = storageEngine.get("user");
	const role = user && user.roles.role;

	//Redux State
	const selected = useSelector((state: any) => state.auth.sideNavSelector);
	const title = useSelector((state: any) => state.auth.topBatTitle);

	//Functions

	const selectedSidenavOption = (option: any) => {
		dispatch(authSlice.actions.setSideNavSelector(option));
	};

	useEffect(() => {
		dispatch(authSlice.actions.setSideNavSelector(title));
	});

	const dispatch = useDispatch();
	const logout = () => {
		dispatch(authSlice.actions.logout());
	};

	const openAccountSettings = () => {
		setAccountStyle(!accountStyle);
	};

	return (
		<aside
			ref={node}
			className={`fixed md:fixed bg-sidebar h-screen  ${
				showSide
					? "block z-50 bg-white mt-20 w-3/4 overflow-y-scroll rounded-tr-md"
					: "hidden w-1/5"
			}`}
		>
			{!showSide && (
				<div
					className={`sidebar-profile flex flex-row justify-center w-full py-50, px-10`}
				>
					<img className="h-full w-14 pt-3 mr-5" src={vibeonixLogo} />
					<img
						className="h-30 w-14 pt-3 mr-5 w-1/5 cursor-pointer"
						src={menuCollapse}
					/>
				</div>
			)}
			<div className="flex flex-wrap justify-between menu-items mt-5">
				{role && role === "group" && (
					<>
						<div
							className="flex w-full gap-3 h-16"
							onClick={() => selectedSidenavOption("dashboard")}
						>
							<div className="mt-4">
								{selected === "dashboard" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>

							<div className="w-full">
								<NavLink
									to="/dashboard"
									activeClassName={
										showSide ? "mobile-active-class" : `activeClass`
									}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={showSide ? mobileDashboard : home}
											width="25"
										/>
										<h1
											onClick={handleClick}
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Dashboard
										</h1>
									</div>
								</NavLink>
							</div>
						</div>
						<div
							className="flex w-full gap-3 h-16"
							onClick={() => selectedSidenavOption("users")}
						>
							<div className="mt-4">
								{selected === "users" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>

							<div className="w-full">
								<NavLink
									to="/userList"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={showSide ? mobileUsers : userIcon}
											width="25"
										/>
										<h1
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Users
										</h1>
									</div>
								</NavLink>
							</div>
						</div>
						<div
							className="flex w-full gap-3 h-16"
							onClick={() => selectedSidenavOption("indicators")}
						>
							<div className="mt-4">
								{selected === "indicators" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/indicators"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={showSide ? mobileIndicator : indicatorIcon}
											width="25"
										/>
										<h1
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Indicators
										</h1>
									</div>
								</NavLink>
							</div>
						</div>

						<div
							className="flex w-full gap-3 h-16"
							onClick={() => selectedSidenavOption("message")}
						>
							<div className="mt-4">
								{selected === "message" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/message"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={messageIconDark}
											width="25"
										/>
										<h1
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Message
										</h1>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				{role && role === "clientAdmin" && (
					<>
						<div
							className="flex w-full gap-3 h-16"
							onClick={() => selectedSidenavOption("dashboard")}
						>
							<div className="mt-4">
								{selected === "dashboard" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>

							<div className="w-full">
								<NavLink
									to="/dashboard"
									activeClassName={
										showSide ? "mobile-active-class" : `activeClass`
									}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={showSide ? mobileDashboard : home}
											width="25"
										/>
										<h1
											onClick={handleClick}
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Dashboard
										</h1>
									</div>
								</NavLink>
							</div>
						</div>

						<div
							className="flex w-full gap-3 h-16"
							onClick={() => selectedSidenavOption("users")}
						>
							<div className="mt-4">
								{selected === "users" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>

							<div className="w-full">
								<NavLink
									to="/userList"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={showSide ? mobileUsers : userIcon}
											width="25"
										/>
										<h1
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Users
										</h1>
									</div>
								</NavLink>
							</div>
						</div>

						{role && role !== "vibeonixAdmin" && (
							<div
								className="flex w-full gap-3 h-16"
								onClick={() => selectedSidenavOption("groups")}
							>
								<div className="mt-4">
									{selected === "Groups" ? (
										<img src={sideNavSelector} />
									) : (
										<div className="w-3"></div>
									)}
								</div>
								<div className="w-full">
									<NavLink
										to="/groupList"
										activeClassName={showSide ? "" : `activeClass`}
										className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
									>
										<div
											className="flex flex-row w-full"
											onClick={() => onChange(false)}
										>
											<img
												className="h-full w-14 mr-5"
												src={showSide ? mobileGroups : groupIcon}
												width="25"
											/>
											<h1
												className={`flex text-xl ${
													showSide
														? "table-text-color font-normal leading-4 tracking-tight mt-1"
														: "text-white"
												}`}
											>
												Groups
											</h1>
										</div>
									</NavLink>
								</div>
							</div>
						)}

						<div
							className="flex w-full gap-3 h-16"
							onClick={() => selectedSidenavOption("indicators")}
						>
							<div className="mt-4">
								{selected === "indicators" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/indicators"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={showSide ? mobileIndicator : indicatorIcon}
											width="25"
										/>
										<h1
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Indicators
										</h1>
									</div>
								</NavLink>
							</div>
						</div>

						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Actions")}
						>
							<div className="mt-4">
								{selected === "actions" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/actions"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={mobileLearningIcon}
											width="25"
										/>
										<h1
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Learning Library
										</h1>
									</div>
								</NavLink>
							</div>
						</div>

						<div
							className="flex w-full gap-3 mt-4"
							onClick={() => selectedSidenavOption("Subscriptions")}
						>
							<div className="mt-4">
								{selected === "subscriptions" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/subscriptions"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 mr-5"
											src={showSide ? mobileSubscription : subscriptionIcon}
											width="25"
										/>
										<h1
											className={`flex text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Subscriptions
										</h1>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				{role && role === "vibeonixAdmin" && (
					<>
						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Dashboard")}
						>
							<div className="mt-4">
								{selected === "Dashboard" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/superDashboard"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 pt-3 mr-5"
											src={mobileDashboard}
											width="25"
										/>
										<h1
											className={`flex pt-3 text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Dashboard
										</h1>
									</div>
								</NavLink>{" "}
							</div>
						</div>
						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Clients")}
						>
							<div className="mt-4">
								{selected === "Clients" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/superClients"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 pt-3 mr-5"
											src={mobileTabGroups}
											width="25"
										/>
										<h1
											className={`flex pt-3 text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Clients
										</h1>
									</div>
								</NavLink>{" "}
							</div>
						</div>
						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Assessment Results")}
						>
							<div className="mt-4">
								{selected === "Assessment Results" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/superAdminAssessmentResults"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 pt-3 mr-5"
											src={mobileTabAssessment}
											width="25"
										/>
										<h1
											className={`flex pt-3 text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Assessment Results
										</h1>
									</div>
								</NavLink>{" "}
							</div>
						</div>

						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Business Users")}
						>
							<div className="mt-4">
								{selected === "Business Users" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/SuperAdminBusinessUserList"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 pt-3 mr-5"
											src={mobileTabUsers}
											width="25"
										/>
										<h1
											className={`flex pt-3 text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Business Users
										</h1>
									</div>
								</NavLink>{" "}
							</div>
						</div>

						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Individual Users")}
						>
							<div className="mt-4">
								{selected === "Individual Users" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/superAdminIndividualUserList"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 pt-3 mr-5"
											src={mobileTabIndividualUser}
											width="25"
										/>
										<h1
											className={`flex pt-3 text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Individual Users
										</h1>
									</div>
								</NavLink>{" "}
							</div>
						</div>
						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Indicators")}
						>
							<div className="mt-4">
								{selected === "Indicators" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/indicators"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 pt-3 mr-5"
											src={mobileTabIndicators}
											width="25"
										/>
										<h1
											className={`flex pt-3 text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Indicators
										</h1>
									</div>
								</NavLink>{" "}
							</div>
						</div>
						<div
							className="flex w-full gap-3"
							onClick={() => selectedSidenavOption("Learning Library")}
						>
							<div className="mt-4">
								{selected === "Learning Library" ? (
									<img src={sideNavSelector} />
								) : (
									<div className="w-3"></div>
								)}
							</div>
							<div className="w-full">
								<NavLink
									to="/learningLibrary"
									activeClassName={showSide ? "" : `activeClass`}
									className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
								>
									<div
										className="flex flex-row w-full"
										onClick={() => onChange(false)}
									>
										<img
											className="h-full w-14 pt-3 mr-5"
											src={mobileLearningIcon}
											width="25"
										/>
										<h1
											className={`flex pt-3 text-xl ${
												showSide
													? "table-text-color font-normal leading-4 tracking-tight mt-1"
													: "text-white"
											}`}
										>
											Learning Library
										</h1>
									</div>
								</NavLink>{" "}
							</div>
						</div>
						{/* <NavLink
              to='/subscriptions'
              activeClassName={showSide ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row w-full'>
                <img
                  className='h-full w-14 pt-3 mr-5'
                  src={showSide ? mobileSubscription : subscriptionIcon}
                  width='25'
                />
                <h1
                  className={`flex pt-3 text-xl ${
                    showSide
                      ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                      : 'text-white'
                  }`}
                >
                  Subscriptions
                </h1>
              </div>
            </NavLink> */}
					</>
				)}

				{/* <NavLink
          to="/subscribe"
          activeClassName="activeClass"
          className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
        >
          <div className="flex flex-row w-full">
            <img className="h-full w-14 pt-3 mr-5" src={showSide ? mobileSubscription : subscriptionIcon} width="25"/>
          </div>
        </NavLink> */}
			</div>
			{showSide && role && role === "clientAdmin" && (
				<div
					className={`justify-center mt-6 items-center overflow-hidden mx-auto w-full ${
						accountStyle ? "account-background" : ""
					}`}
				>
					<div
						className="flex flex-row w-full border-b-2 border-white py-3 px-6"
						onClick={openAccountSettings}
					>
						<img className="h-full w-14 mr-5" src={mobileUser} width="25" />
						<h1
							className={`flex text-xl ${
								showSide
									? "table-text-color font-normal leading-4 tracking-tight mt-1"
									: "text-white"
							}`}
							onClick={() => setDropdownContent(!dropdownContent)}
						>
							My Account
						</h1>
					</div>
					{dropdownContent && (
						<div className="w-36 mt-4 px-10">
							<NavLink
								to="/settings"
								activeClassName={showSide ? "" : `activeClass`}
								className="justify-center items-center overflow-hidden mx-auto w-full cursor-pointer"
							>
								<div
									className="flex justify-between"
									onClick={() => onChange(false)}
								>
									<img src={mobileAccountsIcon} />
									<span className="font-medium table-text-color text-base leading-4 tracking-tight">
										Account Settings
									</span>
								</div>
							</NavLink>
							<div>
								<div className="flex justify-between mt-4 " onClick={logout}>
									<img src={mobileAccountsIcon} />
									<span className="font-medium table-text-color text-base leading-4 tracking-tight mb-1">
										Logout
									</span>
								</div>
							</div>
						</div>
					)}
				</div>
			)}

			{role && role !== "vibeonixAdmin" && (
				<div className="px-3 mt-10">
					<div className="flex flex-col text-center items-center p-8 rounded-xl bg-secondary gap-5">
						<img src={getStarted} width="130" />
						<span className="text-white font-light text-sm">
							Learn how to use your
							<br />
							Vibeonix platform.
						</span>
						<div>
							<Button
								onPress={() => {
									window.open("https://course.vibeonix.com", "_blank");
								}}
								value={"Get Started"}
								borderColor={"white"}
								bgColor={"transparent"}
								color={"white"}
								buttonWidth={"168px"}
								height={"h-10"}
							/>
						</div>
					</div>
				</div>
			)}
		</aside>
	);
};
export default Sidenav;
