import React, {useLayoutEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.scss";
import { cleanClipboardData } from "../../utils/utils";

const RichEditor = ({
	onChange,
	readOnly,
	style,
	onBlur,
	onFocus,
	isSubmit,
	filterClipboardData,
	filterClassNames,
}: any) => {
	useLayoutEffect(() => {
		if (filterClipboardData && filterClassNames) {
			filterClipboardData &&
				filterClassNames &&
				cleanClipboardData(filterClassNames);
		}
	}, [filterClipboardData]);
	const quillRef = useRef(null);

	if (isSubmit) {
		if (quillRef) {
			const quillEditor = (quillRef.current as any).getEditor();
			quillEditor.setText("");
		}
	}

	return (
		<ReactQuill
			ref={quillRef}
			onBlur={onBlur}
			onFocus={onFocus}
			onChange={onChange}
			readOnly={readOnly}
			style={style}
			placeholder="Type Message"
		/>
	);
};
export default RichEditor;
