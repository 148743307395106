import moment from "moment";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
	const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
	return buildDate;
};

export const isValidUrl = (string) => {
	try {
		new URL(string);
		return true;
	} catch (err) {
		return false;
	}
};

export const monthDateYearFormat = (date) => {
	return moment(date).format("DD/MM/YYYY");
};


export const formatTime = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds =Math.round(seconds % 60) ;

  var timeString = hours + ':' +
    minutes + ':' +
    remainingSeconds;

  return timeString;
}

export const currentPage = (currentCount, pagination) => {
	return Math.ceil(currentCount / pagination);
};

export const concatUniqeItem = (arr1, arr2) => {
	let result = arr1.slice();

	for (let obj of arr2) {
		let found = false;
		for (let resObj of result) {
			if (obj.value === resObj.value) {
				found = true;
				break;
			}
		}
		if (!found) {
			result.push(obj);
		}
	}

	return result;
};

export const concatUniqeId = (arr1, arr2) => {
	let result = arr1.slice();

	for (let obj of arr2) {
		let found = false;
		for (let resObj of result) {
			if (obj === resObj) {
				found = true;
				break;
			}
		}
		if (!found) {
			result.push(obj);
		}
	}

	return result;
};

export const concatUniqeItemChat = (arr1, arr2) => {
	let result = arr1.slice();

	for (let obj of arr2) {
		let found = false;
		for (let resObj of result) {
			if (obj.id === resObj.id) {
				found = true;
				break;
			}
		}
		if (!found) {
			result.push(obj);
		}
	}

	return result;
};

export const removeHtmlTags = (html) => {
	return html.replace(/<[^>]+>/g, "");
};

export const hasImageTag=(htmlString)=>{
  // Create a temporary element to parse the HTML string
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // Check if any img tags exist within the temporary element
  const imageTags = tempElement.getElementsByTagName('img');
  return imageTags.length > 0;
}

export const isExitSpecialCharacters = (text) => {
	var regex = /^[0-9aA-zZ\s]+$/;
	if (regex.test(text)) {
		return false;
	} else {
		return true;
	}
};

export const vhToPx = (vh) => {
	return (vh * window.innerHeight) / 100;
};

export const pxToVh = (px) => {
	return (px * 100) / window.innerHeight;
};

export const cleanClipboardData = (className) => {
	var divs = document.querySelectorAll(`${className}`);
	divs[0].addEventListener("paste", function (event) {
		event.preventDefault();
		var text = event.clipboardData.getData("text/plain");
		text = text.replace(/[^\w\s]/gi, ""); // Replace any non-alphanumeric characters
		document.execCommand("insertHTML", false, text);
	});
};

export const getVimeoVideoId = (videoUrl) => {
	const regex =
		/(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|)(\d+)(?:$|\/|\?)/i;
	const match = videoUrl.match(regex);
	if (match) {
		return { isVimeoUrl: true, url: match[1] };
	} else {
		return { isVimeoUrl: false, url: videoUrl }; // Return the exact URL if it's not a valid Vimeo URL
	}
};

export const getPercentage = (duration, currentDuration) => {
	let percentage = (currentDuration / duration) * 100;

	return percentage;
};

export const percentageToNumber = (startNumber, endNumber, percentage) => {
	// Calculate the range of numbers
	let range = endNumber - startNumber;

	// Calculate 45% of the range
	let result = (percentage / 100) * range;
	return result;
};
