export const GET_CLIENT_USER_LIST = 'superAdmin/getClientUserList';
export const GET_SUPER_ADMIN_CLIENT_LIST = 'superAdmin/getSuperAdminClientList';
export const GET_SUPER_ADMIN_INDIVIDUAL_USER_LIST = 'superAdmin/getSuperAdminIndividualUserList';
export const GET_SUPER_ADMIN_BUSINESS_USER_LIST = 'superAdmin/getSuperAdminBusinessUserList';
export const GET_SUPER_ADMIN_USER_LIST = 'superAdmin/getSuperAdminUserList';
export const GET_CLIENT_QUICK_INFO = 'superAdmin/getClientQuickInfo';
export const GET_CLIENT_DETAILS_VIEW = 'superAdmin/getClientDetailsView';
export const GET_CLIENT_USERS_WELL_BEING = 'superAdmin/getClientUsersWellbeing';
export const GET_CLIENT_USERS_ENERGY_STATE =
  'superAdmin/getClientUsersEnergyState';
export const GET_ALL_ORG_LIST = 'superAdmin/getAllOrgList';
export const EXPORT_USERS = 'superAdmin/exportUsers';
export const EXPORT_CLIENTS = 'superAdmin/exportClients';
export const GET_DASHBOARD_QUICK_INFO = 'superAdmin/getDashboardQuickInfo';
export const GET_DASHBOARD_CLIENT_STATUS =
  'superAdmin/getDashboardClientStatus';
export const GET_ALL_USERS_ENERGY_STATE = 'superAdmin/getAllUsersEnergyState';
export const BLOCK_USER = 'superAdmin/blockUser';
export const BLOCK_BUSSINESS_USER = 'superAdmin/blockBussinessUser';
export const GET_SUBSCRIPTION_STATUS = 'superAdmin/getSubscriptionStatus';
export const COPY_EMAIL = 'superAdmin/copyEmails';
export const DELETE_CLIENT_ADMIN = 'superAdmin/deleteClientAdmin';
export const CHANGE_SUBSCRIPTION_END_DATE = 'superAdmin/changeSubscriptionEndDate';
export const DELETE_BUSINESS_USER = 'superAdmin/deleteBusinessUser';
export const EXPORT_BUSINESS_USERS = 'superAdmin/exportBusinessUser';
export const EXPORT_INDIVIDUAL_USERS = 'superAdmin/exportIndividualUser';
export const GET_SUPER_ADMIN_LEARNING_LIBRARY = 'superAdmin/getSuperAdminLearningLibrary';
export const GET_SUPER_ADMIN_TOPICS = 'superAdmin/getSuperAdminTopics';
