/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { ModelCloseButton } from "../../../assets/images";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import groupAdminSlice from "../../../redux/slices/groupAdmin.slice";
import Button from "../../button/Button";
import LoaderButton from "../../LoaderButton/LoaderButton";

const DeleteGroup = (props: any) => {
	const dispatch = useDispatch();
	const { isOpen, closeModel } = props as any;
	const [isSubmited, setIsSubmited] = useState(false);

	const handleCloseModel = () => {
		closeModel();
	};

	const deleteGroupDetails = useSelector(
		(state: { groupAdmin: { deleteChatGroup: any } }) =>
			state.groupAdmin.deleteChatGroup
	);

	const handleSubmit = () => {
		dispatch(
			groupAdminSlice.actions.deleteChatGroup({
				group_uuid: props.groupId,
			} as any)
		);
		setIsSubmited(true);
	};
	useEffect(() => {
		if (deleteGroupDetails && isSubmited) {
			closeModel();
			setIsSubmited(false);
			props.handleGroupDeleteReload(props.groupId);
		}
	}, [deleteGroupDetails]);
	return (
		<div>
			<div
				className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
					isOpen ? "block" : "hidden"
				}`}
			>
				<div className="popup-container absolute w-full mb-6 top-6">
					<img
						className="img z-10 absolute"
						onClick={() => handleCloseModel()}
						src={ModelCloseButton}
						alt=""
					/>
					<div className="popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
						<div className="w-full justify-center flex">
							<div className="font-Lexend-Regular font-medium text-secondary text-2xl">
								Delete Group
							</div>
						</div>
						<div className="w-full h-px bg-secondary-light my-6" />
						<div className="flex items-center flex-col">
							<p style={{ fontSize: 17 }} className=" text-left font-normal">
								Are you sure you want to delete this group?
							</p>
							<div className="flex flex-row m-6 items-center">
								<div className="m-2 border-button">
									<Button onPress={handleCloseModel} value="No" />
								</div>
								<div>
									{isSubmited ? (
										<>
											<LoaderButton value="Deleting..." />
										</>
									) : (
										<Button onPress={handleSubmit} value="Yes" />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default DeleteGroup;
