import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { storageEngine } from '../common/helper';

const PublicRoute = ({ component: Component, ...rest }: any) => {

  // check whether the props contain redirect(ie 'to' exist or not)
  // eslint-disable-next-line max-len
  const checkIfRedirectPresent = (props: any, restParam: any) => (restParam.to ? { ...props, to: restParam.to } : props);

  // checking token present in local storage
  const checkIfTokenExist = () => {
    const publicRouteArray = ['/userSignUp/:id', '/verifing-email/:id', '/groupAdminSignUp/:id', '/unsubscribe/:id'];
    if (publicRouteArray.includes(rest.path)) {
      storageEngine.clear();
      return false;
    }
    const access_token = storageEngine.get('access_token');
    if (!access_token) return false;
    return true;
  };
  const isPadCheck = () => {
    const user = storageEngine.get('user');
    const role = user?.roles?.role;
    const isPaid = user?.is_plan_active;
    if (role === 'clientAdmin' && !isPaid) {
      return false;
    } else {
      return true;
    }


  }
  return (
    <Route
      {...rest}
      render={(props) => (!checkIfTokenExist() ? (
        <Component {...checkIfRedirectPresent(props, rest)} />
      ) : (
        <>
          {
          storageEngine.get('user')?.roles?.role === 'individual'
          ? <Redirect to={{ pathname: '/individualUser/my-subscription', state: { from: props.location } }} />
          :
          isPadCheck() ? (<Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />) : (
            <Redirect to={{ pathname: '/package-selection', state: { from: props.location } }} />
          )
          }

        </>
      ))}
    />
  );
};

export default PublicRoute;