/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { UserGroupChangeDTO } from '../../interface/clientAdmin';
import { GetAllGroupList } from '../../interface/clientAdmin';

type InitialState = {
  quickInfo: any;
  alluserWellBeing: any;
  alluserWellBeingLoader: boolean;
  userEnergyState: any;
  userEnergyStateLoader: boolean;
  groupWellBeingPattern: any;
  groupWellBeingPatternLoader: boolean;
  groupList: any;
  allGroupList: any;
  userEmotionalInfo: any;
  userEmotionalInfoLoader: boolean;
  adminSettings: any;
  remainingGroupsAndUsers: any;
  userGroupChangeLoading: boolean;
  userGroupChangeModal: boolean;
  groupListLoading: boolean;
  settings: any;
  exportUserList: any;
  exportDetailUserList: any;
  exportUserListLoading: boolean;
  exportUserDetailLoading: boolean;
  groupsWithGroupManagerListLoading: boolean;
  groupsWithGroupManagerList: any;
  packageLoading: boolean;
  packageSelectionList: any;
  savedCardsData: any;
  subscriptionDetail: any;
  subscriptionDetailLoader: boolean;
  invoiceDetail: any;
  quickInfoLoader: boolean;
  remainingUsers: number;
  courseDetails: any;
  courseUpdateDetails:any,
};

const initialState: InitialState = {
  quickInfo: undefined,
  alluserWellBeing: undefined,
  alluserWellBeingLoader: false,
  userEnergyState: undefined,
  userEnergyStateLoader: false,
  groupWellBeingPattern: undefined,
  groupWellBeingPatternLoader: false,
  groupList: undefined,
  allGroupList: undefined,
  userEmotionalInfo: undefined,
  userEmotionalInfoLoader: false,
  adminSettings: undefined,
  remainingGroupsAndUsers: undefined,
  userGroupChangeModal: false,
  userGroupChangeLoading: false,
  groupListLoading: false,
  settings: undefined,
  exportUserList: undefined,
  exportDetailUserList: undefined,
  exportUserListLoading: false,
  exportUserDetailLoading: false,
  groupsWithGroupManagerList: undefined,
  groupsWithGroupManagerListLoading: false,
  packageLoading: false,
  packageSelectionList: undefined,
  savedCardsData: undefined,
  subscriptionDetail: undefined,
  subscriptionDetailLoader: false,
  invoiceDetail: undefined,
  quickInfoLoader: false,
  remainingUsers: 0,
  courseDetails: undefined,
  courseUpdateDetails: undefined,
};
const getQuickInfo = (state: InitialState, action: any) => {
  state.quickInfoLoader = true;
};
const setQuickInfo = (state: InitialState, action: any) => {
  state.quickInfo = action.payload;
  state.quickInfoLoader = false;
};
const getAlluserWellBeing = (state: any, action: any) => {
  state.alluserWellBeingLoader = true;
};
const setAlluserWellBeing = (state: InitialState, action: any) => {
  state.alluserWellBeing = action.payload;
  state.alluserWellBeingLoader = false;
};
const getGroupWellBeingPattern = (state: any, action: any) => {
  state.groupWellBeingPatternLoader = true;
};
const setGroupWellBeingPattern = (state: InitialState, action: any) => {
  state.groupWellBeingPatternLoader = false;
  state.groupWellBeingPattern = action.payload;
};
const getGroupWellBeingPatternCustomDate = (state: any, action: any) => {
  state.groupWellBeingPatternLoader = true;
};
const getGroupList = (state: InitialState, action: any) => {
  state.groupListLoading = true;
};
const setGroupList = (state: InitialState, action: any) => {
  state.groupList = action.payload;
  state.groupListLoading = false;
};

const getAllGroupList = (state: InitialState) => {};
const setAllGroupList = (
  state: InitialState,
  action: PayloadAction<GetAllGroupList>
) => {
  state.allGroupList = action.payload;
};

const getuserEnergyState = (state: any, action: any) => {
  state.userEnergyStateLoader = true;
};
const setuserEnergyState = (state: InitialState, action: any) => {
  state.userEnergyState = action.payload;
  state.userEnergyStateLoader = false;
};

const getuserEmotionalInfo = (state: any, action: any) => {
  state.userEmotionalInfoLoader = true;
};
const setuserEmotionalInfo = (state: InitialState, action: any) => {
  state.userEmotionalInfo = action.payload;
  state.userEmotionalInfoLoader = false;
};
const updateClientAdminAccount = (state: any, action: any) => {};
const setUpdateClientAdminAccount = (state: InitialState, action: any) => {
  state.adminSettings = action.payload;
};

const getRemainingGroupsAndUsers = () => {};
const setRemainingGroupsAndUsers = (state: InitialState, action: any) => {
  state.remainingGroupsAndUsers = action.payload;
};

const userGroupChange = (
  state: InitialState,
  action: PayloadAction<UserGroupChangeDTO>
) => {
  state.userGroupChangeLoading = true;
  state.userGroupChangeModal = true;
};
const setUserGroupChange = (state: InitialState, action: any) => {
  state.userGroupChangeLoading = false;
};
const setUserGroupChangeModal = (state: InitialState, action: any) => {
  state.userGroupChangeModal = false;
};

const getSettings = () => {};
const setSettings = (state: any, action: any) => {
  state.settings = action.payload;
};

const exportUsers = (state: InitialState) => {
  state.exportUserListLoading = true;
};

const handleUserDetailExport = (state: InitialState) => {
  state.exportUserDetailLoading = true;
};

const setexportUsers = (state: InitialState, action: any) => {
  const dataArray: any[] = [];
  action.payload.data.map((item: any, index: number) => {
    dataArray.push({
      ...item,
      Assessment_time: item.Assessment_time
        ? moment(item.Assessment_time).format('MMMM DD, YYYY, h:mm A')
        : ' ',
    });
  });
  state.exportUserList = { ...action.payload, data: dataArray };
  state.exportUserListLoading = false;
};
const setexportUserListNull = (state: InitialState) => {
  state.exportUserList = undefined;
  state.exportUserListLoading = false;
};
const setUserDetailexportUsers = (state: InitialState, action: any) => {
  const dataArray: any[] = [];
  action.payload.data.map((item: any, index: number) => {
    dataArray.push({
      ...item,
      last_assessment_date: item.last_assessment_date
        ? moment(item.last_assessment_date).format('MMMM DD, YYYY, h:mm A')
        : ' ',
      CreatedAt: item.CreatedAt
        ? moment(item.CreatedAt).format('MMMM DD, YYYY')
        : ' ',
    });
  });
  state.exportDetailUserList = { ...action.payload, data: dataArray };
  state.exportUserDetailLoading = false;
};
const setexportUserDetailNull = (state: InitialState) => {
  state.exportDetailUserList = undefined;
  state.exportUserDetailLoading = false;
};
const setExportUserListLoading = (state: InitialState) => {
  state.exportUserListLoading = false;
};
const setExportUserDetailLoading = (state: InitialState) => {
  state.exportUserDetailLoading = false;
};

const groupsWithGroupManager = (
  state: InitialState,
  action: PayloadAction<{
    limit: number;
    page: number;
    sort: string;
    order: string;
  }>
) => {
  state.groupsWithGroupManagerListLoading = true;
};
const setGroupsWithGroupManager = (state: InitialState, action: any) => {
  state.groupsWithGroupManagerList = action.payload;
  state.groupsWithGroupManagerListLoading = false;
};

const packageSelection = (state: InitialState) => {
  state.packageLoading = true;
};
const setPackageSelection = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.packageSelectionList = action.payload;
  state.packageLoading = false;
};
const getSavedCards = () => {};
const setSavedCards = (state: any, action: any) => {
  state.savedCardsData = action.payload;
};
const defaultCard = (state: any, action: any) => {};
const deleteCard = (state: any, action: any) => {};
const userDetail = (state: any) => {};
const subscriptionDetail = (state: any, action: any) => {
  state.subscriptionDetailLoader = true;
};
const setsubscriptionDetail = (state: any, action: any) => {
  state.subscriptionDetail = action.payload;
  state.subscriptionDetailLoader = false;
};
const cancelSubscription = () => {};
const invoices = (state: any, action: any) => {};
const setInvoiceDetail = (state: any, action: any) => {
  state.invoiceDetail = action.payload;
};
const getRemainingUsers = () => {};
const setRemainingUsers = (state: any, action: any) => {
  state.remainingUsers = action.payload;
};
const getCourseDetails = () => {};
const setCourseDetails = (state: any, action: any) => {
  state.courseDetails = action.payload;
};
const updateCourseDetails  = (state: any, action: any) => {};
const setUpdateCourseDetails = (state: InitialState, action: any) => {
  state.courseUpdateDetails = action.payload;
};
const getCourseDefaultDetails = () => { };
const setCourseDefaultDetails = (state: any, action: any) => {
  state.courseDefaultDetails = action.payload;
};
const clientAdminSlice = createSlice({
  name: 'clientAdmin',
  initialState,
  reducers: {
    getQuickInfo,
    setQuickInfo,
    getAlluserWellBeing,
    setRemainingUsers,
    setAlluserWellBeing,
    userGroupChange,
    setUserGroupChange,
    getGroupWellBeingPattern,
    setGroupWellBeingPattern,
    getGroupWellBeingPatternCustomDate,
    getGroupList,
    setGroupList,
    getAllGroupList,
    setAllGroupList,
    getuserEnergyState,
    setuserEnergyState,
    getuserEmotionalInfo,
    setuserEmotionalInfo,
    updateClientAdminAccount,
    setUpdateClientAdminAccount,
    getRemainingGroupsAndUsers,
    setRemainingGroupsAndUsers,
    getSettings,
    setSettings,
    exportUsers,
    handleUserDetailExport,
    setexportUserDetailNull,
    setexportUsers,
    setUserDetailexportUsers,
    setExportUserListLoading,
    setExportUserDetailLoading,
    groupsWithGroupManager,
    setGroupsWithGroupManager,
    packageSelection,
    setPackageSelection,
    getRemainingUsers,
    getSavedCards,
    setSavedCards,
    defaultCard,
    deleteCard,
    subscriptionDetail,
    setsubscriptionDetail,
    userDetail,
    setUserGroupChangeModal,
    cancelSubscription,
    invoices,
    setInvoiceDetail,
    setexportUserListNull,
    getCourseDetails,
    setCourseDetails,
    updateCourseDetails,
    setUpdateCourseDetails,
    getCourseDefaultDetails,
    setCourseDefaultDetails
  },
});

export default clientAdminSlice;
