import React from 'react';
import './styles.scss'

const TableLoader = () => {
    return (
        <div className="animate-pulse">
            <div className="h-8 bg-gray-200 mt-3 my-4 rounded"></div>
            <div className="h-8 bg-gray-300 my-4 rounded"></div>
            <div className="h-8 bg-gray-200 my-4 rounded"></div>
            <div className="h-8 bg-gray-300 my-4 rounded"></div>
            <div className="h-8 bg-gray-200 my-4 rounded"></div>
            <div className="h-8 bg-gray-300 my-4 rounded"></div>
            <div className="h-8 bg-gray-200 my-4 rounded"></div>
            <div className="h-8 bg-gray-300 my-4 rounded"></div>
            <div className="h-8 bg-gray-200 my-4 rounded"></div>
            <div className="h-8 bg-gray-300 my-4 rounded"></div>
        </div>
    )
}

export default TableLoader;