export const INDIVIDUAL_SUBSCRIPTION_DETAIL =
  'individualUser/subscriptionDetail';
export const INDIVIDUAL_SUBSCRIPTION_GET_INVOICE = 'individualUser/getInvoices';
export const INDIVIDUAL_SUBSCRIPTION_CANCEL_SUBSCRIPTION =
  'individualUser/cancelSubscription';
export const GET_SAVED_CARD_DATA = 'individualUser/getSavedCardData';
export const ADD_NEW_CARD = 'individualUser/addNewCard';
export const DELETE_CARD = 'individualUser/deleteCard';
export const SET_DEFAULT_CARD = 'individualUser/setDefaultCard';
export const DELETE_ACCOUNT = 'individualUser/deleteAccount';
export const PLAN_UPGRADE = 'individualUser/planUpgrade';
export const SET_SHOW_BACK_BUTTON = 'individualUser/setShowBackButton';
export const RENEW_SUBSCRIPTION = 'individualUser/renewSubscription';
export const SKIP_PAYMENT = 'individualUser/skipPayment';
export const VERIFY_CAPCHA = 'individualUser/verifycapcha'