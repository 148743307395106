import React from "react";
import hamburger from "../../../assets/img/icons/hamburger.svg";
import {backArrow, vibeonixLogo} from "../../../assets/images"

export default function MobileTopNav(props: any) {
  const {showSide, onPressHamBurger} = props
  return (
    <div className="h-full w-full items-center flex flex-row pl-4">
      <div className="mr-4" onClick={() => onPressHamBurger()}>
        <img
          onClick={() => {}}
          className="flex mr-2 fill-current"
          src={showSide ? backArrow : hamburger}
          alt=''
        />
      </div>
      <div >
        <img
          onClick={() => {}}
          className="flex mr-2 fill-current"
          src={vibeonixLogo}
          alt=''
        />
      </div>
    </div>
  );
}


