import React from "react";
import { groupUserIcon } from "../../assets/images";
interface propsInterface {
	title: string;
	handleCreatePopup:any
}
function ChatGroupTitle(props: propsInterface) {
	const handleClick = () => {
		props.handleCreatePopup(true);
	}
	return (
		<div className="flex flex-row justify-between mt-6">
			<div className="flex flex-row items-center justify-start text-xs  align-middle" >
				<img className="w-5 mr-1" src={groupUserIcon} alt="" />
				<span className="font-bold text-vibeonixColor text-sm">{props.title}</span>
			</div>
			<button onClick={handleClick} className="ont-bold text-vibeonixColor underline text-sm">+Create</button>
		</div>
	);
}

export default ChatGroupTitle;
