/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  quickInfo: any;
  quickInfoLoading: boolean;
  userWellBeing: any;
  userEnergyState: any;
  userWellBeingPattern: any;
  userEmotionalInfo: any;
  userWellBeingLoading: boolean;
  userEnergyStateLoading: boolean;
  userWellBeingPatternLoading: boolean;
  userEmotionalInfoLoading: boolean;
  createActionLoader: boolean;
  remainingUsers: any;
  deleteActionLoader: boolean;
  inviteLink: string;
  chatUser: undefined;
  chatRecipient: undefined;
  chatEditRecipient: undefined;
  chatGroups: undefined;
  createGroupDetails: undefined;
  chatMessages: undefined;
  sendChatMessage: undefined;
  deleteChatGroup: undefined;
  editChatGroup: undefined;
  groupMembers: undefined;
  privateVimeoVideo: undefined;
  messageStatus: boolean;
};

const initialState: InitialState = {
  quickInfo: undefined,
  quickInfoLoading: false,
  userWellBeing: undefined,
  userEnergyState: undefined,
  userWellBeingPattern: undefined,
  userEmotionalInfo: undefined,
  userWellBeingLoading: false,
  userEnergyStateLoading: false,
  userWellBeingPatternLoading: false,
  userEmotionalInfoLoading: false,
  createActionLoader: false,
  remainingUsers: undefined,
  deleteActionLoader: false,
  inviteLink: '',
  chatUser: undefined,
  chatRecipient: undefined,
  chatEditRecipient: undefined,
  chatGroups: undefined,
  createGroupDetails: undefined,
  chatMessages: undefined,
  sendChatMessage: undefined,
  deleteChatGroup: undefined,
  editChatGroup: undefined,
  groupMembers: undefined,
  privateVimeoVideo: undefined,
  messageStatus: true,
};
const getQuickInfo = (state: InitialState, action: any) => {
  state.quickInfoLoading = true;
};
const setQuickInfo = (state: InitialState, action: any) => {
  state.quickInfo = action.payload;
  state.quickInfoLoading = false;
};

const getuserWellBeing = (state: any, action: any) => {
  state.userWellBeingLoading = true;
};
const setuserWellBeing = (state: InitialState, action: any) => {
  state.userWellBeing = action.payload;
  state.userWellBeingLoading = false;
};

const getuserEnergyState = (state: any, action: any) => {
  state.userEnergyStateLoading = true;
};
const setuserEnergyState = (state: InitialState, action: any) => {
  state.userEnergyState = action.payload;
  state.userEnergyStateLoading = false;
};

const getuserWellBeingPattern = (state: any, action: any) => {
  state.userWellBeingPatternLoading = true;
};
const setuserWellBeingPattern = (state: InitialState, action: any) => {
  state.userWellBeingPattern = action.payload;
  state.userWellBeingPatternLoading = false;
};

const getuserEmotionalInfo = (state: any, action: any) => {
  state.userEmotionalInfoLoading = true;
};
const setuserEmotionalInfo = (state: InitialState, action: any) => {
  state.userEmotionalInfo = action.payload;
  state.userEmotionalInfoLoading = false;
};


const setCreateAction = (state: InitialState, action: any) => {
  state.createActionLoader = false;
};

const getRemainingUsers = () => {};
const setRemainingUsers = (state: InitialState, action: any) => {
  state.remainingUsers = action.payload;
};
const appTourFinished = (state: any, action: any) => {};

const getInviteLink = (state: InitialState) => {};
const setInviteLink = (state: InitialState, action: any) => {
  state.inviteLink = action.payload;
};

const getChatRecipients = (state: InitialState) => {};
const setChatUsers = (state: InitialState, action: any) => {
  state.chatUser = action.payload;
};
const setChatRecipients = (state: InitialState, action: any) => {
  state.chatRecipient = action.payload;
};
const setChatEditRecipients = (state: InitialState, action: any) => {
  state.chatEditRecipient= action.payload;
};

const getChatGroups = (state: InitialState) => {};
const setChatGroups = (state: InitialState, action: any) => {
  state.chatGroups = action.payload;
};

const createChatGroup  = (state: any, action: any) => {};
const setCreateChatGroup = (state: InitialState, action: any) => {
  state.createGroupDetails = action.payload;
};

const getChatMessages  = (state: InitialState) => {};
const setChatMessages = (state: InitialState, action: any) => {
  state.chatMessages = action.payload;
};

const sendChatMessage  = (state: any, action: any) => {};
const setSendChatMessage = (state: InitialState, action: any) => {
  state.sendChatMessage = action.payload;
  //state.messageStatus = true
};
const setSendMessageStatus = (state: any, action: any) => {
  state.messageStatus = action.payload;
};

const deleteChatGroup  = (state: any, action: any) => {};
const setDeleteChatGroup = (state: InitialState, action: any) => {
  state.deleteChatGroup = action.payload;
};


const editChatGroup  = (state: any, action: any) => {};
const setEditChatGroup = (state: InitialState, action: any) => {
  state.editChatGroup = action.payload;
};

const getGroupMembers  = (state: InitialState) => {};
const setGroupMembers = (state: InitialState, action: any) => {
  state.groupMembers = action.payload;
};

const getPrivateVimeoVideo  = (state: InitialState) => {};
const setPrivateVimeoVideo = (state: InitialState, action: any) => {
  state.privateVimeoVideo = action.payload;
};


const groupAdminSlice = createSlice({
  name: 'groupAdmin',
  initialState,
  reducers: {
    getQuickInfo,
    setQuickInfo,
    getuserWellBeing,
    setuserWellBeing,
    getuserEnergyState,
    setuserEnergyState,
    getuserWellBeingPattern,
    setuserWellBeingPattern,
    getuserEmotionalInfo,
    setuserEmotionalInfo,
    setCreateAction,
    getRemainingUsers,
    setRemainingUsers,
    appTourFinished,
    getInviteLink,
    setInviteLink,
    getChatRecipients,
    setChatRecipients,
    getChatGroups,
    setChatGroups,
    setChatUsers,
    setChatEditRecipients,
    createChatGroup,
    setCreateChatGroup,
    getChatMessages,
    setChatMessages,
    sendChatMessage,
    setSendChatMessage,
    deleteChatGroup,
    setDeleteChatGroup,
    editChatGroup,
    setEditChatGroup,
    getGroupMembers,
    setGroupMembers,
    getPrivateVimeoVideo,
    setPrivateVimeoVideo,
    setSendMessageStatus
  },
});

export default groupAdminSlice;
