/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Axios from 'axios';
import { APP_ENTRYPOINT } from '../../../config/config';
import {
  CreateClientAdmin,
  CreateUser,
  LoginUser,
  EmailInterface,
  GroupAdminSignUp,
  CheckEmail,
  IndividualUserSignup,
} from '../../../interface/users';

function userSignUp(value: CreateUser) {
  return Axios.put(`${APP_ENTRYPOINT}auth/userSignup`, value);
}

function individualUserSignup(value: IndividualUserSignup) {
  return Axios.post(`${APP_ENTRYPOINT}individuals/user/signup`, value);
}

function individualAcceptInvite(value: IndividualUserSignup) {
  return Axios.post(`${APP_ENTRYPOINT}individuals/accept/invite`, value);
}

function individualUserPlans() {
  return Axios.get(`${APP_ENTRYPOINT}individuals/plan/details`);
}

function individualUserAddCard(value: any) {
  return Axios.post(`${APP_ENTRYPOINT}individuals/add/card`, value);
}

function clientSignUp(value: CreateClientAdmin) {
  return Axios.post(`${APP_ENTRYPOINT}auth/clientAdminSignup`, value);
}

const login = async (value: LoginUser) => {
  return Axios.post(`${APP_ENTRYPOINT}auth/login`, value);
};

const sendVerificationEmail = async (value: EmailInterface) => {
  return Axios.post(`${APP_ENTRYPOINT}auth/resendVerification`, value);
};

const sendEmailToVerify = async (value: EmailInterface) => {
  return Axios.put(`${APP_ENTRYPOINT}auth/verifyEmail`, value);
};

const resendEmail = async (value: EmailInterface) => {
  return Axios.post(`${APP_ENTRYPOINT}individuals/resend/verification`, value);
};

const groupAdminSignUp = async (value: GroupAdminSignUp) => {
  return Axios.put(`${APP_ENTRYPOINT}auth/groupAdminSignUp`, value);
};

const changePassword = async (value: LoginUser) => {
  return Axios.put(`${APP_ENTRYPOINT}users/changePassword`, value);
};
const forgotPassword = async (value: EmailInterface) => {
  return Axios.post(`${APP_ENTRYPOINT}users/forgotPassword`, value);
};
const userRoleCheck = async (value: any) => {
  return Axios.get(`${APP_ENTRYPOINT}auth/role`, {
    params: value,
  });
};
const existingEmail = async (value: CheckEmail) => {
  return Axios.post(`${APP_ENTRYPOINT}auth/existingEmail`, value);
};
const isAlreadyRegistered = async (value: any) => {
  return Axios.get(`${APP_ENTRYPOINT}auth/isAlreadyRegistered`, {
    params: value,
  });
};
const subscriptionDetail = async () => {
  return Axios.get(`${APP_ENTRYPOINT}individuals/subscription/details`);
};

const emailUnsubscribe = async (value: EmailInterface) => {
  return Axios.put(`${APP_ENTRYPOINT}users/unsubscribe`, value);
};

const skipPayment = async (value: EmailInterface) => {
  return Axios.post(`${APP_ENTRYPOINT}individuals/skip/payment`, value);
};

const verifyCapcha = async (value: any) => {
  return Axios.post(`${APP_ENTRYPOINT}auth/verifyCaptcha`, value);
};

const withoutAuth = {
  userSignUp,
  login,
  clientSignUp,
  sendVerificationEmail,
  sendEmailToVerify,
  groupAdminSignUp,
  changePassword,
  forgotPassword,
  userRoleCheck,
  existingEmail,
  isAlreadyRegistered,
  individualUserSignup,
  individualAcceptInvite,
  resendEmail,
  individualUserPlans,
  individualUserAddCard,
  subscriptionDetail,
  emailUnsubscribe,
  skipPayment,
  verifyCapcha,
};

export default withoutAuth;
