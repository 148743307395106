/* eslint-disable max-len */
import React from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';

import { setTopLevelNavigation } from './navigator';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import AuthLayout from '../pages/layout/AuthLayout';
import Layout from '../pages/layout/Layout';
import IndividualUserLayout from '../pages/layout/IndividualUserLayout';
import TermsAndConditionLayout from '../pages/layout/TermsAndConditionLayout';
import ForgotPasswordLayout from '../pages/layout/ForgotPasswordLayout';
import PaymentLayout from '../pages/layout/paymentLayout';
import NewAuthLayout from '../pages/layout/NewAuthLayout';

export default function Routes() {
  return (
    <Router ref={setTopLevelNavigation}>
      <Switch>
        <PublicRoute path='/' component={AuthLayout} exact />
        <PublicRoute path='/register' component={AuthLayout} exact />
        <PublicRoute path='/organizationDetails' component={AuthLayout} exact />
        <PublicRoute path='/email-Verify' component={AuthLayout} exact />
        <PublicRoute path='/verifing-email/:id' component={AuthLayout} exact />
        <PublicRoute path='/unsubscribe/:id' component={AuthLayout} exact />
        <PublicRoute path='/userSignUp/:id' component={NewAuthLayout} exact />
        <PublicRoute path='/thanksforsignup' component={NewAuthLayout} exact />
        <PublicRoute
          path='/groupAdminSignUp/:id'
          component={AuthLayout}
          exact
        />

        <PublicRoute
          path='/terms_and_condition'
          component={TermsAndConditionLayout}
          exact
        />
        <PublicRoute
          path='/forgot-password'
          component={ForgotPasswordLayout}
          exact
        />
        <PublicRoute
          path='/password-changed-confirmation'
          component={ForgotPasswordLayout}
          exact
        />
        <PublicRoute
          path='/create-new-password/:id'
          component={ForgotPasswordLayout}
          exact
        />
        <PublicRoute
          path='/email-not-found'
          component={ForgotPasswordLayout}
          exact
        />
        <PublicRoute
          path='/email-send-confirmation'
          component={ForgotPasswordLayout}
          exact
        />
        <PublicRoute
          path='/individualUser/sign-up/:inviteid'
          component={IndividualUserLayout}
          exact
        />
        <PublicRoute
          path='/individualUser/sign-up'
          component={IndividualUserLayout}
          exact
        />
        <PublicRoute
          path='/individualUser/email-verification'
          component={IndividualUserLayout}
          exact
        />
        <PublicRoute
          path='/individualUser/feature-list'
          component={IndividualUserLayout}
          exact
        />
        {/* <PublicRoute
          path='/mySubscription'
          component={IndividualUserLayout}
          exact
        />
          <PublicRoute
          path='/mySubscriptionMyCard'
          component={IndividualUserLayout}
          exact
        />     */}
        <PrivateRoute
          path='/individualUser/my-subscription'
          component={IndividualUserLayout}
          exact
        />
        <PrivateRoute
          path='/individualUser/my-subscription-my-card'
          component={IndividualUserLayout}
          exact
        />
        <PrivateRoute
          path='/individualUser/upgrade-congratulations'
          component={IndividualUserLayout}
          exact
        />
        <PrivateRoute path='/dashboard' component={Layout} exact />
        <PrivateRoute path='/userList' component={Layout} exact />
        <PrivateRoute path='/userDetails/:id' component={Layout} exact />
        <PrivateRoute path='/createUser' component={Layout} exact />
        <PrivateRoute path='/groupList' component={Layout} exact />
        <PrivateRoute path='/groupDetails/:id' component={Layout} exact />
        <PrivateRoute path='/clientAdminGroups' component={Layout} exact />
        <PrivateRoute path='/indicators' component={Layout} exact />
        <PrivateRoute path='/learningLibrary' component={Layout} exact />
        <PrivateRoute path='/subscriptions' component={Layout} exact />
        <PrivateRoute path='/actions' component={Layout} exact />
        <PrivateRoute path='/message' component={Layout} exact />
        <PrivateRoute path='/settings' component={Layout} exact />
        <PrivateRoute path='/superClients' component={Layout} exact />
        <PrivateRoute
          path='/superAdminAssessmentResults'
          component={Layout}
          exact
        />
        <PrivateRoute path='/superUsers' component={Layout} exact />
        <PrivateRoute path='/superClientDetails/:id' component={Layout} exact />
        <PrivateRoute
          path='/package-selection'
          component={PaymentLayout}
          exact
        />
        <PrivateRoute path='/*' component={Layout} exact />
      </Switch>
    </Router>
  );
}
