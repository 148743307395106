import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import authSlice from '../../redux/slices/auth.slice';
import { ProfileIcon, FocusedProfile } from '../../assets/images'
import { useHistory } from "react-router-dom";
import { storageEngine } from "../../common/helper";
import './styles.scss';

export default function VibeonixPopover(props: any) {

    const { isActiveBackground } = props;

    const user = storageEngine.get("user");
    const role = user && user.roles.role
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const dispatch = useDispatch();
    const logout = () => {
        dispatch(authSlice.actions.logout());
    }

    const openSettings = () => {
        history.push("/settings")
        handleClose()
    }
    return (
        <div className="logoutPopup">
            {/* <div className="flex flex-row w-32 justify-between">
            {role === "clientAdmin" &&  <button className={`${!anchorElNotification ? "butonOff" : "activebutton"} ${isActiveBackground && 'activebutton'} rounded-lg border flex items-center justify-center`} aria-describedby={id} onClick={handleClickNotification}>
                    {!anchorElNotification ? (
                        <img className="p-3" src={superAdminNotification} />
                    ) : (
                        <img className="p-3" src={superAdminNotification} />
                    )}
                </button>} */}
                <button className={`${!anchorEl ? "butonOff" : "activebutton"} ${isActiveBackground && 'activebutton'} rounded-xl border flex items-center justify-center`} style={{'height':"40px", 'width':'40px'}} aria-describedby={id} onClick={handleClick}>
                    {!anchorEl ? (
                        <img className="p-2"  src={ProfileIcon} alt=""/>
                    ) : (
                        <img className="p-2"  src={FocusedProfile} alt=""/>
                    )}
                </button>
            {/* </div> */}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="logoutPopover"
            >
                <Typography
                    className="logouTypography flex flex-col"
                >
                    {(role === "clientAdmin" && user?.is_plan_active) && 
                        <button className="flex justify-start" onClick={() => openSettings()}>
                            <span className="cursor-pointer	font-medium font-Lexend-Regular text-secondary px-4 py-3 text-sm" >My Account</span>
                        </button>
                    }
                    {role === "clientAdmin" && <div className="w-full h-px" style={{ backgroundColor: "#EDF0F9" }} />}
                    <button className="flex justify-start" onClick={logout}>
                        <span className="cursor-pointer	font-medium font-Lexend-Regular text-secondary px-4 py-3 text-sm">Logout</span>
                    </button>
                </Typography>
            </Popover>
        </div>
    );
}
