/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { storageEngine } from '../../common/helper';
import menuCollapse from '../../assets/img/icons/menuCollapse.svg';
import { useDispatch, useSelector } from 'react-redux';
import authSlice from '../../redux/slices/auth.slice';
import groupAdminSlice from '../../redux/slices/groupAdmin.slice';
import {
  vibeonixLogo,
  sideNavSelector,
  getStarted,
  tabGroups,
  tabIndicators,
  tabSubscription,
  tabUsers,
  tabDashboard,
  menuCollapseClose,
  tabIndividualUser,
  tabAssessment,
  learningIcon,
  messageIcon
} from '../../assets/images';
import './styles.scss';
import { Tooltip } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import Text from '../../common/AppTour/Text';
import Tooltips from '../../common/AppTour/Tooltip';
import '../../common/AppTour/styles.css';
import Button from '../../components/button/Button';

function SideNavTab(props:any) {
  const {isSideNav}= props;
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourClientAdmin, setTourClientAdmin] = useState(false);
  const [switchValue, setSwitchValue] = useState(1);
  const node = useRef<any>();
  const appTour = storageEngine.get('appTour');
  const [appTourOneId, setAppTourOneId] = useState('');
  const [appTourTwoId, setAppTourTwoId] = useState('');

  useEffect(() => {
    if (role === 'group') {
      if (appTour.length > 0) {
        appTour.map((item: any) => {
          if (item.app_tour_screens.screenPrefix === 'SIDE_NAVIGATION') {
            if (!item?.is_finished) {
              openTour();
              setAppTourOneId(item.app_tour_screen_id);
            }
          }
        });
      }
    } else {
      if (appTour.length > 0) {
        appTour.map((item: any) => {
          if (item.app_tour_screens.screenPrefix === 'SIDE_NAVIGATION') {
            if (!item?.is_finished) {
              openTourClientAdmin();
              setSwitchValue(2);
              setAppTourTwoId(item.app_tour_screen_id);
            }
          }
        });
      }
    }
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (node?.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    e.stopPropagation();
  };
  //State
  const user = storageEngine.get('user');
  const role = user && user.roles.role;

  //Redux State
  const selected = useSelector((state: any) => state.auth.sideNavSelector);
  const title = useSelector((state: any) => state.auth.topBatTitle);

  //Functions

  const selectedSidenavOption = (option: any) => {
    dispatch(authSlice.actions.setSideNavSelector(option));
  };

  useEffect(() => {
    dispatch(authSlice.actions.setSideNavSelector(title));
  });
  const tabSideShow=useSelector((state:any)=>state.auth.showSideNav)
  const handleOpenTabSideNav = (payload = !tabSideShow) => {
    dispatch(authSlice.actions.setSideNav(payload))
}
  const dispatch = useDispatch();

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#444C63',
      boxShadow: '0px 0px 4px rgba(68, 76, 99, 0.2)',
      borderRadius: 10,
      color: '#ffffff',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: 10,
    },
  }))(Tooltip);

  const tourConfig = [
    {
      selector: '[data-tut="reactour__iso"]',
      content: () => (
        <Text>
          Track your <Tooltips data-tooltip='this helper ⬇'>Users</Tooltips>{' '}
          here
        </Text>
      ),
      position: 'left',
    },
  ];

  const tourConfigClientAdmin = [
    {
      selector: '[data-tut="reactour__group"]',
      content: () => (
        <Text>
          First things first! Click <Tooltips>Groups</Tooltips>
          <br />
          to invite a group manager
        </Text>
      ),
      position: 'left',
    },
  ];

  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);
  const closeTour = () => {
    if (switchValue === 1) {
      const data = {
        screen_id: parseInt(appTourOneId),
      };
      dispatch(groupAdminSlice.actions.appTourFinished(data));
      setIsTourOpen(false);
    } else {
      const data = {
        screen_id: parseInt(appTourTwoId),
      };
      dispatch(groupAdminSlice.actions.appTourFinished(data));
      setTourClientAdmin(false);
    }
  };

  const openTour = () => {
    setIsTourOpen(true);
  };

  const openTourClientAdmin = () => {
    setTourClientAdmin(true);
  };

  const accentColor = '#5cb7b7';
  return (
    <aside
      ref={node}
      className={`fixed md:static bg-primary h-screen hidden sm:hidden lg:block xl:block md:block transform  ease-linear transition-all duration-500 z-30${
        !tabSideShow
          ? 'block z-50 bg-white mt-10 w-20 rounded-tr-md '
          : 'mt-10 w-3/12 rounded-tr-md absolute z-50 lg:w-2/6 xl:w-3/12 2xl:w-2/3'
      }`}
      style={{position:isSideNav?'relative':'fixed' ,height:props.title==='message'? '100vh':'100%', overflow:'auto' }}
    >
      <Tour
        onRequestClose={() => closeTour()}
        steps={switchValue === 1 ? tourConfig : tourConfigClientAdmin}
        isOpen={switchValue === 1 ? isTourOpen : tourClientAdmin}
        maskClassName='mask'
        className='helper'
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showNumber={false}
        showNavigation={false}
        showButtons={false}
      />
      {tabSideShow ? (
        <div
          className='flex ml-4 lg:ml-4 mt-6 justify-between'
          onClick={() => handleOpenTabSideNav()}
        >
          <img
            className='h-full w-32 pt-3 mr-0 sm:w-24 sm:mr-0 md:mr-4 lg:mr-6  cursor-pointer lg:w-40'
            src={vibeonixLogo}
          />
          <img
            className='h-30 w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-6  cursor-pointer'
            src={menuCollapse}
            width='30'
          />
        </div>
      ) : (
        <div
          className='cursor-pointer pl-5'
          onClick={() => handleOpenTabSideNav()}
        >
          <img src={menuCollapseClose} />
        </div>
      )}

      <div className='flex flex-wrap justify-between items-menu overflow-hidden'>
        <div
          className={`w-full border-t ${
            tabSideShow ? 'mt-12 mb-6' : 'mt-6 mb-3'
          }`}
        ></div>
        {role && role === 'group' && (
          <>
            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('dashboard')}
            >
              <div className='mt-4'>
                {selected === 'Dashboard' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>

              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/dashboard'
                  activeClassName={
                    tabSideShow ? 'mobile-active-class' : `activeClass`
                  }
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Dashboard
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={tabDashboard}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        onClick={handleClick}
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Dashboard
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>
            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('users')}
            >
              <div className='mt-4'>
                {selected === 'users' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>

              <div
                data-tut='reactour__iso'
                className='w-full'
                onClick={() => {
                  closeTour();
                  handleOpenTabSideNav(false);
                }}
              >
                <NavLink
                  to='/userList'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Users
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={tabUsers}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Users
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>
            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('indicators')}
            >
              <div className='mt-4'>
                {selected === 'indicators' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>
              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/indicators'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Indicators
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={tabIndicators}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Indicators
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>

            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('message')}
            >
              <div className='mt-4'>
                {selected === 'message' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>
              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/message'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Message
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={messageIcon}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Message
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>
          </>
        )}

        {role && role === 'clientAdmin' && (
          <>
            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('dashboard')}
            >
              <div className='mt-4'>
                {selected === 'dashboard' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>

              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/dashboard'
                  activeClassName={
                    tabSideShow ? 'mobile-active-class' : `activeClass`
                  }
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Dashboard
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 sm:mr-0 md:mr-4 lg:mr-8 '
                        src={tabDashboard}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        onClick={handleClick}
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Dashboard
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>

            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('users')}
            >
              <div className='mt-4'>
                {selected === 'users' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>

              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/userList'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Users
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={tabUsers}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Users
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>

            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('groups')}
            >
              <div className='mt-4'>
                {selected === 'Groups' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>
              <div
                data-tut='reactour__group'
                className='w-full'
                onClick={() => {
                  handleOpenTabSideNav(false);
                  closeTour();
                }}
              >
                <NavLink
                  to='/groupList'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Groups
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={tabGroups}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Groups
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>

            <div
              className='flex w-full gap-3 h-16'
              onClick={() => selectedSidenavOption('indicators')}
            >
              <div className='mt-4'>
                {selected === 'indicators' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>
              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/indicators'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Indicators
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={tabIndicators}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Indicators
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>

            <div
              className='flex w-full gap-3'
              onClick={() => selectedSidenavOption('actions')}
            >
              <div className='mt-4'>
                {selected === 'actions' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>
              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/actions'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Learning Library
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={learningIcon}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Learning Library
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>
            
            <div
              className='flex w-full gap-3 mt-4'
              onClick={() => selectedSidenavOption('subscriptions')}
            >
              <div className='mt-4'>
                {selected === 'subscriptions' ? (
                  <img src={sideNavSelector} />
                ) : (
                  <div className='w-3'></div>
                )}
              </div>
              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}>
                <NavLink
                  to='/subscriptions'
                  activeClassName={tabSideShow ? '' : `activeClass`}
                  className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
                >
                  <div className='flex flex-row items-center w-full'>
                    <HtmlTooltip
                      title={
                        <span className='text-lg font-medium leading-5 tracking-tight'>
                          Subscriptions
                        </span>
                      }
                      disableHoverListener={tabSideShow}
                      placement='right'
                      arrow
                    >
                      <img
                        className='h-full w-14 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                        src={tabSubscription}
                        width='30'
                      />
                    </HtmlTooltip>
                    {tabSideShow && (
                      <h1
                        className={`flex text-xl ${
                          !tabSideShow
                            ? 'table-text-color font-normal leading-4 tracking-tight mt-1'
                            : 'text-white sm:text-sm md:text-base lg:text-xl'
                        }`}
                      >
                        Subscriptions
                      </h1>
                    )}
                  </div>
                </NavLink>
              </div>
            </div>

          </>
        )}

        {role && role === 'vibeonixAdmin' && (
          <>
            {/* <div
              className='flex w-full gap-3'
              onClick={() => selectedSidenavOption('Dashboard')}
            >
              <div className='flex flex-col justify-end'>
                {selected === 'Dashboard' ? (
                  <img className='' src={sideNavSelector} />
                ) : (
                  <div className='w-3 '></div>
                )}
              </div>
              <div className='w-full' onClick={() => handleOpenTabSideNav(false)}> */}
            <NavLink
              to='/dashboard'
              activeClassName={tabSideShow ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row items-center w-full'>
                <HtmlTooltip
                  title={
                    <span className='text-lg font-medium leading-5 tracking-tight'>
                      Dashboard
                    </span>
                  }
                  disableHoverListener={tabSideShow}
                  placement='right'
                  arrow
                >
                  <img
                    className='h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                    src={tabDashboard}
                    width='30'
                  />
                </HtmlTooltip>
                <h1
                  className={`flex pt-3 text-xl ${
                    tabSideShow
                      ? 'text-white font-normal leading-4 tracking-tight mt-1'
                      : 'text-white sm:text-sm md:text-base lg:text-xl'
                  }`}
                >
                  Dashboard
                </h1>
              </div>
            </NavLink>{' '}
            {/* </div>
            </div> */}
            <NavLink
              to='/superClients'
              activeClassName={tabSideShow ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row items-center w-full'>
                <HtmlTooltip
                  title={
                    <span className='text-lg font-medium leading-5 tracking-tight'>
                      Clients
                    </span>
                  }
                  disableHoverListener={tabSideShow}
                  placement='right'
                  arrow
                >
                  <img
                    className='h-full w-14 pt-3 mr-10 sm:mr-0 md:mr-4 lg:mr-8'
                    src={tabGroups}
                    width='30'
                  />
                </HtmlTooltip>
                <h1
                  className={`flex pt-3 text-xl ${
                    tabSideShow
                      ? 'text-white font-normal leading-4 tracking-tight mt-1'
                      : 'text-white sm:text-sm md:text-base lg:text-xl'
                  }`}
                >
                  Clients
                </h1>
              </div>
            </NavLink>
            <NavLink
              to='/superAdminAssessmentResults'
              activeClassName={tabSideShow ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row items-center w-full'>
                <HtmlTooltip
                  title={
                    <span className='text-lg font-medium leading-5 tracking-tight'>
                      Assessment Results
                    </span>
                  }
                  disableHoverListener={tabSideShow}
                  placement='right'
                  arrow
                >
                  <img
                    className='h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                    src={tabAssessment}
                    width='30'
                  />
                </HtmlTooltip>
                <h1
                  className={`flex pt-3 text-xl leading-none ${
                    tabSideShow
                      ? 'text-white font-normal leading-4 tracking-tight mt-1'
                      : 'text-white sm:text-sm md:text-base lg:text-xl'
                  }`}
                >
                  Assessment Results
                </h1>
              </div>
            </NavLink>
            <NavLink
              to='/SuperAdminBusinessUserList'
              activeClassName={tabSideShow ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row items-center w-full'>
                <HtmlTooltip
                  title={
                    <span className='text-lg font-medium leading-5 tracking-tight'>
                      Business Users
                    </span>
                  }
                  disableHoverListener={tabSideShow}
                  placement='right'
                  arrow
                >
                  <img
                    className='h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                    src={tabUsers}
                    width='30'
                  />
                </HtmlTooltip>
                <h1
                  className={`flex pt-3 text-xl leading-none ${
                    tabSideShow
                      ? 'text-white font-normal leading-4 tracking-tight mt-1'
                      : 'text-white sm:text-sm md:text-base lg:text-xl'
                  }`}
                >
                  Business Users
                </h1>
              </div>
            </NavLink>
            <NavLink
              to='/superAdminIndividualUserList'
              activeClassName={tabSideShow ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row items-center w-full'>
                <HtmlTooltip
                  title={
                    <span className='text-lg font-medium leading-5 tracking-tight'>
                      Individual Users
                    </span>
                  }
                  disableHoverListener={tabSideShow}
                  placement='right'
                  arrow
                >
                  <img
                    className='h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                    src={tabIndividualUser}
                    width='30'
                  />
                </HtmlTooltip>
                <h1
                  className={`flex pt-3 text-xl leading-none ${
                    tabSideShow
                      ? 'text-white font-normal leading-4 tracking-tight mt-1'
                      : 'text-white sm:text-sm md:text-base lg:text-xl ml-2'
                  }`}
                >
                  Individual Users
                </h1>
              </div>
            </NavLink>
            {role && role !== 'vibeonixAdmin' && (
              <NavLink
                to='/groupList'
                activeClassName={tabSideShow ? '' : `activeClass`}
                className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
              >
                <div className='flex flex-row items-center w-full'>
                  <HtmlTooltip
                    title={
                      <span className='text-lg font-medium leading-5 tracking-tight'>
                        Groups
                      </span>
                    }
                    disableHoverListener={tabSideShow}
                    placement='right'
                    arrow
                  >
                    <img
                      className='h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                      src={tabGroups}
                      width='30'
                    />
                  </HtmlTooltip>
                  {/* <h1
                  className={`flex pt-3 text-xl ${
                    tabSideShow
                      ? "table-text-color font-normal leading-4 tracking-tight mt-1"
                      : "text-white sm:text-sm md:text-base lg:text-xl"
                  }`}
                >
                  Groups
                </h1> */}
                </div>
              </NavLink>
            )}
            <NavLink
              to='/indicators'
              activeClassName={tabSideShow ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row items-center w-full'>
                <HtmlTooltip
                  title={
                    <span className='text-lg font-medium leading-5 tracking-tight'>
                      Indicators
                    </span>
                  }
                  disableHoverListener={tabSideShow}
                  placement='right'
                  arrow
                >
                  <img
                    className='h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                    src={tabIndicators}
                    width='30'
                  />
                </HtmlTooltip>
                <h1
                  className={`flex pt-3 text-xl ${
                    tabSideShow
                      ? 'text-white font-normal leading-4 tracking-tight mt-1'
                      : 'text-white sm:text-sm md:text-base lg:text-xl'
                  }`}
                >
                  Indicators
                </h1>
              </div>
            </NavLink>

            <NavLink
              to='/learningLibrary'
              activeClassName={tabSideShow ? '' : `activeClass`}
              className='menu-card justify-center items-center overflow-hidden mx-auto w-full'
            >
              <div className='flex flex-row items-center w-full'>
                <HtmlTooltip
                  title={
                    <span className='text-lg font-medium leading-5 tracking-tight'>
                      Learning Library
                    </span>
                  }
                  disableHoverListener={tabSideShow}
                  placement='right'
                  arrow
                >
                  <img
                    className='h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8'
                    src={learningIcon}
                    width='30'
                  />
                </HtmlTooltip>
                <h1
                  className={`flex pt-3 text-xl ${
                    tabSideShow
                      ? 'text-white font-normal leading-5 tracking-tight mt-1'
                      : 'text-white sm:text-sm md:text-base lg:text-xl'
                  }`}
                >
                  Learning Library
                </h1>
              </div>
            </NavLink>
          </>
        )}

        {role && role !== 'vibeonixAdmin' && tabSideShow && (
          <div className='px-5 mt-20 mb-20 w-full flex justify-center'>
            <div
              className='flex flex-col justify-between text-center items-center rounded-xl bg-secondary'
              style={{ width: '272px', height: '324px', padding: '31px' }}
            >
              <img src={getStarted} width='130' />
              <span className='text-white font-light text-sm'>
                Learn how to use your<br/>Vibeonix platform.
              </span>
              <div>
                <Button
                  onPress={() => {
                    window.open(
                      'https://course.vibeonix.com',
                      '_blank'
                    );
                  }}
                  value={'Get Started'}
                  borderColor={'white'}
                  bgColor={'transparent'}
                  color={'white'}
                  buttonWidth={'168px'}
                  height={'h-10'}
                />
              </div>
            </div>
          </div>
        )}
        {/* <NavLink
          to="/subscribe"
          activeClassName="activeClass"
          className="menu-card justify-center items-center overflow-hidden mx-auto w-full"
          >
          <div className="flex flex-row w-full">
          
          <img className="h-full w-14 pt-3 mr-5 sm:mr-0 md:mr-4 lg:mr-8" src={tabSubscription} width="30"/>
          </div>
        </NavLink> */}
      </div>
    </aside>
  );
}

export default SideNavTab;
