import React from 'react';
import './styles.scss';

const ButtonLoader = () => {
    return (
        <div className="flex justify-center vibeonix-buttonloader py-2">
            <div
                className="bg-white p-1  w-1 h-1 rounded-full animate-ping first-circle mx-1"
            />
            <div
                className="bg-white p-1 w-1 h-1 rounded-full animate-ping second-circle mx-1"
            />
            <div
                className="bg-white p-1  w-1 h-1 rounded-full animate-ping third-circle mx-1"
            />
            <div
                className="bg-white p-1  w-1 h-1 rounded-full animate-ping fourth-circle mx-1"
            />
            <div
                className="bg-white p-1  w-1 h-1 rounded-full animate-ping fifth-circle mx-1"
            />
        </div>
    )
}

export default ButtonLoader;