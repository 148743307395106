import React, { useState, useEffect } from 'react';
import Page from '../../../components/Page/Page';
import SearchBox from '../../../components/SearchBox/SearchBox';
import sort from '../../../assets/img/icons/sort.svg';
import Paginate from '../../../components/Paginate/Paginate';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import authSlice from '../../../redux/slices/auth.slice';
import superAdminSlice from '../../../redux/slices/superAdmin.slice';
import TableLoader from '../../../components/Loader/TableLoader';
import download from '../../../assets/img/icons/download.svg';
import ExportCsvFile from '../../../components/ExportCsvFile/ExportCsvFile';
import ButtonLoader from '../../../components/Loader/ButtonLoader';
import './superAdminUserListStyle.scss';
import ResponsiveButton from '../../../components/responsiveButton/ResponsiveButton';
import CustomMultiselect from '../../../components/Dropdown/CustomMultiselect';

function SuperAdminAssessmentResults() {
  const dispatch = useDispatch();

  

  //State
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [options, setOptions] = useState([]);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [filterData, setFilterData] = useState({
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
    search: '',
    organization_name: '',
    wellbeing: ['all'],
    status: ['all'],
    // group_id: null,
  });

  //Redux State
  //const exportUserListLoading = useSelector(((state: { superAdmin: { userList: any } }) => state.superAdmin.exportUserListLoading)
  //const { exportUserList, exportUserListLoading } = useSelector(state => state.superAdmin);
  //const exportUserList = useSelector((state: { superAdmin: { userList: any } }) => state.superAdmin.exportUserList)
  const exportUserList = useSelector(
    (state: { superAdmin: { exportUserList: any } }) =>
      state.superAdmin.exportUserList
  );
  const exportUserListLoading = useSelector(
    (state: { superAdmin: { exportUserListLoading: any } }) =>
      state.superAdmin.exportUserListLoading
  );
  const clientList = useSelector(
    (state: { superAdmin: { userList: any } }) => state.superAdmin.userList
  );
  const allOrgList = useSelector(
    (state: { superAdmin: { allOrgList: any } }) => state.superAdmin.allOrgList
  );
  const clientListLoading = useSelector(
    (state: { superAdmin: { userListLoading: any } }) =>
      state.superAdmin.userListLoading
  );
  const copiedEmail = useSelector(
    (state: { superAdmin: { copiedEmail: any } }) =>
      state.superAdmin.copiedEmail
  );
  const allClient = clientList && clientList?.userList;
  const totalCount = clientList && clientList?.count;

  useEffect(() => {
    getClientList(filterData);
  }, [filterData]);
  useEffect(() => {
    dispatch(superAdminSlice.actions.getAllOrgList());
  }, []);

  const case_insensitive_comp = (strA:any, strB:any) => {
    return strA.toLowerCase().localeCompare(strB.toLowerCase());
  };

  useEffect(() => {
    let labelArray: any = [];
    let sortArray: any = [];
    if (allOrgList && allOrgList.length) {
      allOrgList.map((grp: any) => {
        const value = grp?.organization_name;
        const found = sortArray.some((el:any) => el.value === value);
        if (!found) {
          sortArray.push(value);
        }
      });
      const strValue = sortArray.sort(case_insensitive_comp);
      strValue.map((grp: any) => {
        const label = grp;
        const value = grp;
        const found = labelArray.some((el:any) => el.value === value);
        if (!found) {
          labelArray.push({ label, value });
        }
        setOptions(labelArray);
      });
    }
  }, [allOrgList]);

  const getClientList = (args: any) => {
    dispatch(authSlice.actions.setTopBatTitle('Assessment Results'));
    dispatch(superAdminSlice.actions.getSuperAdminUserList(args));
  };

  const search = async (value: any) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      window.setTimeout(() => {
        goToSearch(value);
      }, 1000)
    );
  };

  const goToSearch = async (value: string) => {
    let args = { ...filterData, search: value, page: 1 };
    setFilterData(args);
    getClientList(args);
  };

  const handlePageChange = (event: any) => {
    const selected = event.selected;
    const arg = { ...filterData, page: selected + 1 };
    setFilterData(arg);
    getClientList(arg);
  };

  const handleSort = (value: string) => {
    const order =
      filterData.sort === value
        ? filterData.order === 'DESC'
          ? 'ASC'
          : 'DESC'
        : 'ASC';
    let args = { ...filterData, sort: value, order, page: 1 };
    getClientList(args);
    setFilterData(args);
  };

  const handleWellbeing = (event: any[]) => {
    const wellbeing: any = [];
    event &&
      event.length &&
      event.map((item: any) => (
        wellbeing.push(item)
      ));
    if (event.length === 0 && totalCount / 10 < 1) {
      setFlag(false);
    } else {
      setFlag(true);
    }
    if (wellbeing.length > 0) {
      let args = { ...filterData, wellbeing: wellbeing, page: 1 };
      setFilterData(args);
      getClientList(args);
    } else {
      let args = { ...filterData, wellbeing: ['all'], page: 1 };
      setFilterData(args);
      getClientList(args);
    }
  };

  const handleStatus = (event: any[]) => {
    const status: any = [];
    event &&
      event.length &&
      event.map((item: any) => (
        status.push(item)
      ));
    if (event.length === 0 && totalCount / 10 < 1) {
      setFlag(false);
    } else {
      setFlag(true);
    }
    if (status.length > 0) {
      let args = { ...filterData, status: status, page: 1 };
      setFilterData(args);
      getClientList(args);
    } else {
      let args = { ...filterData, status: ['all'], page: 1 };
      setFilterData(args);
      getClientList(args);
    }
  };

  const handleOrganization = (event: any) => {
    const organization: any = [];
    event &&
      event.length &&
      event.map((item: any) => (
        organization.push(item)
      ));
    if (event.length === 0 && totalCount / 10 < 1) {
      setFlag(false);
    } else {
      setFlag(true);
    }
    let args = { ...filterData, organization_name: organization, page: 1 };
    setFilterData(args);
    getClientList(args);
  };

  const handleExport = () => {
    dispatch(superAdminSlice.actions.exportUsers());
    setIsExportOpen(true);
  };
  useEffect(() => {
    if (!exportUserListLoading) {
      setIsExportOpen(false);
    }
  }, [exportUserListLoading]);
 
  useEffect(() => {
    copiedEmail?.length > 0 && navigator.clipboard.writeText(copiedEmail);
  }, [copiedEmail]);

  return (
    <Page title='Clients'>
      <div className='mx-auto px-4 sm:px-8'>
        <div className=''>
          <div className='md:flex w-full mb-3 justify-between'>
            <div className='xl:flex w-full lg:justify-between lg:gap-4 pr-4'>
              <div className='w-1/2 sm:w-1/2 flex items-end'>
                <SearchBox
                  onChange={(e:any) => search(e.target.value)}
                  placeholder='Search by User ID'
                />
              </div>
              <div className='w-full sm:w-1/4 sm:flex-shrink-0 flex items-end'>
                <CustomMultiselect
                  options={options}
                  onFieldChange={(e: any) => {
                    handleOrganization(e);
                  }}
                  label={`Organization`}
                />
                {/* <CustomDropdown /> */}
              </div>
              <div className='w-full sm:w-1/4 sm:flex-shrink-0 grid items-end'>
                <CustomMultiselect
                  options={[
                    { label: 'High', value: 'high' },
                    { label: 'Medium', value: 'medium' },
                    { label: 'Low', value: 'low' },
                  ]}
                  onFieldChange={(e:any) => {
                    handleWellbeing(e);
                  }}
                  label={`EI`}
                />
              </div>
              <div className='w-full sm:w-1/4 sm:flex-shrink-0 grid items-end'>
                <CustomMultiselect
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Blocked', value: 'blocked' },
                    { label: 'Pending', value: 'pending' },
                  ]}
                  onFieldChange={(e:any) => {
                    handleStatus(e);
                  }}
                  label={`Status`}
                />
              </div>
            </div>
            <div className='w-full md:w-3/12 flex h-full md:justify-end my-4'>

              <div className='ml-4'>
                <ResponsiveButton
                  onPress={() => {
                    handleExport();
                  }}
                  value={exportUserListLoading ? <ButtonLoader /> : 'Download'}
                  icon={download}
                />
              </div>
            </div>
            {isExportOpen && exportUserList && (
              <ExportCsvFile csvReport={exportUserList} />
            )}
          </div>
        </div>
        <div className='overflow-x-hidden relative'>
          <div className='overflow-x-auto border-user rounded-xl'>
            <div className='inline-block min-w-full rounded-lg overflow-hidden'>
              <table className='min-w-full leading-normal myborder user-table-width'>
                <thead
                  style={{
                    minWidth: 2500,
                    width: '100%',
                  }}
                >
                  <tr className='flex' style={{ backgroundColor: '#F5F7FF' }}>
                    <th
                      style={{ width: 80 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider '
                    >
                      Sl No
                    </th>
                    <th
                      style={{ width: 200 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider '
                    >
                      <div className='flex items-center justify-between'>
                        <span>User ID</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('id')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th>
                    <th
                      style={{ width: 150 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Organization</span>
                        {/* <img
                          className='cursor-pointer'
                          onClick={() => handleSort('firstName')}
                          src={sort}
                          alt=''
                        /> */}
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>EI Score</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Average Score</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 500 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Emotional Intelligence</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 500 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>High Energy State</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 500 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Low Energy State</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Created Date</span>
                        {/* <img
                          className='cursor-pointer'
                          onClick={() => handleSort('number_of_users')}
                          src={sort}
                          alt=''
                        /> */}
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Last Assessment Date </span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('last_assessment_date')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Total Assessment</span>
                        {/* <img
                          className='cursor-pointer'
                          onClick={() => handleSort('assesmentsCount')}
                          src={sort}
                          alt=''
                        /> */}
                      </div>
                    </th>
                    <th
                      style={{ width: 150 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Status</span>
                      </div>
                    </th>
                    {/* <th
                      style={{ width: 200 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Status</span>
                      </div>
                    </th> */}
                    {/* <th
                      style={{ width: 30, backgroundColor: '#F5F7FF' }}
                      className='top-border-radius px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider headcol'
                    >
                      <span style={{ color: '#F5F7FF' }}>.</span>
                    </th> */}
                  </tr>
                </thead>
                {!clientListLoading ? (
                  <tbody
                    style={{
                      minWidth: 2630,
                      width: '100%',
                    }}
                  >
                    {!!allClient &&
                      !!allClient.length &&
                      allClient.map((item: any, index: any) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 ? 'secondcolor' : 'bg-white'
                          } flex`}
                        >
                          <td
                            style={{ width: 80 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            <div className='flex'>
                              {' '}
                              {index +
                                1 +
                                (filterData.page - 1) * filterData.limit}
                            </div>
                          </td>
                          <td
                            style={{ width: 200 }}
                            // onClick={() => reRouteToDetails(item)}
                            className={`px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate`}
                          >
                            {item.user.id}
                          </td>
                          <td
                            style={{ width: 150 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.user.organization_name
                              ? item.user.organization_name
                              : '-'}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.ei_score ? item?.ei_score : '-'}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate label-victory-style'
                          >
                            {item.average_ei_score ? item?.average_ei_score : '-'}
                          </td>
                          <td
                            style={{ width: 500 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.overall_scores ? (
                              <div className='grid grid-cols-2 col-gap-5'>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#DAA8FA',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Self Awareness
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary label-victory-style'>
                                      {item?.overall_scores?.self_awareness}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                      width: 15.25,
                                      height: 14,
                                      backgroundColor: '#54B8D1',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Self Expression
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary label-victory-style'>
                                      {item?.overall_scores?.self_expression}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                      width: 15.25,
                                      height: 14,
                                      backgroundColor: '#BDD6AC',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Self Management
                                    </span>
                                    <span className='text-sm text-secondary mr-4'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary label-victory-style'>
                                      {item?.overall_scores?.self_management}%
                                    </span>
                                  </div>
                                </div>

                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                      width: 15.25,
                                      height: 14,
                                      backgroundColor: '#CDDC39',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Empathy
                                    </span>
                                    <span ></span>
                                    <span className='text-sm text-secondary ml-2'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary label-victory-style'>
                                      {item?.overall_scores?.empathy}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className='w-full h-full flex justify-center items-center'>
                                -
                              </div>
                            )}
                          </td>
                          <td
                            style={{ width: 500 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.indicators ? (
                              <div className='grid grid-cols-2 col-gap-5'>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#66BB6A',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Positive Stress
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.positive_stress}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#F06292',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Love
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.love}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#91B3CB',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Confidence
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.confidence}%
                                    </span>
                                  </div>
                                </div>

                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#BDD6AC',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Motivation
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.motivation}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#DF8B6E',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Happiness
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.happiness}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#7986CB',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Peace
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.relax}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className='w-full h-full flex justify-center items-center'>
                                -
                              </div>
                            )}
                          </td>
                          <td
                            style={{ width: 500 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.indicators ? (
                              <div className='grid grid-cols-2 col-gap-5'>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#C5595F',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Anger
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.anger}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#BEBEBE',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Loneliness
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.loneliness}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#819C99',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Confusion
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.confused}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#4872C0',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Sadness
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.sadness}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#82829C',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Fear
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.fear}%
                                    </span>
                                  </div>
                                </div>
                                <div className='flex items-center w-full py-1'>
                                  <div
                                    style={{
                                       width: 15.25,
                                      height: 14,
                                      backgroundColor: '#E46957',
                                    }}
                                  ></div>
                                  <div className='flex justify-between w-full ml-1'>
                                    <span className='text-sm text-secondary'>
                                      Stress
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      -
                                    </span>
                                    <span className='text-sm text-secondary'>
                                      {item?.indicators?.stress}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className='w-full h-full flex justify-center items-center'>
                                -
                              </div>
                            )}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.user?.createdAt
                              ? moment(item?.user?.createdAt).format(
                                  'MMMM DD, YYYY'
                                )
                              : '-'}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.user?.last_assessment_date
                              ? moment(item?.user?.last_assessment_date).format(
                                  'MMMM DD, YYYY, h:mm A'
                                )
                              : '-'}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.total_assessment
                              ? item?.total_assessment
                              : '-'}
                          </td>
                          <td
                            style={{ width: 200 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            <span className='relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight'>
                              <span
                                aria-hidden
                                className={`${
                                  item?.user?.status === 'active'
                                    ? 'active-bg'
                                    : item?.user?.status === 'blocked'
                                    ? 'blocked-bg'
                                    : 'pending-bg'
                                } absolute inset-0  rounded-full`}
                              ></span>
                              <span
                                className={`${
                                  item?.user?.status === 'active'
                                    ? 'active-bg'
                                    : item?.user?.status === 'blocked'
                                    ? 'blocked-bg'
                                    : 'pending-bg'
                                } relative capitalize text-xs`}
                              >
                                {item?.user?.status}
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <TableLoader />
                )}
              </table>
            </div>
          </div>

          <div className=''>
            <Paginate
              pageCount={totalCount / 10}
              handlePageChange={handlePageChange}
              page={filterData.page}
              limit={filterData.limit}
              totalCount={totalCount}
              flag={flag}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default SuperAdminAssessmentResults;
