/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storageEngine } from '../../common/helper';
import { CardId, PlanUpgrade } from '../../interface/individualUser';

type InitialState = {
  subscriptionDetail: any,
  invoiceData: any,
  invoice: any,
  invoiceLoader: any,
  savedCardsData: any,
  showBackButton: boolean,
  renewSubscriptionLoader: boolean;
  userData: any;
  cancelSubscriptionLoader: boolean;
  deleteAccountLoader: boolean;
  planUpgradeLoader: boolean;
  capchaData: any;
  capchaInput: any;
  capchaVerifyLoader: boolean;
};

const initialState: InitialState = {
  subscriptionDetail: undefined,
  invoiceData: undefined,
  invoice: undefined,
  invoiceLoader: false,
  savedCardsData: undefined,
  showBackButton: false,
  renewSubscriptionLoader: false,
  userData: storageEngine.get('user'),
  cancelSubscriptionLoader: false,
  deleteAccountLoader: false,
  planUpgradeLoader: false,
  capchaData: null,
  capchaInput:null,
  capchaVerifyLoader:true,
};

// eslint-disable-next-line no-unused-vars

const subscriptionDetail = (state: InitialState) => {};

const setSubscriptionDetail = (state: InitialState, action: any) => {
  state.subscriptionDetail = action.payload;
};

const getInvoices = (state: InitialState, action: any) => {
  state.invoiceLoader = true;
};

const setInvoices = (state: InitialState, action: any) => {
    state.invoiceData = action.payload;
    state.invoiceLoader = false;
  };


const getSavedCardData = (state: InitialState) => {};

const setSavedCardData = (state: InitialState, action: any) => {
  state.savedCardsData = action.payload;
};

const cancelSubscription = (state: InitialState, action: PayloadAction<any>) => {
  state.cancelSubscriptionLoader = true;
};

const setCancelSubscriptionLoader = (state: InitialState, action: PayloadAction<boolean>) => {
  state.cancelSubscriptionLoader = action.payload;
};

const deleteCard = (state: InitialState, action: PayloadAction<CardId>) => {};

const deleteAccount = (state: InitialState, action:  PayloadAction) => {
  state.deleteAccountLoader = true;
}

const setDeleteAccountLoader = (state: InitialState, action:  PayloadAction<boolean>) => {
  state.deleteAccountLoader = action.payload;
}

const setDefaultCard = (
  state: InitialState,
  action: PayloadAction<CardId>
) => {};

const planUpgrade = (
  state: InitialState,
  action: PayloadAction<PlanUpgrade>
) => {};

const setPlanUpgradeLoader = (state: InitialState, action: PayloadAction<boolean>) => {
  state.planUpgradeLoader = action.payload;
}

const setShowButtonButton = (
  state: InitialState,
  action: PayloadAction<boolean>
) => {
  state.showBackButton = action.payload;
};

const skipPayment = (state: InitialState, action: PayloadAction) => {};
const renewSubscription = (state: InitialState, action: PayloadAction<any>) => {
  state.renewSubscriptionLoader = true;
}

const setRenewSubscriptionLoader = (
  state: InitialState,
  action: PayloadAction<boolean>
) => {
  state.renewSubscriptionLoader = action.payload;
};

const setUserData = (state: InitialState, action: PayloadAction<any>) => {
  state.userData = action.payload;
};

const setCapchaData = (state: InitialState, action: PayloadAction<any>) => {
  state.capchaData = action.payload;
  state.capchaVerifyLoader = false;
};

const verifycapcha = (state: InitialState, action: any) => {
  state.capchaInput = action.payload;
};

const individualUserSlice = createSlice({
  name: 'individualUser',
  initialState,
  reducers: {
    subscriptionDetail,
    setSubscriptionDetail,
    getInvoices,
    setInvoices,
    cancelSubscription,
    getSavedCardData,
    setSavedCardData,
    deleteCard,
    setDefaultCard,
    deleteAccount,
    planUpgrade,
    setShowButtonButton,
    renewSubscription,
    skipPayment,
    setRenewSubscriptionLoader,
    setUserData,
    setCancelSubscriptionLoader,
    setDeleteAccountLoader,
    setPlanUpgradeLoader,
    verifycapcha,
    setCapchaData
  },
});
export default individualUserSlice;
