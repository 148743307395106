/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Page from "../../components/Page/Page";
import { useDispatch, useSelector } from "react-redux";
import authSlice from "../../redux/slices/auth.slice";
import UserCard from "../../components/Message/UserCard";
import GroupCard from "../../components/Message/GroupCard";
import ChatUsersTitle from "../../components/Message/ChatUsersTitle";
import ChatGroupTitle from "../../components/Message/ChatGroupTitle";
import ChatMessage from "../../components/Message/ChatMessage";
import RichEditor from "../../components/RichEditor/RichEditor";
import "./message.css";
import Topnav from "../layout/Topnav";
import { storageEngine } from "../../common/helper";
import { backArrowBlack, sendIcon } from "../../assets/images";
import SearchBox from "../../components/SearchBox/SearchBox";
import CreateGroup from "../../components/modals/message/CreateGroup";
import EditGroup from "../../components/modals/message/EditGroup";
import groupAdminSlice from "../../redux/slices/groupAdmin.slice";
import {
	concatUniqeItem,
	currentPage,
	hasImageTag,
	isExitSpecialCharacters,
	pxToVh,
	removeHtmlTags,
	vhToPx,
} from "../../utils/utils";
import SpinerRoundLoader from "../../components/Loader/SpinerRoundLoader";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import ChatSkeletonLoader from "../../components/Loader/ChatSkeletonLoader";
import SkeletonLoaderLine from "../../components/Loader/SkeletonLoaderLine";
import DeleteGroup from "../../components/modals/message/DeleteGroup";
import ChatTitle from "../../components/Message/ChatTitle";
import Button from "../../components/button/Button";
import CustomButton from "../../components/button/CustomButton";

function Message() {
	const dispatch = useDispatch();
	const user = storageEngine.get("user");
	const title = useSelector((state: any) => state.auth.topBatTitle);

	const messagesRef = useRef(null);

	//state
	const [actionMessage, setActionMessage] = useState(null);
	const [activeChat, setActiveChat] = useState(null);
	const [showChat, setShowChat] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isCreateOpen, setIsCreateOpen] = useState(false);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [GroupId, setGroupId] = useState(null);
	const [label, setLabel] = useState("");
	const [isScrolledBottom, setIsScrolledBottom] = useState(false);
	const [isScrolledBottomGroup, setIsScrolledBottomGroup] = useState(false);
	const [recipients, setRecipients] = useState([]);
	const [chatGroupData, setChatGroupData] = useState([]);
	const [typingTimeout, setTypingTimeout] = useState(0);
	const [typingTimeoutGroup, setTypingTimeoutGroup] = useState(0);
	const [messages, setMessages] = useState(null);
	const [messagesLoader, setMessagesLoader] = useState(false);
	const [userLoader, setUserLoader] = useState(true);
	const [groupLoader, setGroupLoader] = useState(true);
	const [isHideEditorOption, setIsHideEditorOption] = useState(true);
	const [isScrolledTop, setIsScrolledTop] = useState(false);
	const [activeUser, setActiveUser] = useState(null as any);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [isMessagebarScrolledBottom, setIsMessagebarScrolledBottom] =
		useState(false);
	const [preMessageBarHeight, setPreMessageBarHeight] = useState(0);
	const [tempMessages, setTempMessages] = useState([]);
	const [scrollTimeout, setScrollTimeout] = useState([]);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isEnableButton, setIsEnableButton] = useState(false);
	const [searchRecipientError, setSearchRecipientError] = useState(false);
	const [isUserLayoutFinalysed, setIsUserLayoutFinalysed] = useState(false);
	const [isGroupLayoutFinalysed, setIsGroupLayoutFinalysed] = useState(false);
	const [searchGroupError, setSearchGroupError] = useState(false);
	const [userMinHeight, setUserMinHeight] = useState("auto");
	const [groupMinHeight, setGroupMinHeight] = useState("auto");
	const initialHeight = `${
		((window.innerHeight / window.screen.height) * 100) / 2.5
	}`;
	const [windowHeight, setWindowHeight] = useState(initialHeight);
	const [userRemainingHeight, SetUserRemainingHeight] = useState(0);
	const [groupRemainingHeight, SetGroupRemainingHeight] = useState(0);
	const [filterData, setFilterData] = useState({
		limit: 20,
		page: 1,
		sort: "firstName",
		order: "ASC",
		search: "",
		type: "user",
	});
	const [filterDataGroup, setFilterDataGroup] = useState({
		limit: 20,
		page: 1,
		sort: "id",
		order: "DESC",
		search: "",
	});

	const [filterDataMessage, setFilterDataMessage] = useState({
		limit: 20,
		page: 1,
		sort: "createdAt",
		order: "DESC",
		recipient_target_id: null,
		recipient_type: "individual",
	});

	function handleScroll(event: any) {
		if (
			(messagesRef.current as any).scrollHeight -
				(messagesRef.current as any).scrollTop ===
			(messagesRef.current as any).clientHeight
		) {
			handleScrollMessage();
			setPreMessageBarHeight((messagesRef.current as any).scrollHeight);
		}
	}

	const handleActiveState = (type: string, index: any, id: any, label: any) => {
		setIsEnableButton(false);
		const className: any = `${type}-${index}`;
		setActiveChat(className);
		setShowChat(!showChat);
		if (isMobile) {
			(
				document.getElementsByClassName("footer-global")[0] as any
			).style.display = "none";
			(
				document.getElementsByClassName("mobileTopNav")[0] as any
			).style.visibility = "hidden";
		}
		const arg: any = {
			...filterDataMessage,
			recipient_target_id: id,
			recipient_type: type,
			page: 1,
		};
		setActionMessage(null);
		setActiveUser({ id: id, type: type, label: label } as any);
		setFilterDataMessage(arg);
		setMessagesLoader(true);
		setIsMessagebarScrolledBottom(false);
		setMessages([] as any);
		setTempMessages([] as any);
	};

	const handleArrow = () => {
		setShowChat(false);
		(document.getElementsByClassName("footer-global")[0] as any).style.display =
			"flex";
		(
			document.getElementsByClassName("mobileTopNav")[0] as any
		).style.visibility = "visible";
	};

	const getUserList = (args: any) => {
		dispatch(authSlice.actions.setTopBatTitle("message"));
		dispatch(groupAdminSlice.actions.getChatRecipients(args));
	};
	const getGroupList = (args: any) => {
		dispatch(groupAdminSlice.actions.getChatGroups(args));
	};

	const getMessageList = (args: any) => {
		dispatch(groupAdminSlice.actions.getChatMessages(args));
	};

	const handleGroupRelaod = () => {
		setChatGroupData([]);
		const arg = { ...filterDataGroup, page: 1 };
		setFilterDataGroup(arg);
	};

	const handleGroupEditReload = (name: any, uuid: any) => {
		const index = chatGroupData.findIndex(
			(element) => (element as any).uuid === uuid
		);
		let manipulatedData = chatGroupData;
		(manipulatedData[index] as any).label = name;
		setChatGroupData(manipulatedData);
	};

	const handleGroupDeleteReload = (uuid: any) => {
		const index = chatGroupData.findIndex(
			(element) => (element as any).uuid === uuid
		);
		let manipulatedData = chatGroupData;
		manipulatedData.splice(index, 1);
		setChatGroupData(manipulatedData);
		setIsDeleteOpen(false);
	};

	const handleMessageSend = () => {
		if (actionMessage) {
			let validateMessage = removeHtmlTags(actionMessage).trim();
			const checkmessageHasImageTag = hasImageTag(actionMessage);
			validateMessage = validateMessage.replaceAll("&amp;", " ");
			validateMessage = validateMessage.replaceAll("&nbsp;", " ");
			// eslint-disable-next-line no-mixed-operators
			if (validateMessage && activeUser !== null || checkmessageHasImageTag && activeUser !== null) {
				const arg: any = {
					message: actionMessage,
					formatted_message: validateMessage,
					recipient_target_id: activeUser.id,
					recipient_type: activeUser.type,
					page: 1,
				};
				setIsSubmit(true);
				dispatch(groupAdminSlice.actions.sendChatMessage(arg));
				const tempMsg = {
					id: tempMessages.length + 1 + "temp",
					message: actionMessage,
					created_at: new Date().toISOString(),
					isFailed: false
				};
				setTempMessages([tempMsg, ...tempMessages] as any);
				setActionMessage(null);
				setTimeout(
					() => {
						setIsSubmit(false);
					},
					[100] as any
				);
			}
		}
	};

	const messageStatus = useSelector(
		(state: { groupAdmin: { messageStatus: any } }) => state.groupAdmin.messageStatus
	);

	const chatRecipients = useSelector(
		(state: { groupAdmin: { chatUser: any } }) => state.groupAdmin.chatUser
	);

	const chatGroups = useSelector(
		(state: { groupAdmin: { chatGroups: any } }) => state.groupAdmin.chatGroups
	);

	const chatMessage = useSelector(
		(state: { groupAdmin: { chatMessages: any } }) =>
			state.groupAdmin.chatMessages
	);

	const search = async (value: string) => {
		setUserMinHeight(`${userScrollRef?.current?.offsetHeight}px`);
		clearTimeout(typingTimeout);
		if (!isExitSpecialCharacters(value) || value === "") {
			setSearchRecipientError(false);
			setTypingTimeout(
				setTimeout(() => {
					setRecipients([]);
					goToSearch(value);
				}, 1000) as any
			);
		} else {
			setSearchRecipientError(true);
		}
	};

	const searchGroup = async (value: string) => {
		setGroupMinHeight(`${groupScrollRef?.current?.offsetHeight}px`);
		clearTimeout(typingTimeoutGroup);
		if (!isExitSpecialCharacters(value) || value === "") {
			setSearchGroupError(false);
			setTypingTimeoutGroup(
				setTimeout(() => {
					setChatGroupData([]);
					goToSearchGroup(value);
				}, 1000) as any
			);
		} else {
			setSearchGroupError(true);
		}
	};

	const handlePageChange = (page: number) => {
		clearTimeout(scrollTimeout as any);
		setScrollTimeout(
			setTimeout(() => {
				const arg = { ...filterData, page: page + 1 };
				setFilterData(arg);
			}, 1000) as any
		);
	};

	const handlePageChangeGroups = (page: number) => {
		clearTimeout(scrollTimeout as any);
		setScrollTimeout(
			setTimeout(() => {
				const arg = { ...filterDataGroup, page: page + 1 };
				setFilterDataGroup(arg);
			}, 1000) as any
		);
	};

	const handlePageChangeMessages = (page: number) => {
		clearTimeout(scrollTimeout as any);
		setScrollTimeout(
			setTimeout(() => {
				const arg: any = {
					...filterDataMessage,
					page: page + 1,
				};
				setFilterDataMessage(arg as any);
			}, 1000) as any
		);
	};

	const goToSearch = async (value: any) => {
		let args = { ...filterData, search: value, page: 1 };
		setFilterData(args);
		setUserLoader(true);
	};

	const goToSearchGroup = async (value: any) => {
		let args = { ...filterDataGroup, search: value, page: 1 };
		setFilterDataGroup(args);
		setGroupLoader(true);
	};

	const handleScrollUser = () => {
		if (chatRecipients?.count <= recipients.length) {
			setIsScrolledBottom(false);
		} else {
			setIsScrolledBottom(true);
			handlePageChange(currentPage(recipients.length, 20));
		}
	};

	const handleScrollGroup = () => {
		if (chatGroups.count <= chatGroupData.length) {
			setIsScrolledBottomGroup(false);
		} else {
			setIsScrolledBottomGroup(true);
			handlePageChangeGroups(currentPage(chatGroupData.length, 20));
		}
	};

	const handleScrollMessage = () => {
		if (messages !== null && chatMessage.count <= (messages as any).length) {
			setIsScrolledTop(true);
		} else if (messages !== null) {
			setIsScrolledTop(false);
			handlePageChangeMessages(currentPage((messages as any).length, 20));
		}
	};

	const handleCreatePopup = (status: boolean) => {
		setIsCreateOpen(status);
	};

	const handleEditPopup = (uuid: any, label: string) => {
		setIsEditOpen(true);
		setGroupId(uuid);
		setLabel(label);
	};

	const handleDeletePopup = (uuid: any) => {
		setIsDeleteOpen(true);
		setGroupId(uuid);
	};

	useEffect(() => {
		if (!messageStatus) {
			setTimeout(() => {
				setTempMessages(prevArray => {
					const newArray = [...prevArray]; // Create a copy of the array
					const lastIndex = newArray.length - 1; // Get the index of the last element
					(newArray[lastIndex] as void as any)= { ...newArray[lastIndex] as any, isFailed: true } as void as any; // Update the isFailed status of the last element
					return newArray;
				});
			},100)
			
		}
	},[messageStatus])

	useEffect(() => {
		if (messagesRef.current) {
			(messagesRef.current as any).scrollTop = 0;
		}
	}, [tempMessages]);

	useEffect(() => {
		getUserList(filterData);
	}, [filterData]);

	useEffect(() => {
		getGroupList(filterDataGroup);
	}, [filterDataGroup]);

	useEffect(() => {
		if (filterDataMessage.recipient_target_id !== null) {
			getMessageList(filterDataMessage);
		}
	}, [filterDataMessage]);

	useEffect(() => {
		if (actionMessage) {
			let validateMessage = removeHtmlTags(actionMessage).trim();
			const checkmessageHasImageTag = hasImageTag(actionMessage);
			if (validateMessage || checkmessageHasImageTag) {
				setIsEnableButton(true);
			} else {
				setIsEnableButton(false);
			}
		}
	}, [actionMessage]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 750) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		if (chatRecipients && chatRecipients.count) {
			setIsScrolledBottom(false);
			let filterRecipients = [];
			filterRecipients = chatRecipients.data.map((item: any) => {
				return { label: `${item.firstName} ${item.lastName}`, value: item.id };
			});
			if (chatRecipients.length !== 0) {
				const mergedRecipients: any = concatUniqeItem(
					recipients,
					filterRecipients
				);
				setRecipients(mergedRecipients);
			}
			setUserLoader(false);

			setIsUserLayoutFinalysed(true);
		}
		if (chatRecipients && chatRecipients.count === 0) {
			setRecipients([]);
			setUserLoader(false);
		}
	}, [chatRecipients]);

	useEffect(() => {
		if (chatGroups && chatGroups?.count) {
			setIsScrolledBottomGroup(false);
			let filterGroups = [];
			filterGroups = chatGroups.result.map((item: any) => {
				return { label: `${item.name}`, value: item.id, uuid: item.uuid };
			});
			if (filterGroups.length !== 0) {
				const mergedRecipients: any = concatUniqeItem(
					chatGroupData,
					filterGroups
				);
				setChatGroupData(mergedRecipients);
			} else {
				setChatGroupData([]);
			}
			setGroupLoader(false);

			setIsGroupLayoutFinalysed(true);
		}

		if (chatGroups && chatGroups.count === 0) {
			setChatGroupData([]);
			setGroupLoader(false);
		}
	}, [chatGroups]);

	useEffect(() => {
		if ((chatMessage && chatMessage?.count) || chatMessage?.count === 0) {
			setIsScrolledTop(true);
			if (chatMessage.result.length !== 0) {
				let cloneMessage: any = [];
				if (messages !== null) {
					cloneMessage = messages;
				}

				let chatResult = chatMessage.result;
				const mutableCopy = chatResult.slice();
				mutableCopy.reverse();
				setMessages([...cloneMessage, ...mutableCopy] as any);
			} else {
				setMessages([] as any);
			}
			setMessagesLoader(false);
		}

		if (chatMessage && chatMessage.count === 0) {
			setMessages([] as any);
		}
	}, [chatMessage]);

	useEffect(() => {
		if (
			messages !== null &&
			!isMessagebarScrolledBottom &&
			messagesRef.current
		) {
			//(messagesRef.current as any).scrollTop = 0;
			setIsMessagebarScrolledBottom(false);
		} else if (messages != null && messagesRef.current) {
			(messagesRef.current as any).scrollTop =
				(messagesRef.current as any).scrollHeight - preMessageBarHeight;
		}
	}, [messages]);

	useEffect(() => {
		const handleResize = () => {
			const heightVh = `${
				((window.innerHeight / window.screen.height) * 100) / 2.5
			}`;
			setWindowHeight(heightVh);
			userDivOverflowCheck(userScrollRef);
			groupDivOverflowCheck(groupScrollRef);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const userScrollRef = useBottomScrollListener(handleScrollUser);
	const groupScrollRef = useBottomScrollListener(handleScrollGroup);

	const userDivOverflowCheck = (ref: any) => {
		const element: any = ref.current;
		if (element) {
			if (element.offsetHeight < element.scrollHeight) {
				SetUserRemainingHeight(0);
			} else {
				const remainingHeightPX = vhToPx(windowHeight) - element.offsetHeight;
				SetUserRemainingHeight(pxToVh(remainingHeightPX));
			}
		}
	};

	const groupDivOverflowCheck = (ref: any) => {
		const element: any = ref.current;
		if (element) {
			if (element.offsetHeight < element.scrollHeight) {
				SetGroupRemainingHeight(0);
			} else {
				const remainingHeightPX = vhToPx(windowHeight) - element.offsetHeight;
				SetGroupRemainingHeight(pxToVh(remainingHeightPX));
			}
		}
	};

	useEffect(() => {
		userDivOverflowCheck(userScrollRef);
		groupDivOverflowCheck(groupScrollRef);
	}, [isUserLayoutFinalysed, isGroupLayoutFinalysed]);

	return (
		<Page>
			<div
				className="flex h-screen antialiased text-gray-800 chat-message-section"
				style={{ height: "99vh", overflow: "hidden" }}
			>
				<div
					className="flex flex-row  w-full overflow-x-hidden "
					style={{ position: "unset", overflow: "hidden" }}
				>
					<div className="flex flex-col  pl-6 pr-2  bg-white flex-shrink-0 chat-leftsidebar ">
						{/* <div className="topbar-profile w-full flex items-center pb-6 md:pt-3 pr-6 sm:flex">
							<p className="text-vibeonixColor text-2xl font-medium  capitalize top-title">
								New Messages
							</p>
						</div> */}
						<div
							className={`-mt-0 ${searchRecipientError ? "search-error" : ""}`}
						>
							<SearchBox
								onChange={(e: any) => search(e.target.value)}
								placeholder="Search by first name..."
							/>
							<p className="error-message">No special characters allowed</p>
						</div>
						<div className="flex flex-col mt-8">
							<ChatUsersTitle title="All Messages" />
							<div
								ref={userScrollRef as React.RefObject<HTMLDivElement>}
								className="flex flex-col space-y-1 mt-4 -mx-2  overflow-y-auto mostly-customized-scrollbar"
								style={{
									height: userMinHeight,
									maxHeight: `${Number(windowHeight)}vh`,
									borderBottom: "2px solid #f1f1f1",
								}}
							>
								{!userLoader ? (
									<>
										{recipients.length !== 0 ? (
											<>
												{recipients.map((item: any, index: number) => (
													<UserCard
														profile={false}
														label={item.label}
														value={item.value}
														index={index}
														handleActiveState={handleActiveState}
														activeChat={activeChat}
													/>
												))}

												{isScrolledBottom ? (
													<>
														<SpinerRoundLoader size={20} />
													</>
												) : (
													<></>
												)}
											</>
										) : (
											<>
												<div
													style={{
														textAlign: "center",
														height: "100%",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<p>No data found</p>
												</div>
											</>
										)}
									</>
								) : (
									<>
										<SkeletonLoaderLine count={10} />
									</>
								)}
							</div>
							<div className={`${searchGroupError ? "search-error" : ""}`}>
								<SearchBox
									onChange={(e: any) => searchGroup(e.target.value)}
									placeholder="Search Groups..."
								/>
								<p className="error-message">No special characters allowed</p>
							</div>
							<ChatGroupTitle
								handleCreatePopup={handleCreatePopup}
								title="Groups"
							/>
							<div
								ref={groupScrollRef as React.RefObject<HTMLDivElement>}
								className="flex flex-col space-y-1 mt-4 -mx-2 overflow-y-auto mostly-customized-scrollbar"
								style={{
									height: groupMinHeight,
									maxHeight: `${Number(windowHeight) + userRemainingHeight}vh`,
									borderBottom: "2px solid #f1f1f1",
								}}
							>
								{!groupLoader ? (
									<>
										{chatGroupData.length !== 0 ? (
											<>
												{chatGroupData.map((item: any, index: number) => (
													<GroupCard
														profile={false}
														label={item.label}
														value={item.value}
														uuid={item.uuid}
														index={index}
														handleActiveState={handleActiveState}
														activeChat={activeChat}
														handleEditPopup={handleEditPopup}
														handleDeletePopup={handleDeletePopup}
													/>
												))}
												{isScrolledBottomGroup ? (
													<>
														<SpinerRoundLoader size={20} />
													</>
												) : (
													<></>
												)}
											</>
										) : (
											<>
												<div
													style={{
														textAlign: "center",
														height: "100%",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<p>No data found</p>
												</div>
											</>
										)}
									</>
								) : (
									<>
										<SkeletonLoaderLine count={10} />
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className={`flex flex-col flex-auto h-full p-6 user-chat ${
							showChat ? "user-chat-show" : "user-chat-hide"
						}`}
					>
						<Topnav title={title} user={user} />
						<div
							className="flex flex-col flex-auto flex-shrink-0 rounded-2xl "
							style={{
								borderWidth: 1,
								borderStyle: "solid",
								borderColor: "#e2e8f0",
							}}
						>
							<div
								className="w-full user-chat-header "
								style={{
									background: "#ECF8F8",
									borderTopRightRadius: "16px",
									borderTopLeftRadius: "16px",
									minHeight: "70px",
									marginTop: "-0.5rem",
								}}
							>
								<img
									alt="backArrow"
									style={{ paddingLeft: "21px", cursor: "pointer" }}
									className=""
									src={backArrowBlack}
									onClick={handleArrow}
								/>
								<ChatTitle activeUser={activeUser} />
								<Topnav title={title} user={user} />
							</div>
							<div className="flex flex-col flex-auto flex-shrink-0 overflow-hidden rounded-2xl chat-inner-box">
								{messages !== null && !messagesLoader ? (
									<>
										<div className="flex flex-col  overflow-x-auto ">
											<div className="flex flex-col  overflow-auto mb-75px">
												<div
													onScroll={handleScroll}
													ref={messagesRef as React.RefObject<HTMLDivElement>}
													className="chat-holder mostly-customized-scrollbar"
												>
													{messages !== null ? (
														<>
															{(tempMessages as any[]).map((message) => (
																<>
																	<ChatMessage message={message} user={user} />
																</>
															))}
															{(messages as any[]).map((message) => (
																<>
																	<ChatMessage message={message} user={user} />
																</>
															))}

															{!isScrolledTop ? (
																<>
																	<SpinerRoundLoader size={20} />
																</>
															) : (
																<></>
															)}
														</>
													) : (
														<></>
													)}
												</div>
											</div>
										</div>
										<div
											className=" bg-white w-full desktop-editor"
											style={{
												height: "200px",
												padding: "13px",
												position: "relative",
												bottom: "60px",
											}}
										>
											<RichEditor
												values={actionMessage}
												onChange={(value: any) => setActionMessage(value)}
												readOnly={false}
												style={{ width: "100%" }}
												isSubmit={isSubmit}
												filterClipboardData={false}
												filterClassNames={".desktop-editor .ql-editor"}
											/>

											<div className="m-4  flex justify-end mr-0 ">
												<Button
													width={"w-24"}
													onPress={handleMessageSend}
													icon={sendIcon}
													value="Send"
													disabled={!isEnableButton}
												/>
											</div>
										</div>

										<div
											className={`flex relative  flex-row items-center bg-white w-full mobile-editor ${
												isHideEditorOption ? "mobile-editor-hide" : ""
											}`}
											style={{
												position: "fixed",
												bottom: 0,
											}}
										>
											<CustomButton
												icon={sendIcon}
												className="z-50 items-center flex  justify-center w-8 h-8 mr-2 text-pink-100 transition-colors duration-150 rounded-full focus:shadow-outline hover:bg-pink-800"
												style={{
													position: "relative",
													right: "0px",
													bottom: "-60px",
													width: "3rem",
													float: "right",
													height: "3rem",
													background: "#3A9EA5",
												}}
												disabled={!isEnableButton}
												onPress={handleMessageSend}
											/>
											<RichEditor
												onBlur={() => {
													setIsHideEditorOption(true);
												}}
												onFocus={() => {
													setIsHideEditorOption(false);
												}}
												values={actionMessage}
												onChange={(value: any) => setActionMessage(value)}
												readOnly={false}
												style={{ width: "100%" }}
												isSubmit={isSubmit}
												filterClipboardData={false}
												filterClassNames={".mobile-editor .ql-editor"}
											/>
										</div>
									</>
								) : (
									<>
										{!messagesLoader ? (
											<>
												<div className="flex justify-center items-center h-full">
													<p className="text-lg">No user Selected</p>
												</div>
											</>
										) : (
											<>
												<ChatSkeletonLoader count={15} />
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{isCreateOpen && (
				<CreateGroup
					isOpen={setIsCreateOpen}
					closeModel={() => setIsCreateOpen(false)}
					handleGroupRelaod={handleGroupRelaod}
				/>
			)}

			{isEditOpen && (
				<EditGroup
					isOpen={isEditOpen}
					closeModel={() => setIsEditOpen(false)}
					groupId={GroupId}
					label={label}
					handleGroupEditReload={handleGroupEditReload}
				/>
			)}

			{isDeleteOpen && (
				<DeleteGroup
					isOpen={isDeleteOpen}
					closeModel={() => setIsDeleteOpen(false)}
					groupId={GroupId}
					handleGroupDeleteReload={handleGroupDeleteReload}
				/>
			)}
		</Page>
	);
}

export default Message;
