import React from "react";
import { chatTypeIcon } from "../../assets/images";
interface propsInterface {
	title: string;
}
function ChatUsersTitle(props: propsInterface) {
	return (
		<div className="flex flex-row items-center justify-start text-xs">
			<img className="w-5 mr-1" src={chatTypeIcon} alt="" />

			<span className="font-bold text-vibeonixColor text-sm">{props.title}</span>
			{/*<span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">
        4
  </span> */}
		</div>
	);
}

export default ChatUsersTitle;