import { takeEvery, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ResponseGenerator } from '../../interface/Response';
import {
  CreateGroup,
  GetGroupById,
  GetGroupList,
  GetUserListWithClientGroupId,
  GetGroupWellBeing,
  GetGroupEnergyState,
  GetUserListWithGroupId,
  AddUsersToGroup,
  BlockGroup,
  UpdateGroup,
  NumUserValidation,
  DeleteGroup,
} from '../../interface/group';
import {
  CREATE_GROUP,
  GET_GROUP_LIST,
  GET_GROUP_BY_ID,
  GET_GROUP_WELLBEIND,
  GET_GROUP_WISE_ENERGY_STATE,
  GET_USER_LIST_WITH_GROUP_ID,
  GET_CLIENT_USER_LIST,
  ADD_USERS_TO_GROUP,
  GET_USER_LIST_WITH_CLIENT_GROUP_ID,
  UPDATE_GROUP,
  BLOCK_GROUP,
  NUM_USER_VALIDATION,
  DELETE_GROUP,
} from '../actions/group.action';
import Api from '../../services/Apis';
import loaderSlice from '../slices/loader.slice';
import groupSlice from '../slices/group.slice';
import clientAdminSlice from '../slices/clientAdmin.slice';

function* createGroup(action: PayloadAction<CreateGroup>) {
  try {
    const emailExist: ResponseGenerator = action.payload.isGroupManagerInvite
      ? yield Api.withoutAuth.existingEmail({ email: action.payload.email })
      : null;
    if (action.payload.isGroupManagerInvite && emailExist.data.data) {
      toast.error('Email already exists');
      yield put(
        groupSlice.actions.setCreatedGroupError('Email already exists')
      );
      yield put(groupSlice.actions.setCreatedGroup(false));
    } else {
      const response: ResponseGenerator = yield Api.group.createGroup(
        action.payload
      );
      action.payload.setSecondPage(true);
      yield put(groupSlice.actions.setCreatedGroup(false));
      yield put(groupSlice.actions.setCreatedGroupError(''));
      yield put(clientAdminSlice.actions.getRemainingGroupsAndUsers());
      const groupListParams: GetGroupList = yield select(
        (state) => state.group.groupFilterParams
      );
      const groupResponse: ResponseGenerator = yield Api.group.getGroupList(
        groupListParams
      );
      yield put(groupSlice.actions.setSecondPage());
      yield put(groupSlice.actions.setGroupList(groupResponse?.data?.data));

      if (action.payload.isGroupManagerInvite) {
        toast.success('Group added successfully');
      } else {
        toast.success('New group is added');
      }
    }
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
    yield put(groupSlice.actions.setCreatedGroup(false));
  }
}

function* updateGroup(action: PayloadAction<UpdateGroup>) {
  try {
    const emailExist: ResponseGenerator = action.payload.isGroupManagerInvite
      ? yield Api.withoutAuth.existingEmail({ email: action.payload.email })
      : null;
    if (
      action.payload.isGroupManagerInvite &&
      emailExist.data.data &&
      action.payload.old_email !== action.payload.email
    ) {
      toast.error('Email already exists');
      yield put(
        groupSlice.actions.setCreatedGroupError('Email already exists')
      );
      yield put(groupSlice.actions.setCreatedGroup(false));
    } else {
      const response: ResponseGenerator = yield Api.group.updateGroup(
        action.payload
      );
      action.payload.setSecondPage(true);
      yield put(groupSlice.actions.setSecondPage());
      yield put(groupSlice.actions.setUpdateGroup(false));
      yield put(groupSlice.actions.setCreatedGroupError(''));
      yield put(clientAdminSlice.actions.getRemainingGroupsAndUsers());
      const groupListParams: GetGroupList = yield select(
        (state) => state.group.groupFilterParams
      );
      const groupResponse: ResponseGenerator = yield Api.group.getGroupList(
        groupListParams
      );
      yield put(groupSlice.actions.setGroupList(groupResponse?.data?.data));
    }
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
    yield put(groupSlice.actions.setUpdateGroup(false));
  }
}

function* blockGroup(action: PayloadAction<BlockGroup>) {
  try {
    // yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.group.blockGroup(
      action.payload
    );

    yield put(clientAdminSlice.actions.getRemainingGroupsAndUsers());
    const groupListParams: GetGroupList = yield select(
      (state) => state.group.groupFilterParams
    );
    const groupResponse: ResponseGenerator = yield Api.group.getGroupList(
      groupListParams
    );
    yield put(groupSlice.actions.setGroupList(groupResponse?.data?.data));
    yield put(groupSlice.actions.setBlockGroup(response.data));
    if (action.payload.status === 'blocked') {
      toast.success('Successfully Blocked');
    } else {
      toast.success('Successfully Unblocked');
    }
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* deleteGroup(action: PayloadAction<DeleteGroup>) {
  try {
    // yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.group.deleteGroup(
      action.payload
    );

    yield put(clientAdminSlice.actions.getRemainingGroupsAndUsers());
    const groupListParams: GetGroupList = yield select(
      (state) => state.group.groupFilterParams
    );
    const groupResponse: ResponseGenerator = yield Api.group.getGroupList(
      groupListParams
    );
    yield put(groupSlice.actions.setGroupList(groupResponse?.data?.data));
    yield put(groupSlice.actions.setDeleteGroup(response.data));
    toast.success('Successfully Deleted');
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getGroupList(action: PayloadAction<GetGroupList>) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.group.getGroupList(
      action.payload
    );
    yield put(groupSlice.actions.setGroupList(response?.data?.data));
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err?.response?.data?.message);
  }
}

function* getGroupById(action: PayloadAction<GetGroupById>) {
  try {
    const response: ResponseGenerator = yield Api.group.getGroupById(
      action.payload
    );
    yield put(groupSlice.actions.setGroupsListById(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* getGroupWellBeing(action: PayloadAction<GetGroupWellBeing>) {
  try {
    const response: ResponseGenerator = yield Api.group.getGroupWellBeing(
      action.payload
    );
    yield put(groupSlice.actions.setGroupWellBeing(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getGropwiseEnergeState(action: PayloadAction<GetGroupEnergyState>) {
  try {
    const response: ResponseGenerator = yield Api.group.getGropwiseEnergeState(
      action.payload
    );
    yield put(groupSlice.actions.setGroupwiseEnergeState(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* getUsersListWithGroupId(
  action: PayloadAction<GetUserListWithGroupId>
) {
  try {
    const response: ResponseGenerator = yield Api.group.getUsersListWithGroupId(
      action.payload
    );
    yield put(groupSlice.actions.setUsersListWithGroupId(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getClientUserList(action: PayloadAction<GetUserListWithGroupId>) {
  try {
    const response: ResponseGenerator = yield Api.group.getClientUserList(
      action.payload
    );
    yield put(groupSlice.actions.setClientUserList(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}
function* addUsersToGroup(action: PayloadAction<AddUsersToGroup>) {
  try {
    const response: ResponseGenerator = yield Api.group.addUsersToGroup(
      action.payload
    );
    const getGroupDetails: GetGroupById = yield select(
      (state) => state.group.getGroupDetails
    );
    const getGroupresponse: ResponseGenerator = yield Api.group.getGroupById(
      getGroupDetails
    );
    yield put(groupSlice.actions.setAddUsersToGroup());
    yield put(groupSlice.actions.setGroupsListById(getGroupresponse.data.data));
    toast.success(response.data.message);
    yield put(groupSlice.actions.addUsersToGroupModalOpenClose());
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* getUserListByClientGroupId(
  action: PayloadAction<GetUserListWithClientGroupId>
) {
  try {
    const response: ResponseGenerator =
      yield Api.group.getUserListByClientGroupId(action.payload);
    yield put(groupSlice.actions.setUsersListWithGroupId(response.data.data));
  } catch (err: any) {
    toast.error(err?.response?.data?.message);
  }
}

function* numUserValidation(action: PayloadAction<NumUserValidation>) {
  try {
    const response: ResponseGenerator =
      yield Api.group.numUserValidationServices(action.payload);
    yield put(groupSlice.actions.setNumUserValidation(response.data.data));
  } catch (err) {}
}

function* groupSaga() {
  yield takeEvery(CREATE_GROUP, createGroup);
  yield takeEvery(UPDATE_GROUP, updateGroup);
  yield takeEvery(BLOCK_GROUP, blockGroup);
  yield takeEvery(GET_GROUP_LIST, getGroupList);
  yield takeEvery(GET_GROUP_BY_ID, getGroupById);
  yield takeEvery(GET_GROUP_WELLBEIND, getGroupWellBeing);
  yield takeEvery(GET_GROUP_WISE_ENERGY_STATE, getGropwiseEnergeState);
  yield takeEvery(GET_USER_LIST_WITH_GROUP_ID, getUsersListWithGroupId);
  yield takeEvery(GET_CLIENT_USER_LIST, getClientUserList);
  yield takeEvery(ADD_USERS_TO_GROUP, addUsersToGroup);
  yield takeEvery(
    GET_USER_LIST_WITH_CLIENT_GROUP_ID,
    getUserListByClientGroupId
  );
  yield takeEvery(NUM_USER_VALIDATION, numUserValidation);
  yield takeEvery(DELETE_GROUP, deleteGroup);
}

export default groupSaga;
