import ReactTooltip from 'react-tooltip';
import './styles.scss';
import { Delete } from '../../assets/images';

export default function EditBlock(props: any) {
  const {
    onEditSelected,
    index,
    onBlockSelected,
    onDeleteSelected,
    item,
    user,
  } = props;
  return (
    <div className='editBlocktooltip cursor-pointer'>
      <div className="cursor-pointer" data-for={`editBlock${index}`} data-tip='8'>
        <svg className='inline-block h-6 w-6 fill-current' viewBox='0 0 24 24'>
          <path d='M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z' />
        </svg>
      </div>
      <ReactTooltip
        id={`editBlock${index}`}
        getContent={() => (
          <div className='flex'>
            <button className='px-1' onClick={() => onEditSelected()}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M11.6667 1.66699L15 5.00033L5.83333 14.167H2.5V10.8337L11.6667 1.66699Z'
                  stroke='#F5F7FF'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M2.5 18.333H17.5'
                  stroke='#F5F7FF'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </button>
            {item?.group_manager_id !== user?.id &&
              (item.status === 'active' || item.status === 'blocked') && (
                <button className='px-1' onClick={() => onBlockSelected()}>
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13.3335 17.5V15.8333C13.3335 14.9493 12.9823 14.1014 12.3572 13.4763C11.7321 12.8512 10.8842 12.5 10.0002 12.5H4.16683C3.28277 12.5 2.43493 12.8512 1.80981 13.4763C1.18469 14.1014 0.833496 14.9493 0.833496 15.8333V17.5'
                      stroke='#F5F7FF'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M7.08333 9.16667C8.92428 9.16667 10.4167 7.67428 10.4167 5.83333C10.4167 3.99238 8.92428 2.5 7.08333 2.5C5.24238 2.5 3.75 3.99238 3.75 5.83333C3.75 7.67428 5.24238 9.16667 7.08333 9.16667Z'
                      stroke='#F5F7FF'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M14.6875 7.5L18.8542 11.6667'
                      stroke='#F5F7FF'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M18.8542 7.5L14.6875 11.6667'
                      stroke='#F5F7FF'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </button>
              )}
            {(item?.group_manager_id === user?.id || parseInt(item?.total_users) === 0 ) && (
              <button className='px-1' onClick={() => onDeleteSelected()}>
                <img src={Delete} alt='' />
              </button>
            )}
          </div>
        )}
        clickable={true}
        multiline={true}
        backgroundColor={`#3A9EA5`}
        place='left'
        event='click'
        globalEventOff='click'
      />
    </div>
  );
}
