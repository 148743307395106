import React from 'react';
import './styles.scss'

const PageLoader = () => {
    return (
        <div className="flex justify-center vibeonix-pageloader py-2 items-center">
            <div
                className="bg-primary p-1  w-1 h-1 rounded-full animate-ping first-circle mx-1"
            />
            <div
                className="bg-primary p-1 w-1 h-1 rounded-full animate-ping second-circle mx-1"
            />
            <div
                className="bg-primary p-1  w-1 h-1 rounded-full animate-ping third-circle mx-1"
            />
            <div
                className="bg-primary p-1  w-1 h-1 rounded-full animate-ping fourth-circle mx-1"
            />
            <div
                className="bg-primary p-1  w-1 h-1 rounded-full animate-ping fifth-circle mx-1"
            />
        </div>
    )
}

export default PageLoader;