import React from 'react';

export default function CustomButton(props:any) {
  const {
    onPress,
    value,
    icon,
    bgColor = '#3A9EA5',
    borderColor = '#3A9EA5',
    color = '#fff',
    type = 'button',
    disabled,
    buttonWidth,
    buttonHeight,
    fontSize,
    fontWeight,
    opacity = props.disabled ? 0.5 : 1,
    cursor = props.disabled ? 'not-allowed' : 'pointer',
    style,
    className
  } = props;
  
  let styleMerge={
    backgroundColor: bgColor,
    borderColor: borderColor,
    color: color,
    width: buttonWidth,
    height: buttonHeight,
    opacity: opacity,
    cursor: cursor,
    ...style
  }
  return (

      <button
        disabled={disabled}
        type={type}
        onClick={() => {
          onPress();
        }}
        style={styleMerge}
        className={className}
      >
        <span className={`text-Lexend ${fontWeight ? fontWeight : 'font-medium'} ${fontSize ? fontSize : 'text-sm'} leading-3`}>
          {value}
        </span>

        {icon && <img  src={icon} alt='' />}
      </button>
  
  );
}
