import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
interface propsInterface{
  count: number;
}
const ChatSkeletonLoader = (props:propsInterface) => {
	const count = props.count;

	const items = [];
	for (let i = 0; i < count; i++) {
		items.push(
			<div key={i} className="p-2">
				<div className="flex justify-between">
					<Skeleton style={{ width: 200 }} />
					<Skeleton style={{ width: 200 }} />
				</div>
				<Skeleton style={{ width: "70%" }} />
				<Skeleton style={{ width: "100%", height: 3 }} />
			</div>
		);
	}
	return (
		<>
			<div className="w-full p-2">{items}</div>
		</>
	);
};

export default ChatSkeletonLoader;
