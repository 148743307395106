/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import Router from './navigation/routes';
import store from './redux/store/store';
import Toast from './components/Toast/Toast';
import withClearCache from './ClearCache';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  (window as any).store = store;
  return (
    <div className='flex w-full text-black'>
      <Provider store={store}>
        <Toast />
        <Router />
      </Provider>
    </div>
  );
}

export default App;
