import React, { useState, useEffect, useRef } from "react";

import { AsyncPaginate } from "react-select-async-paginate";

const DropDownPeriodAdndEnergy = (props: any) => {
    const { options, onFieldChange, label, placeholder, selectedDate } = props;
    const [value, setValue]: any = useState(null);
    const firstUpdate = useRef(false)
    const onChange = (e: any) => {
        setValue(e);
        onFieldChange(e);
    }
    const loadOptions = async () => {
        return {
            options: options,
            hasMore: false
        };
    };

    useEffect(() => {
        if(firstUpdate.current){
            setValue({label: selectedDate, value: "custom"});
        }
        firstUpdate.current = true
    }, [selectedDate])

    return (
        <div>

            <label
                className="text-secondary font-semibold font-Lexend-Regular"
                htmlFor={label} style={{ fontSize: 11, top: -5 }}
            >
                {label}
            </label>
            <AsyncPaginate
                isSearchable={false}
                maxMenuHeight={150}
                value={value}
                loadOptions={loadOptions}
                onChange={(e) => { onChange(e) }}
                placeholder={placeholder}
            />
        </div>
    );
};

export default DropDownPeriodAdndEnergy;