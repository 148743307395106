/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { ResponseGenerator } from '../../../interface/Response';
import { request } from '../../request/Request';
import { CardId, PlanUpgrade } from '../../../interface/individualUser';

function* subscriptionDetail() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}individuals/subscription/details`
  );
  return response;
}
function* getInvoices(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}individuals/invoices`,
    {
      params: value,
    }
  );
  return response;
}

function* deleteCard(value: CardId) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}individuals/delete/card`,
    value
  );
  return response;
}

function* setDefaultCard(value: CardId) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}individuals/choose-default/card`,
    value
  );
  return response;
}

function* cancelSubscription() {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}individuals/cancel/subscription`
  );
  return response;
}

function* deleteAccount() {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}individuals/delete/account`
  );
  return response;
}

function* getSavedCardData() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}individuals/my-cards`
  );
  return response;
}

function* planUpgrade(value: PlanUpgrade) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}individuals/upgrade/plan`, value
  );
  return response;
}

function* renewSubscription() {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}individuals/renew/plan`
  );
  return response;
}

const individualUser = {
  subscriptionDetail,
  getInvoices,
  cancelSubscription,
  getSavedCardData,
  deleteCard,
  setDefaultCard,
  deleteAccount,
  planUpgrade,
  renewSubscription,
};

export default individualUser;
