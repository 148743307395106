import React, { useState, useEffect } from "react";
import VibeonixPopover from "../../components/UserMenuPopOver/UserMenuPopOver";
import "./styles.scss";
import ReactTooltip from "react-tooltip";
import SearchBox from "../../components/SearchBox/SearchBox";
import { useDispatch } from "react-redux";
import authSlice from "../../redux/slices/auth.slice";

const Topnav = ({ title, user, isHidden }: any) => {
	const dispatch = useDispatch();
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < 470 ? true : false
	);
	const handleResize = () => {
		if (window.innerWidth < 470) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	const switchAccount = () => {
		dispatch(authSlice.actions.switchAccount());
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});
	const truncate = (textvalue: string) => {
		return textvalue.length > 4 ? textvalue.substring(0, 3) + ".." : textvalue;
	};
	return (
		<>
			{!isHidden ? (
				<>
					<header className="topbar-profile w-full flex items-center pb-6 md:pt-6 pl-6 pr-6 sm:flex">
						<div className="w-full flex justify-between items-center table-text-color align-center md:visible">
							<div className="nav-title">
								<span className="text-2xl font-medium table-text-color capitalize top-title">
									{title}
								</span>
							</div>
							<div className="flex items-center gap-3">
								{user?.roles?.role === "group" && user.is_default_group===true ||
								user?.roles?.role === "clientAdmin" ? (
									<span className="flex">
											<span 
												className="border-blue-500 border rounded-3xl pl-1 pr-2 bg-blue-100"
										>
											<select
												onChange={switchAccount}
													id="user_roles"
													className="bg-blue-100 rounded-3xl  py-1 px-2 card-color-primary text-xs"
										
													>
												<option
													value="US"
													selected={
														user?.roles?.role === "group" ? true : false
													}
												>
													Group Manager
												</option>
												<option
													value="CA"
													selected={
														user?.roles?.role === "clientAdmin" ? true : false
													}
												>
													Admin
												</option>
											</select>
										</span>
										<span>&nbsp;&nbsp;</span>
										<span data-tip data-for="tip" className="font-semibold ">
											{isMobile ? truncate(user.firstName) : user.firstName}
										</span>
										{isMobile && (
											<ReactTooltip
												className="react-tooltip"
												id="tip"
												place="top"
												effect="solid"
											>
												{user.firstName}
											</ReactTooltip>
										)}
									</span>
								) : (
									<span className="bg-blue-100 border-blue-500 border py-1 px-2 rounded-3xl card-color-primary text-xs">
											{user?.roles?.role === "group" && user.is_default_group === false ?'Group Manager':'Super Admin'}
									</span>
								)}
								<div>
									<VibeonixPopover />
								</div>
							</div>
						</div>
					</header>
				</>
			) : (
				<></>
			)}
		</>
	);
};
export default Topnav;
