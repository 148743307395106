/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import PageLoader from '../../components/Loader/PageLoader';

const Error404 = lazy(() => import('../Error/Error404'));
const Register = lazy(() => import('../Auth/Register/Register'));
const Login = lazy(() => import('../Auth/Login/Login'));
const UserSignUp = lazy(() => import('../Auth/UserSignUp/UserSignUp'));
const ThanksPage = lazy(() => import('../Auth/UserSignUp/ThanksPage'));

const OrganizationDetails = lazy(
  () => import('../Auth/Register/OrganizationDetails')
);
const ReasendEmailVerification = lazy(
  () => import('../Auth/Register/ResendEmailverification')
);
const VerifingEmail = lazy(() => import('../Auth/Register/VerifingEmail'));
const EmailUnsubscribe = lazy(
  () => import('../Auth/EmailUnsubscribe/EmailUnsubscribe')
);
const GroupAdminSignUp = lazy(() => import('../Auth/GroupSignUp/GroupSignUp'));

const AuthLayout = () => {
  const history = useHistory();
  const { location } = history;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    // <Suspense fallback={<PageLoader />}>
    // <div className= 'flex w-full h-screen'><MySubscription /> </div>
    // <div className= 'flex w-full h-screen'><MySubscriptionMyCard /> </div>
    // </Suspense>

    <section className='bg-primary w-screen h-screen'>
      <div className='bg-primary w-full min-h-full flex flex-col justify-center items-center py-5 overflow-auto'>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route exact path='/'>
              <Login />
            </Route>
            <Route exact path='/register'>
              <Register />
            </Route>
            <Route exact path='/organizationDetails'>
              <OrganizationDetails />
            </Route>
            <Route exact path='/email-Verify'>
              <ReasendEmailVerification />
            </Route>
            <Route exact path='/verifing-email/:id'>
              <VerifingEmail />
            </Route>
            <Route exact path='/unsubscribe/:id'>
              <EmailUnsubscribe />
            </Route>
            <Route exact path='/groupAdminSignUp/:id'>
              <GroupAdminSignUp />
            </Route>
            <Route exact path='/userSignUp/:id'>
              <UserSignUp />
            </Route>
            <Route exact path='/thanksforsignup'>
              <ThanksPage />
            </Route>
            {/* <Route exact path="/subscribe">
              <SignupSubscription />
            </Route> */}
            <Route exact path='/*'>
              <Error404 />
            </Route>
          </Switch>
        </Suspense>
        <footer
          className='flex justify-center font-Lexend-Light text-base pt-4'
          style={{ color: '#D1E6F0' }}
        >
          © Copyright Vibeonix - All Rights Reserved.
        </footer>
      </div>
    </section>
  );
};
export default AuthLayout;
