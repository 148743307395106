import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import Page from '../../../components/Page/Page';
import SearchBox from '../../../components/SearchBox/SearchBox';
import sort from '../../../assets/img/icons/sort.svg';
import Paginate from '../../../components/Paginate/Paginate';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import authSlice from '../../../redux/slices/auth.slice';
import superAdminSlice from '../../../redux/slices/superAdmin.slice';
import TableLoader from '../../../components/Loader/TableLoader';
import UserBlockModal from '../../../components/modals/userList/userBlock/UserBlockModal';
import CancelSubscription from '../../../components/modals/cancelSubscription/CancelSubscription';
import CustomMultiselect from '../../../components/Dropdown/CustomMultiselect';
import ButtonLoader from '../../../components/Loader/ButtonLoader';
import ResponsiveButton from '../../../components/responsiveButton/ResponsiveButton';
import download from '../../../assets/img/icons/download.svg';
import ExportCsvFile from '../../../components/ExportCsvFile/ExportCsvFile';
import DeleteUser from '../../../components/Tooltip/DeleteUser';
import userSlice from '../../../redux/slices/user.slice';

function SuperAdminIndividualUserList() {
  const dispatch = useDispatch();


  //State
  const [currentSelected, setCurrentSelected] = useState<any>(undefined);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [totalCountValue, setTotalCountValue] = useState(0);
  const [flag, setFlag] = useState(false);
  const [item, setItem]: any = useState('');
  const [cancelSubscription, setCancelSubscription] = useState(false)
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
    search: '',
    status: ['all'],
    subscription: ['all']
  });

  useEffect(() => {
    getClientList(filterData);
  }, [filterData]);

  const getClientList = (args: any) => {
    dispatch(authSlice.actions.setTopBatTitle('Individual Users'));
    dispatch(superAdminSlice.actions.getSuperAdminIndividualUserList(args));
  };

  //Redux State
  const individualsUserList = useSelector(
    (state: { superAdmin: { individualsUserList: any } }) => state.superAdmin.individualsUserList
  );
  const individualUserListLoading = useSelector(
    (state: { superAdmin: { individualUserListLoading: any } }) =>
      state.superAdmin.individualUserListLoading
  );
  const exportIndividualUserListLoading = useSelector(
    (state: { superAdmin: { exportIndividualUserListLoading: any } }) =>
      state.superAdmin.exportIndividualUserListLoading
  );
  const exportIndividualUserList = useSelector(
    (state: { superAdmin: { exportIndividualUserList: any } }) =>
      state.superAdmin.exportIndividualUserList
  );

  const allClient = individualsUserList && individualsUserList?.allIndividualUsers;
  const totalCount = individualsUserList && individualsUserList?.count;

  useEffect(() => {
    setTotalCountValue(individualsUserList && individualsUserList?.count);
  }, [individualsUserList]);

  const search = async (value: string) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      window.setTimeout(() => {
        goToSearch(value);
      }, 1000)
    );
  };

  const goToSearch = async (value: any) => {
    let args = { ...filterData, search: value };
    setFilterData(args);
    getClientList(args);
  };

  const handlePageChange = (event: any) => {
    const selected = event.selected;
    const arg = { ...filterData, page: selected + 1 };
    setFilterData(arg);
    getClientList(arg);
  };

  const handleSort = (value: string) => {
    const order =
      filterData.sort === value
        ? filterData.order === 'DESC'
          ? 'ASC'
          : 'DESC'
        : 'ASC';
    let args = { ...filterData, sort: value, order, page: 1 };
    getClientList(args);
    setFilterData(args);
  };

  const handleStatus = (event: any[]) => {
    const status: any = [];
    event &&
      event.length &&
      event.map((item: any) => {
        status.push(item);
      });
    if (event.length === 0 && totalCount / 10 < 1) {
      setFlag(false);
    } else {
      setFlag(true);
    }
    if (status.length > 0) {
      let args = { ...filterData, status: status, page: 1 };
      setFilterData(args);
      getClientList(args);
    } else {
      let args = { ...filterData, status: ['all'], page: 1 };
      setFilterData(args);
      getClientList(args);
    }
  };

  const handleSubscription = (event: any[]) => {
    const subscription: any = [];
    event &&
      event.length &&
      event.map((item: any) => {
        subscription.push(item);
      });
    event.length === 0 && subscription.push('all');
    let args = { ...filterData, subscription: subscription };
    setFilterData(args);
    getClientList(args);
  };

  const handleBlockedUser = () => {
    if (currentSelected?.status !== "blocked") {
      dispatch(
        userSlice.actions.blockUser({
          doc_id: currentSelected?.doc_id,
          status: "blocked",
        })
      );
    } else {
      dispatch(
        userSlice.actions.blockUser({
          doc_id: currentSelected?.doc_id,
          status: "active",
        })
      );
    }
    setIsBlockOpen(false);
  };
  const onDeleteSelected = () => {
    dispatch(userSlice.actions.deleteUser({ doc_id: item?.doc_id, type: 'individualUser' }));
    setCancelSubscription(false)
  }
  const handleExport = () => {
    dispatch(superAdminSlice.actions.exportIndividualUser());
    setIsExportOpen(true);
  };
  useEffect(() => {
    if (!exportIndividualUserListLoading) {
      setIsExportOpen(false)
    }
  }, [exportIndividualUserListLoading])
  return (
    <Page title='Clients'>
      <div className='mx-auto px-4 sm:px-8'>
        <div className=''>
          <div className='flex w-full mb-3 justify-between'>
            <div className='md:flex md:w-2/3 md:gap-4'>
              <div className='w-full md:w-1/2 flex items-end'>
                <SearchBox
                  onChange={(e: any) => search(e.target.value)}
                  placeholder='Search by email'
                />
              </div>
              <div className='w-1/2 md:w-1/4 grid items-end'>
                <CustomMultiselect
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Blocked', value: 'blocked' },
                    { label: 'Expired', value: 'expired' },
                    { label: 'Pending', value: 'pending' },
                    {
                      label: 'Email Not Verified',
                      value: 'email not verified',
                    },
                  ]}
                  onFieldChange={(e: any) => {
                    handleStatus(e);
                  }}
                  label={`Status`}
                />
              </div>
              <div className='w-1/2 md:w-1/4 grid items-end'>
                <CustomMultiselect
                  options={[
                    { label: 'Free trial', value: 'free_trial' },
                    // { label: 'Teams', value: 'teams' },
                    // { label: 'Enterprise', value: 'enterprise' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Yearly', value: 'yearly' },
                  ]}
                  onFieldChange={(e: any) => {
                    handleSubscription(e);
                  }}
                  label={`Subscription`}
                />
              </div>
            </div>
            <div className='w-full md:w-3/12 flex h-full md:justify-end mt-4'>
              <div className='ml-4'>
                <ResponsiveButton
                  onPress={() => {
                    handleExport();
                  }}
                  value={exportIndividualUserListLoading ? <ButtonLoader /> : 'Download'}
                  icon={download}
                />
              </div>
            </div>
            {isExportOpen && exportIndividualUserList && (
              <ExportCsvFile csvReport={exportIndividualUserList} />
            )}
          </div>
        </div>
        <div className='overflow-x-hidden relative'>
          <div className='overflow-x-auto border-user rounded-xl'>
            <div className='inline-block min-w-full rounded-lg overflow-hidden'>
              <table className='min-w-full leading-normal myborder user-table-width'>
                <thead
                  style={{
                    minWidth: 1110,
                    width: '100%',
                  }}
                >
                  <tr
                    className='flex justify-between'
                    style={{ backgroundColor: '#F5F7FF' }}
                  >
                    <th
                      style={{ width: 80 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider '
                    >
                      Sl No
                    </th>
                    {/* <th
                      style={{ width: 150 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider '
                    >
                      <div className='flex items-center justify-between '>
                        <span>User id</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('id')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th> */}
                    <th
                      style={{ width: 150 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider '
                    >
                      <div className='flex items-center justify-between '>
                        <span>First Name</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('firstName')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th>
                    <th
                      style={{ width: 200 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Last Name</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('lastName')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Phone Numner</span>
                        {/* <img
                          className='cursor-pointer'
                          onClick={() => handleSort('organization_name')}
                          src={sort}
                          alt=''
                        /> */}
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Email</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('email')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Birth Year</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Joined Date</span>
                      </div>
                    </th>
                    {/* <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Organisation Name</span>
                        <img
                          className='cursor-pointer'
                          onClick={() => handleSort('organization_name')}
                          src={sort}
                          alt=''
                        />
                      </div>
                    </th> */}
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Gender</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Total Assessments</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Subscription Name</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 250 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Expiry Date</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 200 }}
                      className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      <div className='flex items-center justify-between '>
                        <span>Status</span>
                      </div>
                    </th>
                    <th
                      style={{ width: 30, backgroundColor: '#F5F7FF' }}
                      className='top-border-radius px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider headcol'
                    >
                      <span style={{ color: '#F5F7FF' }}>.</span>
                    </th>
                  </tr>
                </thead>
                {!individualUserListLoading ? (
                  <tbody
                    style={{
                      minWidth: 1110,
                      width: '100%',
                    }}
                  >
                    {!!allClient &&
                      !!allClient.length &&
                      allClient.map((item: any, index: any) => (
                        <tr
                          key={index}
                          className={`${index % 2 ? 'secondcolor' : 'bg-white'
                            } flex justify-between cursor-pointer`}
                        >
                          <td
                            style={{ width: 80 }}
                            className='hover px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            <div className='flex'>
                              {' '}
                              {index +
                                1 +
                                (filterData.page - 1) * filterData.limit}
                            </div>
                          </td>
                          {/* <td
                            style={{ width: 150 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.User_id?.length > 0 ? item?.User_id : '-'}
                          </td> */}
                          <td
                            style={{ width: 150 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.firstName}
                          </td>
                          <td
                            style={{ width: 200 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.lastName}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.phone_number?.length > 0 ? item?.phone_number : '-'}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.email}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.dateOfBirth
                              ? new Date(item?.dateOfBirth).getFullYear()
                              : '-'
                            }
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item.createdAt
                              ? moment(item.createdAt).format('MMMM DD, YYYY')
                              : '-'}
                          </td>
                          {/* <td
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.organization_name ? item?.organization_name : '-'}
                          </td> */}
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.gender}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.total_assessments}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.packages?.name ? item?.packages?.name : '-'}
                          </td>
                          <td
                            style={{ width: 250 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            {item?.expiry_date ? moment(item?.expiry_date).format('MMMM DD, YYYY') : '-'}
                          </td>
                          <td
                            style={{ width: 200 }}
                            className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                          >
                            <span className='relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight'>
                              <span
                                aria-hidden
                                className={`${item.status === 'active'
                                  ? 'active-bg'
                                  : item.status === 'blocked'
                                    ? 'blocked-bg'
                                    : 'pending-bg'
                                  } absolute inset-0  rounded-full`}
                              ></span>
                              <span
                                className={`${item.status === 'active'
                                  ? 'active-bg'
                                  : item.status === 'blocked'
                                    ? 'blocked-bg'
                                    : 'pending-bg'
                                  } relative capitalize text-xs`}
                              >
                                {item.status}
                              </span>
                            </span>
                          </td>
                          <td
                            style={{ width: 30 }}
                            className={`${index % 2 ? 'secondcolor' : 'bg-white'
                              } px-5 py-3  text-sm text-right headcol`}
                          >
                            <button
                              type='button'
                              className='inline-block card-text-primary'
                              onClick={() => setCurrentSelected(item)}
                              onMouseEnter={() => ReactTooltip.hide()}
                            >

                              <DeleteUser
                                item={item}
                                key={index}
                                index={index}
                                onDeleteSelected={(item: any) => {
                                  setItem(item)
                                  setCancelSubscription(true)
                                }}
                                onBlockSelected={() => {
                                  setIsBlockOpen(!isBlockOpen)
                                }}
                              />
                              {/* <SuperAdminClient
                                item={item}
                                key={index}
                                index={index}
                                onBlockSelected={() =>
                                  setIsBlockOpen(!isBlockOpen)
                                }
                                onDeleteSelected={(item: any) => {
                                  setItem(item)
                                  setCancelSubscription(true)
                                }}
                              /> */}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <TableLoader />
                )}
              </table>
            </div>
          </div>
          <CancelSubscription
            hasModalView={cancelSubscription}
            title='Do you want to delete this User'
            closeModal={() => setCancelSubscription(false)}
            onPress={() => onDeleteSelected()}
            buttonTextOne='No'
            buttonTextTwo='Yes'
          />
          {isBlockOpen && (
            <UserBlockModal
              hasModalView={isBlockOpen}
              closeModal={() => setIsBlockOpen(false)}
              onPress={handleBlockedUser}
              title={`Do you want to ${currentSelected?.status !== 'blocked' ? 'block' : 'unblock'
                } ?`}
              confirmText={`Are you sure you want to ${currentSelected?.status !== 'blocked' ? 'block' : 'unblock'
                } this user?`}
              buttonText={`${currentSelected?.status !== 'blocked' ? 'block' : 'unblock'
                }`}
            />
          )}
          <div className=''>
            <Paginate
              pageCount={totalCountValue / 10}
              handlePageChange={handlePageChange}
              page={filterData.page}
              limit={filterData.limit}
              totalCount={totalCountValue}
              pageValue={1}
              flag={flag}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default SuperAdminIndividualUserList;
