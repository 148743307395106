import React from 'react';
import Button from '../../../button/Button';
import userDeleteModal from '../../../../assets/img/icons/userDeleteModal.svg';
import editClose from '../../../../assets/img/icons/editClose.svg';
import './styles.scss';

const UserDeleteModal = ({ closeModal, hasModalView, onPress, type }:any) => {
  return (
    <div>
      <div
        className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          hasModalView ? 'block' : 'hidden'
        }`}
      >
        <div className='popup-container absolute w-full mb-6 top-6'>
          <img
            className='img z-10 absolute'
            onClick={closeModal}
            src={editClose}
            alt=""
          />
          <div className='popup-inner-container w-full float-left bg-white relative overflow-hidden px-10' style={{paddingBottom:'72px', paddingTop:'72px'}}>
            <div className='w-full justify-center flex'>
              <div className='w-10 h-10 bg-red-200 border border-red-700 items-center justify-center flex rounded-md' >
                <img
                  className='img z-10 '
                  onClick={closeModal}
                  src={userDeleteModal}
                  alt=""
                />
              </div>
            </div>
            <div className='flex w-full flex-col'>
              <div className='popup-title font-medium text-2xl justify-center flex pb-6' style={{paddingTop:'36px', color:'#161F38'}}>
                Do you want to delete ?
              </div>
              <p className='text-center font-medium text-base px-16' style={{paddingBottom:'28px', color:'#444C63'}}>
                Are you sure you want to delete this {`${type}`}?
              </p>
              <div className='popup-frm w-full float-left'>
                <div className='flex justify-center gap-4'>
                  <div className=''>
                    <Button
                      onPress={closeModal}
                      value={'Cancel'}
                      bgColor={'white'}
                      color={'#A6A9B4'}
                      borderColor={'#A6A9B4'}
                      buttonWidth={'123px'}
                      buttonHeight={'38px'}
                    />
                  </div>
                  <div className=''>
                    <Button
                      onPress={onPress}
                      value={'Delete'}
                      buttonWidth={'123px'}
                      buttonHeight={'38px'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDeleteModal;
