import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';
import { ResponseGenerator } from '../../../interface/Response';
import {
  GetClientQuickInfo,
  GetSuperAdminClientList,
  GetWellbeing,
  GetEnergyState,
  GetDashboardEnergyState,
  BlockUser,
  GetSubscriptionStatus,
  CopyEmail,
  DeleteClientAdminRequest,
  GetSuperAdminIndividualUserList,
  GetSuperAdminBusinessUserList,
} from '../../../interface/superAdmin';

function* getSuperAdminClientListService(value: GetSuperAdminClientList) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getclientlist`,
    {
      params: value,
    }
  );
  return response;
}
function* getSuperAdminUserList(value: GetSuperAdminClientList) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/assessment/results`,
    {
      params: value,
    }
  );
  return response;
}
function* getSuperAdminIndividualUserList(
  value: GetSuperAdminIndividualUserList
) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/individualUsers`,
    {
      params: value,
    }
  );
  return response;
}
function* getSuperAdminBusinessUserList(value: GetSuperAdminBusinessUserList) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/businessUsers`,
    {
      params: value,
    }
  );
  return response;
}
function* getSuperAdminOrgList() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getOrganizationDropDown`
  );
  return response;
}

function* getClientQuickInfoService(value: GetClientQuickInfo) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getClientQuickInfo`,
    {
      params: value,
    }
  );
  return response;
}

function* getClientDetailsViewService(value: GetClientQuickInfo) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getClientDetailsView`,
    {
      params: value,
    }
  );
  return response;
}

function* getClientUsersWellbeingService(value: GetWellbeing) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getClientUsersWellbeing`,
    {
      params: value,
    }
  );
  return response;
}

function* getClientUsersEnergyStateService(value: GetEnergyState) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getClientUsersEnergyState`,
    {
      params: value,
    }
  );
  return response;
}
function* getClientUserList(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}/superAdmin/getclientList`,
    {
      params: value,
    }
  );
  return response;
}
function* exportUsers() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/exportUsers`
  );
  return response;
}
function* exportBusinessUser() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/export/business/users`
  );
  return response;
}
function* exportIndividualUser() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/export/individuals/users`
  );
  return response;
}
function* exportClients(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/exportClients`,
    {
      params: value,
    }
  );
  return response;
}
function* getDashboardQuickInfoService(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getSuperAdminQuickInfo`,
    {
      params: value,
    }
  );
  return response;
}

function* getDashboardClientStatusService() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getClientStatus`
  );
  return response;
}

function* getAllUsersEnergyStateService(value: GetDashboardEnergyState) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/allUsersEnergyState`,
    {
      params: value,
    }
  );
  return response;
}

function* blockUser(value: BlockUser) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}users/blockUser`,
    value
  );
  return response;
}

function* getSubscriptionStatus(value: GetSubscriptionStatus) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/getSubscriptionStatus`,
    {
      params: value,
    }
  );
  return response;
}

function* copyEmails(value: CopyEmail) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}superAdmin/copyEmails`,
    {
      params: value,
    }
  );
  return response;
}
function* deleteClientAdmin(value: DeleteClientAdminRequest) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}superAdmin/deleteClient`,
    value
  );
  return response;
}
function* changeSubscriptionEndDate(value: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}superAdmin/changeExpiryDate`,
    value
  );
  return response;
}
function* getSuperAdminLearningLibrary(value: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}superAdmin/actions`,
    value
  );
  return response;
}
function* getSuperAdminTopics(value: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}common/actionTopics`,
    {
      params: value,
    }
  );
  return response;
}
const superAdmin = {
  getSuperAdminClientListService,
  getSuperAdminUserList,
  getClientQuickInfoService,
  getClientDetailsViewService,
  getClientUsersWellbeingService,
  getClientUsersEnergyStateService,
  getClientUserList,
  exportUsers,
  exportBusinessUser,
  exportIndividualUser,
  getDashboardQuickInfoService,
  getDashboardClientStatusService,
  getAllUsersEnergyStateService,
  blockUser,
  getSubscriptionStatus,
  copyEmails,
  getSuperAdminOrgList,
  deleteClientAdmin,
  changeSubscriptionEndDate,
  exportClients,
  getSuperAdminBusinessUserList,
  getSuperAdminIndividualUserList,
  getSuperAdminLearningLibrary,
  getSuperAdminTopics
};

export default superAdmin;
