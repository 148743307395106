import ReactTooltip from 'react-tooltip';

export default function ListItemTooltip({ names, index, number }:any) {
    return (
        <div>
            <div className="cursor-pointer" data-for={`soclose${index}`} data-tip='8'>
                <div className="ml-2 text-white rounded-full w-5 h-5 justify-center flex text-xs" style={{ backgroundColor: "#161F38" }}>
                    +{number}
                </div>
            </div>
            <ReactTooltip
                id={`soclose${index}`}
                getContent={() => (
                    <div className="flex flex-col">
                        {names && names.length && names.map((item: any, ind: any) => (
                            <div className="">
                                {ind >= 2 && (
                                    <>
                                        <div className="py-1">{item}</div>
                                        <div className="w-full h-px bg-white " />
                                    </>
                                )}

                            </div>
                        ))}
                    </div>
                )}
                clickable={true}
                multiline={true}
                backgroundColor={`#3A9EA5`}
                place='bottom'
                event='click'
                globalEventOff="click"
            />
        </div >
    );
}