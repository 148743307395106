import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Android, Ios, logoTab } from '../../assets/images';
import PageLoader from '../../components/Loader/PageLoader';
import VibeonixPopover from '../../components/UserMenuPopOver/UserMenuPopOver';
import '../IndividualUsers/IndividualUsers.scss';
import MySubscriptionMycard from '../IndividualUsers/MySubscriptionMycard/MySubscriptionMycard';
import UpgradeCongratulationsPage from '../IndividualUsers/Subscriptions/UpgradeCongratulationsPage';

const IndividualUserSignUp = lazy(
  () => import('../IndividualUsers/IndividualUserSignup')
);

const EmailVerification = lazy(
  () => import('../IndividualUsers/EmailVerification')
);

const FeatureList = lazy(() => import('../IndividualUsers/FeatureList'));
const MySubscription = lazy(
  () => import('../IndividualUsers/Subscriptions/MySubscription')
);

const IndividualUserLayout = () => {
  const history = useHistory();
  const { location } = history;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const authenticated = localStorage.getItem('active');

  const showBackButton = useSelector(
    (state: any) => state.individualUser.showBackButton
  );

  return (
    <section className='h-screen w-screen'>
      <div className='h-20 bg-white flex justify-between items-center px-10'>
        <img src={logoTab} alt='' className='h-6 sm:h-10' />
        <div className='flex justify-center items-center gap-5 text-gray-800'>
          {showBackButton && (
            <button
              onClick={() => {
                history.push('/individualUser/my-subscription');
              }}
            >
              Back
            </button>
          )}
          {authenticated && (
            <div>
              <VibeonixPopover />
            </div>
          )}
        </div>
      </div>
      <div className='user-bg w-full min-h-full flex flex-col items-center'>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path='/individualUser/sign-up/:inviteid'>
              <IndividualUserSignUp />
            </Route>
            <Route exact path='/individualUser/sign-up'>
              <IndividualUserSignUp />
            </Route>
            <Route exact path='/individualUser/email-verification'>
              <EmailVerification />
            </Route>
            <Route exact path='/individualUser/feature-list'>
              <FeatureList />
            </Route>
            <Route exact path='/individualUser/my-subscription'>
              <MySubscription />
            </Route>
            <Route exact path='/individualUser/my-subscription-my-card'>
              <MySubscriptionMycard />
            </Route>
            <Route exact path='/individualUser/upgrade-congratulations'>
              <UpgradeCongratulationsPage />
            </Route>
          </Switch>
        </Suspense>
        <footer className='flex flex-col w-full md:px-20 px-10 mt-5'>
          <div className='flex flex-wrap w-full justify-between py-24 gap-5 border-t'>
            <span
              className='md:text-28px text-xl font-medium'
              style={{ color: '#444C63' }}
            >
              Download <span className='vibeonix-color'>Vibeonix App </span>
              <br /> and check your vibe!
            </span>
            <div className='flex justify-center gap-5'>
              <a
                href='https://play.google.com/store/apps/details?id=com.vibeonix'
                target='_blank'
                rel="noreferrer"
              >
                <img src={Android} alt='' height='58' width='194' />
              </a>
              <a
                href='https://apps.apple.com/us/app/vibeonix/id1579287679'
                target='_blank'
                rel="noreferrer"
              >
                <img src={Ios} alt='' height='58' width='194' />
              </a>
            </div>
          </div>
          <span className='w-full text-sm md:text-base text-center text-gray-700 pb-5'>
            © Copyright Vibeonix - All Rights Reserved.
          </span>
        </footer>
      </div>
    </section>
  );
};

export default IndividualUserLayout;
