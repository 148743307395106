import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationLeft, PaginationRight } from '../../assets/images';
import './styles.scss';

const Paginate = ({
  flag,
  pageValue,
  pageCount,
  handlePageChange,
  page,
  limit,
  totalCount,
}: any) => {
  return (
    <div className='flex justify-between mt-4 mb-20'>
      <div className='text-secondary'>
        Showing {page * limit - (limit - 1)}-
        {limit * page > totalCount ? totalCount : limit * page} of {totalCount}
      </div>
      <ReactPaginate
        previousLabel={<img src={PaginationLeft} alt='' />}
        nextLabel={<img src={PaginationRight} alt='' />}
        containerClassName='vibeonix-pagination'
        pageCount={pageCount}
        disabledClassName={'disabled'}
        activeClassName={'active-page'}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        onPageChange={handlePageChange}
        forcePage={flag && 0}
        disableInitialCallback={false}
      />
    </div>
  );
};

export default Paginate;
