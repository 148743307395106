import React from 'react';
import Button from '../../../button/Button';
import userDeleteModal from '../../../../assets/img/icons/userDeleteModal.svg';
import editClose from '../../../../assets/img/icons/editClose.svg';
import './styles.scss';
import ButtonLoader from '../../../Loader/ButtonLoader';

const ConfirmationPopUp = (props) => {
  const {
    closeModal,
    hasModalView,
    onPress,
    type,
    heading,
    subHeading,
    firstButton,
    secondButton,
    loader,
  } = props;

  return (
    <div>
      <div
        className={`popup-wrapper-confirm items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          hasModalView ? 'block' : 'hidden'
        }`}
      >
        <div className='popup-container-confirm absolute w-full mb-6 top-6 h-full' style={{height: subHeading ? '402px' : '300px'}}>
          <img
            className='img z-10 absolute'
            onClick={closeModal}
            src={editClose}
            alt=""
          />
          <div
            className='popup-inner-container w-full h-full float-left bg-white relative'
            style={{ padding: '72px' }}
          >
            <div className='flex w-full flex-col justify-between h-full'>
              {type !== 'default' && (
                <div className='w-full justify-center flex'>
                  <div
                    className='bg-red-200 border border-red-700 items-center justify-center flex rounded-md p-3'
                    style={{ height: '52px', width: '52px' }}
                  >
                    <img
                      className='img z-10 '
                      onClick={closeModal}
                      src={userDeleteModal}
                      alt=""
                    />
                  </div>
                </div>
              )}
              <div
                className='popup-title font-medium text-2xl justify-center flex text-center'
                style={{ color: '#161F38' }}
              >
                {heading}
              </div>
              {subHeading && (
                <p
                  className='text-center font-medium text-base'
                  style={{ color: '#444C63' }}
                >
                  {subHeading}
                </p>
              )}
              <div className='popup-frm w-full float-left'>
                <div className='flex flex-row'>
                  <div className='justify-end flex w-2/4 pr-2'>
                    <Button
                      onPress={closeModal}
                      value={firstButton}
                      bgColor={'white'}
                      color={'#A6A9B4'}
                      borderColor={'#A6A9B4'}
                      buttonWidth={'123px'}
                      buttonHeight={'38px'}
                    />
                  </div>
                  <div className='justify-start w-2/4 pl-2'>
                    <Button
                      onPress={onPress}
                      value={loader ? <ButtonLoader /> : secondButton}
                      buttonWidth={'123px'}
                      buttonHeight={'38px'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;
