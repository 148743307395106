export const USER_SIGN_UP = 'auth/userSignUp';
export const INDIVIDUAL_USER_SIGN_UP = 'auth/individualUserSignup';
export const LOGIN = 'auth/login';
export const LOGOUT = 'auth/logout';

export const IS_ALREADY_REGISTERED = 'auth/isAlreadyRegistered';
export const SET_CLIENT_REGISTER = 'auth/setClientRegister';
export const ORGANIZATION_DETAILS = 'auth/organizationDetails';
export const SEND_VERIFICATION_EMAIL = 'auth/sendVerificationEmail';
export const SEND_EMAIL_To_VERIFY = 'auth/sendEmailToVerify';
export const RESEND_EMAIL ='auth/resendEmail';
export const GROUP_ADMIN_SIGN_UP = 'auth/groupAdminSignUp';
export const CHANGE_PASSWORD = 'auth/changePassword';
export const FORGOTPASSWORD = 'auth/forgotPassword';
export const CHECK_IF_EMAIL_ALREADY_EXISTS = 'auth/existingEmail';
export const INDIVIDUAL_USER_PLANS = 'auth/getIndividualUserPlans';
export const INDIVIDUAL_USER_ADD_CARD ='auth/individualUserAddCard';
export const INDIVIDUAL_SUBSCRIPTION_DETAIL = 'auth/subscriptionDetail';
export const EMAIL_UNSUBSCRIBE = 'auth/emailUnsubscribe';
export const SET_SIDENAV = "auth/setSideNav"
export const SWITCH_ACCOUNT = 'auth/switchAccount';

