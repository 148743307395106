/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BlockUser,
  GetUserList,
  InviteUser,
  GetUserByDocId,
  AssessmentsHistoryInerfase,
  GetUserWellbingCalender,
  GetUserAssessmentDetails,
  GetGropListForSortUserList,
  EmailInterface,
  ContactUs,
} from '../../interface/users';

type InitialState = {
  user: any;
  userList: any;
  userDetails: any;
  assessmentsHistorys: any;
  userWellbeingCalendar: any;
  userAssessmentDetails: any;
  userAssessmentDetailsLoading: boolean;
  gropListForSortUserList: any;
  userFilterParams: {
    limit: number;
    page: number;
    sort: string;
    order: string;
    search: string;
    status: any;
    group_id: any;
  };
  userDetailsRefresh: boolean;
  userUpdateLoader: boolean;
  resendInviteLoader: boolean;
  userListLoading: boolean;
  inviteUserLoader: boolean;
  contactUsLoading: boolean;
};

const initialState: InitialState = {
  user: undefined,
  userList: undefined,
  userDetails: undefined,
  assessmentsHistorys: undefined,
  userWellbeingCalendar: undefined,
  userAssessmentDetails: undefined,
  userAssessmentDetailsLoading: false,
  gropListForSortUserList: undefined,
  userFilterParams: {
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
    search: '',
    status: ['blocked', 'active', 'pending'],
    group_id: null,
  },
  userDetailsRefresh: false,
  userUpdateLoader: false,
  resendInviteLoader: false,
  userListLoading: false,
  inviteUserLoader: false,
  contactUsLoading: false,
};

const inviteUser = (state: InitialState, action: PayloadAction<InviteUser>) => {
  state.inviteUserLoader = true;
};
const setInviteUser = (state: InitialState, action: PayloadAction<any>) => {
  state.inviteUserLoader = false;
};
const getUserList = (
  state: InitialState,
  action: PayloadAction<GetUserList>
) => {
  if (action.payload === undefined) {
    state.userFilterParams.search = '';
    state.userFilterParams.order = 'DESC';
    state.userFilterParams.limit = 10;
    state.userFilterParams.page = 1;
    state.userFilterParams.sort = 'createdAt';
    state.userFilterParams.status = ['blocked', 'active', 'pending'];
  } else {
    state.userFilterParams.search = action.payload.search;
    state.userFilterParams.order = action.payload.order;
    state.userFilterParams.limit = action.payload.limit;
    state.userFilterParams.page = action.payload.page;
    state.userFilterParams.sort = action.payload.sort;
    state.userFilterParams.status = action.payload.status;
  }
  state.userDetailsRefresh = false;
  state.userListLoading = true;
};
const blockUser = (state: InitialState, action: PayloadAction<BlockUser>) =>
  state;

const deleteUser = (
  state: InitialState,
  action: PayloadAction<GetUserByDocId>
) => state;

const setRefresh = (state: InitialState, action: PayloadAction<boolean>) => {
  state.userDetailsRefresh = true;
};

const setUserList = (state: InitialState, action: any) => {
  state.userList = action.payload;
  state.userListLoading = false;
};

const getUserDetails = (
  state: InitialState,
  action: PayloadAction<GetUserByDocId>
) => {
  state.userDetailsRefresh = false;
};

const setUserDetails = (state: any, action: any) => {
  state.userDetails = action.payload;
};

const getAssessmentsHistorys = (
  state: InitialState,
  action: PayloadAction<AssessmentsHistoryInerfase>
) => state;
const setAssessmentsHistorys = (state: InitialState, action: any) => {
  state.assessmentsHistorys = action.payload;
};
const getUserWellbeingCalendar = (
  state: InitialState,
  action: PayloadAction<GetUserWellbingCalender>
) => state;

const setUserWellbeingCalendar = (state: InitialState, action: any) => {
  state.userWellbeingCalendar = action.payload;
};

const getUserAssessmentDetails = (
  state: InitialState,
  action: PayloadAction<GetUserAssessmentDetails>
) => {
  state.userAssessmentDetailsLoading = true;
};

const setUserAssessmentDetails = (state: InitialState, action: any) => {
  state.userAssessmentDetails = action.payload;
  state.userAssessmentDetailsLoading = false;
};

const getGropListForSortUserList = (
  state: InitialState,
  action: PayloadAction<GetGropListForSortUserList>
) => state;

const setGropListForSortUserList = (state: InitialState, action: any) => {
  state.gropListForSortUserList = action.payload;
};

const updateUser = (state: InitialState, action: PayloadAction<any>) => {
  state.userUpdateLoader = true;
};

const setUpdateUser = (state: InitialState, action: any) => {
  state.userUpdateLoader = false;
};

const resendInvite = (
  state: InitialState,
  action: PayloadAction<EmailInterface>
) => {
  state.resendInviteLoader = true;
};

const setResendInvite = (state: InitialState, action: any) => {
  state.resendInviteLoader = false;
};

const contactUs = (state: InitialState, action: PayloadAction<ContactUs>) => {
  state.contactUsLoading = true;
};

const setContactUs = (state: InitialState, action: PayloadAction<any>) => {
  state.contactUsLoading = false;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    inviteUser,
    resendInvite,
    setResendInvite,
    setInviteUser,
    updateUser,
    setUpdateUser,
    getUserList,
    setUserList,
    blockUser,
    deleteUser,
    setRefresh,
    getUserDetails,
    setUserDetails,
    getAssessmentsHistorys,
    setAssessmentsHistorys,
    getUserWellbeingCalendar,
    setUserWellbeingCalendar,
    getUserAssessmentDetails,
    setUserAssessmentDetails,
    getGropListForSortUserList,
    setGropListForSortUserList,
    contactUs,
    setContactUs,
  },
});
export default userSlice;
