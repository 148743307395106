import Button from "../../button/Button";
import userBlockModal from "../../../assets/img/icons/userBlockModal.svg";
import editClose from "../../../assets/img/icons/editClose.svg";
import "./styles.scss";

const  CancelSubscription = (props) => {
  const { closeModal, hasModalView, onPress, confirmText, title, buttonTextOne, buttonTextTwo,isCancel } = props;
  return (
    <div>
      <div
        className={`z-50 popup-wrapper-style items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${hasModalView ? "block" : "hidden"
          }`}
      >
        <div className="popup-container absolute w-full mb-6 top-6">
          <img
            className="img z-10 absolute"
            onClick={closeModal}
            src={editClose}
            alt=""
          />
          <div className="popup-inner-container w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
            <div className="w-full justify-center flex">
              { isCancel && <div className="w-10 h-10 bg-red-200 border border-red-700 items-center justify-center flex rounded-md px-3 py-3">
                <img
                  className="img z-10 "
                  onClick={closeModal}
                  src={userBlockModal}
                  alt=""
                />
              </div>}
            </div>
            <div className="px-10 flex w-full flex-col">
              <div className="text-gray-900 my-6 popup-title font-semibold text-l justify-center flex">
                <p style={{textAlign:'center'}}>{title}</p>
              </div>
              <p className="text-gray-900 text-center">{confirmText}
              </p>
              <div className="popup-frm w-full float-left pt-4 pb-2">
                <div className="flex flex-row w-full gap-4">
                  <div className="w-1/2">
                    <Button
                      onPress={closeModal}
                      value={buttonTextOne}
                      bgColor="#fff" borderColor="#A6A9B4" color="#A6A9B4"
                    />
                  </div>
                  <div className="w-1/2">
                    <Button  onPress={onPress} value={buttonTextTwo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscription;
