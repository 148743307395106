import React, { useState, useEffect } from 'react';
import CardDelete from '../../../components/Tooltip/CardDelete ';
import TableLoader from '../../../components/Loader/TableLoader';
import AddCard from '../../../components/modals/AddCard/AddCard';
import { useDispatch, useSelector } from 'react-redux';
import '../IndividualUsers.scss';
import individualUserSlice from '../../../redux/slices/individualUser.slice';
import ConfirmationPopUp from '../../../components/modals/Auth/confirmationPopUp/ConfirmationPopUp';
import ReactTooltip from 'react-tooltip';

export default function MySubscriptionMycard() {
  const dispatch = useDispatch();
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDefaultOpen, setIsDefaultOpen] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<any>(null);

  useEffect(() => {
    dispatch(individualUserSlice.actions.getSavedCardData());
    dispatch(individualUserSlice.actions.setShowButtonButton(true));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(individualUserSlice.actions.setShowButtonButton(false));
    };
  }, []);

  const savedCardsData = useSelector(
    (state: any) => state.individualUser.savedCardsData
  );

  const onSetDefault = () => {
    dispatch(
      individualUserSlice.actions.setDefaultCard({
        card_id: currentSelected?.id,
      })
    );
    setCurrentSelected(null);
    setIsDefaultOpen(false);
  };

  const onDelete = () => {
    dispatch(
      individualUserSlice.actions.deleteCard({ card_id: currentSelected?.id })
    );
    setCurrentSelected(null);
    setIsDeleteOpen(false);
  };
  return (
    <div className='card-container w-full px-10 lg:px-20'>
      <div className='flex justify-between mt-16 w-full'>
        {/* <SubHeading title='Saved Card Details' /> */}
        <div className='flex flex-row w-full justify-between'>
          <span
            style={{ color: '#3A9EA5' }}
            className='text-2xl font-lexend font-medium'
          >
            My Cards
          </span>
          <button
            type='button'
            onClick={() => setIsPaymentOpen(true)}
            style={{
              backgroundColor: '#3A9EA5',
              width: '162px',
              height: '38px',
            }}
            className='flex justify-center items-center hover:bg-blue-700 text-white font-medium rounded-full'
          >
            <span className='tracking-wider'>Add New Card</span>
          </button>
        </div>
      </div>
      <span className='text-lg flex font-lexend font-medium mt-3 mb-2'>
        Saved Cards Details
      </span>
      <div className='overflow-x-auto border-user rounded-xl mt-4 mb-8 hidden w-full sm:block'>
        <div className='inline-block min-w-full rounded-lg overflow-hidden'>
          <table className='min-w-full leading-normal myborder user-table-width'>
            <thead
              style={{
                // maxWidth: 1400,
                width: '100%',
                minWidth: '100%',
                // backgroundColor: 'red'
              }}
            >
              <tr
                className='flex justify-between'
                style={{ backgroundColor: '#F5F7FF' }}
              >
                <th
                  style={{ width: 200 }}
                  className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                >
                  Card
                </th>
                <th
                  style={{ width: 200 }}
                  className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                >
                  <div className='flex items-center justify-between'>
                    <span>Card Type</span>
                  </div>
                </th>
                <th
                  style={{ width: 200 }}
                  className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                >
                  <div className='flex items-center justify-between'>
                    <span>Card Holder</span>
                  </div>
                </th>
                <th
                  style={{ width: 180 }}
                  className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                >
                  <div className='flex items-center justify-between'>
                    <span>Expiry Date</span>
                  </div>
                </th>
                <th
                  style={{ width: 70 }}
                  className='px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700 tracking-wider'
                >
                  {/* <div className="flex items-center justify-between">
                          <span>Expiry Date</span>

                        </div> */}
                </th>
              </tr>
            </thead>

            {savedCardsData ? (
              <tbody
                style={{
                  // maxWidth: 1100,
                  width: '100%',
                }}
              >
                {!!savedCardsData &&
                  !!savedCardsData?.savedCards.length &&
                  savedCardsData?.savedCards?.map((item: any, index: any) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 ? 'secondcolor' : 'bg-white'
                      } flex justify-between`}
                    >
                      <td
                        style={{ width: 200 }}
                        className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                      >
                        <div className='flex text-base'>
                          {'**** **** **** '}
                          {item.card_number}
                        </div>
                      </td>
                      <td
                        style={{ width: 200 }}
                        className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                      >
                        {item.type}
                      </td>
                      <td
                        style={{ width: 200 }}
                        className='px-5 py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                      >
                        {item.card_holder_name}
                      </td>
                      <td
                        style={{ width: 180 }}
                        className='px-5 py-3 text-left float-left text-sm card-text-primary truncate'
                      >
                        {item?.card_expiry}
                      </td>
                      <td
                        style={{ width: 70, justifyContent: 'start' }}
                        className='py-3 text-left float-left text-sm card-text-primary truncate'
                      >
                        {!item.default_card ? (
                          <button
                            key={index}
                            type='button'
                            className='inline-block card-text-primary'
                            onClick={() => {
                              setCurrentSelected(item);
                            }}
                            onMouseEnter={() => ReactTooltip.hide()}
                          >
                            <CardDelete
                              key={index}
                              index={index}
                              onSetDefault={() => {
                                setIsDefaultOpen(true);
                              }}
                              onDeleteSelected={() => {
                                setIsDeleteOpen(true);
                              }}
                              item={item}
                            />
                          </button>
                        ) : (
                          <span
                            className={
                              'active-bg capitalize text-xs px-3 py-1 rounded-lg'
                            }
                          >
                            Default
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <TableLoader />
            )}
          </table>
        </div>
      </div>
      <div className='sm:hidden w-full flex flex-col gap-5 my-10'>
        {savedCardsData ? (
          !!savedCardsData &&
          !!savedCardsData?.savedCards.length &&
          savedCardsData?.savedCards?.map((item: any, index: any) => (
            <div className='flex flex-col gap-2 card-item py-5 px-5'>
              <h4 className='text-sm font-medium'>Card</h4>
              <h5 className='text-sm font-normal text-gray-700'>
                {' '}
                {'**** **** **** '}
                {item.card_number}
              </h5>
              <div className='flex justify-between'>
                <div className='flex flex-col gap-2 text-left capitalize'>
                  <h4 className='text-sm font-normal text-gray-700'>
                    Card Holder
                  </h4>
                  <h3 className='text-sm font-medium'>
                    {item.card_holder_name}
                  </h3>
                </div>
                <div className='flex flex-col gap-2 text-right capitalize'>
                  <h4 className='text-sm font-normal text-gray-700'>
                    Card Type
                  </h4>
                  <h3 className='text-sm font-medium'> {item.type}</h3>
                </div>
              </div>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-2'>
                  <h4 className='text-sm font-normal text-gray-700'>
                    Expiry Date
                  </h4>
                  <h3 className='text-sm font-medium'>{item?.card_expiry}</h3>
                </div>
                <div>
                  {!item.default_card ? (
                    <button
                      key={index + 100}
                      type='button'
                      className='inline-block card-text-primary z-50'
                      onClick={() => {
                        setCurrentSelected(item);
                      }}
                      onMouseEnter={() => ReactTooltip.hide()}
                    >
                      <CardDelete
                        key={index + 100}
                        index={index + 100}
                        onSetDefault={() => {
                          setIsDefaultOpen(true);
                        }}
                        onDeleteSelected={() => {
                          setIsDeleteOpen(true);
                        }}
                        item={item}
                      />
                    </button>
                  ) : (
                    <span className={'active-bg capitalize text-xs px-3 py-1 rounded-lg'}>
                      Default
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <TableLoader />
        )}
      </div>
      {isPaymentOpen && (
        <AddCard
          paymentData={null}
          isOpen={isPaymentOpen}
          closeModel={() => setIsPaymentOpen(false)}
          page={'MySubscriptionMycard'}
        />
      )}
      <ConfirmationPopUp
        hasModalView={isDefaultOpen}
        closeModal={() => setIsDefaultOpen(false)}
        onPress={onSetDefault}
        type='default'
        heading={'Do you want to set this as default?'}
        // subHeading={'Are you sure you want to set this as default?'}
        firstButton={'Cancel'}
        secondButton={'OK'}
      />
      <ConfirmationPopUp
        hasModalView={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        onPress={onDelete}
        type='default'
        heading={'Do you want to delete this card?'}
        subHeading={'Are you sure you want to delete this card?'}
        firstButton={'Cancel'}
        secondButton={'OK'}
      />
    </div>
  );
}
