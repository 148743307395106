import React, { useEffect, useRef, useState } from 'react';
import editClose from '../../../../assets/img/icons/editClose.svg';
import Button from '../../../FormikFields/Button/Button';
import SwitchBox from '../../../../common/SwitchBox/SwitchBox';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { Formik } from 'formik';
import {
  AddGroupWithNameOnlySchema,
  AddGroupWithNameSchema,
  AddGroupWithExistingName,
  UpdateGroupWithNameSchema,
} from '../../../../utils/validation/schemas';
import InputBox from '../../../../components/FormikFields/InputBox/InputBox';
import groupSlice from '../../../../redux/slices/group.slice';
import BackButton from '../../../../components/button/Button';
import { storageEngine } from '../../../../common/helper';
import { like1 } from '../../../../assets/images';
import ButtonLoader from '../../../Loader/ButtonLoader';

function AddGroup({ hasModalView, closeModal, groupData }:any) {
  const initialValues = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    userLimit: null,
    userLimitEdit: null,
    isGroupManagerInvite: false,
  };

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.setSecondPageReset());
      setSecondPage(false);
    };
  }, []);

  const dispatch = useDispatch();
  const [isEditOrUpdate, setIsEditOrUpdate] = useState<any>(null);
  const [secondPage, setSecondPage] = useState(false);
  const [clientAsGroupEmail, setClientAsGroupEmail] = useState('');
  const [userLimit, setUserLimit] = useState(0);
  const [userMinValue, setUserMinValue] = useState(1);
  const [isGroupManagerAsGroupAdmin, setIsGroupManagerAsGroupAdmin] =
    useState(false);

  const formikRef = useRef<any>(null);
  const createGroupLoader = useSelector(
    (state:any) => state.group.createGroupLoader
  );
  const remaining = useSelector(
    (state:any) => state.clientAdmin.remainingGroupsAndUsers
  );
  const remainingUser = remaining?.remainingNumOfUsers;

  const user = storageEngine.get('user');
  const [oldEmail, setOldEmail] = useState(null);

  const registerAddGroup = (values: any) => {
    
    if (groupData) {
      if (values.isGroupManagerInvite) {
        setClientAsGroupEmail(values.email);
        setOldEmail(groupData.group_manager_email);
        dispatch(
          groupSlice.actions.updateGroup({
            ...values,
            name: values.name?.replace(/\s+$/, ''),
            userLimit: parseInt(values.userLimitEdit),
            doc_id: groupData.group_doc_id,
            old_email: groupData.group_manager_email,
            setSecondPage,
          })
        ); 
      } else {
        setClientAsGroupEmail('');
        dispatch(
          groupSlice.actions.updateGroup({
            isGroupManagerInvite: false,
            name: values.name?.replace(/\s+$/, ''),
            doc_id: groupData.group_doc_id,
            setSecondPage,
          })
        );
      }
    } else {
      if (values.isGroupManagerInvite) {
        setClientAsGroupEmail(values.email);
        dispatch(
          groupSlice.actions.createGroup({
            ...values,
            name: values.name?.replace(/\s+$/, ''),
            userLimit: parseInt(values?.userLimit),
            setSecondPage,
          })
        );
      } else {
        setClientAsGroupEmail('');
        dispatch(
          groupSlice.actions.createGroup({
            isGroupManagerInvite: false,
            name: values.name?.replace(/\s+$/, ''),
            setSecondPage,
          })
        );
      }
    }
  };
  useEffect(() => {
    if (groupData) {
      setIsEditOrUpdate('update');
    } else {
      setIsEditOrUpdate('add');
    }
  }, [createGroupLoader]);

  useEffect(() => {
    if (groupData) {
      const admin = groupData.is_group_manager_invite;
      setIsGroupManagerAsGroupAdmin(admin);
      formikRef.current.setValues({
        name: groupData.group_name,
        firstName: groupData.first_name,
        lastName: groupData.last_name,
        email: groupData.group_manager_email,
        userLimit: groupData.user_limit,
        userLimitEdit: groupData.user_limit,
        isGroupManagerInvite: admin,
      });
    } else {
      setUserLimit(remainingUser);
    }

    if (groupData) {
      dispatch(
        groupSlice.actions.numUserValidation({
          group_id: groupData.group_id,
        })
      );
    }
  }, []);

  const numUserValidation = useSelector(
    (state:any) => state.group.numUserValidationList
  );

  useEffect(() => {
    if (groupData) {
      if (groupData.user_limit > remainingUser) {
        setUserLimit(groupData.user_limit);
      } else {
        setUserLimit(remainingUser);
      }
      setUserMinValue(groupData.user_limit);
    } else {
      setUserLimit(remainingUser);
    }
  }, [remainingUser]);

  const isEditableCheck = () => {
    if (groupData || isGroupManagerAsGroupAdmin) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div
        className={`popup-wrapper items-center justify-center flex fixed w-full h-full top-0 left-0 overflow-auto ${
          hasModalView ? 'block' : 'hidden'
        }`}
      >
        <div className='popup-container absolute w-full mb-6 top-6'>
          <img
            className='img z-10 absolute'
            onClick={() => closeModal()}
            src={editClose}
            alt=""
          />
          {secondPage ? (
            <div className='popup-inner-container w-full float-left bg-white px-10 rounded-md relative overflow-hidden py-24'>
              <div className='flex justify-center'>
                <img src={like1} alt=""/>
              </div>

              <div className='flex justify-center text-primary font-medium text-2xl pt-8 pb-6'>
                {groupData ? 'Group is updated' : 'New group is added'}
              </div>
              <div className='pb-6'>
                {clientAsGroupEmail && oldEmail !== clientAsGroupEmail ? (
                  <>
                    <div className='flex justify-center text-center text-secondary font-medium text-base'>
                      {' '}
                      An invitation email has been sent to
                    </div>

                    <div className='flex justify-center text-center text-primary font-medium text-base'>
                      {clientAsGroupEmail}
                    </div>
                  </>
                ) : (
                  <div className='flex justify-center text-center text-secondary font-medium text-base'>
                    {groupData ? 'Group is updated' : 'A new group is created'}
                  </div>
                )}
              </div>
              <div className='flex justify-center'>
                <BackButton
                  onPress={() => closeModal()}
                  value={'OK'}
                  disabled={false}
                />
              </div>
            </div>
          ) : (
            <div className='popup-inner-container w-full float-left bg-white px-10 rounded-md relative overflow-hidden'>
              <div className='text-gray-900 popup-title text-2xl flex justify-center mt-12'>
                {groupData ? 'Edit Group' : 'Add Group'}
              </div>
              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={registerAddGroup}
                validationSchema={() =>
                  formikRef &&
                  formikRef?.current?.values?.isGroupManagerInvite &&
                  isEditOrUpdate === 'add'
                    ? AddGroupWithNameSchema(userLimit, userMinValue)
                    : formikRef &&
                      formikRef?.current?.values?.isGroupManagerInvite &&
                      isEditOrUpdate === 'update'
                    ? UpdateGroupWithNameSchema(
                        userLimit,
                        groupData?.existing_users,
                        numUserValidation?.finalRemainingUsers
                      )
                    : formikRef?.current?.values?.name &&
                      isGroupManagerAsGroupAdmin
                    ? AddGroupWithExistingName
                    : AddGroupWithNameOnlySchema
                }
              >
                {({ handleSubmit, isValid, values }) => (
                  <div>
                    <div data-tut='reactour__name_group'>
                      <InputBox
                        name='name'
                        label='Group Name'
                        placeholder=''
                        value={values.name}
                      />
                    </div>
                    <div
                      data-tut='reactour__turn_on'
                      className='flex items-center mt-2'
                    >
                      <span className='text-base'>
                        Do you want to invite group manager to this group?
                        </span>
                      <SwitchBox
                        onChange={(e:any) =>
                          isEditableCheck()
                            ? ''
                            : formikRef.current.setValues({
                                name: formikRef.current.values.name,
                                isGroupManagerInvite: e,
                              })
                        }
                        checked={
                          isGroupManagerAsGroupAdmin
                            ? isGroupManagerAsGroupAdmin
                            : values.isGroupManagerInvite
                        }
                      />
                    </div>
                    {values.isGroupManagerInvite && (
                      <div>
                        <div data-tut='reactour__email' className='my-4'>
                          <InputBox
                            name='firstName'
                            label='First Name'
                            placeholder=''
                            value={values.firstName}
                          />
                        </div>

                        <div data-tut='reactour__admin' className='my-4'>
                          <InputBox
                            name='lastName'
                            label='Last Name'
                            placeholder=''
                            value={values.lastName}
                          />
                        </div>
                        <div data-tut='reactour__personal' className='my-4'>
                          <InputBox
                            name='email'
                            label='Email'
                            placeholder=''
                            value={values.email}
                            disabled={groupData?.is_default_group? true: false}
                          />
                        </div>
                        {isEditOrUpdate === 'add' && (
                          <div className='my-4'>
                            <InputBox
                              name='userLimit'
                              label='Number Of Users'
                              placeholder=''
                              value={values.userLimit}
                              type='number'
                            />
                          </div>
                        )}
                        {isEditOrUpdate === 'update' && (
                          <div className='my-4'>
                            <InputBox
                              name='userLimitEdit'
                              label='Number Of Users'
                              placeholder=''
                              value={values.userLimitEdit}
                              type='number'
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className='w-1/2 m-auto my-6'>
                      <Button
                        onClick={handleSubmit}
                        text={
                          createGroupLoader ? (
                            <ButtonLoader />
                          ) : groupData ? (
                            'Update'
                          ) : values.isGroupManagerInvite ? (
                            'Invite'
                          ) : (
                            'Create'
                          )
                        }
                        active={isValid}
                      />
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default AddGroup;
