import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetIndicators } from "../../interface/indicators";


type InitialState = {}

const initialState: InitialState = {
    indicatorList : null,
}

const getIndicator = (state : any, action:PayloadAction<GetIndicators>) => state;
const setIndicatorList = (state:any,action:any) => {
    state.indicatorList = action.payload
}

const indicatorSlice = createSlice({
    name : "indicator",
    initialState,
    reducers: {
        getIndicator,setIndicatorList,
    }
})
export default indicatorSlice