import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';
import { ResponseGenerator } from '../../../interface/Response';
import {
  CreateGroup,
  GetGroupList,
  GetGroupById,
  GetGroupWellBeing,
  GetUserListWithClientGroupId,
  GetGroupEnergyState,
  GetUserListWithGroupId,
  AddUsersToGroup,
  BlockGroup,
  NumUserValidation,
  DeleteGroup,
} from '../../../interface/group';

function* getGroupList(value: GetGroupList) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groups/getAllGroups`,
    {
      params: value,
    }
  );
  return response;
}

function* createGroup(value: CreateGroup) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}groups/createGroup`,
    value
  );
  return response;
}

function* updateGroup(value: CreateGroup) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}groups/updateGroup`,
    value
  );
  return response;
}
function* blockGroup(value: BlockGroup) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}groups/blockGroup`,
    value
  );
  return response;
}

function* deleteGroup(value: DeleteGroup) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}clientAdmin/deleteClientGroup`,
    value
  );
  return response;
}

function* getGroupById(value: GetGroupById) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}groups/getGroup`,
    {
      params: value,
    }
  );
  return response;
}
function* getGroupWellBeing(value: GetGroupWellBeing) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/groupWellbeing`,
    {
      params: value,
    }
  );
  return response;
}
function* getGropwiseEnergeState(value: GetGroupEnergyState) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/getAllUsersEnergyState`,
    {
      params: value,
    }
  );
  return response;
}
function* getUsersListWithGroupId(value: GetUserListWithGroupId) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}users/userListByGroupId`,
    {
      params: value,
    }
  );
  return response;
}

function* getClientUserList(value: GetUserListWithGroupId) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}clientAdmin/getUserListToAdd`,
    {
      params: value,
    }
  );
  return response;
}

function*  addUsersToGroup(value: AddUsersToGroup) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}clientAdmin/addUsersToGroup`,
    value
  );
  return response;
}
function* getUserListByClientGroupId(value: GetUserListWithClientGroupId) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}users/userListByClientGroupId`,
    {
      params: value,
    }
  );
  return response;
}

function* numUserValidationServices(value: NumUserValidation) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}groups/numUserValidation`,
    value
  );
  return response;
}

const guide = {
  createGroup,
  updateGroup,
  blockGroup,
  deleteGroup,
  getGroupList,
  getGroupById,
  getGroupWellBeing,
  getGropwiseEnergeState,
  getUsersListWithGroupId,
  getClientUserList,
  addUsersToGroup,
  getUserListByClientGroupId,
  numUserValidationServices,
};

export default guide;
