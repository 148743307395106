import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import { GetIndicators } from "../../interface/indicators";
import { ResponseGenerator } from "../../interface/Response";
import Api from "../../services/Apis";
import { GET_INDICATOR } from "../actions/indicator.action"
import indicatorSlice from "../slices/indicator.slice"

function* getIndicators(action: PayloadAction<GetIndicators>) {
    try {
        const response: ResponseGenerator = yield Api.indicator.getIndicators(
            action.payload
        );
        yield put(indicatorSlice.actions.setIndicatorList(response.data.data));
    } catch(err: any) {
        toast.error(err?.response?.data?.message)
    }
}

function* indicatorSaga() {
    yield takeEvery(GET_INDICATOR, getIndicators)
}


export default indicatorSaga