export const CREATE_GROUP = "group/createGroup";
export const UPDATE_GROUP = "group/updateGroup";
export const BLOCK_GROUP = "group/blockGroup";
export const DELETE_GROUP = "group/deleteGroup";
export const GET_GROUP_LIST = "group/getGroupList";
export const GET_GROUP_BY_ID = "group/getGroupById"
export const GET_GROUP_WELLBEIND = "group/getGroupWellBeing";
export const GET_GROUP_WISE_ENERGY_STATE = "group/getGropwiseEnergeState";
export const GET_USER_LIST_WITH_GROUP_ID = "group/getUsersListWithGroupId";
export const GET_CLIENT_USER_LIST = "group/getClientUserList";

export const ADD_USERS_TO_GROUP = "group/addUsersToGroup";
export const GET_USER_LIST_WITH_CLIENT_GROUP_ID = "group/getUserListByClientGroupId";
export const ADD_USERS_TO_GROUP_MODAL_OPEN_CLOSE = "group/addUsersToGroupModalOpenClose";
export const NUM_USER_VALIDATION ='group/numUserValidation';