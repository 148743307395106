import React, { useEffect, useState, useRef } from 'react';
import LableWithValue from '../../common/LableWithValue/LableWithValue';
import SubHeading from '../../common/SubHeading/SubHeading';
import Graph from '../../components/Graph/Graph';
import { useDispatch, useSelector } from 'react-redux';
import groupSlice from '../../redux/slices/group.slice';
import sort from '../../assets/img/icons/sort.svg';
import moment from 'moment';
import { NoData, Add } from '../../assets/images';
import authSlice from '../../redux/slices/auth.slice';
import VictoryDonutChart from '../../components/Graph/VictoryDonutChart';
import VibeonixDatePicker from '../../components/DatePicker/Datepicker';
import { storageEngine } from '../../common/helper';
import Button from '../../components/button/Button';
import SearchBox from '../../components/SearchBox/SearchBox';
import Paginate from '../../components/Paginate/Paginate';
import UserBlockModal from '../../components/modals/userList/userBlock/UserBlockModal';
import { Formik } from 'formik';
import InputBox from '../../components/FormikFields/InputBox/InputBox';
import DropDownPeriodAdndEnergy from '../../components/Dropdown/DropDownPeriodAdndEnergy';
import { groupManagerEditValidation } from '../../utils/validation/schemas';
import TableLoader from '../../components/Loader/TableLoader';
import ButtonLoader from '../../components/Loader/ButtonLoader';
import ReactLoading from 'react-loading';
import MonthPickerModal from '../../components/modals/MonthPickerModal/MonthPickerModal';
import GroupNameTooltip from '../../components/Tooltip/GroupNameTooltip';

const initialValues = {
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  numberOfUsers: 0,
  totalAssessment: 0,
  isGroupManagerInvite: false,
};

function DetailedGroups(props:any) {
  const { id } = props.match.params;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const user = storageEngine.get('user');
  const formikRef = useRef<any>(null);

  const today = `${new Date().getFullYear()}/${
    new Date().getMonth() + 1
  }/${new Date().getDate()}`;
  const [groupWellbeingDataGraph, setGroupWellbeingDataGraph] = useState<any[]>(
    []
  );
  const [userWellBeingDate, setUserWellBeingDate] = useState(today);
  const [userEnergyStateDate, setUserEnergyStateDate] = useState('week');
  const [energyLevel, setEnergyLevel] = useState('high');
  const [userEnergyStateInformation, setUserEnergyStateInformation] = useState(
    {}
  );
  const [selectedUser, setSelectedUser]: any = useState([]);
  const [energyStateSelectedDate, setEnergyStateSelectedDate] = useState('');
  const [
    isEnergyStateCustomMonthModalView,
    setEnergyStateCustomMonthModalView,
  ] = useState<boolean>(false);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [score, setScore] = useState('dailyScore');
  const [isAverage, setIsAverage] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.addUsersToGroupModalReset());
    };
  }, []);

  useEffect(() => {
    getUserDetailsById();
  }, []);

  const getUserDetailsById = () => {
    dispatch(groupSlice.actions.getGroupById({ doc_id: id }));
  };

  const handleAddGroupModal = () => {
    dispatch(groupSlice.actions.addUsersToGroupModalOpenClose());
    let args = { ...clientUserListFilterData, search: '', page: 1 };
    setclientUserListFilterData(args);
    getUserList(args);
    // setSelectedUser(oldSelectedUser);
  };
  const handleAddGroupModalClose = () => {
    dispatch(groupSlice.actions.addUsersToGroupModalOpenClose());
    setTableHeadCheckAll(false);
    setSelectedUser([]);
    setOldSelectedUsers([]);
  };

  const addUsersToGroupModal = useSelector(
    (state:any) => state.group.addUsersToGroupModal
  );

  const currentGroup = useSelector((state:any) => state.group.groupsListById);
  const groupWellBeing = useSelector((state:any) => state.group.groupWellBeing);
  const userEnergyState = useSelector((state:any) => state.group.groupsWiseEnergy);
  const groupWellBeingLoader = useSelector(
    (state:any) => state.group.groupWellBeingLoader
  );
  const userEnergyStateLoader = useSelector(
    (state:any) => state.group.groupsWiseEnergyLoader
  );
  const usersListWithGroupId = useSelector(
    (state:any) => state.group.usersListWithGroupId
  );
  const clientUserList = useSelector((state:any) => state.group.clientUserList);
  const allGroupslist = usersListWithGroupId?.allusers;
  const allGroupslistCount = usersListWithGroupId?.count;
  const allclientUserList = clientUserList?.allusers;
  const allClientUserListCount = clientUserList?.count;
  const selected_id = clientUserList?.selected_id;
  const clientUserListLoading = useSelector(
    (state:any) => state.group.clientUserListLoading
  );
  const usersListWithGroupIdLoading = useSelector(
    (state:any) => state.group.usersListWithGroupIdLoading
  );
  const addUsersToGroupLoader = useSelector(
    (state:any) => state.group.addUsersToGroupLoader
  );

  const [userListFilterData, setUserListFilterData] = useState({
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'ASC', //DESC
    search: '',
  });
  const [clientUserListFilterData, setclientUserListFilterData] = useState({
    limit: 10,
    page: 1,
    sort: 'createdAt',
    order: 'ASC',
    search: '',
  });

  useEffect(() => {
    if (currentGroup) {
      dispatch(authSlice.actions.setTopBatTitle(currentGroup.name));
      // dispatch(
      //   groupSlice.actions.getGroupWellBeing({
      //     period: today,
      //     group_id: parseInt(currentGroup.id),
      //     timezone: timeZone,
      //   })
      // );
      dispatch(
        groupSlice.actions.getGropwiseEnergeState({
          period: userEnergyStateDate,
          energy_level: energyLevel,
          group_id: parseInt(currentGroup.id),
          timezone: timeZone,
        })
      );
      if (currentGroup?.group_manager_id === user.id) {
        // getUserList(clientUserListFilterData);
        dispatch(
          groupSlice.actions.getUserListByClientGroupId({
            ...userListFilterData,
            client_id: parseInt(currentGroup.id),
          })
        );
      } else {
        dispatch(
          groupSlice.actions.getUsersListWithGroupId({
            ...userListFilterData,
            group_id: parseInt(currentGroup?.group_manager_id),
          })
        );
      }
      setFormicValues();
    }
  }, [currentGroup, userListFilterData]);

  useEffect(() => {
    if (currentGroup) {
    dispatch(
      groupSlice.actions.getGroupWellBeing({
        period: today,
        group_id: parseInt(currentGroup.id),
        timezone: timeZone,
        is_average: isAverage
      })
    );
    }
  }, [isAverage,currentGroup, userListFilterData]);

  const setFormicValues = () => {
    formikRef?.current?.setValues({
      name: currentGroup.name,
      email: currentGroup.group_manager.email,
      firstName: currentGroup.group_manager.firstName,
      lastName: currentGroup.group_manager.lastName,
      numberOfUsers: currentGroup?.total_users,
      totalAssessment: currentGroup?.total_assessment,
    });
  };

  useEffect(() => {
    if (
      groupWellBeing &&
      groupWellBeing.wellBeing &&
      groupWellBeing.wellBeing.total_users_taken_assessment
    ) {
      const wellBeing = groupWellBeing.wellBeing;
      const dataSet = [
        {
          x: 1,
          y: wellBeing?.total_ei_score ,
          color: wellBeing?.total_ei_score<=30 ? '#DF8B6E':
          (wellBeing?.total_ei_score>30 && wellBeing?.total_ei_score<=60)?'#F1E47C':
          (wellBeing?.total_ei_score>60 && wellBeing?.total_ei_score<=100)?'#92BF73':
          '',
          // label: eiLabel,
          count: `${Math.floor(
            wellBeing?.total_ei_score
          )} clients`,
        },
 
      ];
      setGroupWellbeingDataGraph(dataSet);
    } else {
      setGroupWellbeingDataGraph([]);
    }
  }, [groupWellBeing]);
  useEffect(() => {
    if (userEnergyState) {
      setUserEnergyStateInformation(userEnergyState);
    } else {
      setUserEnergyStateInformation([]);
    }
  }, [userEnergyState]);

  const handleUserWellBeing = (date: string) => {
    setUserWellBeingDate(date);
    dispatch(
      groupSlice.actions.getGroupWellBeing({
        period: date,
        group_id: parseInt(currentGroup.id),
        timezone: timeZone,
        is_average: false
      })
    );
  };

  const handleUserEnergyState = (period: string) => {
    if (period !== 'custom') {
      setUserEnergyStateDate(period);
      dispatch(
        groupSlice.actions.getGropwiseEnergeState({
          period,
          energy_level: energyLevel,
          group_id: parseInt(currentGroup.id),
          timezone: timeZone,
        })
      );
    } else {
      setEnergyStateCustomMonthModalView(true);
    }
  };

  const handleUserEnergyStateCustomDate = (period: any) => {
    setEnergyStateCustomMonthModalView(false);
    const month_year =
      period.year + '-' + (period.key < 10 ? '0' + period.key : period.key);
    setEnergyStateSelectedDate(period.year + '-' + period.month);
    setUserEnergyStateDate(month_year);
    dispatch(
      groupSlice.actions.getGropwiseEnergeState({
        period: month_year,
        energy_level: energyLevel,
        group_id: parseInt(currentGroup.id),
        timezone: timeZone,
      })
    );
  };

  const handleEnergyLevel = (energyLevel: string) => {
    setEnergyLevel(energyLevel);
    dispatch(
      groupSlice.actions.getGropwiseEnergeState({
        period: userEnergyStateDate,
        energy_level: energyLevel,
        group_id: parseInt(currentGroup.id),
        timezone: timeZone,
      })
    );
  };

  const handleChange=(event:any)=>{
    setScore(event.target.value)
    setIsAverage(!isAverage)
  }

  const [typingTimeout, setTypingTimeout] = useState(0);
  const search = async (value: string) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      window.setTimeout(() => {
        goToSearch(value);
      }, 1000)
    );
  };
  const goToSearch = async (value: string) => {
    let args = { ...clientUserListFilterData, search: value };
    setclientUserListFilterData(args);
    getUserList(args);
  };
  const getUserList = (args: any) => {
    dispatch(
      groupSlice.actions.getClientUserList({
        ...args,
        group_id: parseInt(currentGroup.id),
      })
    );
  };

  const handleCheckboxItem = (event: any, id: any) => {
    if (event.target.checked) {
      setSelectedUser((oldArray: any) => [...oldArray, parseInt(id)]);
    } else {
      const filteredAry = selectedUser.filter((e: any) => e !== parseInt(id));
      setSelectedUser(filteredAry);
      setTableHeadCheckAll(false);
    }
  };
  const handleCheckboxSelectAll = (event:any) => {
    if (event.target.checked) {
      const idArrray:any = [];
      allclientUserList &&
        allclientUserList.length &&
        allclientUserList.forEach((item: any) => {
          if (!selectedUser.includes(Number(item.id))) {
            idArrray.push(parseInt(item.id));
          }
        });
      setSelectedUser((oldArray:any) => [...oldArray, ...idArrray]);
      setTableHeadCheckAll(true);
    } else {
      const idArrray:any = [];
      allclientUserList &&
        allclientUserList.length &&
        allclientUserList.forEach((item: any) => {
          idArrray.push(parseInt(item.id));
        });
      const removeUserIdList = selectedUser.filter(
        (item:any) => !idArrray.includes(item)
      );
      setSelectedUser(removeUserIdList);
      setTableHeadCheckAll(false);
    }
  };

  const [oldSelectedUser, setOldSelectedUsers]: any = useState([]);
  const [tableHeadCheckAll, setTableHeadCheckAll] = useState(false);
  const firstUpdate = useRef(true);
  const currentGroupRef = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    } else {
      const removeUserIdList = oldSelectedUser.filter(
        (item:any) => !selectedUser.includes(item)
      );
      const uniqRemoveUserIdList = [...new Set(removeUserIdList)];
      if (
        allclientUserList &&
        allclientUserList.length &&
        selected_id &&
        selected_id.length
      ) {
        const selected: any = [];
        allclientUserList.map((item: any) => {
          if (
            selected_id?.includes(item.id) &&
            !uniqRemoveUserIdList.includes(parseInt(item.id))
          ) {
            selected.push(parseInt(item.id));
          }
        });
        selected?.length === 10
          ? setTableHeadCheckAll(true)
          : setTableHeadCheckAll(false);
        setSelectedUser((oldArray: any) => {
          let val = [...oldArray, ...selected];
          return [...new Set(val)];
        });
        setOldSelectedUsers((oldArray: any) => {
          let value = [...oldArray, ...selected];
          return [...new Set(value)];
        });
      }
    }
  }, [allclientUserList, selected_id]);

  useEffect(() => {
    const newUsersIdList = selectedUser.filter(
      (item:any) => !oldSelectedUser.includes(item)
    );
    const removeUserIdList = oldSelectedUser.filter(
      (item:any) => !selectedUser.includes(item)
    );
  }, [selectedUser]);

  const addUsersToGroup = () => {
    const newUsersIdList = selectedUser.filter(
      (item:any) => !oldSelectedUser.includes(item)
    );
    const removeUserIdList = oldSelectedUser.filter(
      (item:any) => !selectedUser.includes(item)
    );
    setSelectedUser(
      selectedUser.filter((item:any) => !removeUserIdList.includes(item))
    );
    setOldSelectedUsers(
      oldSelectedUser.filter((item:any) => !removeUserIdList.includes(item))
    );
    const user_id: any = [...new Set(newUsersIdList)];
    const removed_user_id: any = [...new Set(removeUserIdList)];
    dispatch(
      groupSlice.actions.addUsersToGroup({
        group_id: parseInt(currentGroup.id),
        user_id,
        removed_user_id,
      })
    );
  };
  useEffect(() => {
    if (currentGroupRef.current) {
      firstUpdate.current = false;
      return;
    }
    {
      let args = { ...clientUserListFilterData, search: '' };
      getUserList(args);
      setclientUserListFilterData(args);
    }
  }, [currentGroup]);

  const handlePageChange = (event:any) => {
    const selected = event.selected;
    const arg = { ...userListFilterData, page: selected + 1 };
    setUserListFilterData(arg);
    if (currentGroup?.group_manager_id === user.id) {
      dispatch(
        groupSlice.actions.getUserListByClientGroupId({
          ...arg,
          client_id: parseInt(currentGroup.id),
        })
      );
    } else {
      dispatch(
        groupSlice.actions.getUsersListWithGroupId({
          ...arg,
          group_id: parseInt(currentGroup?.group_manager_id),
        })
      );
    }
  };
  const handlePageChangeUser = (event:any) => {
    const selected = event.selected;
    const arg = { ...clientUserListFilterData, page: selected + 1 };
    setclientUserListFilterData(arg);
    getUserList(arg);
  };
  const handleBlockGroup = () => {
    if (currentGroup?.status !== 'blocked') {
      dispatch(
        groupSlice.actions.blockGroup({
          doc_id: currentGroup?.doc_id,
          status: 'blocked',
        })
      );
    } else {
      dispatch(
        groupSlice.actions.blockGroup({
          doc_id: currentGroup?.doc_id,
          status: 'active',
        })
      );
    }

    setIsBlockOpen(false);
  };

  const handleEditGroupDetails = (values:any) => {
    dispatch(
      groupSlice.actions.updateGroup({
        name: values.name,
        firstName: values.firstName?.replace(/\s+$/, ''),
        lastName: values.lastName?.replace(/\s+$/, ''),
        email: values.email,
        userLimit: parseInt(currentGroup?.userLimit),
        isGroupManagerInvite: true,
        doc_id: currentGroup?.doc_id,
        old_email: currentGroup?.group_manager?.email,
      })
    );
    setIsEditOpen(false);
  };

  const handleSort = (value: string) => {
    const order =
      userListFilterData.sort === value
        ? userListFilterData.order === 'DESC'
          ? 'ASC'
          : 'DESC'
        : 'ASC';
    let args = { ...userListFilterData, sort: value, order };
    setUserListFilterData(args);
  };
  return (
    <>
      {currentGroup && (
        <div className='overflow-x-hidden px-6'>
          <div className='flex justify-between'>
            <h2
              className='md:font-serif text-lg font-medium mx-3 mb-3'
              style={{ color: '#161F38' }}
            >
              Group Manager Info
            </h2>
            {/* <EditBlock
              onEditSelected={() => setIsEditOpen(true)}
              onBlockSelected={() => setIsBlockOpen(true)}
              index={'asasas'}
            /> */}
          </div>
          {currentGroup?.group_manager_id !== user.id ? (
            <div className='card-shadow  rounded-lg p-4 mt-4'>
              <div className=' '>
                <Formik
                  innerRef={formikRef}
                  initialValues={initialValues}
                  onSubmit={handleEditGroupDetails}
                  validationSchema={() => groupManagerEditValidation}
                >
                  {({ handleSubmit, values }) => (
                    <div>
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                        <div>
                          <InputBox
                            name='name'
                            label='Group Name'
                            placeholder=''
                            value={values.name}
                            disabled={!isEditOpen}
                            isColorChange={true}
                          />
                        </div>
                        <div>
                          <InputBox
                            name='firstName'
                            label='First Name'
                            placeholder=''
                            value={values.firstName}
                            disabled={!isEditOpen}
                            isColorChange={true}
                          />
                        </div>
                        <div>
                          <InputBox
                            name='lastName'
                            label='Last Name'
                            placeholder=''
                            value={values.lastName}
                            disabled={!isEditOpen}
                            isColorChange={true}
                          />
                        </div>
                        <div>
                          <InputBox
                            name='email'
                            label='Email'
                            placeholder=''
                            value={values.email}
                            disabled={true}
                            isColorChange={!isEditOpen}
                          />
                        </div>
                        <div>
                          <InputBox
                            name='numberOfUsers'
                            label='Number of Users'
                            placeholder=''
                            value={values.numberOfUsers}
                            disabled={true}
                            isColorChange={!isEditOpen}
                          />
                        </div>
                        <div>
                          <InputBox
                            name='totalAssessment'
                            label='Total Assessment'
                            placeholder=''
                            value={values.totalAssessment}
                            disabled={true}
                            isColorChange={!isEditOpen}
                          />
                        </div>
                      </div>
                      {isEditOpen && (
                        <div className='mt-4 flex md:flex-row md:justify-end items-center justify-center flex-col gap-4'>
                          <Button
                            onPress={() => {
                              setFormicValues();
                              setIsEditOpen(false);
                            }}
                            value={`Cancel`}
                            bgColor={`#FFF`}
                            borderColor={`#A6A9B4`}
                            color={`#A6A9B4`}
                            disabled={false}
                          />
                          <Button
                            type='submit'
                            onPress={() => handleSubmit()}
                            value={`Save`}
                            bgColor={`#3A9EA5`}
                            borderColor={`#3A9EA5`}
                            color={`#fff`}
                            disabled={false}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <div
              className='border-2 shadow-xl rounded-lg items-center pt-3'
              style={{ minHeight: '93px' }}
            >
              <div className='grid grid-cols-1 md:grid-cols-3 md:pb-0 pb-4'>
                <LableWithValue
                  title='Number of Users'
                  value={currentGroup?.total_users}
                />
                <LableWithValue
                  title='Total Assessment'
                  value={currentGroup?.total_assessment}
                />
                {!addUsersToGroupModal ? (
                  <div className='flex md:justify-end justify-center items-center mr-3'>
                    <Button
                      buttonWidth='11rem'
                      value={`Add/Edit Users`}
                      onPress={handleAddGroupModal}
                      icon={Add}
                      disabled={false}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {addUsersToGroupModal && (
                <div className='mx-5'>
                  <div className='md:w-1/3 w-full flex items-end my-4'>
                    <SearchBox
                      onChange={(e:any) => search(e.target.value)}
                      placeholder='Search Here...'
                    />
                  </div>
                  <div className='overflow-x-auto border-user rounded-xl'>
                    <div className='inline-block min-w-full rounded-lg overflow-hidden'>
                      <table className='min-w-full leading-normal myborder'>
                        <thead style={{ minWidth: 800, width: '100%' }}>
                          <tr
                            style={{ backgroundColor: '#F5F7FF' }}
                            className='flex justify-between border-b-2 border-gray-200'
                          >
                            <th
                              style={{ width: 50 }}
                              className='py-3 text-center text-xs font-semibold text-gray-700  tracking-wider'
                            >
                              <input
                                checked={tableHeadCheckAll}
                                type='checkbox'
                                className='cursor-pointer relative w-4 h-4 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none'
                                onChange={(e) => handleCheckboxSelectAll(e)}
                              />
                            </th>
                            <th
                              style={{ width: 100 }}
                              className='py-3 text-left text-xs font-semibold text-gray-700  tracking-wider'
                            >
                              Sl No
                            </th>
                            <th
                              style={{ width: 200 }}
                              className='py-3 text-left text-xs font-semibold text-gray-700  tracking-wider'
                            >
                              <div className='flex items-center justify-between'>
                                <span>First Name</span>
                                {/* <img className='mr-4' src={sort} alt='' /> */}
                              </div>
                            </th>
                            <th
                              style={{ width: 200 }}
                              className='py-3 text-left text-xs font-semibold text-gray-700  tracking-wider'
                            >
                              <div className='flex items-center justify-between'>
                                <span>Last Name</span>
                                {/* <img className='mr-4' src={sort} alt='' /> */}
                              </div>
                            </th>
                            <th
                              style={{ width: 250 }}
                              className='py-3 text-left text-xs font-semibold text-gray-700  tracking-wider'
                            >
                              <div className='flex items-center justify-between'>
                                <span>Email</span>
                                {/* <img className='mr-4' src={sort} alt='' /> */}
                              </div>
                            </th>
                            <th
                              style={{ width: 300 }}
                              className='py-3 text-left text-xs font-semibold text-gray-700  tracking-wider'
                            >
                              <div className='flex items-center justify-between'>
                                <span>Group Name</span>
                                {/* <img className='mr-4' src={sort} alt='' /> */}
                              </div>
                            </th>
                          </tr>
                        </thead>

                        {!clientUserListLoading ? (
                          <tbody style={{ minWidth: 800, width: '100%' }}>
                            {!!allclientUserList &&
                            !!allclientUserList.length ? (
                              allclientUserList.map((item: any, index: any) => (
                                <tr
                                  key={index}
                                  className={`${
                                    index % 2 ? 'secondcolor' : 'bg-white'
                                  } flex justify-between`}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <td
                                    style={{ width: 50 }}
                                    className='py-3  text-sm card-text-primary text-center flex justify-center'
                                  >
                                    <input
                                      checked={selectedUser.includes(
                                        parseInt(item.id)
                                      )}
                                      type='checkbox'
                                      className='cursor-pointer relative w-4 h-4 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none'
                                      onChange={(e) =>
                                        handleCheckboxItem(e, item.id)
                                      }
                                    />
                                  </td>
                                  <td
                                    style={{ width: 100 }}
                                    className='py-3  text-sm card-text-primary float-left truncate '
                                  >
                                    <div className='flex'>
                                      {index +
                                        1 +
                                        (clientUserListFilterData.page - 1) *
                                          clientUserListFilterData.limit}
                                    </div>
                                  </td>
                                  <td
                                    style={{ width: 200 }}
                                    className='py-3 capitalize text-sm card-text-primary float-left truncate '
                                  >
                                    {item.firstName}
                                  </td>
                                  <td
                                    style={{ width: 200 }}
                                    className='py-3 capitalize text-sm card-text-primary float-left truncate '
                                  >
                                    {item.lastName}
                                  </td>
                                  <td
                                    style={{ width: 250 }}
                                    className='py-3  text-sm card-text-primary float-left truncate'
                                  >
                                    {item.email}
                                  </td>
                                  <td
                                    style={{ width: 300 }}
                                    className='py-3 capitalize text-left float-left text-sm card-text-primary truncate'
                                  >
                                    {!!item.group_details &&
                                      !!item.group_details.length && (
                                        <div className='flex'>
                                          <div className='flex'>
                                            {item.group_details.map(
                                              (grp: any, i: any) => (
                                                <div className=''>
                                                  {i === 0 && (
                                                    <div className=''>
                                                      {grp?.groups?.name}
                                                    </div>
                                                  )}
                                                  {i === 1 && (
                                                    <div className='ml-2 border-l'>
                                                      <div className='ml-2'>
                                                        {grp?.groups?.name}
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                          {item.group_details.length - 2 >
                                            0 && (
                                            <GroupNameTooltip
                                              number={
                                                item.group_details.length - 2
                                              }
                                              index={index}
                                              names={item.group_details.map(
                                                (i: any) => i?.groups?.name
                                              )}
                                            />
                                          )}
                                        </div>
                                      )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <div className='border-user rounded-xl justify-center flex-col py-20'>
                                <div className='justify-center flex'>
                                  <img src={NoData} alt='' />
                                </div>
                                <div className='justify-center flex'>
                                  <span className='text-xl'>No Data Found</span>
                                </div>
                              </div>
                            )}
                          </tbody>
                        ) : (
                          <TableLoader />
                        )}
                      </table>
                    </div>
                  </div>
                  <div className='mx-5'>
                    {' '}
                    <Paginate
                      pageCount={allClientUserListCount / 10}
                      handlePageChange={handlePageChangeUser}
                      page={clientUserListFilterData.page}
                      limit={clientUserListFilterData.limit}
                      totalCount={allClientUserListCount}
                    />
                  </div>
                  <div className='flex justify-center gap-5 md:justify-end mb-5 md:mr-5 mr-0'>
                    <div className='flex justify-center'>
                      <Button
                        bgColor='#fff'
                        borderColor='#A6A9B4'
                        color='#A6A9B4'
                        value={`Cancel`}
                        onPress={handleAddGroupModalClose}
                        buttonWidth={'123px'}
                        buttonHeight={'38px'}
                        fontWeight={'500'}
                        fontSize={'14px'}
                      />
                    </div>
                    <div className='flex justify-center'>
                      <Button
                        onPress={addUsersToGroup}
                        value={
                          addUsersToGroupLoader ? <ButtonLoader /> : 'Update'
                        }
                        disabled={false}
                        buttonWidth={'123px'}
                        buttonHeight={'38px'}
                        fontWeight={'500'}
                        fontSize={'14px'}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className='md:flex gap-3 w-full mb-32 mt-10'>
            <div className='w-full md:w-1/3'>
              <SubHeading title={`All Users - Emotional Intelligence `} />
              <div className='card-shadow p-4 mt-4 h-full'>
              <div className="flex ">
              <label className={`flex h-8 custom-radio-input items-center cursor-pointer mr-8 ${score === "dailyScore"?'radio-color':'radio-uncheck-color'} `}>
                <input type="radio" 
                  value="dailyScore" 
                  name="score" 
                  checked={score === "dailyScore"}
                  className='mr-3 radio-daily'
                  onChange={handleChange}/> <p>Daily Score</p>
                  </label>
                  <label className={`flex h-8 items-center cursor-pointer mr-8 ${score === "averageScore"?'radio-color':'radio-uncheck-color'} `}>
                <input 
                  type="radio" 
                  value="averageScore" 
                  name="score"
                  className='mr-3 radio-daily'
                  checked={score === "averageScore"}
                  onChange={handleChange}/> <p>Average Score</p>
                  </label>
              </div>
              { score==="dailyScore"&&
                <VibeonixDatePicker
                  name=''
                  format='MMMM dd, yyyy'
                  onFieldChange={(e:any) =>
                    handleUserWellBeing(
                      `${new Date(e).getFullYear()}/${
                        new Date(e).getMonth() + 1
                      }/${new Date(e).getDate()}`
                    )
                  }
                  selectedDate={new Date(userWellBeingDate)}
                  width={'100%'}
                />}

                {groupWellBeingLoader ? (
                  <div className='flex w-full h-full items-center justify-center'>
                    <ReactLoading type='spinningBubbles' color='#3A9EA5' />
                  </div>
                ) : (
                  <>
                    <div className='mt-3'>
                      {groupWellbeingDataGraph.length ? (
                        <VictoryDonutChart
                          data={groupWellbeingDataGraph}
                          bottomLabels={[
                            { color: '#92BF73', label: 'High (61-100)' },
                            { color: '#F1E47C', label: 'Medium (31-60)' },
                            { color: '#DF8B6E', label: 'Low (0-30)' },
                          ]}
                          centerLabel={
                            groupWellBeing &&
                            groupWellBeing.wellBeing &&
                            groupWellBeing.wellBeing
                              .total_ei_score
                          }
                          value={'EI Score'}
                          bottomRightLabel={ groupWellBeing?.wellBeing
                            ?.total_users_taken_assessment}
                          bottomValue={'User'}
                          isAverage={isAverage}
                          isWellBeing={true}
                        />
                      ) : (
                        <div className='border-user rounded-xl justify-center flex-col py-20'>
                          <div className='justify-center flex'>
                            <img src={NoData} alt='' />
                          </div>
                          <div className='justify-center flex'>
                            <span className='text-xl'>No Data Found</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='w-full md:w-4/6'>
              <SubHeading title={'All Users - Energy State'} />
              <div className='card-shadow p-4 mt-4 h-full'>
                <div className='md:flex md:justify-between md:w-full md:gap-3 mt-3'>
                  <div className='w-full border-b'>
                    <DropDownPeriodAdndEnergy
                      options={[
                        { label: 'Today', value: 'today' },
                        { label: 'Last Week', value: 'week' },
                        { label: 'Last Month', value: 'month' },
                        { label: 'Select Month', value: 'custom' },
                      ]}
                      onFieldChange={(e: any) => {
                        handleUserEnergyState(e.value);
                      }}
                      label={`Date`}
                      placeholder={`Last Week`}
                      selectedDate={energyStateSelectedDate}
                    />
                  </div>
                  <div className='w-full border-b'>
                    <DropDownPeriodAdndEnergy
                      options={[
                        { label: 'Positive Energy', value: 'high' },
                        { label: 'Negative Energy', value: 'low' },
                      ]}
                      onFieldChange={(e:any) => {
                        handleEnergyLevel(e.value);
                      }}
                      label={`Energy State`}
                      placeholder={`Positive Energy`}
                    />
                  </div>
                </div>
                {userEnergyStateLoader ? (
                  <div className='flex w-full h-full items-center justify-center'>
                    <ReactLoading type='spinningBubbles' color='#3A9EA5' />
                  </div>
                ) : Object.keys(userEnergyStateInformation).length > 0 ? (
                  <Graph data={userEnergyStateInformation} height={500} />
                ) : (
                  <div className='border-user rounded-xl justify-center flex-col py-20'>
                    <div className='justify-center flex'>
                      <img src={NoData} alt='' />
                    </div>
                    <div className='justify-center flex'>
                      <span className='text-xl'>No Data Found</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!!allGroupslist && !!allGroupslist.length && (
            <div className='mx-auto'>
              <SubHeading title={`Users`} />
              <div className='overflow-x-hidden relative mt-4'>
                <div className='overflow-x-auto border-user rounded-xl'>
                  <div className='inline-block min-w-full rounded-lg overflow-hidden'>
                    <table className='min-w-full leading-normal myborder'>
                      <thead style={{ minWidth: 1300, width: '100%' }}>
                        <tr
                          style={{ backgroundColor: '#F5F7FF' }}
                          className='flex justify-between'
                        >
                          <th
                            style={{ width: 100 }}
                            className='py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-700  tracking-wider'
                          >
                            Sl No
                          </th>
                          <th
                            style={{ width: 200 }}
                            className='py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700  tracking-wider'
                          >
                            <div className='flex items-center justify-between'>
                              <span>First Name</span>
                              <img
                                className='cursor-pointer pr-5'
                                onClick={() => handleSort('firstName')}
                                src={sort}
                                alt=''
                              />
                            </div>
                          </th>
                          <th
                            style={{ width: 200 }}
                            className='py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700  tracking-wider'
                          >
                            <div className='flex items-center justify-between'>
                              <span>Last Name</span>
                              <img
                                className='cursor-pointer pr-5'
                                onClick={() => handleSort('lastName')}
                                src={sort}
                                alt=''
                              />
                            </div>
                          </th>
                          <th
                            style={{ width: 250 }}
                            className='py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700  tracking-wider'
                          >
                            <div className='flex items-center justify-between'>
                              <span>Email</span>
                              <img
                                className='cursor-pointer pr-5'
                                onClick={() => handleSort('email')}
                                src={sort}
                                alt=''
                              />
                            </div>
                          </th>
                          <th
                            style={{ width: 150 }}
                            className='py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700  tracking-wider'
                          >
                            <div className='flex items-center justify-between'>
                              <span>Created On</span>
                            </div>
                          </th>
                          <th
                            style={{ width: 250 }}
                            className='py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700  tracking-wider'
                          >
                            <div className='flex items-center justify-between'>
                              <span>Last Assessment</span>
                            </div>
                          </th>
                          <th
                            style={{ width: 150 }}
                            className='py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-700  tracking-wider'
                          >
                            <div className='flex items-center justify-between'>
                              <span>Status</span>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      {!usersListWithGroupIdLoading ? (
                        <tbody style={{ minWidth: 1300, width: '100%' }}>
                          {!!allGroupslist &&
                            !!allGroupslist.length &&
                            allGroupslist.map((item: any, index: any) => (
                              <tr
                                key={index}
                                className={`${
                                  index % 2 ? 'secondcolor' : 'bg-white'
                                } flex justify-between`}
                                style={{ cursor: 'pointer' }}
                              >
                                <td
                                  style={{ width: 100 }}
                                  className='py-3  text-sm card-text-primary text-center flex justify-center'
                                >
                                  <div className='flex'>
                                    {' '}
                                    {index +
                                      1 +
                                      (userListFilterData.page - 1) *
                                        userListFilterData.limit}
                                  </div>
                                </td>
                                <td
                                  style={{ width: 200 }}
                                  className='py-3 capitalize text-sm card-text-primary float-left truncate'
                                >
                                  {item.firstName}
                                </td>
                                <td
                                  style={{ width: 200 }}
                                  className='py-3 capitalize text-sm card-text-primary float-left truncate'
                                >
                                  {item.lastName}
                                </td>
                                <td
                                  style={{ width: 250 }}
                                  className='py-3 text-sm card-text-primary float-left truncate'
                                >
                                  {item.email}
                                </td>

                                <td
                                  style={{ width: 150 }}
                                  className='py-3 text-sm card-text-primary float-left truncate'
                                >
                                  {moment(item?.createdAt).format('ll')}
                                </td>
                                <td
                                  style={{ width: 250 }}
                                  className='py-3 text-sm card-text-primary float-left truncate'
                                >
                                  {!!item.assessments &&
                                    !!item.assessments.length &&
                                    moment(
                                      item.assessments[0].createdAt
                                    ).format('MMMM DD, YYYY, h:mm A')}
                                </td>
                                <td
                                  style={{ width: 150 }}
                                  className='py-3  text-sm card-text-primary'
                                >
                                  <span className='relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight'>
                                    <span
                                      aria-hidden
                                      className={`${
                                        item.status === 'active'
                                          ? 'active-bg'
                                          : item.status === 'blocked'
                                          ? 'blocked-bg'
                                          : 'pending-bg'
                                      } absolute inset-0  rounded-full`}
                                    ></span>
                                    <span
                                      className={`${
                                        item.status === 'active'
                                          ? 'active-bg'
                                          : item.status === 'blocked'
                                          ? 'blocked-bg'
                                          : 'pending-bg'
                                      } relative capitalize text-xs`}
                                    >
                                      {item.status}
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <TableLoader />
                      )}
                    </table>
                  </div>
                </div>

                {/* <div className="flex justify-between mt-4 mb-20">
                  <div className="text-secondary">
                  </div>
                  <div className="flex">
                    <img src={PaginationLeft} alt="" />
                    <div className="mx-2">
                      <span className="mx-2">{userListFilterData.page}</span>
                      <span className="mx-2">{userListFilterData.page + 1}</span>
                      <span className="mx-2">{userListFilterData.page + 2}</span>
                    </div>

                    <img src={PaginationRight} alt="" />
                  </div>
                </div> */}
                <Paginate
                  pageCount={allGroupslistCount / 10}
                  handlePageChange={handlePageChange}
                  page={userListFilterData.page}
                  limit={userListFilterData.limit}
                  totalCount={allGroupslistCount}
                />
              </div>
            </div>
          )}

          {!!allGroupslist && !allGroupslist.length && (
            <div className='border-user rounded-xl justify-center flex-col py-20'>
              <div className='justify-center flex'>
                <img src={NoData} alt='' />
              </div>
              <div className='justify-center flex'>
                <span className='text-xl'>No Data Found</span>
              </div>
            </div>
          )}
        </div>
      )}
      {isBlockOpen && (
        <UserBlockModal
          hasModalView={isBlockOpen}
          closeModal={() => setIsBlockOpen(false)}
          onPress={handleBlockGroup}
          title={`Do you want to ${
            currentGroup?.status !== 'blocked' ? 'block' : 'unblock'
          } ?`}
          confirmText={`Are you sure you want to ${
            currentGroup?.status !== 'blocked' ? 'block' : 'unblock'
          } this group?`}
          buttonText={`${
            currentGroup?.status !== 'blocked' ? 'block' : 'unblock'
          }`}
        />
      )}
      <MonthPickerModal
        hasModalView={isEnergyStateCustomMonthModalView}
        closeModal={() => {
          setEnergyStateCustomMonthModalView(false);
        }}
        buttonTextOne='Cancel'
        buttonTextTwo='OK'
        onPress={(val: any) => {
          handleUserEnergyStateCustomDate(val);
        }}
      />
    </>
  );
}
export default DetailedGroups;
