import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PageLoader from '../../components/Loader/PageLoader';
const TermsAndCondition = lazy(() => import('../TermsAndCondition/TermsAndCondition'));

const TermsAndConditionLayout = () => {
    return (
        <section className="flex w-full bg-primary">
            <div className="w-full overflow-x-hidden items-center flex-col justify-center">
                <Suspense fallback={<PageLoader />}>
                    <Switch>
                        <Route exact path="/terms_and_condition" >
                            <TermsAndCondition />
                        </Route>
                    </Switch>
                </Suspense>
            </div>
        </section>
    )
}

export default TermsAndConditionLayout;