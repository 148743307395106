/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateUser,
  CreateClientAdmin,
  EmailInterface,
  GroupAdminSignUp,
  CheckEmail,
  IndividualUserSignup,
} from '../../interface/users';

type InitialState = {
  showSideNav:boolean,
  individualUser:
    | {
        firstName: string;
        lastName: string;
        birthYear: string;
        gender: string;
        phoneNumber?: number;
        email: string;
      }
    | any;
  individualUserLoader: boolean;
  currentUserEmail: any;
  authenticated: boolean;
  initialAuthentcationRequest: boolean;
  user:
    | {
        id: string;
        firstName: string;
        lastName: string;
        role: string;
        email: string;
      }
    | any;
  clientRegister: CreateClientAdmin | any;
  topBatTitle: string;
  sideNavSelector: string;
  groupRegister: { firstName: string; lastName: string; email: string };
  userRegister: { firstName: string; lastName: string; email: string };
  clientSignUp:
    | {
        firstName: string;
        lastName: string;
      }
    | any;
  changePasswordLoader: boolean;
  forgotPasswordEmail: string;
  forgotPasswordLoading: boolean;
  loginLoading: boolean;
  forgotPasswordUserRole: string;
  email: boolean;
  clientRegisterLoader: boolean;
  organizationDetailsLoader: boolean;
  groupAdminSignUpLoader: boolean;
  individualUserPlanDetails: any;
  subscriptionDetail: any
};

const initialState: InitialState = {
  subscriptionDetail: undefined,
  individualUser: undefined,
  individualUserLoader: false,
  individualUserPlanDetails: undefined,
  currentUserEmail: '',
  user: undefined,
  authenticated: false,
  initialAuthentcationRequest: false,
  clientRegister: undefined,
  topBatTitle: 'dashboard',
  groupRegister: { firstName: '', lastName: '', email: '' },
  userRegister: { firstName: '', lastName: '', email: '' },
  sideNavSelector: 'groups',
  clientSignUp: undefined,
  changePasswordLoader: false,
  forgotPasswordEmail: '',
  forgotPasswordLoading: false,
  loginLoading: false,
  forgotPasswordUserRole: 'user',
  email: false,
  clientRegisterLoader: false,
  organizationDetailsLoader: false,
  groupAdminSignUpLoader: false,
  showSideNav:false

};

export const checkauth = (state: InitialState) => state;

// eslint-disable-next-line no-unused-vars
export const login = (
  state: InitialState,
  action: PayloadAction<{ password: string; email: string }>
) => {
  state.loginLoading = true;
};
export const setlogin = (state: InitialState, action: PayloadAction<any>) => {
  state.loginLoading = false;
};

export const setSideNav = (state:any,action:any) => {
  state.showSideNav = action.payload
}

export const logout = (state: InitialState) => state;

export const setAuthenticatedUser = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.user = action.payload;
  state.authenticated = true;
  state.initialAuthentcationRequest = true;
};

export const setAuthentication = (
  state: InitialState,
  action: PayloadAction<boolean>
) => {
  state.authenticated = action.payload;
  state.initialAuthentcationRequest = true;
};

export const clearAuthentication = (
  state: InitialState,
  action: PayloadAction<void>
) => {
  state.authenticated = false;
  state.initialAuthentcationRequest = true;
  state.user = undefined;
};

const userSignUp = (state: any, action: PayloadAction<CreateUser>) => state;
export const setUserData = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.user = action.payload;
};

const individualUserSignup = (
  state: InitialState,
  action: PayloadAction<IndividualUserSignup>
) => {
  state.individualUser = {
    firstName: action?.payload?.firstName,
    lastName: action?.payload?.lastName,
    birthYear: action?.payload?.birthYear,
    gender: action?.payload?.gender,
    phoneNumber: action?.payload?.phoneNumber,
    email: action?.payload?.email,
  };
};

const individualUserSignupLoader = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.individualUserLoader = action.payload;
};

const getIndividualUserPlans = (state: InitialState) => state;

const setIndividualUserPlans = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.individualUserPlanDetails = action.payload;
};

const individualUserAddCard = (
  state: InitialState,
  action: PayloadAction<any>
) => state;

const resendEmail = (state: InitialState, action: PayloadAction<any>) => state;

const setClientRegister = (
  state: any,
  action: PayloadAction<CreateClientAdmin>
) => {
  state.clientRegister = action.payload;
  state.clientRegisterLoader = true;
};
const setClientRegisterLoader = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.clientRegisterLoader = action.payload;
};
const organizationDetails = (state: InitialState, action: any) => {
  state.organizationDetailsLoader = true;
};
const setOrganizationDetailsLoader = (state: InitialState, action: any) => {
  state.organizationDetailsLoader = false;
};
const sendVerificationEmail = (
  state: InitialState,
  action: PayloadAction<EmailInterface>
) => state;
const sendEmailToVerify = (
  state: InitialState,
  action: PayloadAction<EmailInterface>
) => {
  state.currentUserEmail = action.payload;
};

const setCurrentUserEmail = (state: InitialState, action: PayloadAction<any>) => {
  state.currentUserEmail = action.payload;
}

const groupAdminSignUp = (
  state: InitialState,
  action: PayloadAction<GroupAdminSignUp>
) => {
  state.groupAdminSignUpLoader = true;
};
const setGroupAdminSignUp = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.groupAdminSignUpLoader = false;
};

const setTopBatTitle = (state: InitialState, action: PayloadAction<any>) => {
  state.topBatTitle = action.payload;
};
const setUserRegDetails = (state: InitialState, action: any) => {
  state.groupRegister = action.payload;
};
const setSideNavSelector = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.sideNavSelector = action.payload;
};

const setSignUpInitialValue = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.clientSignUp = action.payload;
};
const setgroupRegisterInitValue = (
  state: InitialState,
  action: PayloadAction<{ firstName: string; lastName: string; email: string }>
) => {
  state.groupRegister = action.payload;
};
const setUserRegisterInitValue = (
  state: InitialState,
  action: PayloadAction<{ firstName: string; lastName: string; email: string }>
) => {
  state.userRegister = action.payload;
};

const changePassword = (
  state: InitialState,
  action: PayloadAction<{ email: string; password: string; role: string }>
) => {
  state.changePasswordLoader = true;
  state.forgotPasswordUserRole = action.payload.role;
};
const setChangePassword = (state: InitialState, action: PayloadAction<any>) => {
  state.changePasswordLoader = false;
};
const forgotPassword = (
  state: InitialState,
  action: PayloadAction<EmailInterface>
) => {
  state.forgotPasswordEmail = action.payload.email;
  state.forgotPasswordLoading = true;
};
const setforgotPassword = (state: InitialState, action: PayloadAction<any>) => {
  state.forgotPasswordLoading = false;
};

const emailUnsubscribe = (state: InitialState, action: PayloadAction<any>) => {
  
};

export const existingEmail = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.email = true;
};

export const doesEmailExist = (
  state: InitialState,
  action: PayloadAction<any>
) => {
  state.email = action.payload.data;
};

export const isAlreadyRegistered = (
  state: InitialState,
  action: PayloadAction<CheckEmail>
) => {};

export const subscriptionDetail = (state: InitialState,) => {};

const setSubscriptionDetail = (state: InitialState, action: any) => {
  state.subscriptionDetail = action.payload;
};

export const switchAccount = (
  state: any
) => {
  state.loginLoading = true;
};
export const setSwitchAccount = (state: InitialState, action: PayloadAction<any>) => {
  state.loginLoading = false;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSideNav,
    setTopBatTitle,
    login,
    setlogin,
    logout,
    setAuthenticatedUser,
    checkauth,
    setAuthentication,
    clearAuthentication,
    userSignUp,
    setUserRegisterInitValue,
    setUserData,
    setClientRegister,
    setClientRegisterLoader,
    organizationDetails,
    setOrganizationDetailsLoader,
    sendVerificationEmail,
    changePassword,
    setChangePassword,
    forgotPassword,
    setforgotPassword,
    sendEmailToVerify,
    groupAdminSignUp,
    setGroupAdminSignUp,
    setUserRegDetails,
    setSideNavSelector,
    setSignUpInitialValue,
    setgroupRegisterInitValue,
    existingEmail,
    doesEmailExist,
    isAlreadyRegistered,
    individualUserSignup,
    individualUserSignupLoader,
    resendEmail,
    getIndividualUserPlans,
    setIndividualUserPlans,
    individualUserAddCard,
    setCurrentUserEmail,
    subscriptionDetail,
    setSubscriptionDetail,
    emailUnsubscribe,
    switchAccount
  },
});
export default authSlice;
